import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { UsuarioService } from 'src/app/services/usuario.service';
import { FileItem } from 'src/app/models/file-item';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.css']
})
export class FacturacionComponent implements OnInit {

  public load_data = true;
  public load_data_facturas = true;
  public pagos: Array<any>  = [];
  public filtro: Array<any>  = [];
  public facturas: Array<any>  = [];

  public page = 1;
  public pageSize = 5;

 
  //pagos
  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  public filtro_cliente;
  public filtro_fecha_i;
  public filtro_fecha_f;
  //facturas
  options_f: string[] = [];
  controlFacturas = new FormControl('');
  filteredOptions_f: Observable<string[]>;
  public filtro_cliente_f;
  public filtro_fecha_i_f;
  public filtro_fecha_f_f;
  //pagos clientes
  public clientes : Array<any> = [];
  public opcionSeleccionada = 'Seleccione el cliente';
  public users : Array<any> = [];
  public documentoCliente = '';
  public token;
  estaSobreElemento = false;
  public archivos: FileItem[] = [];
  public fechapago = '';
  public archivosPath = 'pago-canon';
  public pago: any = {
    cliente: 'Seleccione el cliente',
    fecha: new Date().toLocaleString('default'),
    comentarios:""
  };

  //facturas propietarios
  public propietarios : Array<any> = [];
  public detalleFactura: any = {
    propietario: 'Seleccione el propietario'
  };
  public facturaPropietario: FileItem[] = [];
  public isLoadingFactura = false;

  public contrato: any = {};

  constructor(
    private _admin_service: AdminService,
    private _usuarioService : UsuarioService,
    private _cliente: ClienteService 
  ) { this.token = localStorage.getItem('token') }
  buscarPorCliente = this.myControl.value;
  
  //buscarPorFecha = []
  ngOnInit(): void {
    this.consultar_pagos()
    this.consultar_facturas()
    this.listar_usuario();
  }

  registrarPago(){
      let fecha = Date.now();
      this.pago.fecha = new Date(fecha);
      this._cliente.cargarArchivo(this.archivos,this.pago);
  }

  registrarFacturaPropietario(){
    let fecha = Date.now();
    this.detalleFactura.fecha = new Date(fecha);
    this.isLoadingFactura = true;
    this._cliente.cargarFacturaPropietario(this.facturaPropietario, this.detalleFactura)
      .then(()=>{
        this.isLoadingFactura = false;
        this.limpiarArchivos();
      })
}

  limpiarArchivos(){
    this.archivos = [];
    this.facturaPropietario = [];
  }

  async selectChange(){
    this.documentoCliente = this.opcionSeleccionada;
    let cliente = await this.clientes.find(x => x.email == this.pago.cliente);
    if(cliente) this.pago.nombre = cliente.nombres + ' ' + cliente.apellidos;
  }

  filtrarClientes(usuarios: Array<any>){
    for(let usuario of usuarios){

      if(usuario.rol == 'cliente'){
        this.clientes.push(usuario);
      }
    }
  
  }

  filtrarPropietarios(usuarios: Array<any>){
    for(let usuario of usuarios){

      if(usuario.rol == 'propietario'){
        this.propietarios.push(usuario);
      }
    }
  }
  
    listar_usuario()
    {
      this._usuarioService.listar_usuario(this.token).subscribe(
        response =>{
          this.users = response.data;
          this.load_data = false;
          this.filtrarClientes(this.users);
          this.filtrarPropietarios(this.users);
        },
        error =>{
          console.log(error);
        }
      )
    }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filter_f(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options_f.filter(option => option.toLowerCase().includes(filterValue));
  }

  change(){
    console.log(this.filtro_cliente);
    console.log(this.filtro_fecha_i);
    console.log(this.filtro_fecha_f);
    console.log()

  }
  consultar_facturas(){
    this._admin_service.consultar_facturas()
    .then((querySnapshot) => {
      
      querySnapshot.forEach((doc) => {
   
          this.facturas.push(doc.data());
          this.options_f.push(doc.data().cliente);  
      });

      this.load_data_facturas = false;
      this.filteredOptions_f =  this.controlFacturas.valueChanges.pipe(
        startWith(''),
        map(value => this._filter_f(value || '')),
      );
      console.log(this.options)
  })
  .catch((error) => {
    console.log("Error getting documents: ", error);
});
  }
  consultar_pagos(){
    this._admin_service.consultar_pagos("","")
    .then((querySnapshot) => {
      
      querySnapshot.forEach((doc) => {
          
          let pago = doc.data();
          let fecha = new Date(pago.fecha.toDate());
          let dia = fecha.getDate();
          let mes = fecha.toLocaleString('default', {month: 'long', });
          let ano = fecha.getFullYear();
          pago.fecha = dia + '/' + mes + '/' + ano;

          this.pagos.push(pago);

          this.options.push(doc.data().cliente);  
      });

      this.load_data = false;
      this.filtro = this.pagos;
      console.log(this.filtro)
      this.filteredOptions =  this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );

  })
  .catch((error) => {
      console.log("Error getting documents: ", error);
  });
  }
   opciones(){
    for(const opcion of this.pagos){
      this.options.push(opcion.cliente);
    }
  }
  filtrar_cliente(){

  }
  filtrar_fecha(){
    
  }

}
