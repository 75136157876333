import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vigencia'
})
export class VigenciaPipe implements PipeTransform {

  transform(timestamp: any): any {
    let fecha = timestamp.toDate()
    let vigencia = fecha.toLocaleString('default', {month: 'long', }) + ' ' + fecha.getFullYear();
    
    return vigencia;
  }

}
