
<div class="card-header card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Consultas</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo lista todas las consultas</p>

    </div>
</div>

<div class="card box-shadow-lg" style="width:auto;">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;"></h5>
    </div>

    <div class="card-header">
        <div class="row">
            <div class="float-end">
                <label class="fw-bold" for="">Estatus</label>
                <select class="form-control custom-select" [(ngModel)]="filter_status" name="filter_status" (change)="order_by_status()">
                    
                    <option value="Todos">Todos</option>
                    <option value="Nuevo">Nuevo</option>
                    <option value="Procesado">Procesado</option>
                    <option value="Cerrado">Cerrado</option>
                  </select>

            </div>
        </div>

    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                    <tr>

                        <th>Propiedad</th>
                        <th>Fecha</th>
                        <th>Nombres</th>

                        <th>Telefono</th>
                        <th>Email</th>
                        <th>Mensaje</th>
                        <th>Opciones</th>
                    </tr>
                </thead>

                <tbody *ngIf="load_data">
                    <tr>
                        <td class="text-center" colspan="7">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <ng-container *ngIf="!load_data">
                    <tbody *ngFor=" let item of consultas| slice:(page-1) * pageSize: (page-1) * pageSize + pageSize  ;">
                        <tr >


                            <td> {{item.propiedad.titulo}} </td>
                            <td class="text-center"> {{item.createdAt| date :'dd-MMM-yyyy'}} </td>
                            <td> {{item.nombres}} </td>

                            <td> {{item.telefono}} </td>
                            <td> {{item.email}} </td>
                            <td class="text-truncate" style="max-width: 150px;"> {{item.texto}} </td>
                            <td>
                                <div class="btn-group dropdown d-inline-block mb-3 mr-2 ">
                                    <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Opciones <span><i class="bi bi-caret-down-fill"></i></span>
                            </button>
                                    <div class="dropdown-menu">

                                        <a class="dropdown-item" [routerLink]="['/panel/consultas/detalles/',item._id]">Detalles</a>
                                        <a class="dropdown-item" style="cursor: pointer;" (click)="abrirDialogo(item._id, 'Eliminar consulta sobre ' + item.propiedad.titulo)">Eliminar</a>

                                    </div>
                                </div>


                            </td>
                        </tr>
                    </tbody>
                </ng-container>


            </table>
        </div>
    </div>
    <div class="card-footer ">


        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="consultas.length" [maxSize]="4">

        </ngb-pagination>


    </div>
</div>