import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';

@Component({
  selector: 'app-consultar-contrato',
  templateUrl: './consultar-contrato.component.html',
  styleUrls: ['./consultar-contrato.component.css']
})
export class ConsultarContratoComponent implements OnInit {

  public token;
  public usuario:any = {}
  public contratos: Array<any> = []
  public contrato = this.contratos[0];
  public esactivo = false;
  public pdfUrl = this.contrato?.url ?? '';
  public decodedToken;
  public documentoCliente = '';
  public opcionSeleccionada = 'Seleccione el contrato';
  public clientes : Array<any> = [];
  public cargando;

  constructor(
    private _usuarioService: UsuarioService,
    private _imagenesService: CargaImagenesService,
    private _ClienteService: ClienteService) { 

      this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
          

      }

      if (!this.decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
        
      }

    } catch (error) {
      localStorage.removeItem('token');
     
    }
    //console.log(decodedToken.email)

  }

  ngOnInit(): void {
    this.cargando = true;
    this.obtener_usuario();
    this.consultarContrato();
  }

  consultarContrato(){
    this._ClienteService.obtener_contrato(this.decodedToken.email)
    .then((showcontrato) => {
      let index = 0;
      showcontrato.forEach((documento) => {
        let contrato = {
          ...documento.data(),
          nombre: 'Contrato ' + (index + 1)
        }
        this.contratos.push(contrato)
        if(index == 0){
          this.pdfUrl = documento.data().url;
          this.opcionSeleccionada = documento.data().url;
        }
        this.esactivo= true;
        index ++;
      })
      this.cargando = false;
    })
  }

  selectChange(){
    this.pdfUrl = this.opcionSeleccionada;  
  }

  filtrarClientes(usuarios: Array<any>){
    for(let usuario of usuarios){
      if(usuario.rol == 'cliente'){
        this.clientes.push(usuario);
      }
    }
  
  }

  obtener_usuario()
  {
    this._usuarioService.obtener_usuario(this.token).subscribe(
      response => 
      {
        this.usuario = response.data;
        //console.log(this.usuario.email)
      }
    )
  }

}