import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GLOBAL, GLOBALSOCKET } from 'src/app/services/GLOBAL';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { io } from "socket.io-client";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';


declare var iziToast: any;
declare var $: any;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  public consultas  : Array<any> = [];
  public notificacion : Array<any> = [];
  public arr_consultas :  Array<any> = [];
  public token;
  public url;
  public notificaciones;
  public load_data;
  public filtro;
  public fecha;
  public actualizacion;
  public decodedToken;
  public email;
  public filter_status = "Todos";
  public socket = io(GLOBALSOCKET.url,{transports: ['websocket'], upgrade: false});
  public page = 1;
  public pageSize = 10;

  constructor(
    private _adminService: AdminService,
    private _propiedadService : PropiedadService,
    private _ClienteService: ClienteService,

  ) {
    this.token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    this.decodedToken = helper.decodeToken(this.token);
    this.url = GLOBAL.url;
    this.email = 'douglasasesor@gmail.com';
   }

  ngOnInit(): void {
    console.log(this.decodedToken.email);
    this.obtener_notificaciones();
  }

  obtener_notificaciones(){
    this._ClienteService.obtener_notificaciones(this.email).then((showcontrato) => {
      showcontrato.forEach( async(documento) => {
        this.notificaciones = await documento.data()
        this.notificacion.push(this.notificaciones)
        console.log(this.notificacion)
      })
    })
  }
}

