<form #registroForm="ngForm" (ngSubmit)="registro(registroForm)">
    <!--Inicio inventario-->
    <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <br>
                            <label for="">Asunto</label>
                            <input type="text" class="form-control" placeholder="Detallar adecuadamente tu solicitud" required name="asunto" [(ngModel)]="solicitud.asunto">
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-cancelar" (click)="cerrarDialog()">Cancelar</button>
            <button *ngIf="!load_btn" class="btn btn-enviar mr-3" type="submit">Enviar Solicitud</button>
            <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                Creando...
              </button>
        </div>
    </div>
</form>