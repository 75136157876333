<ng-container *ngIf="cargando; else elseTemplate">
    <app-loading></app-loading>
</ng-container>
<ng-template #elseTemplate>
<div class="card-header border-bottom  mt-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Solicitar Certificados</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">
            En este módulo, tendrás la facilidad de solicitar diversos certificados, tales como el certificado de ingresos o el certificado de paz y salvo. Lo único que necesitas hacer es detallar adecuadamente tu solicitud, y un asesor estará disponible para brindarte la asistencia necesaria. Toda esta información quedará almacenada en la plataforma, lo cual te permitirá consultarla y descargarla en el momento que lo requieras.
        </p>
    </div>
</div>
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="col-6 mt-3 mb-2 ml-2">
                <button class="btn btn-enviar" (click)="openDialog()">
                    <span style="font-size: 1.1 rem;">
                        Nueva Solicitud
                        <i *ngIf="false" class="fa fa-solid fa-spinner fa-spin"> </i>
                    </span>
                </button>
            </div>
            <div *ngIf="solicitudcertificado.length > 0" class="card-body pt-0">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col">Asunto</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Detalles</th>
                            </tr>
                        </thead>
                            <tbody>
                                <tr *ngFor="let solicitud of solicitudcertificado">
                                    <td class="text-truncate" style="max-width: 150px;"> {{solicitud.asunto}} </td>
                                    <td> {{solicitud.estado}}</td>
                                    <td> 
                                        <a [routerLink]="['/panel/detalle-certificado', solicitud._id]" class="descargar">
                                            Detalles 
                                            <i class="fa fa-solid fa-eye"></i>
                                        </a> 
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-template>