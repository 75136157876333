import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-grid-asesores',
  templateUrl: './grid-asesores.component.html',
  styleUrls: ['./grid-asesores.component.css']
})
export class GridAsesoresComponent implements OnInit {

  public page = 1;
  public pageSize = 12;
  
  public asesores : Array<any> =[];
  public url;
  public load_data = true;

  public sort_by = '';

  constructor(private _asesorService : ClienteService) { }

  ngOnInit(): void {
    this.obtener_asesores();
  }

  obtener_asesores(){
    this._asesorService.obtener_asesores().subscribe(
      response => {
        this.asesores = response.data;
      },error =>{
        console.log(error);
      }
    )

    this.load_data = false;
  }

}
