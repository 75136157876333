import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CalendarDataModal } from '../../clases/data-modal';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormControl, Validators} from '@angular/forms';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { CalendarEventTitleFormatter,
  CalendarEventTimesChangedEvent,
  CalendarEventTimesChangedEventType,
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
  CalendarUtils

} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { AgendaService } from 'src/app/services/agenda.service';

let cita : CalendarEvent = {
  title: '',
  start: new Date,
  end: new Date
};

@Component({
  selector: 'app-nueva-cita-dialog',
  templateUrl: './nueva-cita-dialog.component.html',
  styleUrls: ['./nueva-cita-dialog.component.css']
})


export class NuevaCitaDialogComponent implements OnInit {

  constructor(
    private agendaService : AgendaService,
    public dialogRef: MatDialogRef<NuevaCitaDialogComponent>,
    @ Inject(MAT_DIALOG_DATA) public data: CalendarDataModal
  ) { }

   colors: Record<string, EventColor> = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    }
  };

  titulo = new FormControl('', [Validators.required]);
  inicio = new FormControl('', [Validators.required]);
  fin = new FormControl('', [Validators.required]);
  prioridad = new FormControl('', [Validators.required]);

  getErrorMessage() {
    
    if (this.inicio.hasError('required')) {
      return 'Este campo es requerido';
    }

    return this.inicio.hasError('inicio') ? 'Ingresa una fecha de inicio correcta' : '';
  }

  agendar(){

    this.agendaService.registrarCita(
          this.titulo.value,
          this.inicio.value,
          this.fin.value,
          this.prioridad.value == 'alta' 
          ? {...this.colors.red }
          : {...this.colors.blue}
    )
  }

  ngOnInit(): void {
    //this.agendaService.consultarCitas();
  }

  cancelar() {
   this.dialogRef.close();
  }

}
