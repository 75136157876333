import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClienteService } from 'src/app/services/cliente.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CrearsolicitudcertificadoComponent } from '../crearsolicitudcertificado/crearsolicitudcertificado.component';

declare var iziToast : any;

@Component({
  selector: 'app-solicitarcertificado',
  templateUrl: './solicitarcertificado.component.html',
  styleUrls: ['./solicitarcertificado.component.css']
})
export class SolicitarcertificadoComponent implements OnInit {
  public cargando;
  public solicitudcertificado: Array<any> = []
  public email;
  public decodedToken;
  public token;

  constructor(
    private _ClienteService: ClienteService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
      }

      if (!this.decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
      }

    } catch (error) {
      localStorage.removeItem('token');
     
    }
    this.ConsultarCertificados();
    
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CrearsolicitudcertificadoComponent, {
      width: '500px',
    });

    // Suscribirse al evento afterClosed para recibir una notificación cuando se cierre el diálogo
    dialogRef.afterClosed().subscribe(result => {
      iziToast.show({
        title: 'Success',
        titleColor: '#1DC74C',
        class: 'text-success',
        color:'#FFF',
        position: 'topRight',
        message:'solicitud registrada correctamente'
  });
      this.ConsultarCertificados();
    });
  }


  cerrarDialog(): void {
    const dialogRef = this.dialog.closeAll();
  }

  ConsultarCertificados() {
    this.cargando = true;
    this._ClienteService.obtener_certificado_prop_by_email(this.decodedToken.email)
      .then((showmantenimiento) => {
        this.solicitudcertificado = showmantenimiento.docs.map(doc => {
          const data = doc.data();
          return {
            _id: doc.id,
            ...data
          };
        });
        console.log(this.solicitudcertificado)
        this.cargando = false
      })
      .catch(error => {
        console.error("Error al obtener los mantenimientos:", error);
      });
  }

}
