import { Component, OnInit,ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal } from '../../clases/data-modal';
import { ModalDialogComponent } from '../../components/modal-dialog/modal-dialog.component';
import Swal from 'sweetalert2';

declare var iziToast:any

@Component({
  selector: 'app-index-admin',
  templateUrl: './index-admins.component.html',
  styleUrls: ['./index-admins.component.css']
})
export class IndexAdminsComponent implements OnInit {

  public token;
  public load_data = true;
  public admins : Array<any> = [];
  public page = 1;
  public pageSize = 5;
  constructor(
    private _adminService : AdminService,
    public dialog: MatDialog
  ) {
    this.token = localStorage.getItem('token');
   }

   abrirDialogo(id: any, titulo: string) {
    const dialogo1 = this.dialog.open(ModalDialogComponent, {
      data: new DataModal(id, titulo, '¿Estás seguro de eliminar este usuario?')
    });

    dialogo1.afterClosed().subscribe(datos => {
    
     this.eliminar(datos.id)
    });
  }

  ngOnInit(): void {
    this.listar_admins();
  }



  listar_admins()
  {
    this._adminService.listar_admins(this.token).subscribe(
      response =>{
        this.admins = response.data;
        this.load_data = false;
        
      },
      error =>{
        console.log(error);
      }
    )
  }
  
  eliminar(id:any)
  {
    this._adminService.eliminar_admin(id,this.token).subscribe(
      response =>{
        Swal.fire({
          icon:'success',
          text:'Usuario eliminado correctamente'
        });
    this.listar_admins();
      },
      error  =>{
        console.log(error);
      }

    )
  }


}
