import { Component, OnInit, ViewEncapsulation,AfterContentInit,  NgZone  } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import {CommonModule} from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal } from '../../../clases/data-modal';
import { ModalDialogComponent } from '../../../components/modal-dialog/modal-dialog.component';
import { FileItem } from 'src/app/models/file-item';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { ActivatedRoute } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import lgZoom from 'lightgallery/plugins/zoom';
import PhotoSwipe from 'photoswipe';

import Swal from 'sweetalert2';

declare var iziToast:any

@Component({
  selector: 'app-generar-requerimientos',
  templateUrl: './generar-requerimientos.component.html',
  styleUrls: ['./generar-requerimientos.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GenerarRequerimientosComponent implements OnInit {
  estaSobreElemento = false;
  public token;


  public decodedToken;
  public mantenimiento;
  public detalle;
  public propiedad = {
    cliente: '',
    inmueble: '',
    detalle:'',
    respuesta: ''
  }
  public codigo;
  public inmueble = {
    titulo: ''
  }
  public cliente;
  public nombreCliente;
  public imagenesMantenimiento: Array<any> =[];
  public documentId;
  public archivosPath = 'evidencias-mantenimiento';
  public documentoCliente = '';
  public load_data = true;
  public solicitudesMantenimiento: Array<any> =[];
  public archivos: FileItem[] = [];
  public propiedades_cliente: Array<any> =[];
  public tieneRequerimiento: boolean;
  public mostrarRequerimientos = true;

  constructor(
    private _usuarioService: UsuarioService,
    private _imagenesService: CargaImagenesService,
    private ngZone: NgZone,
    private _propiedad_service: PropiedadService,
    private _route: ActivatedRoute,
    private _ClienteService: ClienteService,
    private _subirArchivo: CargaImagenesService
    ) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
          

      }

      if (!this.decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
        
      }

    } catch (error) {
      localStorage.removeItem('token');
     
    }

    this.obtener_propiedades_cliente();
    this.consultarMantenimiento();
  }

  verRequerimientos(display){
    console.log('clicked ' + display)
    this.mostrarRequerimientos = display;
  }

  async obtener_propiedades_cliente() {
    this._propiedad_service.obtener_propiedades_cliente(this.decodedToken['uid']).subscribe(propiedades =>{

    this.propiedades_cliente = propiedades.data;
    
 //si en DB existe un requerimiento para esa propiedad mostrarlo
 this.propiedades_cliente.forEach(propiedad =>{
  this.tieneRequerimiento = false;
  this._propiedad_service.obtener_requerimiento_inmueble(propiedad._id)
  .then(requerimiento =>{

    requerimiento.forEach( async(documento) => {
      let doc = await documento.data();
      console.log(doc.id)
    if(doc.inmueble != ""){
    this.tieneRequerimiento = true;
  }
  let mantenimiento = {
    id: doc.id,
    propiedad: propiedad._id,
    titulo: propiedad.titulo,
    tieneMantenimiento: this.tieneRequerimiento
  }
  this.solicitudesMantenimiento.push(mantenimiento)
  this.load_data = false;

    })


    
  })
   

 })
 
  //Almacenar titulo, ID y si tiene requerimientos (bool)
  })
 
  
 }
obtener_requerimiento_inmueble(propiedad: any){
  
 this._propiedad_service.obtener_requerimiento_inmueble(propiedad)

.then(requerimiento =>{

  requerimiento.forEach( async(documento) => {
    let doc = await documento.data();
    //console.log(doc.inmueble)
  if(doc.inmueble != ""){
  this.tieneRequerimiento = true;
}

  })
  
})

}

    consultarMantenimiento(){
      this._ClienteService.obtener_mantenimiento(this.decodedToken.email).then((showcontrato) => {
        showcontrato.forEach( async(documento) => {
          this.mantenimiento = documento.data()
          this.codigo = this.mantenimiento.id;
          this.cliente = this.mantenimiento.cliente;
          this.detalle = this.mantenimiento.detalle;
          console.log(this.codigo)
        })
      })
    }

    cargarDocumento()
  {
    this._subirArchivo.cargarArchivo(this.archivos,this.decodedToken.email, this.archivosPath)
  }

  limpiarArchivos(){
    this.archivos = [];
  }

}
