import { CurrencyPipe, formatCurrency } from '@angular/common';
import { Component, Input, OnInit,Inject,LOCALE_ID } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { FileItem } from 'src/app/models/file-item';
import { ClienteService } from 'src/app/services/cliente.service';
import { GetUsuarioService } from 'src/app/services/get-usuario.service';


@Component({
  selector: 'app-realizar-pago',
  templateUrl: './realizar-pago.component.html',
  styleUrls: ['./realizar-pago.component.css']
})
export class RealizarPagoComponent implements OnInit {

  public imagenes: FileItem[] = [];
  estaSobreElemento = false;
  public usuario: any = {};
  //@Input() usuario: any = {};
  public load_btn = false;
  public contrato: any = {};
  public token;
  public pago: any = {
    comentarios:""
  };
  public decodedToken;
  public load_data = true;
  constructor(
    private _cliente: ClienteService,
    private _getUsuario: GetUsuarioService,
    @Inject(LOCALE_ID) public locale: string) {



     }

  ngOnInit(): void {
   /* console.log(this.usuario)
    this._getUsuario.usuario$.subscribe(usuario => {
      this.usuario = usuario;
      this.consultar_contrato();
      
    })
*/
    this.token = localStorage.getItem('token');
 
 try {
   const helper = new JwtHelperService();
   this.decodedToken = helper.decodeToken(this.token);

 if(helper.isTokenExpired(this.token))
   {
       localStorage.clear();
       

   }

   if (!this.decodedToken) {
     console.log('no acceso')
     localStorage.removeItem('token');
     
   }
  

 } catch (error) {
   localStorage.removeItem('token');
 
 }
  


 this.consultar_contrato(this.decodedToken['email']);
  }


  consultar_contrato(usuario: any){
    this._cliente.obtener_contrato(usuario).then((querySnapshot) => {
      
          querySnapshot.forEach((doc) => {
           //this.contrato = doc.data();
           let fecha = doc.data().fecha.toDate()
           this.pago.valor =  doc.data().valor;
           this.contrato.valor = formatCurrency(doc.data().valor,this.locale,'$')
           this.contrato.nombres = this.decodedToken['nombres'] + ' ' + this.decodedToken['apellidos'];
           this.contrato.vigencia = fecha.toLocaleString('default', {month: 'long', }) + ' ' + fecha.getFullYear();
           this.load_data = false;
              
      });
      
  })
  .catch((error) => {
      console.log("Error getting documents: ", error);
  });
  
  }

  tratar_datos(){
    let fecha = new Date(this.contrato[0].fecha.toDate());
    this.contrato[0].nombres = this.decodedToken['nombres'] + ' ' + this.decodedToken['apellidos'];
    this.contrato[0].valor = formatCurrency(this.contrato.valor,'col','symbol','COP')
    this.contrato[0].vigencia = fecha.toLocaleString('default', {month: 'long', });
   
   console.log(this.contrato)
  }

  limpiarImagenes(){
    this.imagenes = [];
  }

  registrarPago(){
    this.pago.cliente = this.decodedToken['email']; 
       let fecha = Date.now();
      this.pago.fecha = new Date(fecha);
      this._cliente.cargarArchivo(this.imagenes,this.pago);
    console.log(this.pago)
  }
}
