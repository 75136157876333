import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AdminService } from 'src/app/services/admin.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { multi } from './data';
//import { single } from './data2';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent {

  data: any[];
  multi: [];
  view: [number, number] = [700, 300];

  single: any[];
  view2: any[] = [700, 400];

  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels2: boolean = true;
  isDoughnut: boolean = false;

  colorScheme2 = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Día';
  yAxisLabel: string = 'Total';
  timeline: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor (private adminService : AdminService,
               private _fb : FirebaseService) {
  
     this.consultarVisitasAdmin();
    Object.assign(this, { multi });
    //Object.assign(this, { single });
    
  }

  ngOnInit() {
    // Ejecutar la función consultarVisitasAdmin() cada minuto
    setInterval(() => {
      this.consultarVisitasAdmin();
    }, 60000); // 60000 milisegundos = 1 minuto
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  onSelect2(data2): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data2)));
  }

  onActivate2(data2): void {
    console.log('Activate', JSON.parse(JSON.stringify(data2)));
  }

  onDeactivate2(data2): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data2)));
  }

  consultarVisitasAdmin() : any {

    let starCountRef =  this._fb.RTDB.ref('visitas/-NQ8LUvLMHQO43rylqxz');
    starCountRef.once('value', (snapshot) => {
      const data = snapshot.val();
      this.data = data;
      
      const now = new Date();
      //const primerDiaMes = new Date(now.getFullYear(), now.getMonth(), 1);
      if (now.getDate() === 15 && now.getHours() === 0 && now.getMinutes() === 9) {
        const mesAnterior = this.data[1].value;
        const mesActual = this.data[0].value;
        this.data[0].value = mesAnterior;
        this.data[1].value = 0;

        this.actualizarDatosEnFirebase(this.data);
      }


    });
  
  }

  actualizarDatosEnFirebase(data: any[]) {
    this._fb.RTDB.ref('visitas/-NQ8LUvLMHQO43rylqxz').update(data)
      .then(() => {
        console.log('Datos actualizados en Firebase');
      })
      .catch((error) => {
        console.error('Error actualizando datos en Firebase:', error);
      });
  }
}
