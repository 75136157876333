<section class="section-mapa section-t8" id="mapa">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-wrap d-flex justify-content-between">
                    <div class="title-box">
                        <h2 class="title-a">Cómo llegar</h2>
                    </div>

                </div>
            </div>
            <div class="map-responsive mb-3">

                <agm-map [latitude]="lat2" [longitude]="lng2" [zoom]="11" [scrollwheel]="null">
                    <agm-map-type-control></agm-map-type-control>

                    <!-- <agm-marker [latitude]="lat" [longitude]="lng">
                        <agm-info-window #infoWindow>

                        </agm-info-window>
                    </agm-marker> -->

                    <agm-marker [latitude]="lat2" [longitude]="lng2">
                        <agm-info-window #infoWindow>

                        </agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
        </div>
    </div>
</section>