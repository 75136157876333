import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(pagos: any, criterio: any[]): any {
    let buscarPorCliente = [];

    if(criterio == []){
      buscarPorCliente = pagos
    }
    else{
    for(const pago of pagos){
      if(pago.cliente == criterio){
        buscarPorCliente.push(pago);
      }
    }
  }
    return buscarPorCliente;
  }

}
