
<!-- Page title-->
<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Detalles de requerimiento</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted"></p>

    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Detalles</h5>
    </div>

    <div class="card-body">

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <div class="card mb-3" style="width: 100%;" *ngIf="requerimiento">
                    <div class="row g-0">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="card-title text-center pt-3">Gestion De Requerimientos</h5>
                            <div class="card-body text-center">
                                <form #detallesForm="ngForm" (submit)="guardarCambios()">
                                    <div class="col-lg-12 form-group">
                                        <label for="id">Id Requerimiento</label>
                                        <input id="id" class="text-center form-control" readonly name="asunto" [(ngModel)]="requerimiento.id" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="id">Fecha y hora de creación</label>
                                        <input id="id" class="text-center form-control" readonly name="fechaHora" [(ngModel)]="requerimiento.fechaHora" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="Asunto">Asunto</label>
                                        <input id="Asunto" class="text-center form-control" readonly name="asunto" [(ngModel)]="requerimiento.asunto" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="Cliente">Cliente</label>
                                        <input id="Cliente" class="text-center form-control" readonly name="cliente" [(ngModel)]="requerimiento.cliente" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="Propietario">propietario</label>
                                        <input id="Propietario" class="text-center form-control" readonly name="propietario" [(ngModel)]="requerimiento.propietario" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="estado">Estado</label>
                                        <select class="form-control custom-select" id="select-input" required name="tipo" [(ngModel)]="requerimiento.estado">
                                                <option class="text-center" value="Pendiente" >Pendiente</option>
                                                <option class="text-center" value="En Proceso" >En Proceso</option>
                                                <option class="text-center" value="Cerrado" >Cerrado</option>
                                            </select>
                                    </div>

                                    <div class="col-lg-12 form-group">
                                        <label for="detalle">Detalle</label>
                                        <textarea class="form-control text-center" name="detalle" id="detalle" rows="5" readonly [(ngModel)]="requerimiento.detalle"></textarea>
                                    </div>
                                    
                                    <div class="col-lg-12 form-group">
                                        <label for="respuesta">Comentarios</label>
                                        <textarea class="form-control text-center" name="respuesta" id="respuesta" rows="5" [(ngModel)]="requerimiento.respuesta"></textarea>
                                    </div>

                                    <div class="mt-3">
                                        <label for="">Evidencias</label>
                                        <div class="card box-shadow-sm"
                                            style="max-width: 1200px; min-width: auto;">
                                            <div class="card-body">
                                                <div class="wrapper">
                                                    <div class="pswp-gallery" id="gallery-maintenance">
                                                        <a *ngFor="let imagen of imagenes"
                                                            [href]="imagen.url" data-pswp-width="1620"
                                                            data-pswp-height="1080" target="_blank">
                                                            <img [src]="imagen.url" alt="" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    


                                    <div class="card-footer text-center">
                                        <button *ngIf="!load_btn" class="btn btn-enviar mr-3 mt-3" type="submit">Guardar</button>

                                        <button *ngIf="load_btn" type="button" class="btn btn-secondary">
                                                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                                                Guardando
                                              </button>
                                        <button class="btn btn-cancelar mr-3 mt-3" [routerLink]=" ['/panel/listar_requerimientos'] ">Regresar</button>
                                       


                                    </div> 
                                </form>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>






    </div>

</div>