import { Component, OnInit, ChangeDetectorRef, NgZone, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { MapsAPILoader} from '@agm/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UsuarioService } from '../../../../services/usuario.service';
import { Propietario } from '../../../clases/propietario';
import Swal from 'sweetalert2';

declare var $: any;
declare var iziToast:any;
@Component({
  selector: 'app-create-propiedad',
  templateUrl: './create-propiedad.component.html',
  styleUrls: ['./create-propiedad.component.css']
})
export class CreatePropiedadComponent implements OnInit {


  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings = {};
  public file: File = undefined;
  public imgSelect: any | ArrayBuffer = '../../../../../assets/img/01.jpg';
  public config: any = {};
  public asesores : Array<any> = [];
  public propietarios : Array<any> = [];
  public token;
  public load_btn = false;
  public latAca =  6.083
  public lngAca = -75.333
  //public latAca = 16.8638/ 6.083
  //public lngAca =-99.8816 / -75.333
  public zoom =11;
  private geoCoder;
  public propiedad : any = {
    ciudad:'',
    tipo:'',
    oferta:'',
    asesor:'',
    propietario:''

    
    
  };

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    
    private _propiedadService : PropiedadService,
    private _adminService : AdminService,
    private _router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _usuarioService: UsuarioService
  ) {
    this.token = this._adminService.getToken();
    this.config = {
      height : 500 
    }
    
   }

  ngOnInit(): void {
  
    //consultar asesores
    this._propiedadService.consultar_asesores().subscribe(asesores =>{
    this.asesores = asesores.data;
    })

    this._usuarioService.listar_usuario(this.token).subscribe(propietarios =>{
      propietarios.data = propietarios.data.filter(propietario => propietario.rol == 'propietario')
      let usuario = new Propietario(propietarios.data)
      
      this.propietarios = usuario.propietarios;

      
    })

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latAca = place.geometry.location.lat();
          this.lngAca= place.geometry.location.lng();
          this.zoom = 12;

        });
      });
    });
    


   /* this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];*/
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'uid',
      textField: 'nombreCompleto',
      //selectAllText: 'Select All',
     // unSelectAllText: 'UnSelect All',
     enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true
    };


  }
  onItemSelect(item: any) {
    console.log(item);
  }

  addMarker($event){
    this.latAca = $event.coords.lat;
    console.log(this.latAca);
    console.log(this.lngAca);
    this.lngAca = $event.coords.lng;
    this.propiedad.lat = this.latAca;
    console.log(this.propiedad.lat);
    this.propiedad.long = this.lngAca;
    console.log(this.lngAca);

  }

  markerDragEnd($event) {
    this.latAca = $event.coords.lat;
    console.log(this.latAca);
    this.lngAca = $event.coords.lng;
    console.log(this.lngAca);
    this.propiedad.lat = this.latAca;
    console.log(this.propiedad.lat);
    this.propiedad.long = this.lngAca;
    console.log(this.lngAca);

    
  }

  /*onItemSelect(item: any) {
    //this.propiedad.propietario = item.uid;
    console.log(item);
    console.log(this.propiedad)
  }*/
  /*onSelectAll(items: any) {
    console.log(items);
  }
*/
  registro(registroForm:any)
  {
    if(registroForm.valid)
    {
     if(this.file == undefined){
      Swal.fire({
        icon:'error',
        text:'Debes subir una imagen'
      });
     }

      this.load_btn = true;
  
        this._propiedadService.registro_propiedad_admin(this.propiedad,this.file,this.token).subscribe(
          response  =>{
            Swal.fire({
              icon:'success',
              text:'Propiedad registrada correctamente'
            });
            
            this.load_btn = false;
            this._router.navigate(['/panel/propiedades'])
  
          },
          error  => {
            console.log(error);
            this.load_btn = false;
          }
        )
      }else{
        Swal.fire({
          icon:'error',
          text:'Los datos de formulario no son validos'
        });
      this.load_btn = false;
      this.imgSelect = '../../../../../assets/img/01.jpg';
      this.file = undefined;
    
    }

    }

fileChangeEvent(event:any):void
{
   var file : any;
   if(event.target.files && event.target.files[0]){

    file = <File>event.target.files[0];
    console.log(file);

   } else{
    Swal.fire({
      icon:'error',
      text:'No hay una imagen'
    });

   }

   if(file.size  <= 8000000){

    if(file?.type== 'image/png' || file?.type == 'image/web' || file?.type == 'image/jpg' || file?.type == 'image/gif' || file?.type == 'image/jpeg')
    {
      const reader = new FileReader();
      reader.onload = e  => this.imgSelect = reader.result;

      reader.readAsDataURL(file); 

    

      this.file = file; 

    }else{
      Swal.fire({
        icon:'error',
        text:'El archivo debe ser una imagen'
      });

    this.imgSelect = '../../../../../assets/img/01.jpg';
    this.file = undefined;

    }

   }else{
    Swal.fire({
      icon:'error',
      text:'La imagen no puede superar los 8MB'
    });

  this.imgSelect = '../../../../../assets/img/01.jpg';
  this.file = undefined;

   }


}

}
