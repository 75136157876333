import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClienteService } from 'src/app/services/cliente.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detallecertificados',
  templateUrl: './detallecertificados.component.html',
  styleUrls: ['./detallecertificados.component.css']
})
export class DetallecertificadosComponent implements OnInit {

  public cargando = false;
  public decodedToken;
  public token;
  public solicitudcertificado: Array<any> = []
  public idsolicitud;
  public certificado : any = {

  };

  constructor(
    private _router: Router,
    private _ClienteService: ClienteService,
    private location: Location,
    private router: Router,
    public _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
      }

      if (!this.decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
      }

    } catch (error) {
      localStorage.removeItem('token');
     
    }
    this.consultarsolicitudporid();
  }

  consultarsolicitudporid(){
    this._route.params.subscribe(params => {
      this.idsolicitud = params['id'];
      this._ClienteService.obtener_Certificado_by_id(this.idsolicitud).then(mantenimiento => {
        if (mantenimiento) {
          this.certificado = mantenimiento
          console.log(this.certificado)
        } else {
          console.error("No se encontró ningún mantenimiento con el ID especificado.");
        }
      }).catch(error => {
        console.error("Error al obtener el mantenimiento:", error);
      });
    });
  }

  goBackOrToPanel(): void {
    if (this.location.getState()) {
      this.location.back();
    } else {
      this.router.navigate(['/panel']);
    }
  }

}
