
<!-- Page title-->
<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Propiedades</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted"></p>

    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Imagenes del inmueble</h5>
    </div>

    <div class="card-body">
        <div style="max-width: 48rem;">


            <div class="row mb-5 ms-5">
                <div class="col-md-4" style="padding-right: 0px;">
                    <!-- Square thumbnail -->
                    <img [src]="propiedad.portada" class="img-thumbnail rounded-0" alt="Square image">
                </div>
                <div class="col-md-8" style="padding-left:0px;">
                    <div class="card" style="height:100% !important;background: white;">
                        <div class="card-body">
                            <h3 class="card-product-title text-truncate mb-2">
                                <a class="nav-link mb-3" href="#"> {{propiedad.titulo}} </a>
                                <p class="text-muted"> {{propiedad.descripcion}} </p>
                                <div class="d-flex align-items-center">
                                    <span class="h5 d-inline-block mb-0 text-danger"> {{propiedad.precio | currency}} </span>

                                </div>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">

                <div class="col-md-9">

                </div>
            </div>


            <div class="form-group row align-items-center ms-5">


                <div class="col-md-9">
                    <!-- Button addon on the right -->
                    <div class="input-group">
                        <input id="input-img" class="form-control" (change)="fileChangeEvent($event)" type="file">
                        <div class="input-group-append">
                            <ng-container *ngIf="file != undefined">
                                <button class="btn btn-primary" type="button" (click)="subir_imagen()">Subir</button>
                                <button *ngIf="load_btn" type="button" class="btn btn-primary">
                                      <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                                      Subiendo...
                                    </button>
                            </ng-container>
                            <ng-container *ngIf="file == undefined">
                                <button class="btn btn-primary" type="button" disabled>Subir</button>
                            </ng-container>
                        </div>
                    </div>

                </div>

                <div class="col-md-3">

                </div>
                <div class="col-md-9 ">
                    <div class="table-responsive mt-3">
                        <table class="table table-sm table-bordered" *ngIf="propiedad.galeria">
                            <thead class="thead-dark">
                                <tr>

                                    <th>Imágen</th>
                                    <th>Eliminar</th>

                                </tr>
                            </thead>

                            <ng-container *ngIf="propiedad.galeria.length == 0">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="text-center">
                                            <span class="text-muted">NO HAY IMAGENES DISPONIBLES</span>
                                        </td>

                                    </tr>
                                </tbody>
                            </ng-container>



                            <ng-container *ngIf="propiedad.galeria.length >= 1">
                                <tbody *ngFor="let item of propiedad.galeria; let indice  = index">

                                    <tr>
                                        <td>
                                            <p>
                                                <img [src]="item.imagen" style="width: 80px;" alt="">

                                            </p>
                                        </td>
                                        <td> <a style="cursor:pointer" class="text-danger" data-bs-toggle="modal" [attr.data-bs-target]="'#delete-'+item._id">[ELIMINAR]</a> </td>

                                    </tr>

                                    <div class="modal fade" id="delete-{{item._id}}" tabindex="-1" style="display: none;" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title">Eliminar esta imagen ? <img [src]="item.imagen" style="width: 80px;" alt=""> </h4>
                                                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>¿Deseas eliminar esta imágen?</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button class="btn btn-cancelar btn-sm" type="button" data-bs-dismiss="modal">Cancelar</button>
                                                    <button class="btn btn-enviar btn-sm" data-bs-dismiss="modal" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </tbody>
                            </ng-container>

                        </table>
                    </div>
                </div>



            </div>

        </div>

    </div>
    <div class="card-footer">

        <button class="btn btn-cancelar" [routerLink]=" ['/panel/propiedades'] ">Regresar</button>
        <!-- Button with growing spinner and text -->
        <button *ngIf="load_btn" type="button" class="btn btn-enviar">
                  <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                  Guardando
                </button>

    </div>
</div>