import {  Routes } from '@angular/router';
import { CreateAdminComponent } from './admins/create-admin/create-admin.component';
import { IndexAdminsComponent } from './admins/index-admins/index-admins.component';
import { FacturacionComponent } from './admins/facturacion/facturacion.component';
import { CargarFacturaComponent } from './admins/cargar-factura/cargar-factura.component';
import { AdminGuard } from '../guards/admin.guard';
import { DetalleConsultaComponent } from './components/consultas/detalle-consulta/detalle-consulta.component';
import { IndexConsultasComponent } from './components/consultas/index-consultas/index-consultas.component';
import { InicioComponent } from '../pages/inicio/inicio.component';
import { IndexPropiedadesComponent } from './components/propiedades/index-propiedades/index-propiedades.component';
import { CreatePropiedadComponent } from './components/propiedades/create-propiedad/create-propiedad.component';
import { GaleriaPropiedadComponent } from './components/propiedades/galeria-propiedad/galeria-propiedad.component';
import { UpdatePropiedadComponent } from './components/propiedades/update-propiedad/update-propiedad.component';
import { SubirContratoComponent } from './Roles/asesor/subir-contrato/subir-contrato.component';
import { CrearClienteComponent } from './Roles/asesor/crear-cliente/crear-cliente.component';
import { MostrarClientesComponent } from './Roles/asesor/mostrar-clientes/mostrar-clientes.component';
import { ConsultarContratoComponent } from './Roles/cliente/consultar-contrato/consultar-contrato.component';
import { ConsultarInventarioComponent } from './Roles/cliente/consultar-inventario/consultar-inventario.component';
import { DocumentosComponent } from './Roles/cliente/documentos/documentos.component';
import { PagosComponent } from './Roles/cliente/pagos/pagos.component';
import { RealizarPagoComponent } from './Roles/cliente/realizar-pago/realizar-pago.component';
import { CrearRequerimientosComponent } from './Roles/propietario/crear-requerimientos/crear-requerimientos.component';
import { GenerarRequerimientosComponent } from './Roles/cliente/generar-requerimientos/generar-requerimientos.component';
import { IndexMensajesComponent } from './components/mensajes/index-mensajes/index-mensajes.component';
import { IndexRequerimientosComponent } from './Roles/propietario/index-requerimientos/index-requerimientos.component';
import { AsesoresComponent } from '../components/asesores/asesores.component';
import { CreateAsesorsComponent } from './admins/create-asesors/create-asesors.component';
import { SubirContratoPropietarioComponent } from './Roles/asesor/subir-contrato-propietario/subir-contrato-propietario.component';
import { ConsultarContratoPropietarioComponent } from './Roles/propietario/consultar-contrato-propietario/consultar-contrato-propietario.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ReportesComponent } from './components/reportes/reportes.component'
import { AgendaComponent } from './Roles/asesor/agenda/agenda.component';
import { MostrarInventarioComponent } from './Roles/asesor/mostrar-inventario/mostrar-inventario.component';
import { MostrarPagosCanonComponent } from './Roles/asesor/mostrar-pagos-canon/mostrar-pagos-canon.component';
import { CambiarPasswordComponent } from '../panel/components/cambiar-password/cambiar-password.component';
import { UpdateClienteComponent } from './Roles/asesor/update-cliente/update-cliente.component';
import { ConsultarInventarioPropietarioComponent } from './Roles/propietario/consultar-inventario-propietario/consultar-inventario-propietario.component';
import { UpdateAsesorsComponent } from './admins/update-asesors/update-asesors.component';
import { VerRequerimientosComponent } from './Roles/cliente/ver-requerimientos/ver-requerimientos.component';
import { RequerimientosComponent } from './Roles/asesor/requerimientos/requerimientos.component';
import { DetalleRequerimientoComponent } from './Roles/asesor/detalle-requerimiento/detalle-requerimiento.component';
import { SolicitarcertificadoComponent } from './Roles/propietario/solicitarcertificado/solicitarcertificado.component';
import { CrearsolicitudcertificadoComponent } from './Roles/propietario/crearsolicitudcertificado/crearsolicitudcertificado.component';
import { DetallecertificadosComponent } from './Roles/propietario/detallecertificados/detallecertificados.component';
import { GestionarCertificadosComponent } from './Roles/asesor/gestionar-certificados/gestionar-certificados.component';
import { DetalleCertificadoComponent } from './Roles/asesor/detalle-certificado/detalle-certificado.component';

export const panelRoutes: Routes = [
 {path:'admins',component:IndexAdminsComponent,canActivate:[AdminGuard]},
 {path:'asesores',component:CreateAsesorsComponent,canActivate:[AdminGuard]},
 {path:'asesores/:id',component:UpdateAsesorsComponent,canActivate:[AdminGuard]},
 {path:'admins/registro',component:CreateAdminComponent,canActivate:[AdminGuard]},
 {path:'facturacion', component:FacturacionComponent,canActivate:[AdminGuard]},
 {path:'facturacion/cargar_factura',component:CargarFacturaComponent,canActivate:[AdminGuard]},
 {path:'consultas/detalles/:id',component:DetalleConsultaComponent,canActivate:[AdminGuard]},
 {path:'consultas',component:IndexConsultasComponent,canActivate:[AdminGuard]},
 {path:'inicio',  component:InicioComponent,  canActivate:[AdminGuard]},
 {path:'propiedades',component:IndexPropiedadesComponent,canActivate:[AdminGuard]},
 {path:'propiedades/registro',component:CreatePropiedadComponent,canActivate:[AdminGuard]},
 {path:'propiedades/galeria/:id',component:GaleriaPropiedadComponent,canActivate:[AdminGuard]},
 {path:'propiedades/:id',component:UpdatePropiedadComponent,canActivate:[AdminGuard]},
 {path:'subir_contrato',component:SubirContratoComponent,canActivate:[AdminGuard]},
 {path:'subir_contrato_propietario',component:SubirContratoPropietarioComponent,canActivate:[AdminGuard]},
 {path:'clientes/registro',component: CrearClienteComponent,canActivate:[AdminGuard]},
 {path:'clientes',component:MostrarClientesComponent,canActivate:[AdminGuard]},
 {path:'consultar_contrato',component:ConsultarContratoComponent,canActivate:[AdminGuard]},
 {path:'consultar_contrato_propietario',component:ConsultarContratoPropietarioComponent,canActivate:[AdminGuard]},
 {path:'consultar_inventario_clientes',component:ConsultarInventarioComponent,canActivate:[AdminGuard]},
 {path:'documentos',component: DocumentosComponent,canActivate:[AdminGuard]},
 {path:'documentos/descargar_documentos',component: DocumentosComponent,canActivate:[AdminGuard]},
 {path:'pagos',component:PagosComponent, canActivate:[AdminGuard]},
 {path:'pagos/realizar-pago', component: RealizarPagoComponent, canActivate:[AdminGuard]},
 {path:'requerimientos',component: IndexRequerimientosComponent,canActivate:[AdminGuard]},
 {path:'ver_requerimientos',component: VerRequerimientosComponent,canActivate:[AdminGuard]},
 {path:'generar_requerimientos',component: GenerarRequerimientosComponent,canActivate:[AdminGuard]},
 {path:'detalle_requerimiento/:id',component: CrearRequerimientosComponent,canActivate:[AdminGuard]},
 {path:'mensajes',component:IndexMensajesComponent,canActivate:[AdminGuard]},
 {path: 'BI' ,component:ReportesComponent, canActivate:[AdminGuard]},
 {path:'notificaciones',component:NotificationsComponent,canActivate:[AdminGuard]},
 {path:'agenda',component: AgendaComponent ,canActivate:[AdminGuard]},
 {path:'ver_Inventarios',component: MostrarInventarioComponent,canActivate:[AdminGuard]},
 {path:'mostrar_pagos_canon',component: MostrarPagosCanonComponent,canActivate:[AdminGuard]},
 {path:'cambiar-password',component: CambiarPasswordComponent,canActivate:[AdminGuard]},
 {path:'clientes/:id', component: UpdateClienteComponent,canActivate:[AdminGuard]},
 {path:'consultar_inventario_propietario', component: ConsultarInventarioPropietarioComponent, canActivate:[AdminGuard]},
 {path:'listar_requerimientos', component: RequerimientosComponent, canActivate:[AdminGuard]},
 {path:'detalle-requerimiento/:id', component: DetalleRequerimientoComponent, canActivate:[AdminGuard]},
 {path:'solicitar-certificados', component: SolicitarcertificadoComponent, canActivate:[AdminGuard]},
 {path:'crear-solicitud-certificado', component: CrearsolicitudcertificadoComponent, canActivate:[AdminGuard]},
 {path:'detalle-certificado/:id', component: DetallecertificadosComponent, canActivate:[AdminGuard]},
 {path:'gestionar-certificados', component: GestionarCertificadosComponent, canActivate:[AdminGuard]},
 {path:'detallecertificados/:id', component: DetalleCertificadoComponent, canActivate:[AdminGuard]},

  ];
