import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';

declare var iziToast:any
@Component({
  selector: 'app-detalle-requerimiento',
  templateUrl: './detalle-requerimiento.component.html',
  styleUrls: ['./detalle-requerimiento.component.css']
})
export class DetalleRequerimientoComponent implements OnInit {

  public id : any;
  public requerimiento : any = {

  };
  public imagenes : any [];
  public load_btn = false;

  constructor(
    private _router: Router,
    private _ClienteService: ClienteService,
    public _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ObtenerRequerimiento();
  }

  ObtenerRequerimiento() {
    this._route.params.subscribe(params => {
      this.id = params['id'];
      this._ClienteService.obtener_mantenimiento_by_id(this.id).then(mantenimiento => {
        if (mantenimiento) {
          this.requerimiento = mantenimiento
          console.log(this.requerimiento)
        } else {
          console.error("No se encontró ningún mantenimiento con el ID especificado.");
        }
      }).catch(error => {
        console.error("Error al obtener el mantenimiento:", error);
      });
      // Obtener las evidencias
      this._ClienteService.obtener_evidencias_requerimiento(this.id)
      .then(querySnapshot => {
        this.imagenes = [];
        querySnapshot.forEach(doc => {
          this.imagenes.push(doc.data()); // Agregar cada evidencia al array de imágenes
        });
        console.log(this.imagenes);
      })
      .catch(error => {
        console.error("Error al obtener las evidencias:", error);
      });
    });
  }

  guardarCambios(){

    this._route.params.subscribe(params => {
      this.id = params['id'];
      this._ClienteService.actualizar_requerimiento(this.id, this.requerimiento)
      .then(() => {
              iziToast.show({
                title: 'Success',
                titleColor: '#1DC74C',
                class: 'text-success',
                color:'#FFF',
                position: 'topRight',
                message:'Estatus actualizado'
                });
      })
      .catch(error => {
        console.error('Error al actualizar el requerimiento:', error);
      });
    });
  }
}
