
<div class="card-header card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Requerimientos</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo lista todas las requerimientos de los clientes en los inmuebles</p>

    </div>
</div>

<div class="card box-shadow-lg" style="width:auto;">
    <div class="card-header">
        <div class="row">
            <div class="float-end">
                <label class="fw-bold" for="">Estatus</label>
                <select class="form-control custom-select" [(ngModel)]="filter_status" name="filter_status" (change)="order_by_status()">
                    
                    <option value="Todos">Todos</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="En Proceso">En Proceso</option>
                    <option value="Cerrado">Cerrado</option>
                  </select>

            </div>
        </div>

    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                    <tr>

                        <th># Solicitud</th>
                        <th>Asunto</th>
                        <th>Detalle</th>
                        <th>Estado</th>
                        <th>Cliente</th>
                        <th>Propietario</th>
                        <th>Opciones</th>
                    </tr>
                </thead>

                <tbody *ngIf="load_data">
                    <tr>
                        <td class="text-center" colspan="7">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <ng-container *ngIf="!load_data">
                    <tbody *ngFor=" let item of mantenimientos| slice:(page-1) * pageSize: (page-1) * pageSize + pageSize  ;">
                        <tr >


                            <td> {{item.id}} </td>
                            <td class="text-truncate" style="max-width: 150px;"> {{item.asunto}} </td>
                            <td class="text-truncate" style="max-width: 150px;"> {{item.detalle}} </td>
                            <td> {{item.estado}} </td>
                            <td class="text-truncate" style="max-width: 150px;"> {{item.cliente}} </td>
                            <td class="text-truncate" style="max-width: 150px;"> {{item.propietario}} </td>
                            <td>
                                <div class="btn-group dropdown d-inline-block mb-3 mr-2 ">
                                    <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Opciones <span><i class="bi bi-caret-down-fill"></i></span>
                            </button>
                                    <div class="dropdown-menu">

                                        <a class="dropdown-item" [routerLink]="['/panel/detalle-requerimiento/',item._id]">Detalles</a>

                                    </div> 
                                </div>


                            </td>
                        </tr>
                    </tbody>
                </ng-container>


            </table>
        </div>
    </div>
    <div class="card-footer ">


         <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="mantenimientos.length" [maxSize]="4"> 

        </ngb-pagination> 


    </div> 
</div>