
<!-- Page title-->
<div class="card-header card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Cambiar Contraseña</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">En este modulo podrás cambiar tu contraseña</p>

    </div>
</div>

<form #cambiarpasswordform="ngForm"(ngSubmit)="changepsw(cambiarpasswordform)">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">

                        <div class="col-lg-12 form-group">
                            <label for="">Correo</label>
                            <input type="text" class="form-control" [(ngModel)]="emailuser" placeholder="Nombres" required name="correo" readonly>
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Contraseña Anterior</label>
                            <input type="password" class="form-control" [(ngModel)]="user.oldpassword" placeholder="Contraseña" required name="oldpassword">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Nueva Contraseña</label>
                            <input type="password" class="form-control" [(ngModel)]="user.password" placeholder="Contraseña" required name="password">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Confirmar Contraseña</label>
                            <input type="password" class="form-control" [(ngModel)]="user.confirmpassword" placeholder="Contraseña" required name="confirmpassword">
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button *ngIf="!load_btn" class="btn btn-enviar mr-3" type="submit">Cambiar Contraseña</button>
            <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                Cambiando contraseña...
              </button>
            <button class="btn btn-cancelar" [routerLink]=" ['/panel/admins'] ">Regresar</button>
        </div>



</form>