<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Actualizar usuario del sistema</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">


    </div>


</div>

<form #actualizarForm="ngForm" (ngSubmit)="actualizar(actualizarForm)">
    <div class="card box-shadow-sm">
        <div class="card-header">
            <h5 style="margin-bottom: 0px;">Información del usuario</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">

                        <div class="col-lg-12 form-group">
                            <label for="">Nombres</label>
                            <input type="text" class="form-control" placeholder="Nombres" required name="nombres" [(ngModel)]="usuario.nombres">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Apellidos</label>
                            <input type="text" class="form-control" placeholder="Apellidos" required name="Apellidos" [(ngModel)]="usuario.apellidos">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Email</label>
                            <input type="text" class="form-control" placeholder="email" required name="email" [(ngModel)]="usuario.email">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Teléfono</label>
                            <input type="tel" class="form-control" placeholder="Teléfono" required name="telefono" [(ngModel)]="usuario.telefono">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Rol</label>
                            <select name="rol" class="form-control" [(ngModel)]="usuario.rol" id="">
                            <option value="" selected disabled>Elige rol</option>
                            <option value="cliente">Cliente</option>
                            <option value="propietario">Propietario</option>
                        </select>
                        </div>



                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button *ngIf="!load_btn" class="btn  mr-3 btn-enviar" type="submit">Actualizar</button>
            <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                Actualizando...
              </button>
            <button class="btn btn-cancelar" [routerLink]=" ['/panel/clientes'] ">Regresar</button>
        </div>
    </div>



</form>