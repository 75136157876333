import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileItem } from '../models/file-item';
import { GLOBAL } from './GLOBAL';
import { Observable, Timestamp } from 'rxjs';

import { on } from 'events';
import { FirebaseService } from './firebase.service';
import { resolve } from 'dns';
import { rejects } from 'assert';

declare var iziToast: any



@Injectable({
  providedIn: 'root'
})
export class CargaImagenesService {

  private CARPETA_DOCUMENTOS = '';
  public url;

  constructor(

    private _http: HttpClient,
    private _fb: FirebaseService
  ) {

    this.url = GLOBAL.url;
  }
  // Inventario

  private guardarImagen(imagen: { nombre: string, url: string, cliente: string }) {
    //var CARPETA_DOCUMENTOS = 'inventario';
    this._fb.db.collection(`${this.CARPETA_DOCUMENTOS}`).add(imagen)
  }

  private guardarImagenInv(imagen: { nombre: string, url: string, cliente: string, propietario: string, UUID?: string }) {
    //var CARPETA_DOCUMENTOS = 'inventario';
    this._fb.db.collection(`${this.CARPETA_DOCUMENTOS}`).add(imagen)
  }

  private guardarPDFInv(imagen: { nombre: string, url: string, cliente: string, propietario: string }) {
    //var CARPETA_DOCUMENTOS = 'inventario';
    this._fb.db.collection(`${this.CARPETA_DOCUMENTOS}`).add(imagen)
  }

  private guardarImagenCanon(imagen: { nombre: string, url: string, cliente: string, fecha: string }) {
    //var CARPETA_DOCUMENTOS = 'inventario';
    this._fb.db.collection(`${this.CARPETA_DOCUMENTOS}`).add(imagen)
  }

  //cargarArchivo(archivos: FileItem[], token: any): Observable<any>{
  cargarArchivo(archivos: FileItem[], documentoCliente: any, carpetaDocumentos: string) {

    this.CARPETA_DOCUMENTOS = carpetaDocumentos;
    var storage = this._fb.firebase.storage().ref();

    for (let item of archivos) {
      item.estaSubiendo = true;
      if (item.progreso >= 100) {
        continue;
      }
      const uploadTask = storage.child(`${this.CARPETA_DOCUMENTOS}/${documentoCliente}/${item.nombreArchivo}`)
        .put(item.archivo)
      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log('error subiendo los archivos', error)
        },
        () => {
          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color: '#FFF',
            position: 'topRight',
            message: 'Se ha cargado correctamente'

          });


          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {


            item.url = await downloadURL;
            this.guardarImagen({
              nombre: item.nombreArchivo,
              url: item.url,
              cliente: documentoCliente
            })
          });
          item.estaSubiendo = false;

        });
    }

  }

  //Cargar imagenes de asesores

  cargarImagenesAsesores(archivos: FileItem[], documentoCliente: any, carpetaDocumentos: string): Promise<string[]> {
    this.CARPETA_DOCUMENTOS = carpetaDocumentos;
    const storage = this._fb.firebase.storage().ref();
    const promises: Promise<string>[] = [];

    for (const item of archivos) {
      item.estaSubiendo = true;

      if (item.progreso >= 100) {
        continue;
      }

      const uploadTask = storage.child(`${this.CARPETA_DOCUMENTOS}/${documentoCliente}/${item.nombreArchivo}`)
        .put(item.archivo);

      const promise = new Promise<string>((resolve, reject) => {
        uploadTask.on('state_changed',
          (snapshot) => {
            // Handle progress
            item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.log('error subiendo los archivos', error);
            reject(error);
          },
          () => {
            // Handle completion
            uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
              item.url = await downloadURL;
              this.guardarImagen({
                nombre: item.nombreArchivo,
                url: item.url,
                cliente: documentoCliente
              });
              item.estaSubiendo = false;
              resolve(item.url);
            }).catch((error) => {
              console.log('error obteniendo la URL de descarga', error);
              reject(error);
            });
          });
      });

      promises.push(promise);
    }

    return Promise.all(promises);
  }


  //Cargar Inventario Cliente y propietario

  cargarInventario(archivos: FileItem[], documentoCliente: any, documentoPropietario: any, carpetaDocumentos: string, UUID?: string) {

    this.CARPETA_DOCUMENTOS = carpetaDocumentos;
    var storage = this._fb.firebase.storage().ref();

    for (let item of archivos) {
      item.estaSubiendo = true;
      if (item.progreso >= 100) {
        continue;
      }
      const uploadTask = storage.child(`${this.CARPETA_DOCUMENTOS}/${documentoCliente}/${item.nombreArchivo}`)
        .put(item.archivo)
      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log('error subiendo los archivos', error)
        },
        () => {
          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color: '#FFF',
            position: 'topRight',
            message: 'Inventario Cargado correctamente'

          });


          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {


            item.url = await downloadURL;
            if (UUID) {
              this.guardarImagenInv({
                nombre: item.nombreArchivo,
                url: item.url,
                cliente: documentoCliente,
                propietario: documentoPropietario,
                UUID: UUID
              })
            } else {
              this.guardarPDFInv({
                nombre: item.nombreArchivo,
                url: item.url,
                cliente: documentoCliente,
                propietario: documentoPropietario
              })
            }

          });
          item.estaSubiendo = false;

        });
    }

  }

  //Cargar Archivos por fecha
  cargarArchivoPorFecha(archivos: FileItem[], documentoCliente: any, carpetaDocumentos: string, fechacarpeta: string) {

    this.CARPETA_DOCUMENTOS = carpetaDocumentos;
    var storage = this._fb.firebase.storage().ref();

    for (let item of archivos) {
      item.estaSubiendo = true;
      if (item.progreso >= 100) {
        continue;
      }
      const uploadTask = storage.child(`${this.CARPETA_DOCUMENTOS}/${documentoCliente}/${fechacarpeta}/${item.nombreArchivo}`)
        .put(item.archivo)
      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log('error subiendo los archivos', error)
        },
        () => {
          console.log('imagen cargada correctamente');


          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {


            item.url = await downloadURL;
            this.guardarImagenCanon({
              nombre: item.nombreArchivo,
              url: item.url,
              cliente: documentoCliente,
              fecha: fechacarpeta
            })
          });
          item.estaSubiendo = false;

        });
    }

  }

  obtener_imagenes(cliente: string): Promise<any> {

    let imagenesRef = this._fb.db.collection("imagenes");
    let query = imagenesRef.where("cliente", "==", cliente)
      .get()
    return query
  }

  obtener_imagenes_inventario(UUID: string): Promise<any> {
    let imagenesRef = this._fb.db.collection("imagenes");
    let query = imagenesRef.where("UUID", "==", UUID)
      .get()
    return query
  }

  obtener_imagenes_propietario(propietario: string): Promise<any> {

    let imagenesRef = this._fb.db.collection("imagenes");
    let query = imagenesRef.where("propietario", "==", propietario)
      .get()
    return query
  }

  eliminar_imagenes_inventario(cliente: string, imagenes: any[]): Promise<any> {
    let imagenesRef = this._fb.db.collection("imagenes");
    let archivoPromesas: Promise<any>[] = [];

    // Eliminar archivos de Firebase Storage
    imagenes.forEach(imagen => {
      const url = `imagenes/${cliente}/${imagen.nombreArchivo}`
      console.log(url)
      const eliminarArchivo = this._fb.storage.ref().child(url).delete();
      archivoPromesas.push(eliminarArchivo);
    });

    // Eliminar registros de Firebase Firestore
    imagenes.forEach(imagen => {
      const eliminarRegistro = imagenesRef.doc(imagen.id).delete();
      archivoPromesas.push(eliminarRegistro);
    });

    // Esperar a que se completen todas las promesas
    return Promise.all(archivoPromesas);
  }


  obtener_contrato(cliente: string): Promise<any> {

    let contratoRef = this._fb.db.collection("Contratos");
    let query = contratoRef.where("cliente", "==", cliente)
      .get()
    return query
  }

  obtener_inventario(cliente: string): Promise<any> {
    let inventarioRef = this._fb.db.collection("inventario");
    let query = inventarioRef.where("cliente", "==", cliente)
      .get()
    return query
  }

  obtener_inventario_propietario(propietario: string): Promise<any> {
    let inventarioRef = this._fb.db.collection("inventario");
    let query = inventarioRef.where("propietario", "==", propietario)
      .get()
    return query
  }

  eliminar_inventario(cliente: string, nombreArchivo: string, documento: string): Promise<any> {
    const url = `inventario/${cliente}/${nombreArchivo}`
    const desertRef = this._fb.storage.ref().child(url);
    const archivoRef = this._fb.db.collection("inventario");

    // Eliminar archivo y eliminar registro en paralelo
    const eliminarPromesas = Promise.all([
      desertRef.delete(),
      archivoRef.doc(documento).delete()
    ]);

    return eliminarPromesas;
  }

  obtener_pago_canon(cliente: string, fecha: string): Promise<any> {

    let inventarioRef = this._fb.db.collection("pago-canon");
    let query = inventarioRef.where("cliente", "==", cliente)
    let document = query.where("fecha", "==", fecha)
      .get()
    return document
  }

  async guardar_mantenimiento(requerimiento: {
    asunto: string,
    detalle: string,
    cliente: string,
    propietario: string
  }, archivos: FileItem[]): Promise<any> {
    try {
      /** Obtener consecutivo */
      const contadorRef = this._fb.db.collection('contador-mantenimientos').doc('consecutivo');
      const consecutivo = await contadorRef.get();
      const id = consecutivo.data().contador;
      
      /** Obtener la fecha y hora actual y formatearla */
    const fechaHoraActual = new Date();
    const opciones: Intl.DateTimeFormatOptions = { 
      timeZone: 'America/Bogota', // Cambia esto a la zona horaria que desees
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric'
    };
    const fechaHoraFormateada = fechaHoraActual.toLocaleString('es-ES', opciones);

      /** Guardar  el requerimiento de mantenimiento */
      const documentRef = await this._fb.db.collection('mantenimiento').add(
        { ...requerimiento,
          estado: 'Pendiente',
          fechaHora: fechaHoraFormateada,
          id 
        }
      );
      /** Aumentar contador de requerimientos */
      await contadorRef.update({
        contador: this._fb.firebase.firestore.FieldValue.increment(1)
      })
 
      /** Guardar todas las imágenes que se subieron al servidor */
      await this.cargarEvidenciasMantenimiento(archivos, documentRef.id, requerimiento.cliente);
      
        iziToast.show({
          title: 'Correcto',
          titleColor: '#1DC74C',
          class: 'text-success',
          color: '#FFF',
          position: 'topRight',
          message: 'Solicitud de mantenimiento creada'
        });
  
    } catch (error) {
      iziToast.show({
        title: 'Error',
        titleColor: '#FF0000',
        class: 'text-danger',
        color: '#FFF',
        position: 'topRight',
        message: error
      });
    }
    return
  }

  async cargarEvidenciasMantenimiento(archivos: FileItem[], idMantenimiento: string, cliente: string): Promise<any[]> {
    const storage = this._fb.firebase.storage().ref();
    const promises: Promise<any>[] = [];
  
    for (let item of archivos) {
      item.estaSubiendo = true;
      if (item.progreso >= 100) {
        continue;
      }
      const uploadTask = storage.child(`evidencias-mantenimiento/${cliente}/${item.nombreArchivo}`).put(item.archivo);
  
      const promise = new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
          (snapshot) => {
            // Observa los eventos de cambio de estado como progreso, pausa y reanudación
            // Obtiene el progreso de la tarea, incluyendo el número de bytes cargados y el número total de bytes que se cargarán
            item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error('Error subiendo los archivos', error);
            reject(error);
          },
          async () => {
            try{
              const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
              item.url = downloadURL;
  
              const imagen = {
                nombre: item.nombreArchivo,
                url: item.url,
                cliente,
                idMantenimiento
              };
  
              await this._fb.db.collection(`evidencias-mantenimiento`).add(imagen);
              resolve('ok'); // Resuelve la promesa cuando la imagen se ha guardado correctamente
            } catch (error) {
              console.error('Error guardando la imagen en Firestore', error);
              reject(error);
            } finally {
              item.estaSubiendo = false;
            }
          });
      });
      promises.push(promise);
    }
    // Devuelve la lista de promesas para que puedan ser esperadas
    return Promise.all(promises);
  }
}
