import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { HttpClient,HttpClientModule } from "@angular/common/http";
import { AgmCoreModule } from '@agm/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SearchComponent } from './components/search/search.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { UltimasPropiedadesComponent } from './components/ultimas-propiedades/ultimas-propiedades.component';
import { AsesoresComponent } from './components/asesores/asesores.component';
import { AliadosComponent } from './components/aliados/aliados.component';
import { FooterComponent } from './components/footer/footer.component';
import { InmueblesComponent } from './pages/inmuebles/inmuebles.component';
import { DetalleInmuebleComponent } from './pages/detalle-inmueble/detalle-inmueble.component';
import {ClienteService} from './services/cliente.service';
import { GetUsuarioService } from './services/get-usuario.service';
import { ContactoComponent } from './components/contacto/contacto.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { Modal360Component } from './components/modal360/modal360.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './pages/admin/admin.component';
import { GridAsesoresComponent } from './components/grid-asesores/grid-asesores.component';
import { TarjetaAsesoresComponent } from './components/tarjeta-asesores/tarjeta-asesores.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AdminModule } from './panel/admin.module';
import { IndexComponent } from './pages/index/index.component';
import { AdminService } from './services/admin.service';
import { FirebaseService } from './services/firebase.service';
import { PropiedadService } from './services/propiedad.service';
import { UsuarioService } from './services/usuario.service';
import { CargaImagenesService } from './services/carga-imagenes.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { AgendaService } from './services/agenda.service';
import { WhatsappiconComponent } from './components/whatsappicon/whatsappicon.component';
import { InstallAppComponent } from './components/install-app/install-app.component';


@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    NavbarComponent,
    SearchComponent,
    SlideshowComponent,
    ServiciosComponent,
    UltimasPropiedadesComponent,
    AsesoresComponent,
    AliadosComponent,
    FooterComponent,
    InmueblesComponent,
    DetalleInmuebleComponent,
    ContactoComponent,
    MapaComponent,
    Modal360Component,
    //LoginComponent,
    AdminComponent,
    GridAsesoresComponent,
    TarjetaAsesoresComponent,
    IndexComponent,
    WhatsappiconComponent,
    InstallAppComponent,


    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule, 
    FormsModule, 
    HttpClientModule, 
    AgmCoreModule.forRoot({
    apiKey: 'AIzaSyAV-kQ89zuDf0IkydKheqL_z2oNOuEqlXY'
    //apiKey: 'AIzaSyDO0bHRkp8hbqAWHH_cyMI9124QaoBavnw'
    }),
    NgxUsefulSwiperModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    AdminModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule
    
    //CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
    //CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })

    
  
  ],

  providers: [
    ClienteService,
    GetUsuarioService,
    AdminService,
    FirebaseService,
    PropiedadService,
    UsuarioService,
    CargaImagenesService,
    AgendaService 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
