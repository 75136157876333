import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-gestionar-certificados',
  templateUrl: './gestionar-certificados.component.html',
  styleUrls: ['./gestionar-certificados.component.css']
})
export class GestionarCertificadosComponent implements OnInit {

  public mantenimientos: Array<any> = []
  public filter_status = "Todos";
  public load_data;
  public page = 1;
  public pageSize = 10;
  public estado_consulta = "Pendiente";
  public cantidadcertificadosNew: number = 0;

  constructor(
    private _ClienteService: ClienteService
  ) { }

  ngOnInit(): void {
    this.order_by_status();
    this.contarcertificadosnuevos();
  }

  ConsultarCertificados() {
    this._ClienteService.obtener_certificado_prop()
      .then((showmantenimiento) => {
        this.mantenimientos = showmantenimiento.docs.map(doc => {
          const data = doc.data();
          return {
            _id: doc.id,
            ...data
          };
        });
      })
      .catch(error => {
        console.error("Error al obtener los mantenimientos:", error);
      });
  }


  ConsultarCertificadosstatus(){
    this._ClienteService.obtener_certificado_prop_by_status(this.filter_status)
    .then((showmantenimiento) => {
      this.mantenimientos = showmantenimiento.docs.map(doc => {
        const data = doc.data();
        return {
          _id: doc.id,
          ...data
        };
      });
    })
    .catch(error => {
      console.error("Error al obtener los mantenimientos por estado:", error);
    });
  }

  contarcertificadosnuevos(){
    this.cantidadcertificadosNew = 0; 
    this._ClienteService.obtener_certificado_prop_by_status(this.estado_consulta)
    .then((showmantenimiento) => {
      showmantenimiento.forEach((documento) => {
        let mantenimiento = {
          ...documento.data(),
        }
        if (mantenimiento.estado === "Pendiente") {
          this.cantidadcertificadosNew++; // Incrementar el contador si el estado es "new"
        }
      })
    })
  }

  order_by_status()

  {
    if(this.filter_status == 'Todos')
    {
      this.ConsultarCertificados();
    }else
    {
        this.mantenimientos = []
        console.log(this.filter_status)
        this.ConsultarCertificadosstatus();

    }
  }

}
