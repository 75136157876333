import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

declare var $: any;
declare var iziToast:any;
@Component({
  selector: 'app-galeria-propiedad',
  templateUrl: './galeria-propiedad.component.html',
  styleUrls: ['./galeria-propiedad.component.css']
})
export class GaleriaPropiedadComponent implements OnInit {


  public propiedad : any = {};
  public token;
  public id : any;
  public file : any;
  public imgSelect: any | ArrayBuffer = 'assets/img/01.jpg';
  public load_btn = false;
  public url;

  constructor(
    private _route : ActivatedRoute,
    private _propiedadService : PropiedadService,

  ) { 
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;
    this.obtener_propiedad_admin();

  }

  ngOnInit(): void {

  }

  subir_imagen()
  {
    if(this.file != undefined)
    {
      let data = {
        imagen : this.file,
        _id: uuidv4()
      }

      this._propiedadService.agregar_imagen_galeria_admin(this.id,data,this.token).subscribe(
        response => {
         
          this.obtener_propiedad_admin();

        },
        error => 
        {
          console.log(error);
        }
      )
    }else{
      Swal.fire({
        icon:'error',
        text:'Debe seleccionar una imagen para subir.'
      });

    }
  }






  obtener_propiedad_admin()
  {
    this._route.params.subscribe(
      params  => {
        this.id = params['id'];

        this._propiedadService.obtener_propiedad_admin(this.id,this.token).subscribe(
          response  => {
            if(response.data == undefined)
            {

              this.propiedad = undefined;

            }else{
              this.propiedad = response.data;

            }

          },
          error  => {
            console.log(error)
          }
        )
      }

    )
  }

  fileChangeEvent(event:any):void
{
   var file : any;
   if(event.target.files  && event.target.files[0]){

    file = <File>event.target.files[0];


   } else{
    Swal.fire({
      icon:'error',
      text:'No hay una imagen.'
    });

   }

   if(file.size  <= 8000000){

    if(file?.type== 'image/png' || file?.type == 'image/web' || file?.type == 'image/jpg' || file?.type == 'image/gif' || file?.type == 'image/jpeg')
    {


          this.file = file


      

    }else{
      Swal.fire({
        icon:'error',
        text:'El archivo debe ser una imagen'
      });
   
    this.file = undefined;
    }

   }else{
    Swal.fire({
      icon:'error',
      text:'La imagen no puede superar los 8MB'
    });
 
  this.file = undefined;

   }


}


eliminar(id:any)
{
  this._propiedadService.eliminar_imagen_galeria_admin(this.id,{_id:id},this.token).subscribe(
    response  =>{
      Swal.fire({
        icon:'error',
        text:'Se elimino correctamente la imágen'
      });

  this.obtener_propiedad_admin();
    

  

    },
    error  => {
      console.log(error);
    }
  )

}
}
