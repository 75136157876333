import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-aliados',
  templateUrl: './aliados.component.html',
  styleUrls: ['./aliados.component.css']
})
export class AliadosComponent implements OnInit {

  config: SwiperOptions = {
    speed: 600,
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
        el: '.testimonial-carousel-pagination',
        type: 'bullets',
        clickable: true
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20
    },
        320: {
            slidesPerView: 1,
            spaceBetween: 20
        },

        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        }
    }
  };


  constructor() { }

  ngOnInit(): void {
  }

}
