import { Component,  OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClienteService } from 'src/app/services/cliente.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { SwiperOptions } from 'swiper';


@Component({
  selector: 'app-ultimas-propiedades',
  templateUrl: './ultimas-propiedades.component.html',
  styleUrls: ['./ultimas-propiedades.component.css']
})
export class UltimasPropiedadesComponent implements OnInit {

  config: SwiperOptions = {
    /*pagination: { 
      el: '.propery-carousel-pagination', 
      clickable: true
    },
    slidesPerView: 'auto',
    spaceBetween: 10,
    loop:false
  */
    speed: 600,
    loop: false,

    autoplay: {
        delay: 5000,
        disableOnInteraction: true
    },
    //slidesPerView: 'auto',
    slidesPerView: 'auto',
    pagination: {
        el: '.propery-carousel-pagination',
        type: 'bullets',
        clickable: true
    },

    breakpoints: {
      768: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 20
        },

        1200: {
            slidesPerView: 3,
            spaceBetween: 20

        }
      }
  };

  public page = 1;
  public pageSize = 9;
  
  public propiedades : Array<any> =[];
  public staticPropery : Array<any> =[];
  public url;
  public filter_propiedad = '';
  public load_data = true;
  public isLoading = false;
  public sort_by = '';

  public latAca = 16.8638
  public lngAca =-99.8816

  public filter_oferta = '';
  public filter_tipo = '';
  public filter_baths = '';
  public filter_recamaras = '';
  public filter_ciudad = '';
  public filter_precio = '';

  constructor(private _clienteService : ClienteService,) {  this.url = GLOBAL.url;}

  ngOnInit(): void {
    this.isLoading = true;
    this.listar_propiedades();
    //this.listar_propiedades_wasi();
  }
  /*listar_propiedades_wasi(){
   
    
  }*/

  listar_propiedades()
  {
    this._clienteService.listar_propiedades_publico(this.filter_propiedad,this.filter_oferta,this.filter_tipo,this.filter_baths,this.filter_recamaras,this.filter_ciudad,this.filter_precio).subscribe(
      response => {
        this.propiedades = response.data;
        this.isLoading = false;
        /*this.staticPropery.push(response.data[0]);
      
        this.propiedades.splice(0,1)
       console.log(this.propiedades)*/
        
      },error =>{
        console.log(error);
      }
    )

    this.load_data = false;
  }

  buscar_propiedad()
  {
    this.load_data = true;
    this._clienteService.listar_propiedades_publico(this.filter_propiedad,this.filter_oferta,this.filter_tipo,this.filter_baths,this.filter_recamaras,this.filter_ciudad,this.filter_precio).subscribe(
      response => {
        this.propiedades = response.data;
       
        
      },error =>{
        console.log(error);
      }
     
    )


    this.load_data = false;
  }

  reset_propiedades()
  {
    this.load_data = true;
    this._clienteService.listar_propiedades_publico('','','','','','','').subscribe(
      response => {
        this.propiedades = response.data;
        this.filter_propiedad = "";
      },error =>{
        console.log(error);
      }
      
    )
    this.filter_tipo = ''
    this.filter_oferta = ''
    this.load_data = false;
  }


  orden_por()
  {
    if(this.sort_by == 'Defecto')
    {
      
      this.propiedades.sort(function(a,b) {
        if(a.createdAt < b.createdAt)
        {
          return 1;
        }
        if(a.createdAt > b.createdAt)
        {
          return -1;
        }
        return 0;
      })
      this.load_data = false;

    }else if(this.sort_by =='+-Precio'){

      this.propiedades.sort(function(a,b){
        if(a.precio < b.precio)
        {
          return 1;

        }if(a.precio  > b.precio){
          return -1;
        }
        return 0;
      });
      this.load_data = false;
    }else if(this.sort_by =='-+Precio'){
      this.propiedades.sort(function(a,b){
        if(a.precio >  b.precio)
        {
          return 1;

        }if(a.precio  < b.precio){
          return -1;
        }
        
        return 0;
      })
      this.load_data = false;
    }

    else if(this.sort_by == 'azTitulo'){

      this.propiedades.sort(function(a,b){

        if(a.titulo > b.titulo)
        {
          return 1;
        }
        if(a.titulo < b.titulo)
        {
          return -1;
        }

        return 0;

      });
      this.load_data = false;

      

    }
    else if(this.sort_by == 'zaTitulo'){

      this.propiedades.sort(function(a,b){

        if(a.titulo < b.titulo)
        {
          return 1;
        }
        if(a.titulo > b.titulo)
        {
          return -1;
        }

        return 0;

      });

      this.load_data = false;
    }
    
  }

}
