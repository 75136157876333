import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { JwtHelperService } from '@auth0/angular-jwt';
import { AdminService } from 'src/app/services/admin.service';
import { GLOBAL } from 'src/app/services/GLOBAL';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {


  public propiedades : Array<any> =[];
  public url;
  public filter_propiedad;

  public admin : any = {};
 // public id;
  public token;


  constructor(
    private _adminService: AdminService,
    private _route : ActivatedRoute,
    private _router : Router

  ) {
    this.url = GLOBAL.url
    
    //this.id = localStorage.getItem('_id');
    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      var decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
          

      }

      if (!decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
        
      }

    } catch (error) {
      localStorage.removeItem('token');
     
    }
    
 

    this.admin.nombres = decodedToken['nombres']
    this.admin.apellidos = decodedToken['apellidos']
   }

  ngOnInit(): void {
    //this.obtener_admin();
  }

  obtener_admin()
  {
    this._adminService.obtener_admin(this.token).subscribe(
      response => 
      {
        this.admin = response.data;
      }
    )
  }

}
