import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AdminService } from './admin.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetUsuarioService {


    // Observable string sources
    private usuario = new Subject<any>();

    //
    usuario$ = this.usuario.asObservable();
    //
    emitirUsuario(usuario: any) {
      this.usuario.next(usuario)

    }
  
  constructor() {}

}
