
    <div class="card-header border-bottom  mt-3 mb-5">
        <h1 class="mt-2 mt-md-4 mb-3 pt-3">Agendamiento</h1>
        <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
            <p class="text-muted">Este módulo le permite consultar y agendar citas.</p>
        </div>
    </div>

    <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="btn-group">
              <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Month)"
                [class.active]="view === CalendarView.Month"
              >
                Mes
              </div>
              <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Week)"
                [class.active]="view === CalendarView.Week"
              >
                Semana
              </div>
              <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Day)"
                [class.active]="view === CalendarView.Day"
              >
                Día
              </div>
            </div>
            
          </div>
 
        </div>
        <div class="d-flex justify-content-end mt-5">
          

          <div >
            <h3 class="text-end">{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h3>
            
            <div class="btn-group">
              <div class="mr-4">
                <button class="btn btn-primary me-4" (click)="agregarCitaDialogo('create',$event)">+</button>
              </div>
              <div
                class="btn btn-primary"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()"
              >
                Anterior
              </div>
              <div
                class="btn btn-outline-secondary"
                mwlCalendarToday
                [(viewDate)]="viewDate"
              >
                Hoy
              </div>
              <div
                class="btn btn-primary"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()"
              >
                Siguiente
              </div>
            </div>
          </div>
          </div>
          
        </div>
        <br />
    <section class="property-single nav-arrow-b">

  <div class="container">
    <div [ngSwitch]="view">
        <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="abrirDialogo('Detalles de la cita', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        
        >
        </mwl-calendar-month-view>

        <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
       
      >
      </mwl-calendar-day-view>
    </div>

    <!-- Fin cambio de vista mes / dia / semana -->

    <br /><br /><br />

</div>
  </section>
  