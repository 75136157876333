<app-whatsappicon></app-whatsappicon>
<!-- =======Intro Single ======= -->
<section class="intro-single">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-8">
          <div class="title-single-box">
            <h1 class="title-single">Nuestro Equipo De Trabajo</h1>
            <span class="color-text-a">En esta sección podrás conocer a cada uno de nuestros profesionales</span>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
            <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a [routerLink]="['/app/inicio']">Inicio</a>
            </li>
              <li class="breadcrumb-item active" aria-current="page">
                Colaboradores
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section><!-- End Intro Single-->

   <!-- ======= Agents Grid ======= -->
   <section class="agents-grid grid">
    <div class="container">
      <div class="row">
        <div class="col-md-4" *ngFor="let asesor of asesores">
          <div class="card-box-d">
            <div class="card-img-d">
              <img [src]="asesor.foto" alt=" " alt="" class="bd-placeholder-img card-img-top">
            </div>
            <div class="card-overlay card-overlay-hover">
              <div class="card-header-d">
                <div class="card-title-d align-self-center">
                  <h3 class="title-d">
                    <a [routerLink]="['/app/tarjeta-presentacion/',asesor._id]" class="link-two">{{asesor.nombre}}</a>
                  </h3>
                </div>
              </div>
              <div class="card-body-d">
                <p class="content-d color-text-a">{{asesor.descripcion}}</p>
                <div class="info-agents color-a">
                  <p>
                    <strong>Celular: </strong>{{asesor.celular}}
                  </p>
                  <p>
                    <strong>Correo: </strong> {{asesor.correo}}
                  </p>
                </div>
              </div>
              <div class="card-footer-d">
                <div class="socials-footer d-flex justify-content-center">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a class="link-one">
                        <i class="bi bi-whatsapp" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="link-one">
                        <i class="bi bi-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="link-one">
                        <i class="bi bi-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="link-one">
                        <i class="bi bi-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</section><!-- End Agents Grid-->