<app-whatsappicon></app-whatsappicon>
<!-- ======= Intro Single ======= -->
<section class="intro-single">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-8">
          <div class="title-single-box">
            <h1 class="title-single">{{asesor.nombre}}</h1>
            <span class="color-text-a">{{asesor.cargo}}</span>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="['/app/inicio']">Inicio</a>
              </li>
              <li class="breadcrumb-item">
                <a [routerLink]="['/app/colaboradores/']">Colaboradores</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{asesor.nombre}}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section><!-- End Intro Single -->

<!-- ======= Agent Single ======= -->
<section class="agent-single">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-md-6">
            <div class="agent-avatar-box">
              <img [src]="asesor.foto" alt=" " alt="" class="bd-placeholder-img card-img-top">
            </div>
          </div>
          <div class="col-md-5 section-md-t3">
            <div class="agent-info-box">
              <div class="agent-title">
                <div class="title-box-d">
                  <h3 class="title-d">{{asesor.nombre}}
                  </h3>
                </div>
              </div>
              <div class="agent-content mb-3">
                <p class="content-d color-text-a">{{asesor.presentacion}}</p>
                <div class="info-agents color-a">
                  <p>
                    <strong>Celular: </strong>
                    <span class="color-text-a">{{asesor.celular}}</span>
                  </p>
                  <p>
                    <strong>Email: </strong>
                    <span class="color-text-a">{{asesor.correo}}</span>
                  </p>
                </div>
              </div>
              <div class="socials-footer">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a href="#" class="link-one">
                      <i class="bi bi-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#" class="link-one">
                      <i class="bi bi-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#" class="link-one">
                      <i class="bi bi-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#" class="link-one">
                      <i class="bi bi-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 section-t8">
        <div class="title-box-d">
          <h3 class="title-d">Mis propiedades</h3>
        </div>
      </div>
      <div class="row property-grid grid">
        <div class="col-md-4" *ngFor="let item of propiedades_asesor ">
          <div class="card-box-a card-shadow">
            <div class="img-box-a">
              <img [src]="item.portada" width="100%" height="300" alt="" class="bd-placeholder-img card-img-top" style="object-fit: cover;">
            </div>
            <div class="card-overlay">
              <div class="card-overlay-a-content">
                <div class="card-header-a">
                  <h2 class="card-title-a">
                    <a href="#">{{item.titulo}}</a>
                  </h2>
                </div>
                <div class="card-body-a">
                  <div class="price-box d-flex">
                    <span class="price-a">{{item.precio|currency}}</span>
                  </div>
                  <a [routerLink]="['/app/detalle/',item.codigo]" class="link-a">Ver propiedad
                    <span class="bi bi-chevron-right"></span>
                  </a>
                </div>
                <div class="card-footer-a">
                  <ul class="card-info d-flex justify-content-around">
                    <li>
                      <h4 class="card-info-title">Area</h4>
                                            <span class="justify-content-center">{{item.area}}m
                          <sup>2</sup>
                        </span>
                    </li>
                    <li class="text-center">
                      <h4 class="card-info-title ">Habitaciones</h4>
                      <span class="text-center">{{item.habitaciones}}</span>
                  </li>
                  <li class="text-center">
                    <h4 class="card-info-title">Baños</h4>
                    <span>{{item.banos}}</span>
                </li>
                <li class="text-center">
                    <h4 class="card-info-title">Parqueaderos</h4>
                    <span class="text-center">{{item.parqueaderos}}</span>
                </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
         </div>
      </div>
    </div>
  </div>
</section><!-- End Agent Single -->