<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Pagos y facturas</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">En este módulo podrás realizar pagos por QR o mediante pagos en linea (PSE)</p>

    </div>
</div>

<section class="property-single nav-arrow-b">
    <div class="container">


        <div class="row">
            <!--Inicio audiovisual-->
            <div class="col-md-10 offset-md-1">
                <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="documentos-tab" data-bs-toggle="tab" data-bs-target="#documentos" type="button" role="tab" aria-controls="documentos" aria-selected="true">Realizar Pago</button>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="subir-comprobante-tab" data-bs-toggle="pill" href="#subir-comprobante" role="tab" aria-controls="subir-comprobante" aria-selected="false">Subir Comprobante De Pago</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="tab-content" id="pills-tabContent">
      <!--=====Tab 1 Pagos ======-->
    <div class="tab-pane fade show active" id="documentos" role="tabpanel" aria-labelledby="documentos-tab">
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                Puedes realizar el pago mediante tranferencia y/o consignacion en cualquier corresponsal bancario en la siguiente cuenta
            </div>
            <div class="card-body">

                <img src="../../../../../assets/images/informacion pago Vabait.jpeg" width="500px" height="500px">

            </div>

            <!--<div class="card-header">
                Puedes realizar el pago tambien mediante PSE
            </div>
            <div class="card-body">

                <a href="https://www.pse.com.co/persona"><img src="../../../../../assets/images/PSE.png"></a>

            </div>-->

        </div>
    </div>

    <!--=====Tab 2 Subir Comprobante======-->
    <div class="tab-pane fade" id="subir-comprobante" role="tabpanel" aria-labelledby="subir-comprobante-tab">
        <!--inicia tarjeta-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <div class="container">
                    <div class="row">

                        <select class="custom-select" id="fecha-select" [(ngModel)]="fechaseleccionada" (change)="selectChange()">
                                  
                                <option selected disabled>Seleccione La Fecha</option>
                                <option  value="012023">Enero 2023</option>
                                <option  value="022023">Febrero 2023</option>
                                <option  value="032023">Marzo 2023</option>
                                <option  value="042023">Abril 2023</option>
                                <option  value="052023">Mayo 2023</option>
                                <option  value="062023">Junio 2023</option>
                                <option  value="072023">Julio 2023</option>
                                <option  value="082023">Agosto 2023</option>
                                <option  value="092023">Septiembre 2023</option>
                                <option  value="102023">Octubre 2023</option>
                                <option  value="112023">Noviembre 2023</option>
                                <option  value="122023">Diciembre 2023</option>
                                 
                        </select>
                    </div>
                </div>
            </div>
                 <div class="card-header">
                     <div class="container">
                        <div class="row">
                        <div class="col-sm">
                            <h5 style="margin-bottom: 0px;">Seleccione o arrastre el comprobante de pago</h5>
                        </div>
                        <div class="col-sm">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="file-select" id="src-file1">

                                <input appNgDropFiles [archivos]="archivos" type="file" multiple="true" aria-label="Archivo">

                            </div>
                        </div>
                        <!--<div class="col-6 mt-3">
                            <p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>
                            <div class="input-group mb-3">

                                <select class="custom-select" id="cliente-select" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                                  
                                  <option selected disabled>Seleccione el cliente</option>
                                  <option *ngFor="let cliente of clientes | filtroAZClientes" value="{{cliente.email}}"> {{cliente.nombres}} {{cliente.apellidos}} </option>
                                  
                                </select>
                            </div>
                        </div>-->

                    </div>
                </div>


            </div>

            <div class="card-body">
                <div appNgDropFiles [archivos]="archivos" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                    <i class="fa fa-solid fa-upload fa-8x"> </i>
                    <span>   Suelte los archivos en esta zona para subir</span>

                </div>
                <div class="progress mt-2" *ngFor="let archivo of archivos">
                    <!--<div class="progress-bar" role="progressbar" style="width:25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>-->

                    <div class="progress-bar verde" role="progressbar" [ngStyle]="{ 'width': archivo.progreso + '%' }"></div>
                </div>
            </div>

            <div class="card-footer ">


                <button class="btn btn-enviar" (click)="cargarDocumento()" [disabled]="archivos.length == 0">Cargar comprobante</button>
                <button class="btn btn-cancelar" (click)="limpiarArchivos()">Remover archivo</button>


            </div>

        </div>
        <!--fin tarjeta 2-->
    </div>
</div>
<!--
<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Pagos y facturas</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted"> {{ mensaje }} </p>
        <button class="btn btn-enviar" [routerLink]=" ['/panel/pagos/realizar-pago'] " [disabled]="disabled" target="_blank">Realizar pago<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></button>
    </div>--
</div>

<div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Realizar pagos</h5>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead class="table-dark">
                    <tr>

                        <th scope="col">Fecha</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Descargar Factura </th>
                    </tr>
                </thead>
                <tbody *ngIf="load_data">
                    <tr>
                        <td class="text-center" colspan="4">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </td>
                    </tr>
                </tbody> 
                <ng-container *ngIf="!load_data">
                    <tbody *ngFor=" let item of pagos| slice:(page-1) * pageSize: (page-1) * pageSize + pageSize  ;">
                        <tr>
                          
                            <td> {{ item.fecha }} </td>
                            <td> {{ item.valor | currency:'COP':'symbol' }} </td>
                            <td> <a [href]="item.url" target="_blank" class="descargar">Descargar Factura <i class="fa fa-solid fa-download"></i></a></td>
                        </tr>
                    </tbody>
                </ng-container>   
            </table>
         </div>
                
    </div>
 </div>

-->