
<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Gestión de Contratos Propietarios</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo podrás subir los contratos de los propietarios</p>

    </div>
</div>

<section class="property-single nav-arrow-b">
    <div class="container">


        <div class="row">
            <!--Inicio audiovisual-->
            <div class="col-md-10 offset-md-1">
                <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="subir-contrato-tab" data-bs-toggle="tab" data-bs-target="#subir-contrato" type="button" role="tab" aria-controls="subir-contrato" aria-selected="true">Subir Contrato</button>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="ver-contrato-tab" data-bs-toggle="pill" href="#ver-contrato" role="tab" aria-controls="ver-contrato" aria-selected="false">Ver Contratos</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="tab-content" id="pills-tabContent">
    <!--Subir Contrato-->
    <div class="tab-pane fade show active" id="subir-contrato" role="tabpanel" aria-labelledby="subir-contrato-tab">
        <!--inicia tarjeta-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <div class="container">
                    <div class="row">
                        <div class="col-sm">
                            <h5 style="margin-bottom: 0px;">Seleccione o arrastre el documento con el Contrato</h5>
                        </div>
                        <div class="col-sm">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="file-select" id="src-file1">

                                <input appNgDropFiles [archivos]="archivos" type="file" multiple="true" aria-label="Archivo">

                            </div>
                        </div>
                        <div class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">

                                <select class="custom-select" id="cliente-select" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                                  
                                  <option selected disabled>Seleccione el propietario</option>
                                  <option *ngFor="let propietarios of propietarios | filtroAZClientes" value="{{propietarios.email}}"> {{propietarios.nombres}} {{propietarios.apellidos}} </option>
                                  
                                </select>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

            <div class="card-body">
                <div appNgDropFiles [archivos]="archivos" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                    <i class="fa fa-solid fa-upload fa-8x"> </i>
                    <span>   Suelte los archivos en esta zona para subir</span>

                </div>
                <div class="progress mt-2" *ngFor="let archivo of archivos">
                    <!--<div class="progress-bar" role="progressbar" style="width:25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>-->

                    <div class="progress-bar verde" role="progressbar" [ngStyle]="{ 'width': archivo.progreso + '%' }"></div>
                </div>
            </div>

            <div class="card-footer ">


                <button class="btn btn-enviar" (click)="cargarDocumento()" [disabled]="archivos.length == 0">Cargar Contrato</button>
                <button class="btn btn-cancelar" (click)="limpiarArchivos()">Remover archivo</button>


            </div>


        </div>
        <!--fin tarjeta-->
    </div>

    <!--=====Tab 2 Ver Contratos======-->
    <div class="tab-pane fad" id="ver-contrato" role="tabpanel" aria-labelledby="pills-plans-tab">
        <!--inicia tarjeta-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <div class="container">
                    <div class="row">                
                        <div class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">

                                <select class="custom-select" id="cliente-select2" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                                  
                                  <option selected disabled>Seleccione el propietario</option>
                                  <option *ngFor="let propietario of propietarios" value="{{propietario.email}}"> {{propietario.nombres}} {{propietario.apellidos}} </option>
                                  
                                </select>
                            </div>
                            <div class="input-group mb-3">
                                <select class="custom-select" id="cliente-select3" [(ngModel)]="opcionSeleccionadacont" (change)="selectChangecont()">
                                <!--<option selected disabled>Seleccione el contrato</option>-->
                                <option *ngFor="let contrato of contratos" value="{{contrato.nombre}}"> {{contrato.nombre}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">
                                <button 
                                    (click)="eliminarContrato()"
                                    class="btn btn-enviar" 
                                    [disabled]="opcionSeleccionada == 'Seleccione el propietario' || isDeleting || pdfUrl == ''">
                                    <span style="font-size: 1.1 rem;"> 
                                        Eliminar contrato  
                                        <i *ngIf="isDeleting" 
                                            class="fa fa-solid fa-spinner fa-spin">  </i>
                                    </span> 
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

            <div *ngIf="pdfUrl != ''"  class="card-body">

                <ngx-extended-pdf-viewer 
                [src]="pdfUrl" 
                [handTool]="true" 
                [showHandToolButton]="true" 
                backgroundColor="#ffffff" 
                [height]="'100vh'" 
                [useBrowserLocale]="true" 
                [mobileFriendlyZoom]="'150%'"
                [zoom]="'page-width'"
                [showSidebarButton]="false" 
                [showFindButton]="false" 
                [showPagingButtons]="false"
                [showZoomButtons]="true" 
                [showPresentationModeButton]="true" 
                [showOpenFileButton]="false" 
                [showPrintButton]="false" 
                [showDownloadButton]="false" 
                [showBookmarkButton]="false" 
                [showSecondaryToolbarButton]="false" 
                [showRotateButton]="false" 
                [showHandToolButton]="false"
                [showScrollingButton]="false" 
                [showSpreadButton]="false" 
                [showPropertiesButton]="false" 
                [sidebarVisible]="false"
                [listenToURL]="false">
                </ngx-extended-pdf-viewer>

            </div>


        </div>
        <!--fin tarjeta-->

        
    </div>
</div>
