<!-- ======= Intro Section ======= -->
<section class="section-services section-t8 section_video">
    <ng-container>
        <div >
<!--<iframe id="frameVideo" src="https://firebasestorage.googleapis.com/v0/b/vabait-2b1fb.appspot.com/o/videos%2Fvideo_inicio_01.mp4?alt=media&token=6e84ff4f-84a7-466a-a386-6de2e1df49ff" width="100%" height="460" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->

<video autoplay muted loop id="myVideo" #video>
    <!--<source src="" type="video/mp4">-->

    <source src="https://firebasestorage.googleapis.com/v0/b/vabait-2b1fb.appspot.com/o/videos%2Fvideo%20inicio%2001_compilado.mp4?alt=media&token=0e8702b9-1d69-485e-a697-e7cde254794d" type="video/mp4">
    No ha sido posible reproducir video
  </video>

  <div class="intro_backdrop">
    <div class="intro_hero">
        <!--<h2 class="intro_title ">Somos el mejor lugar para comprar, vender o rentar tu inmuble</h2>
        <h4 class="intro_title ">Encuentra casas, fincas, lotes, apartamentos, espacios comerciales e industriales</h4>-->
        <h1 class="intro_title ">Encuentra tu inmueble ideal</h1>
        <div class="input-group mb-3">
            <input type="radio" class="btn-check " name="operacion" id="venta" autocomplete="off" checked (click)="changePlaceholderOperation($event)">
                <label class="btn btn-secondary rounded-start" for="venta">Compra</label>

            <input type="radio" class="btn-check " name="operacion" id="renta" autocomplete="off" (click)="changePlaceholderOperation($event)">
                <label class="btn btn-secondary border-start-0" for="renta">Arriendo</label>
            
        </div>

        <div class="input-group mb-3 flex flex-wrap">
            
                <input class="form-control input-tipo" list="tipoInmuebleOptions"  placeholder="{{placeholderOperation}} Apartamento..." [(ngModel)]="params.tipo">
                    <datalist id="tipoInmuebleOptions">
                        <option *ngFor="let tipo of tipoconsulta" value="{{tipo.nombre}}">
                    </datalist>
                <input type="text" class="form-control input-ciudad" list="ciudadesOptions" placeholder="La Ceja" aria-label="Ciudad" aria-describedby="button-addon2" [(ngModel)]="params.ciudad">
                <datalist id="ciudadesOptions">
                    <option *ngFor="let ciudad of ciudadesconsulta" value="{{ciudad.name}}">
                </datalist>
                <button class="btn btn-outline btn_naranja" type="button" id="button-addon2" (click)="enviar()"><i class="fa fa-search"></i></button>
          
    </div>
        

    </div>
  </div>

  </div>
  </ng-container>
  </section>

<!--
<swiper [config]="config"  class="intro intro-carousel swiper position-relative">

    <div class="swiper-wrapper" id="swiper-intro">

        <div *ngFor="let banner of banners | slice:(page-1) * pageSize: (page-1) * pageSize + pageSize;" class="swiper-slide carousel-item-a intro-item bg-image" style="background-image: url({{banner.portada}})">
            <div class="overlay overlay-a "></div>
            <div class="intro-content display-table ">
                <div class="table-cell ">
                    <div class="container lft-btm">
                        <div class="row ">
                            <div class="col-lg-8 ">
                                <div class="intro-body">
                                    <h2 class="intro-title-top ">{{banner.ciudad}}
                                        <br> {{banner.codigo}}
                                    </h2>
                                    <h1 class="intro-title ">
                                        {{banner.ciudad}}

                                    </h1>

                                    <p class="intro-subtitle intro-price">
                                        <a [routerLink]="[ '/app/detalle/',banner.codigo] "><span class="price-a ">{{banner.oferta}} | {{banner.precio | currency}}</span></a>

                                    </p>
                                    <p class="intro-subtitle ">
                                        <a [routerLink]="[ '/app/detalle/',banner.codigo] " class="link-a ">Ver propiedad
                                        <span class="bi bi-chevron-right "></span>
                                      </a>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-slide carousel-item-a intro-item bg-image " id="slide-intro " style="background-image: url({{staticBanner[0].portada}}) ">
            <div class="overlay overlay-a "></div>
            <div class="intro-content display-table ">
                <div class="table-cell ">
                    <div class="container ">
                        <div class="row ">
                            <div class="col-lg-8 ">
                                <div class="intro-body ">
                                    <h2 class="intro-title-top ">{{staticBanner[0].titulo}}
                                        <br> {{staticBanner[0].codigo}}
                                    </h2>

                                    <a [routerLink]="[ '/detalle/',banners[0].codigo] " class="link-a ">Ver propiedad
                                        <span class="bi bi-chevron-right "></span>
                                      </a>
                                    <p class="intro-subtitle intro-price ">
                                        <a href="# "><span class="price-a ">{{staticBanner[0].oferta}} | {{staticBanner[0].precio | currency}}</span></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        <!--<div class="swiper-slide carousel-item-a intro-item bg-image " style="background-image: url({{banners[1].portada}}) ">
            <div class="overlay overlay-a "></div>
            <div class="intro-content display-table ">
                <div class="table-cell ">
                    <div class="container ">
                        <div class="row ">
                            <div class="col-lg-8 ">
                                <div class="intro-body ">
                                    <h2 class="intro-title-top ">{{banners[1].titulo}}
                                        <br> {{banners[1].codigo}}
                                    </h2>
                                    
        <a [routerLink]="[ '/detalle/',banners[1].codigo] " class="link-a ">Ver propiedad
                                        <span class="bi bi-chevron-right "></span>
                                      </a>
        <p class="intro-subtitle intro-price ">
            <a href="# "><span class="price-a ">{{banners[1].oferta}} | {{banners[1].precio | currency}}</span></a>
        </p>
    </div>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="swiper-slide carousel-item-a intro-item bg-image " style="background-image: url({{banners[2].portada}}) ">
    <div class="overlay overlay-a "></div>
    <div class="intro-content display-table ">
        <div class="table-cell ">
            <div class="container ">
                <div class="row ">
                    <div class="col-lg-8 ">
                        <div class="intro-body ">
                            <h2 class="intro-title-top ">{{banners[2].titulo}}
                                <br> {{banners[2].codigo}}
                            </h2>
                          
        <a [routerLink]="[ '/detalle/',banners[2].codigo] " class="link-a ">Ver propiedad
                                        <span class="bi bi-chevron-right "></span>
                                      </a>
        <p class="intro-subtitle intro-price ">
            <a href="# "><span class="price-a ">{{banners[2].oferta}} | {{banners[2].precio | currency}}</span></a>
        </p>
    </div>
</div>
</div>
</div>
</div>
</div>
</div>

    </div>
    <div class="swiper-scrollbar "></div>
    <div class="swiper-pagination "></div>
</swiper>
End Intro Section -->