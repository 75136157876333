import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'rxjs';

@Pipe({
  name: 'formatoFecha'
})
export class FormatoFechaPipe implements PipeTransform {

  transform(timestamp: any): unknown {
    let fecha = new Date(timestamp.toDate());
    let dia = fecha.getDate();
    let mes = fecha.toLocaleString('default', {month: 'long', });
    let ano = fecha.getFullYear();
    let formatoFecha = dia + '/' + mes + '/' + ano;
    return  formatoFecha;
    
  }

}
