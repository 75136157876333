
<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Inventarios</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo podrás subir y ver los inventarios de los inmuebles</p>

    </div>
</div>

<section class="property-single nav-arrow-b">
    <div class="container">


        <div class="row">
            <!--Inicio audiovisual-->
            <div class="col-md-10 offset-md-1">
                <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="ver-inventario-tab" data-bs-toggle="tab" data-bs-target="#ver-inventario" type="button" role="tab" aria-controls="ver-inventario" aria-selected="true">Ver Inventario</button>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="ver-imagenes-inventario-tab" data-bs-toggle="pill" href="#ver-imagenes-inventario" role="tab" aria-controls="ver-imagenes-inventario" aria-selected="false">Ver registro fotográfico</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="inventario-tab" data-bs-toggle="pill" href="#inventario" role="tab" aria-controls="pills-plans" aria-selected="false" style="cursor: pointer;">Subir Inventario</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="evidencias-tab" data-bs-toggle="pill" href="#evidencias" role="tab" aria-controls="evidencias" aria-selected="false" (click)="consultarInventariosCliente()">Subir registro fotográfico</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="tab-content" id="pills-tabContent">
    <!--Subir Contrato-->
    <div class="tab-pane fade show active" id="ver-inventario" role="tabpanel" aria-labelledby="ver-inventario-tab">
        <!--inicia tarjeta-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <div class="container">
                    <div class="row">                
                        <div class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">

                                <select class="custom-select" id="cliente-select2" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                                  
                                  <option selected disabled>Seleccione el arrendatario</option>
                                  <option *ngFor="let cliente of clientes" value="{{cliente.email}}"> {{cliente.nombres | titlecase}} {{cliente.apellidos | titlecase}}  </option>
                                  
                                </select>
                                
                            </div>
                            <div class="input-group mb-3">
                                <select class="custom-select" id="cliente-select" [(ngModel)]="opcionSeleccionadainv" (change)="selectChangeinv()">
                                <!--<option selected disabled>Seleccione el contrato</option>-->
                                <option *ngFor="let inventario of inventarios" value="{{inventario.nombre}}"> {{inventario.nombre}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">
                                <button 
                                    (click)="eliminarInventario()"
                                    class="btn btn-enviar" 
                                    [disabled]="opcionSeleccionada == 'Seleccione el cliente' || isDeleting || pdfUrl == ''">
                                    <span style="font-size: 1.1 rem;"> 
                                        Eliminar inventario  
                                        <i *ngIf="isDeleting" 
                                            class="fa fa-solid fa-spinner fa-spin">  </i>
                                    </span> 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="card-body">      

                <ngx-extended-pdf-viewer 
                [src]="pdfUrl" 
                [handTool]="true" 
                [showHandToolButton]="true" 
                backgroundColor="#ffffff" 
                [height]="'100vh'" 
                [useBrowserLocale]="true" 
                [mobileFriendlyZoom]="'150%'"
                [zoom]="'page-width'"
                [showSidebarButton]="false" 
                [showFindButton]="false" 
                [showPagingButtons]="false"
                [showZoomButtons]="true" 
                [showPresentationModeButton]="true" 
                [showOpenFileButton]="false" 
                [showPrintButton]="false" 
                [showDownloadButton]="false" 
                [showBookmarkButton]="false" 
                [showSecondaryToolbarButton]="false" 
                [showRotateButton]="false" 
                [showHandToolButton]="false"
                [showScrollingButton]="false" 
                [showSpreadButton]="false" 
                [showPropertiesButton]="false" 
                [sidebarVisible]="false"
                [listenToURL]="false">
                </ngx-extended-pdf-viewer>

            </div>


        </div>
        <!--fin tarjeta-->
    </div>

    <!--=====Tab 2 Ver Contratos======-->
    <div class="tab-pane fad" id="ver-imagenes-inventario" role="tabpanel" aria-labelledby="ver-imagenes-inventario-tab">
        <!--Inicio Galeria inventario-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <div class="container">
                    <div class="row">                
                        <div class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">

                                <select class="custom-select" id="cliente-select3" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                                  
                                  <option selected disabled>Seleccione el arrendatario</option>
                                  <option *ngFor="let cliente of clientes" value="{{cliente.email}}"> {{cliente.nombres | titlecase}} {{cliente.apellidos | titlecase}}  </option>
                                  
                                </select>
                                
                            </div>
                            <div class="input-group mb-3">
                                <select class="custom-select" id="cliente-select2" [(ngModel)]="opcionSeleccionadainv" (change)="selectChangeinv()">
                                <!--<option selected disabled>Seleccione el contrato</option>-->
                                <option *ngFor="let inventario of inventarios" value="{{inventario.nombre}}"> {{inventario.nombre}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">
                                <button 
                                    (click)="eliminarInventario()"
                                    class="btn btn-enviar" 
                                    [disabled]="opcionSeleccionada == 'Seleccione el cliente' || isDeleting || pdfUrl == ''">
                                    <span style="font-size: 1.1 rem;"> 
                                        Eliminar inventario  
                                        <i *ngIf="isDeleting" 
                                            class="fa fa-solid fa-spinner fa-spin">  </i>
                                    </span> 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="card-body" >
         
                <div class="wrapper">
                    <div class="pswp-gallery" id="gallery--getting-started">
                      <a *ngFor="let imagen of inventario.images"
                        [href]="imagen.url"
                        data-pswp-width = "1620"
                        data-pswp-height="1080"
                        target="_blank"
                      >
                        <img
                          [src]="imagen.url"
                          alt=""
                        />
                      </a>
                     
                    </div>
                </div>
            </div>
        
        </div>

        <!--fin tarjeta-->
        
    </div>

    
<!--=====Tab 2 inventario======-->
<div class="tab-pane fade" id="inventario" role="tabpanel" aria-labelledby="pills-plans-tab">
    <!--inicia tarjeta-->
    <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
        <div class="card-header">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <h5 style="margin-bottom: 0px;">Seleccione o arrastre el documento con el inventario</h5>
                    </div>
                    <div class="col-sm">
                        <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                        <div class="file-select" id="src-file1">

                            <input appNgDropFiles [archivos]="archivos" type="file" multiple="false" aria-label="Archivo">

                        </div>
                    </div>
                    <div class="col-6 mt-3">
                        <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                        <div class="input-group mb-3">

                            <select class="custom-select" id="cliente-select3" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                              
                              <option selected disabled>Seleccione el arrendatario</option>
                              <option *ngFor="let cliente of clientes" value="{{cliente.email}}"> {{cliente.nombres}} {{cliente.apellidos}} </option>
                              
                            </select>

                            <select class="custom-select" id="cliente-select4" [(ngModel)]="opcionSeleccionadaprop" (change)="selectChangeprop()">
                              
                                <option selected disabled>Seleccione el propietario</option>
                                <option *ngFor="let propietarios of propietarios | filtroAZClientes" value="{{propietarios.email}}"> {{propietarios.nombres}} {{propietarios.apellidos}} </option>
                                
                            </select>
                        </div>
                    </div>

                </div>
            </div>


        </div>

        <div class="card-body">
            <div appNgDropFiles [archivos]="archivos" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                <i class="fa fa-solid fa-upload fa-8x"> </i>
                <span>   Suelte los archivos en esta zona para subir</span>

            </div>
            <!--Inicio tarjetas vista previa-->
            <div class="container mt-5">
                <div class="row">

                    <div class="card-group">
                        <div class="col " *ngFor="let archivo of archivos">
                            <div class="card">
                                <div class=" card-body ">
                                    <!--<h5 class="card-title "></h5>-->
                                    <p class="card-text "> {{ archivo.nombreArchivo }} </p>
                                    <div class="progress mt-2 ">
                                        <!--<div class="progress-bar " role="progressbar " style="width:25%; " aria-valuenow="25 " aria-valuemin="0 " aria-valuemax="100 "></div>-->

                                        <div class="progress-bar verde " role="progressbar " [ngStyle]="{ 'width': archivo.progreso + '%' } "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--fin tarjetas vista previa-->
        </div>

        <div class="card-footer ">


            <button class="btn btn-enviar" (click)="cargarDocumento()" [disabled]="archivos.length == 0">Cargar inventario</button>
            <button class="btn btn-cancelar" (click)="limpiarArchivos()">Remover archivo</button>


        </div>


    </div>
    <!--fin tarjeta-->
</div>

<!--=====Tab 3 evidencias======-->
<div class="tab-pane fade" id="evidencias" role="tabpanel" aria-labelledby="evidencias-tab">
    <!--inicia tarjeta-->
    <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
        <div class="card-header">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <h5 style="margin-bottom: 0px;">Seleccione o arrastre las imágenes de evidencia</h5>
                    </div>
                    <div class="col-sm">
                        <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                        <div class="file-select" id="src-file1">

                            <input appNgDropFiles [archivos]="imagenes" type="file" multiple="true" aria-label="Archivo">

                        </div>
                    </div>
                    <div class="col-6 mt-3">
                        <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                        <div class="input-group mb-3">

                            <select class="custom-select" id="cliente-select" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                              
                              <option selected disabled>Seleccione el arrendatario</option>
                              <option *ngFor="let cliente of clientes" value="{{cliente.email}}"> {{cliente.nombres}} {{cliente.apellidos}} </option>
                              
                            </select>

                            <select class="custom-select" id="cliente-select" [(ngModel)]="opcionSeleccionadaprop" (change)="selectChangeprop()">
                              
                                <option selected disabled>Seleccione el propietario</option>
                                <option *ngFor="let propietarios of propietarios | filtroAZClientes" value="{{propietarios.email}}"> {{propietarios.nombres}} {{propietarios.apellidos}} </option>
                                
                            </select>
                        </div>
                        <div *ngIf="inventariosArrendatario.length > 1" class="input-group mb-3">
                            <select class="custom-select" id="cliente-select2" [(ngModel)]="inventarioSeleccionado" >
                                <option selected disabled>Seleccione inventario</option>
                                <option *ngFor="let inventario of inventariosArrendatario" [ngValue]="inventario"> {{inventario.nombre}} </option>
                            </select>
                        </div>
                        
                    </div>

                </div>
            </div>


        </div>

        <div class="card-body">
            <div appNgFiles [imagenes]="imagenes" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                <i class="fa fa-solid fa-upload fa-8x"> </i>
                <span>   Suelte los archivos en esta zona para subir</span>

            </div>

            <!--Inicio tarjetas vista previa-->
            <div class="container mt-5">
                <div class="row">

                    <div class="card-group">
                        <div class="col " *ngFor="let imagen of imagenes">
                            <div class="card">
                                <img class="card-img-top" [src]="imagen.tempUrl" alt="imagen.nombreArchivo">
                                <div class=" card-body ">
                                    <!--<h5 class="card-title "></h5>-->
                                    <p class="card-text "> {{ imagen.nombreArchivo }} </p>
                                    <div class="progress mt-2 ">
                                        <!--<div class="progress-bar " role="progressbar " style="width:25%; " aria-valuenow="25 " aria-valuemin="0 " aria-valuemax="100 "></div>-->

                                        <div class="progress-bar verde " role="progressbar " [ngStyle]="{ 'width': imagen.progreso + '%' } "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--fin tarjetas vista previa-->


        </div>

        <div class="card-footer ">


            <button class="btn btn-enviar " (click)="cargarImagenes() " [disabled]="imagenes.length==0 ">Cargar imagenes</button>
            <button class="btn btn-cancelar " (click)="limpiarImagenes() ">Remover archivos</button>


        </div>


    </div>
    <!--fin tarjeta-->

</div>
</div>
