import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import {CommonModule} from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { FileItem } from 'src/app/models/file-item';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
import Swal from 'sweetalert2';

declare var iziToast:any
declare var $: any;

@Component({
  selector: 'app-mostrar-inventario',
  templateUrl: './mostrar-inventario.component.html',
  styleUrls: ['./mostrar-inventario.component.css']
})
export class MostrarInventarioComponent implements OnInit {

  estaSobreElemento = false;
  public archivos: FileItem[] = [];
  public imagenes: Array<any> = []
  public imagenesinv: Array<any> = []
  public idImagenes: Array<any> = []
  public idImagenesinv: Array<any> = []
  public idDocumentoPdf : string = "";
  public nombreDocumentoPdf : string = "";
  public contratos: Array<any> = []
  //public files: File[] = [];
  public token;
  public contrato;
  public usuario:any = {}
  public dataSource = []
  public load_data = true;
  public users : Array<any> = [];
  public clientes : Array<any> = [];
  public page = 1;
  public pageSize = 5;
  public imagesPath = 'imagenes';
  public archivosPath = 'inventario';
  public documentoCliente = '';
  public documentoPropietario = '';
  public inventarios: Array<any> = [];
  public inventario = {
    images: [],
    documentname: "",
    documentid: "" 
  }
  public cargando;
  public propietarios : Array<any> = [];
  public opcionSeleccionada = 'Seleccione el arrendatario';
  public opcionSeleccionadaprop = 'Seleccione el propietario';
  public opcionSeleccionadainv = 'Seleccione el inventario';
  public esactivo = false;
  public isDeleting = false;
  //public pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/vabait-2b1fb.appspot.com/o/facturas%2Fjuanfe1694%40misena.com%2FDepartamento%20de%20Antioquia%20-%20Impuestos%20de%20veh%C3%ADculos%20_%20Webcheckout%20PlacetoPay.pdf?alt=media&token=7d1569dd-4a4d-414e-b3bb-9bd93d20b638';
  public pdfUrl = "";
 // pdfExample ="../../../../assets/pdfs/CONTRATO 100V-28 APTO 101 TORRE 8 JUAN PABLO II-1-9.pdf"

/** Inventarios por arrendatario y propietario */
  public inventariosArrendatario : Array<any> = [];
  public inventarioSeleccionado;

  constructor(private _usuarioService : UsuarioService,
    private _imagenesService: CargaImagenesService,
    private _ClienteService: ClienteService,
    private _subirArchivo: CargaImagenesService) { 
      this.token = localStorage.getItem('token')
    }

  ngOnInit(): void {
    this.listar_usuario();
    this.listar_propietarios();
    $('#cliente-select2').select2();
    $('#cliente-select2').on('change', (event: any) => {
      const opcionSeleccionada = event.target.value;
      this.documentoCliente = opcionSeleccionada;
      this.opcionSeleccionada = this.documentoCliente
      this.obtener_inventario();
    });
  }
  
  consultarInventariosCliente(){
    if(this.opcionSeleccionada != 'Seleccione el arrendatario' && 
      this.opcionSeleccionadaprop != 'Seleccione el propietario'){
      this._ClienteService.obtener_inventario_cliente_propietario(this.opcionSeleccionada, this.opcionSeleccionadaprop)
        .then(snap =>  {
          let index = 0;
          const inventarios = [];
          snap.forEach(element => {
            const inventario = {
              ...element.data(),
              UUID: element.id
            }
            inventarios.push(inventario)
            /** Si es el primer inventario setearlo como seleccionado */
            if(index == 0){
              this.inventarioSeleccionado = inventario;
              console.log(this.inventarioSeleccionado)
            }
            index ++;
          })
          this.inventariosArrendatario = inventarios;
        })
    }
  }

  eliminarInventario(){
    this.isDeleting = true;
    this._imagenesService.eliminar_imagenes_inventario(this.opcionSeleccionada, this.inventario.images)
      .then(()=> this.isDeleting = false)
    this._imagenesService.eliminar_inventario(this.opcionSeleccionada, this.inventario.documentname, this.inventario.documentid)
      .finally(()=> {this.isDeleting = false; this.obtener_inventario()})
  }

  selectChange(){
    this.documentoCliente = this.opcionSeleccionada;
    this.obtener_inventario();
    //this.obtener_imagenes();
    this.consultarInventariosCliente();
  }

  selectChangeprop(){
    this.documentoPropietario = this.opcionSeleccionadaprop;
    this.consultarInventariosCliente();
  }

  selectChangeinv(){
    const inventarioSeleccionado = this.inventarios.find(x => x.nombre == this.opcionSeleccionadainv);
    if( inventarioSeleccionado){
      this.pdfUrl = inventarioSeleccionado.url;
      this.opcionSeleccionada = inventarioSeleccionado.cliente;
      this.inventario = inventarioSeleccionado;
      //this.obtener_imagenes();
    }
    console.log(inventarioSeleccionado)
  }

  
  cargarDocumento(){
    this._subirArchivo.cargarInventario(this.archivos,this.documentoCliente, this.documentoPropietario,this.archivosPath)
    }

  cargarImagenes(){
      this._subirArchivo.cargarInventario(this.imagenes, this.documentoCliente, this.documentoPropietario,this.imagesPath, this.inventarioSeleccionado.UUID)
    }

  limpiarArchivos(){
      this.archivos = [];
  }
    
  limpiarImagenes(){
      this.imagenes = [];
  }
  


  filtrarClientes(usuarios: Array<any>){
    for(let usuario of usuarios){

      if(usuario.rol == 'cliente'){
        this.clientes.push(usuario);
      }
    }
  
  }
  
    listar_usuario()
    {
      this._usuarioService.listar_usuario(this.token).subscribe(
        response =>{
          this.users = response.data;
          this.load_data = false;
          this.filtrarClientes(this.users);
        },
        error =>{
          console.log(error);
        }
      )
    }

    listar_propietarios()
    {
      this._usuarioService.listar_usuario(this.token).subscribe(
        response =>{
          this.users = response.data;
          this.load_data = false;
          this.filtrarPropietarios(this.users);
        },
        error =>{
          console.log(error);
        }
      )
    }


  obtener_inventario(){
      this.inventarios = [];
      this._imagenesService.obtener_inventario(this.opcionSeleccionada)
      .then(snapshot =>{
        let index = 0;
        snapshot.forEach(async (element) => {
          const clientImages = [];
          const images = await this._imagenesService.obtener_imagenes_inventario(element.id);
          images.forEach(image => clientImages.push({id: image.id, nombreArchivo: image.data().nombre, url: image.data().url}))
  
          let inventario = {
            ...element.data(),
            images: clientImages,
            documentid: element.id,
            documentname: element.data().nombre
          }

          this.inventarios.push(inventario);
          console.log(this.inventarios)
          if(index == 0){
            this.pdfUrl = element.data().url;
            this.opcionSeleccionada = element.data().cliente;
            this.opcionSeleccionadainv = inventario.nombre;
            this.inventario = inventario;
          }
          index ++;
  
        });
        
        this.cargando = false;
      })
  }

  obtener_imagenes(){
    console.log('obtener_imagenes ' + this.inventario.documentid)
    this.imagenesinv= [];
    this._imagenesService.obtener_imagenes_inventario(this.inventario.documentid)
    .then((querySnapshot) => {
        
      querySnapshot.forEach((doc) => {
          this.imagenesinv.push(doc.data());
          this.idImagenesinv.push({id: doc.id, nombreArchivo: doc.data().nombre});
      });
        this.obtener_tamano_imagenes();
        //this.cargar_inventario = false;
        console.log(this.imagenesinv)
  })
  .catch((error) => {
      console.log("Error getting documents: ", error);
  });
  }

  obtener_tamano_imagenes(){
    for(let imagen of this.imagenes){
       let foto = new Image(200,300);
      
      //document.images[0].src=foto.src;
      foto.src = imagen.url;
      let ancho = foto.naturalWidth;
      let alto = foto.naturalHeight;
      //let ancho = document.images[0].naturalWidth;
      //let alto =  document.images[0].naturalHeight;
      imagen.ancho = ancho;
      imagen.alto = alto;
      let image = {
        src: imagen.url,
        width: ancho,
        height: alto,
        alt: imagen.nombre
      }
      this.dataSource.push(image)
      //console.log(this.dataSource)
    }
    //Se inicializa el lightgallery una vez el arreglo de imagenes esté completo
  
  }

  filtrarPropietarios(usuarios: Array<any>){
    for(let usuario of usuarios){
      //console.log(usuario)
      if(usuario.rol == 'propietario'){
        this.propietarios.push(usuario);
      }
    }
  
  }


  
    
    eliminar(id:any)
    {
      this._usuarioService.eliminar_usuario(id,this.token).subscribe(
        response =>{
          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color:'#FFF',
            position: 'topRight',
            message:'Usuario eliminado correctamente'
         
      });
      this.listar_usuario();
        },
        error  =>{
          console.log(error);
        }
  
      )
    }
    

    obtener_contrato(){
      this._imagenesService.obtener_contrato(this.usuario.email)
      .then((querySnapshot) => {
        
        querySnapshot.forEach((doc) => {
            
            
            this.contratos.push(doc.data());

            
        });

    })
    .catch((error) => {
      Swal.fire({
        icon:'error',
        text:'Error obteniendo el contrato del cliente, este error puede generarse porque actualmente el cliente no tiene ningun contrato asignado'
      });
    });
    }

}
