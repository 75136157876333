export var GLOBAL = {

      //url : 'http://localhost:5001/vabait-2b1fb/us-central1/api/',
      //url : 'https://vabait-prod.herokuapp.com/api/',
     url : 'https://us-central1-vabait-2b1fb.cloudfunctions.net/api/',
    //url : 'http://127.0.0.1:5001/vabait-2b1fb/us-central1/api/',
}



export var GLOBALSOCKET = {

      //url : 'http://localhost:5001/vabait-2b1fb/us-central1/api/',
      //url : 'https://api.axsainmobiliaria.com.mx',
     // url : 'https://vabait-prod.herokuapp.com/api/',
     //url : 'http://127.0.0.1:5001/vabait-2b1fb/us-central1/api/',
     url : 'https://us-central1-vabait-2b1fb.cloudfunctions.net/api/',

}
