import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';

declare var iziToast : any;

@Component({
  selector: 'app-crear-cliente',
  templateUrl: './crear-cliente.component.html',
  styleUrls: ['./crear-cliente.component.css']
})
export class CrearClienteComponent implements OnInit {

  public load_btn;

  public user : any = {
    rol:""
  };
  public token;


  constructor(
    private _usuarioService : UsuarioService,
    private _router : Router
    
  ) {
    this.token = localStorage.getItem('token');
   }

  ngOnInit(): void {
  }




  registro(registroForm:any)
  {
    if(registroForm.valid){
      this.load_btn = true;
      this._usuarioService.registro_usuario(this.user,this.token).subscribe(
        response => {
          console.log(response);
          this.load_btn = false;
          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color:'#FFF',
            position: 'topRight',
            message:'Usuario registrado correctamente'
      });
          this.load_btn = false;
          this._router.navigate(['/panel/clientes'])
        },
        error => {
          console.log(error);
        }
      )

    }else{
      iziToast.show({
        title: 'Error',
        titleColor: 'FF0000',
        class: 'text-danger',
        color:'red',
        position: 'topRight',
        message:'Los datos del formulario no son válidos'
    });
    }
  }

}
