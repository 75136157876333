
<ng-container *ngIf="cargando; else elseTemplate">
    <app-loading></app-loading>
</ng-container>

<ng-template #elseTemplate>
    <div class="card-header border-bottom  mt-2 ">
        <h1 class="mt-2 mt-md-4 mb-3 pt-3">Requerimientos</h1>
        <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
            <p class="text-muted">En este módulo podrás crear y consultar el estado de tus requerimientos</p>
        </div>
    </div>

    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="crear-requerimiento" role="tabpanel"
            aria-labelledby="crear-requerimiento-tab">
            <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
                <div class="card-body">
                    <div class="container">

                        <!--Inicia Mostrar requerimientos-->
                        <div class="card box-shadow-sm">
                            <div class="card-header">
                                <h3 style="margin-bottom: 0px;">Información del requerimiento</h3>
                            </div>

                            <form action="">
                                <ng-container>
                                    <div class="card-body ">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-12 form-group mb-0">
                                                        <label for="">Asunto </label>
                                                        <input type="text" class="form-control" disabled name="asunto"
                                                            [(ngModel)]="propiedad.asunto">
                                                    </div>
                                                    <div class="form-floating">
                                                        <textarea disabled class="form-control"
                                                            placeholder="Dejenos sus comentarios" id="floatingTextarea"
                                                            name="comentarios"
                                                            [(ngModel)]="propiedad.detalle"></textarea>
                                                        <label for="floatingTextarea">Detalle de la solicitud</label>
                                                    </div>
                                                    <div class="form-floating">
                                                        <textarea class="form-control"
                                                            placeholder="Dejenos sus comentarios" id="floatingTextarea"
                                                            name="respuesta" [(ngModel)]="propiedad.respuesta"
                                                            disabled></textarea>
                                                        <label for="floatingTextarea">Comentarios</label>
                                                    </div>
                                                    <div class="col-lg-12 form-group mb-0">
                                                        <label for="">Fecha y hora de creacion</label>
                                                        <input type="text" class="form-control" disabled name="fechaHora"
                                                            [(ngModel)]="propiedad.fechaHora">
                                                    </div>
                                                    <div class="mt-3">
                                                        <label for="">Evidencias</label>
                                                        <div class="card box-shadow-sm"
                                                            style="max-width: 1200px; min-width: auto;">
                                                            <div class="card-body">
                                                                <div class="wrapper">
                                                                    <div class="pswp-gallery" id="gallery-maintenance">
                                                                        <a *ngFor="let imagen of imagenesMantenimiento"
                                                                            [href]="imagen.url" data-pswp-width="1620"
                                                                            data-pswp-height="1080" target="_blank">
                                                                            <img [src]="imagen.url" alt="" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </form>
                            <div class="card-footer">
                                <button class="btn btn-cancelar" (click)="goBackOrToPanel()" >Regresar</button>
                            </div>
                        </div>
                        <!--Termina mostrar requerimientos-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>