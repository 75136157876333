import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { io } from "socket.io-client";
import { GLOBALSOCKET } from 'src/app/services/GLOBAL';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal } from '../../../clases/data-modal';
import { ModalDialogComponent } from '../../modal-dialog/modal-dialog.component';

declare var iziToast:any;
@Component({
  selector: 'app-index-mensajes',
  templateUrl: './index-mensajes.component.html',
  styleUrls: ['./index-mensajes.component.css']
})
export class IndexMensajesComponent implements OnInit {

 
  public mensajes : Array<any>=[];
  public token;
  public load_data = true;
  public page = 1;
  public pageSize = 20;
  public filtro='';
  public socket = io(GLOBALSOCKET.url,{transports: ['websocket'], upgrade: false});
  constructor(
    private _adminService : AdminService,
    public dialog: MatDialog
  ) {

    this.token = localStorage.getItem('token');
    this.socket.on('get-messages',(data)=>{
      this.obtener_mensajes();
    })
   }

   abrirDialogo(id: any, titulo: string) {
    const dialogo1 = this.dialog.open(ModalDialogComponent, {
      data: new DataModal(id, titulo, '¿Estás seguro de eliminar este mensaje?')
    });

    dialogo1.afterClosed().subscribe(datos => {
    this.eliminar_mensaje(datos.id)
    });}

    abrirDialogoCerar(id: any, titulo: string) {
      const dialogo2 = this.dialog.open(ModalDialogComponent, {
        data: new DataModal(id, titulo, '¿Estás seguro de cerrar este mensaje?')
      });
  
      dialogo2.afterClosed().subscribe(datos => {
      this.cerrar(datos.id)
      });}
  ngOnInit(): void {
    this.obtener_mensajes();
  }


  obtener_mensajes()
  {
    this._adminService.listar_mensajes(this.token).subscribe(
      response =>
      {
        this.mensajes = response.data;
        
      },
      error =>{
        console.log(error);
      }
    )
    this.load_data= false;
  }

  cerrar(id:any)
  {
    this._adminService.cerrar_mensaje_admin(id,{data:undefined},this.token).subscribe(
      response  =>{
        
        iziToast.show({
          title: 'Success',
          titleColor: '#1DC74C',
          class: 'text-success',
          color:'#FFF',
          position: 'topRight',
          message:'Mensaje cerrado correctamente'
    });

    this.obtener_mensajes();
    

      },
      error  => {
        console.log(error);
      }
    )

  }

  eliminar_mensaje(id){
    this._adminService.eliminar_mensaje_admin(id,this.token).subscribe(
      response =>{
        iziToast.show({
          title: 'Success',
          titleColor: '#1DC74C',
          class: 'text-success',
          color:'#FFF',
          position: 'topRight',
          message:'Mensaje eliminado correctamente'
    });
    this.obtener_mensajes();
      },
      error =>{
        console.log(error);
      }
    )
  }

}
