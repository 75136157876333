
<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Ver Pagos</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo podrás ver los pagos realizados por transferencia o codigo QR directo a la cuenta</p>

    </div>
</div>

<section class="property-single nav-arrow-b">
    <div class="container">


        <div class="row">
            <!--Inicio audiovisual-->
            <div class="col-md-10 offset-md-1">
                <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="ver-pagoscanon-tab" data-bs-toggle="tab" data-bs-target="#ver-pagoscanon" type="button" role="tab" aria-controls="ver-pagoscanon" aria-selected="true">Ver Pagos</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="tab-content" id="pills-tabContent">
    <!--Subir Contrato-->
    <div class="tab-pane fade show active" id="ver-pagoscanon" role="tabpanel" aria-labelledby="ver-pagoscanon-tab">
        <!--inicia tarjeta-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <div class="container">
                    <div class="row">                
                        <div class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">

                                <select class="custom-select" id="cliente-select" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                                  
                                  <option selected disabled>Seleccione el cliente</option>
                                  <option *ngFor="let cliente of clientes" value="{{cliente.email}}"> {{cliente.nombres | titlecase}} {{cliente.apellidos | titlecase}}  </option>
                                  
                                </select>

                                
                            </div>
                            

                            <div class="input-group mb-3">

                                <select class="custom-select" id="fecha-select" [(ngModel)]="fechaseleccionada" (change)="selectChangeData()">
                                  
                                        <option selected disabled>Seleccione La Fecha</option>
                                        <option  value="012023">Enero 2023</option>
                                        <option  value="022023">Febrero 2023</option>
                                        <option  value="032023">Marzo 2023</option>
                                        <option  value="042023">Abril 2023</option>
                                        <option  value="052023">Mayo 2023</option>
                                        <option  value="062023">Junio 2023</option>
                                        <option  value="072023">Julio 2023</option>
                                        <option  value="082023">Agosto 2023</option>
                                        <option  value="092023">Septiembre 2023</option>
                                        <option  value="102023">Octubre 2023</option>
                                        <option  value="112023">Noviembre 2023</option>
                                        <option  value="122023">Diciembre 2023</option>
                                         
                                </select>

                                
                            </div>
                        </div>

                    </div>
                </div>


            </div>

            <div class="card-body">      

                <div class="wrapper">
                    <div class="pswp-gallery" id="gallery--getting-started">
                      <a *ngFor="let imagen of imagenes"
                        [href]="imagen.url"
                        data-pswp-width = "1620"
                        data-pswp-height="1080"
                        target="_blank"
                      >
                        <img
                          [src]="imagen.url"
                          alt=""
                        />
                      </a>
                     
                    </div>
                </div>

            </div>


        </div>
        <!--fin tarjeta-->
    </div>
</div>
