
<!-- Page title-->
<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Detalles de requerimiento</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted"></p>

    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Detalles</h5>
    </div>

    <div class="card-body">

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <div class="card mb-3" style="width: 100%;" *ngIf="requerimiento">
                    <div class="row g-0">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="card-title text-center pt-3">Gestion De Certificados</h5>
                            <div class="card-body text-center">
                                <form #detallesForm="ngForm" (submit)="guardarCambios()">
                                    <div class="col-lg-12 form-group">
                                        <label for="asunto">Asunto</label>
                                        <textarea class="form-control text-center" name="detalle" id="detalle" rows="2" readonly [(ngModel)]="requerimiento.asunto"></textarea>
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="Email">Email</label>
                                        <input id="Email" class="text-center form-control" readonly name="email" [(ngModel)]="requerimiento.email" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="estado">Estado</label>
                                        <select class="form-control custom-select" id="select-input" required name="tipo" [(ngModel)]="requerimiento.estado">
                                                <option class="text-center" value="Pendiente" >Pendiente</option>
                                                <option class="text-center" value="En Proceso" >En Proceso</option>
                                                <option class="text-center" value="Cerrado" >Cerrado</option>
                                            </select>
                                    </div>

                                    <div class="col-lg-12 form-group">
                                        <label for="Rol">Rol</label>
                                        <input id="Rol" class="text-center form-control" readonly name="rol" [(ngModel)]="requerimiento.rol" [ngModelOptions]="{standalone: true}">
                                    </div>

                                    <div class="col-lg-12 form-group">
                                        <label for="respuesta">Comentarios</label>
                                        <textarea class="form-control text-center" name="respuesta" id="respuesta" rows="5" [(ngModel)]="requerimiento.comentario"></textarea>
                                    </div>


                                    <div class="tab-pane fade show active" id="subir-certificado" role="tabpanel">
                                        <label for="respuesta">Subir Certificado</label>
                                        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
                                            <div class="card-header">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-sm">
                                                            <h5 style="margin-bottom: 0px;">Seleccione o arrastre el documento con el Contrato</h5>
                                                        </div>
                                                        <div class="col-sm">
                                                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                                                            <div class="file-select" id="src-file1">
                                
                                                                <input appNgDropFiles [archivos]="archivos" type="file" multiple="true" aria-label="Archivo">
                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                
                                
                                            </div>
                                
                                            <div class="card-body">
                                                <div appNgDropFiles [archivos]="archivos" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                                                    <i class="fa fa-solid fa-upload fa-8x"> </i>
                                                    <span>   Suelte los archivos en esta zona para subir</span>
                                
                                                </div>
                                                <div class="progress mt-2" *ngFor="let archivo of archivos">
                                                    <!--<div class="progress-bar" role="progressbar" style="width:25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>-->
                                
                                                    <div class="progress-bar verde" role="progressbar" [ngStyle]="{ 'width': archivo.progreso + '%' }"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer text-center">
                                        <button *ngIf="!load_btn" class="btn btn-enviar mr-3 mt-3" type="submit">Guardar</button>

                                        <button *ngIf="load_btn" type="button" class="btn btn-secondary">
                                                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                                                Guardando
                                              </button>
                                        <button class="btn btn-cancelar mr-3 mt-3" [routerLink]=" ['/panel/gestionar-certificados'] ">Regresar</button>
                                       


                                    </div> 
                                </form>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>






    </div>

</div>