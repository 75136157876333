<!-- ======= Agents Section ======= -->
<section class="section-agents section-t8" id="colaboradores">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-wrap d-flex justify-content-between">
                    <div class="title-box">
                        <h2 class="title-a">Nuestros profesionales</h2>
                    </div>
                    <!--
                    <div class="title-link">
                        <a href="agents-grid.html">All Agents
                  <span class="bi bi-chevron-right"></span>
                </a>
                    </div>-->
                </div>
            </div>
        </div>
        <swiper [config]="config">
            <div class="swiper-wrapper">

                <div class=" swiper-slide " *ngFor="let asesor of asesores">
                    <div class=" card-box-d ">
                        <div class="card-img-d ">
                            <img [src]="asesor.foto" alt=" " alt="" class="bd-placeholder-img card-img-top img_asesores">
                        </div>
                        <div  class="card-overlay card-overlay-hover ">
                            <div class="asesor_card_body" [routerLink]="['/app/tarjeta-presentacion/',asesor._id]">
                                <div class="card-header-d ">
                                    <div class="card-title-d align-self-center ">
                                        <h3 class="title-d ">
                                            <a  class="link-two">{{asesor.nombre}}</a>
                                        </h3>
                                    </div>
                                </div>
                                <div  class="card-body-d ">
                                    <p class="content-d color-text-a ">
                                        {{asesor.descripcion}}
                                    </p>
                                    <div class="info-agents color-a ">
                                        <p>
                                            <strong>Celular: </strong>{{asesor.celular}}
                                        </p>
                                        <p>
                                            <strong>Correo: </strong> {{asesor.correo}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer-d ">
                                <div class="socials-footer d-flex justify-content-center ">
                                    <ul class="list-inline ">
                                        <li class="list-inline-item ">
                                            <a href="https://www.facebook.com/vabaitinmobiliaria" target="_blank" class="link-one ">
                                                <i class="bi bi-facebook " aria-hidden="true "></i>
                                            </a>
                                        </li>
                                        <!--<li class="list-inline-item ">
                                            <a href="# " class="link-one ">
                                                <i class="bi bi-twitter " aria-hidden="true "></i>
                                            </a>
                                        </li>-->
                                        <li class="list-inline-item ">
                                            <a href="https://www.instagram.com/vabaitinmobiliaria/?igshid=YmMyMTA2M2Y%3D" target="_blank" class="link-one ">
                                                <i class="bi bi-instagram " aria-hidden="true "></i>
                                            </a>
                                        </li>
                                        <!--<li class="list-inline-item ">
                                            <a  class="link-one ">
                                                <i class="bi bi-linkedin " aria-hidden="true "></i>
                                            </a>
                                        </li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Fin asesor 1-->

            </div>
        </swiper>
        <!--<div class="row ">
            <div class="col-md-4 ">
                <div class="card-box-d ">
                    <div class="card-img-d ">
                        <img src="assets/img/agent-4.jpg " alt=" " class="img-d img-fluid ">
                    </div>
                    <div class="card-overlay card-overlay-hover ">
                        <div class="card-header-d ">
                            <div class="card-title-d align-self-center ">
                                <h3 class="title-d ">
                                    <a href="agent-single.html " class="link-two ">Margaret Sotillo
                        <br> Escala</a>
                                </h3>
                            </div>
                        </div>
                        <div class="card-body-d ">
                            <p class="content-d color-text-a ">
                                Sed porttitor lectus nibh, Cras ultricies ligula sed magna dictum porta two.
                            </p>
                            <div class="info-agents color-a ">
                                <p>
                                    <strong>Phone: </strong> +54 356 945234
                                </p>
                                <p>
                                    <strong>Email: </strong> agents@example.com
                                </p>
                            </div>
                        </div>
                        <div class="card-footer-d ">
                            <div class="socials-footer d-flex justify-content-center ">
                                <ul class="list-inline ">
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-facebook " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-twitter " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-instagram " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-linkedin " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 ">
                <div class="card-box-d ">
                    <div class="card-img-d ">
                        <img src="assets/img/agent-1.jpg " alt=" " class="img-d img-fluid ">
                    </div>
                    <div class="card-overlay card-overlay-hover ">
                        <div class="card-header-d ">
                            <div class="card-title-d align-self-center ">
                                <h3 class="title-d ">
                                    <a href="agent-single.html " class="link-two ">Stiven Spilver
                        <br> Darw</a>
                                </h3>
                            </div>
                        </div>
                        <div class="card-body-d ">
                            <p class="content-d color-text-a ">
                                Sed porttitor lectus nibh, Cras ultricies ligula sed magna dictum porta two.
                            </p>
                            <div class="info-agents color-a ">
                                <p>
                                    <strong>Phone: </strong> +54 356 945234
                                </p>
                                <p>
                                    <strong>Email: </strong> agents@example.com
                                </p>
                            </div>
                        </div>
                        <div class="card-footer-d ">
                            <div class="socials-footer d-flex justify-content-center ">
                                <ul class="list-inline ">
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-facebook " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-twitter " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-instagram " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-linkedin " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 ">
                <div class="card-box-d ">
                    <div class="card-img-d ">
                        <img src="assets/img/agent-5.jpg " alt=" " class="img-d img-fluid ">
                    </div>
                    <div class="card-overlay card-overlay-hover ">
                        <div class="card-header-d ">
                            <div class="card-title-d align-self-center ">
                                <h3 class="title-d ">
                                    <a href="agent-single.html " class="link-two ">Emma Toledo
                        <br> Cascada</a>
                                </h3>
                            </div>
                        </div>
                        <div class="card-body-d ">
                            <p class="content-d color-text-a ">
                                Sed porttitor lectus nibh, Cras ultricies ligula sed magna dictum porta two.
                            </p>
                            <div class="info-agents color-a ">
                                <p>
                                    <strong>Phone: </strong> +54 356 945234
                                </p>
                                <p>
                                    <strong>Email: </strong> agents@example.com
                                </p>
                            </div>
                        </div>
                        <div class="card-footer-d ">
                            <div class="socials-footer d-flex justify-content-center ">
                                <ul class="list-inline ">
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-facebook " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-twitter " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-instagram " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item ">
                                        <a href="# " class="link-one ">
                                            <i class="bi bi-linkedin " aria-hidden="true "></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</section>
<!-- End Agents Section -->






<!-- End #main -->