import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {
  public latAca = ﻿6.0819444444444
  public lngAca =-75.334166666667
  public lat = ﻿6.0840635956703615
  public lng =-75.33520392988876
  public lat2 =6.023850241123921
  public lng2 =-75.43104830449084
  constructor() { }

  ngOnInit(): void {
  }
}
