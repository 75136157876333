import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import {CommonModule} from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { FileItem } from 'src/app/models/file-item';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
import Swal from 'sweetalert2';

declare var iziToast:any
declare var $: any;

@Component({
  selector: 'app-subir-contrato',
  templateUrl: './subir-contrato.component.html',
  styleUrls: ['./subir-contrato.component.css']
})
export class SubirContratoComponent implements OnInit {

  estaSobreElemento = false;
  public archivos: FileItem[] = [];
  public imagenes: FileItem[] = [];
  public contratos: Array<any> = []
  //public files: File[] = [];
  public token;
  public contrato = this.contratos[0];
  public idContrato;
  public usuario:any = {}
  public load_data = true;
  public users : Array<any> = [];
  public clientes : Array<any> = [];
  public page = 1;
  public pageSize = 5;
  public imagesPath = 'imagenes';
  public archivosPath = 'Contratos';
  public documentoCliente = '';
  public opcionSeleccionada = 'Seleccione el arrendatario';
  public esactivo = false;
  public isDeleting = false;
  //public pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/vabait-2b1fb.appspot.com/o/facturas%2Fjuanfe1694%40misena.com%2FDepartamento%20de%20Antioquia%20-%20Impuestos%20de%20veh%C3%ADculos%20_%20Webcheckout%20PlacetoPay.pdf?alt=media&token=7d1569dd-4a4d-414e-b3bb-9bd93d20b638';
  public pdfUrl = '';
  pdfExample ="../../../../assets/pdfs/CONTRATO 100V-28 APTO 101 TORRE 8 JUAN PABLO II-1-9.pdf"
  public opcionSeleccionadacont = 'Seleccione el inventario';

  constructor(private _usuarioService : UsuarioService,
    private _imagenesService: CargaImagenesService,
    private _ClienteService: ClienteService,
    private _subirArchivo: CargaImagenesService) {
      this.token = localStorage.getItem('token');
     }

  ngOnInit(): void {
    this.listar_usuario();
    $('#cliente-select2').select2();
    $('#cliente-select2').on('change', (event: any) => {
      const opcionSeleccionada = event.target.value;
      this.documentoCliente = opcionSeleccionada;
      this.opcionSeleccionada = this.documentoCliente
      this.consultarContrato();
    });
    $('#cliente-select').select2();
    $('#cliente-select').on('change', (event: any) => {
      const opcionSeleccionada = event.target.value;
      this.documentoCliente = opcionSeleccionada;
      this.opcionSeleccionada = this.documentoCliente
    });
  }

  limpiarEstado(){
        this.idContrato = '';
        this.contrato = {};
        this.pdfUrl = '';
        this.esactivo= false;
        this.contratos = [];
  }

  eliminarContrato(){
    this.isDeleting = true;
    this._ClienteService.eliminar_contrato(this.opcionSeleccionada, this.opcionSeleccionadacont, this.idContrato)
      .then(()=> {
        this.isDeleting = false;
        this.opcionSeleccionada = 'Seleccione el arrendatario';
        this.limpiarEstado();
        iziToast.show({
          title: 'Success',
          titleColor: '#1DC74C',
          class: 'text-success',
          color:'#FFF',
          position: 'topRight',
          message:'Contrato eliminado correctamente'
        })
      })
      .catch((error) => {
        this.isDeleting = false;
        Swal.fire({
          icon:'error',
          text: error
        });
      });
  }

  procesarSeleccion(opcionSeleccionada: string) {
   console.log(this.opcionSeleccionada)
  }

  consultarContrato(){
    this._ClienteService.obtener_contrato(this.opcionSeleccionada).then((showcontrato) => {
      
      if(!showcontrato.length ){
        this.limpiarEstado();
      }

      let index = 0;
      showcontrato.forEach((documento) => {
        console.log(documento.data())
        let contrato = {
          ...documento.data(),
          id: documento.id
        }
        this.contratos.push(contrato);
        if(index == 0){
          this.pdfUrl = documento.data().url;
          this.opcionSeleccionadacont = documento.data().nombre;
          this.idContrato= contrato.id;
        }
        this.esactivo= true;
        index ++;
      })
    })
    .catch(() => {
      this.limpiarEstado();
    })
  }

  selectChangecont(){
    const contratoseleccionado = this.contratos.find(x => x.nombre == this.opcionSeleccionadacont);
    console.log(contratoseleccionado)
    if( contratoseleccionado){
      this.pdfUrl = contratoseleccionado.url;
      this.opcionSeleccionadacont = contratoseleccionado.nombre;
      this.contrato = contratoseleccionado;
      this.idContrato = contratoseleccionado.id;
    }
  }

  transform(clientes: any[], searchTerm: string): any[] {
    if (!clientes || !searchTerm) {
      return clientes;
    }

    searchTerm = searchTerm.toLowerCase();

    return clientes.filter(cliente =>
      cliente.nombres.toLowerCase().includes(searchTerm) ||
      cliente.apellidos.toLowerCase().includes(searchTerm)
    );
  }

  selectChange(){
    this.pdfUrl = this.opcionSeleccionada;  
  }

  filtrarClientes(usuarios: Array<any>){
    for(let usuario of usuarios){

      if(usuario.rol == 'cliente'){
        this.clientes.push(usuario);
      }
    }
  
  }
    fileChangeEvent(event:any):void
  {
     var files : File[];
     if(event.target.files){
  
      files = event.target.files;
      console.log('impresion desde componente');
      console.log(files);
  
     }
     }
  
  cargarDocumento()
  {
    this._subirArchivo.cargarArchivo(this.archivos,this.documentoCliente, this.archivosPath)
  }
  cargarImagenes(){
    this._subirArchivo.cargarArchivo(this.imagenes, this.documentoCliente, this.imagesPath)
    }
  limpiarArchivos(){
    this.archivos = [];
  }
  limpiarImagenes(){
    this.imagenes = [];
  }
  //Listar solo los usuarios con contrato
    listar_usuario()
    {
      this._usuarioService.listar_usuario(this.token).subscribe(
        response =>{
          this.users = response.data;
          this.load_data = false;
          this.filtrarClientes(this.users);
        },
        error =>{
          console.log(error);
        }
      )
    }
    
    eliminar(id:any)
    {
      this._usuarioService.eliminar_usuario(id,this.token).subscribe(
        response =>{
          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color:'#FFF',
            position: 'topRight',
            message:'Usuario eliminado correctamente'
         
      });
      this.listar_usuario();
        },
        error  =>{
          console.log(error);
        }
  
      )
    }
    

    obtener_contrato(){
      this._imagenesService.obtener_contrato(this.usuario.email)
      .then((querySnapshot) => {
        
        querySnapshot.forEach((doc) => {
            
            
            this.contratos.push(doc.data());

            
        });

    })
    .catch((error) => {
      Swal.fire({
        icon:'error',
        text:'Error obteniendo el contrato del cliente, este error puede generarse porque actualmente el cliente no tiene ningun contrato asignado'
      });
    });
    }

}
