import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component';
import { InmueblesComponent } from './pages/inmuebles/inmuebles.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { DetalleInmuebleComponent } from './pages/detalle-inmueble/detalle-inmueble.component';
//import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './pages/admin/admin.component';
import { GridAsesoresComponent } from './components/grid-asesores/grid-asesores.component';
import { TarjetaAsesoresComponent } from './components/tarjeta-asesores/tarjeta-asesores.component';
import { SidebarComponent } from './panel/components/sidebar/sidebar.component';
import { panelRoutes  } from './panel/panel.routes';
import { LoginComponent } from './panel/components/login/login.component';
import { IndexComponent } from './pages/index/index.component';

const routes: Routes = [
  {path:'', redirectTo:'app/inicio',pathMatch:'full'},
  //{path:'inicio',component:InicioComponent},
  {path:'app',component:IndexComponent, children:[
    {path:'inicio',component:InicioComponent},
    {path:'inmuebles',component:InmueblesComponent},
    {path:'detalle/:codigo',component:DetalleInmuebleComponent},
    {path:'colaboradores',component:GridAsesoresComponent},
    {path:'tarjeta-presentacion/:codigo',component:TarjetaAsesoresComponent},
    {path:'contacto',component:ContactoComponent},
  ]},

  {path: 'admin',component:AdminComponent},
  {path: 'panel', component: SidebarComponent, children:panelRoutes},
  {path: 'login',component: LoginComponent}

 // {path:'**',pathMatch:'full', redirectTo:'inicio'}
  
];

@NgModule({
 // imports: [RouterModule.forRoot(routes,{useHash:true,relativeLinkResolution: 'legacy', initialNavigation: 'disabled',scrollPositionRestoration: 'enabled'})],
 imports: [RouterModule.forRoot(routes,{useHash:true,scrollPositionRestoration: 'enabled'})], 
 exports: [RouterModule]
})
export class AppRoutingModule { }

