import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClienteService } from 'src/app/services/cliente.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crearsolicitudcertificado',
  templateUrl: './crearsolicitudcertificado.component.html',
  styleUrls: ['./crearsolicitudcertificado.component.css']
})
export class CrearsolicitudcertificadoComponent implements OnInit {
  public cargando;
  public solicitud : any = {};
  public load_btn;
  public decodedToken;
  public token;

  constructor(
    private _ClienteService: ClienteService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
      }

      if (!this.decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
      }

    } catch (error) {
      localStorage.removeItem('token');
     
    }
  }

  registro(registroForm){
    this.solicitud.asunto = registroForm.value.asunto; // Tomar el asunto del formulario
    this.solicitud.email = this.decodedToken.email;
    this.solicitud.rol = this.decodedToken.rol; // Agregar correo desde el token
    this.solicitud.estado = 'Pendiente'; // Agregar estado

    // Llamar al método del servicio para crear el requerimiento
    this._ClienteService.crearsolicitud(this.solicitud)
      .then(() => {
        console.log('Requerimiento creado exitosamente');
        this.cerrarDialog();
      })
      .catch((error) => {
        console.error('Error al crear el requerimiento:', error);
        // Aquí puedes manejar el error de manera apropiada
      });
  }

  cerrarDialog(): void {
    const dialogRef = this.dialog.closeAll();
  }

}
