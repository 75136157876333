import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2';

declare var iziToast : any;

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.css']
})
export class CreateAdminComponent implements OnInit {

  public load_btn;

  public admin : any = {
    rol:""
  };
  public token;


  constructor(
    private _adminService : AdminService,
    private _router : Router
    
  ) {
    this.token = localStorage.getItem('token');
   }

  ngOnInit(): void {
  }




  registro(registroForm:any)
  {
    if(registroForm.valid){
      this.load_btn = true;
      this._adminService.registro_admin(this.admin,this.token).subscribe(
        response => {
          console.log(response);
          this.load_btn = false;
          Swal.fire({
            icon:'success',
            text:'Usuario registrado correctamente'
          });
          this.load_btn = false;
          this._router.navigate(['/panel/admins'])
        },
        error => {
          Swal.fire({
            icon:'error',
            text:'No fue posible crear el usuario, por favor contactar al equipo de soporte'
          })
        }
      )

    }else{
      Swal.fire({
        icon:'success',
        text:'Los datos del formulario no son válidos'
      });
    }
  }

}
