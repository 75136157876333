import { Component, OnInit, ChangeDetectorRef, NgZone, ViewChild, ElementRef } from '@angular/core';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MapsAPILoader} from '@agm/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UsuarioService } from '../../../../services/usuario.service';
import { Propietario } from '../../../clases/propietario';
import { NgForm } from '@angular/forms';


declare var $: any;
declare var iziToast: any;

@Component({
  selector: 'app-update-cliente',
  templateUrl: './update-cliente.component.html',
  styleUrls: ['./update-cliente.component.css']
})

export class UpdateClienteComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings = {};
  public usuario : any = {};
  public load_btn = false;
  public imgSelect: any | ArrayBuffer = '../../../../../assets/img/01.jpg';
  public token;
  public id : any;
  public url;
  public file: any
  public asesores : Array<any> = [];
  public propietarios : Array<any> = [];
  public propietarioSeleccionado : Array<any> = [];
  public desestPropietario;
  public zoom =11;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    private _propiedadService : PropiedadService,
    private _router: Router,
    public _route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _usuarioService: UsuarioService
  ) { 
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {

    this._route.params.subscribe(

      params  => {
        this.id = params['id'];
        console.log(this.id)
        this._usuarioService.obtener_usuario_id(this.id,this.token).subscribe(
          response  => {
            if(response.data == undefined)
            {
              this.usuario = undefined;
            }else{
              this.usuario = response.data;
              console.log(this.usuario)
            }
          },
          error  => {
            console.log(error)
          }
        )
      }
    )

    
  }

  actualizar(actualizarForm)
  {
    if(actualizarForm.valid){

      var data : any = {};

      data.nombres = this.usuario.nombres;
      data.apellidos = this.usuario.apellidos;
      data.email = this.usuario.email;
      data.telefono = this.usuario.telefono;
      data.rol = this.usuario.rol;
      this.load_btn = true;

      console.log(data)

      this._usuarioService.actualizar_usuario(data,this.id,this.token).subscribe(
        response => {

          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color:'#FFF',
            position: 'topRight',
            message:'El cliente se ha actualizado correctamente'
      });
            this.load_btn = false;

            this._router.navigate(['/panel/clientes'])
        },
        error =>
        {
          console.log(error);
          this.load_btn = false;
        }
      )

    }else{
      iziToast.show({
        title: 'Error',
        titleColor: 'FF0000',
        class: 'text-danger',
        color:'red',
        position: 'topRight',
        message:'Los datos de formulario no son validos'
  });
  this.load_btn = false;
    }
  }

}


