
<!-- Page title-->
<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Inmuebles</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo lista todos los inmuebles registrados.</p>
        <p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap btn-modulo" [routerLink]=" ['/panel/propiedades/registro'] ">Registrar nuevo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>
    </div>
</div>

<div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Filtro de propiedades</h5>
    </div>

    <div class="card-header">
        <div class="row">
            <div class="col-md-8">
                <div class="input-group mb-3">
                    <input class="form-control" type="text" name="filtro" [(ngModel)]="filtro"  placeholder="Código">
                    <button class="btn btn-primary btn-cancelar" type="button" (click)="filtrar()">Buscar</button>
                </div>
            </div>

            <div class="col-md-4">
                <select class="form-select custom-select" (change)="order_by_status()" name="sort_status" [(ngModel)]="sort_status">
                            <option value="Todos">Todos</option>
                            <option value="Venta ">Venta</option>
                            <option value="Renta ">Renta</option>
                          </select>
            </div>
        </div>

    </div>



    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead class="table-dark">
                    <tr>
                        <th scope="col">Titulo</th>

                        <th scope="col">Ubicación</th>
                        <th scope="col">Estatus</th>
                        <th scope="col">Precio</th>
                        <th scope="col">OP</th>
                    </tr>
                </thead>
                <tbody *ngIf="load_data">
                    <tr>
                        <td class="text-center" colspan="7">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ng-container *ngIf="!load_data">
                    <tbody *ngFor=" let item of propiedades| slice:(page1-1) * pageSize: (page1-1) * pageSize + pageSize  ;">
                        <tr>

                            <td>
                                <h6 style="padding-bottom:0px;margin-bottom: 0px;">
                                    <!-- Square thumbnail -->
                                    <img style="width:50px;" [src]="item.portada" class="img-thumbnail rounded-0" alt="Square image">
                                    <a [routerLink]=" ['/panel/propiedades',item._id] " style="text-decoration: none; font-size: 1em; color: #0e3144;">{{item.titulo}} </a>
                                </h6>
                            </td>

                            <td> {{item.ciudad}} </td>


                            <td> {{item.oferta}} </td>
                            <td> {{item.precio|currency}} </td>
                            <td>
                                <div class="btn-group dropdown d-inline-block mb-3 mr-2 ">
                                    <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Opciones <span><i class="bi bi-caret-down-fill"></i></span>
                              </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" [routerLink]=" ['/panel/propiedades',item._id] ">Editar</a>
                                        <a class="dropdown-item" style="cursor: pointer;" (click)="abrirDialogo(item._id, 'Eliminar ' + item.titulo)" >Eliminar</a>
                                        <a class="dropdown-item" [routerLink]=" ['/panel/propiedades/galeria',item._id] ">Galeria</a>

                                    </div>
                                </div>



                                <!--<div class="modal fade" id="delete-{{item._id}}" tabindex="-1" style="display: none;" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" style="z-index: 9999;" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title">Eliminar, {{item.titulo}} </h4>
                                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p class="fs-sm">¿Deseas eliminar este inmueble?</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button class="btn btn-secondary btn-sm" type="button" data-bs-dismiss="modal">Cancelar</button>
                                                <button class="btn btn-primary btn-sm" data-bs-dismiss="modal" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                                <!--<section [ngClass]="{'modal': !showModal, 'modal-show': showModal}" id="target">
                                    
                                       
                            
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title">Eliminar, {{item.titulo}} </h4>
                                                    <button class="btn-close" type="button" (click)="hideModal()" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p class="fs-sm">¿Deseas eliminar este inmueble?</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button class="btn btn-secondary btn-sm" type="button" (click)="hideModal()">Cancelar</button>
                                                    <button class="btn btn-primary btn-sm" data-bs-dismiss="modal" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                                                </div>
                                           
                                        </div>
                            
                            
                            
                                     </div>
                            
                                </section>-->


                            </td>
                        </tr>
                    </tbody>
                </ng-container>

            </table>
        </div>



    </div>

    <div class="card-footer ">


        <ngb-pagination [(page)]="page1" [pageSize]="pageSize" [collectionSize]="propiedades.length" [maxSize]="4">

        </ngb-pagination>


    </div>


</div>