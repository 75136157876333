import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FileItem } from 'src/app/models/file-item';
import { ClienteService } from '../../../services/cliente.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-cargar-factura',
  templateUrl: './cargar-factura.component.html',
  styleUrls: ['./cargar-factura.component.css']
})
export class CargarFacturaComponent implements OnInit {

  public facturas: Array<any> = [];
  public load_btn = false;
  public load_data = false;
  public pago ;
  public valorFactura;
  public nombres;
  public voucher;
  public estaSobreElemento: boolean;
  imagenes: FileItem[] = [];
  public usuario;
  public token;
  public cliente;
  public fecha = new Date(Date.now());
  constructor(
    private _clienteService: ClienteService,
    private router: ActivatedRoute,
    @Inject(LOCALE_ID) public locale: string) { 

      this.router.paramMap.subscribe(params => {
         this.pago = {
          nombre: params.get('nombre'),
          valor: params.get('valor'),
          fecha: params.get('fecha'),
          comentarios: params.get('comentarios'),
          cliente: params.get('cliente'),
          urlSoporte: params.get('urlSoporte')
        };
      });
    
    this.token = localStorage.getItem('token');
     
    }
    
  ngOnInit(): void {

  }
  
  cargarFactura(){
  this._clienteService.cargarArchivoFactura(this.imagenes,this.pago.cliente,this.fecha,this.pago.valor)
  }

  
  limpiarFactura(){
  this.facturas = [];
  }


}
