
<div class="card-header border-bottom container  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Administradores</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo lista todos los administradores registrados.</p>
        <p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap btn-modulo" [routerLink]=" ['/panel/admins/registro'] ">Registrar nuevo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>
    </div>
</div>

<div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Administradores registrados</h5>
    </div>

    <div class="card-header">
    </div>



    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead class="table-dark">
                    <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellidos</th>
                        <th scope="col">Dirección</th>
                        <th scope="col">Rol</th>
                        <th scope="col">Teléfono</th>
                        <th scope="col">OP</th>
                    </tr>
                </thead>
                <tbody *ngIf="load_data">
                    <tr>
                        <td class="text-center" colspan="7">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ng-container *ngIf="!load_data">
                    <tbody *ngFor=" let item of admins| slice:(page-1) * pageSize: (page-1) * pageSize + pageSize  ;">
                        <tr>

                            <td>
                                {{item.nombres}}
                            </td>
                            <td> {{item.apellidos}} </td>
                            <td> {{item.email}} </td>


                            <td> {{item.rol}} </td>
                            <td> {{item.telefono}} </td>
                            <td>
                                <div class="btn-group dropdown d-inline-block mb-3 mr-2 ">
                                    <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        Opciones <span><i class="bi bi-caret-down-fill"></i></span>
                      </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                        <a class="dropdown-item" style="cursor: pointer;" (click)="abrirDialogo(item.uid, ' Eliminar usuario ' + item.email)" >Eliminar</a>
                                    </li>
                                    </ul>
                                </div>




                            </td>
                        </tr>
                    </tbody>
                </ng-container>

            </table>
        </div>



    </div>

    <div class="card-footer ">


        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="admins.length" [maxSize]="4">

        </ngb-pagination>


    </div>


</div>