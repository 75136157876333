<div class="row" style="width: 100%;
height: 100%;margin-top: 80px;">
    <div class="col-lg-7 mx-auto">
        <div class=" px-md-5 px-4">
          <h3 class="modal-title mt-4  text-center mb-3">Iniciar sesión</h3>
          <img src="../../../assets/images/logo-valorinmobiliaria.png" alt="logo vabait" class="logo_login mx-auto mb-5">
         <p class="font-size-sm text-muted text-center">Inicia sesión con tus credenciales de acceso</p>
         <form #loginForm="ngForm" (ngSubmit)="login(loginForm)" >
           <div class="form-group mt-3">
             <label for="signin-email">Correo electrónico</label>
             <input type="email" class="form-control" name="email" [(ngModel)]="user.email" placeholder="Tú Correo" required="">
           </div>
             <div class="form-group mt-3">
                 <label for="signin-password" class="form-label">Contraseña</label>
                 <div class="cs-password-toggle input-group-overlay">
                 <input [type]="fieldTextType ? 'text' : 'password'" class="form-control appended-form-control" name="password"  [(ngModel)]="user.password"  placeholder="Tú contraseña" required>
                 <div class="input-group-append-overlay">
                     <label class="btn cs-password-toggle-btn input-group-text">
                     <input type="checkbox" class="custom-control-input">
                     <i class="" [ngClass]="{'bi bi-eye-slash-fill': !fieldTextType,
                                              'bi bi-eye-fill':fieldTextType  }"  (click)="showPassword()" ></i>
                     <span class="sr-only">Mostrar contraseña</span>
                     </label>
                 </div>
                 </div>
             </div>


           <button type="submit" class="btn btn-primary btn-block btn_iniciar mt-3">Ingresar</button>

         </form>
          </div>
    </div>
</div>
