import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import  firebase from 'firebase/app';
import "firebase/firestore";
import 'firebase/storage';
import "firebase/database";

firebase.initializeApp(environment.firebase);

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public db = firebase.firestore();
  public RTDB = firebase.database();
  public storage = firebase.storage();
  public firebase = firebase;

  constructor() { }
}
