import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import {CommonModule} from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal, DataModalDelete } from '../../../clases/data-modal';
import { ModalDialogComponent } from '../../../components/modal-dialog/modal-dialog.component';
import { FileItem } from 'src/app/models/file-item';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';

declare var iziToast:any

@Component({
  selector: 'app-mostrar-clientes',
  templateUrl: './mostrar-clientes.component.html',
  styleUrls: ['./mostrar-clientes.component.css']
})
export class MostrarClientesComponent implements OnInit {

  estaSobreElemento = false;
  public archivos: FileItem[] = [];
  public imagenes: FileItem[] = [];
  //public files: File[] = [];
  public filtro = "";
  public token;
  public load_data = true;
  public users : Array<any> = [];
  public clientes : Array<any> = [];
  public page = 1;
  public pageSize = 10;
  public imagesPath = 'imagenes';
  public archivosPath = 'inventario';
  public documentoCliente = '';
  public opcionSeleccionada = 'Seleccione el cliente';
  public sort_status = 'Todos';
  
 constructor(
    private _usuarioService : UsuarioService,
    private _subirArchivo: CargaImagenesService,
    public dialog: MatDialog
  ) {
    this.token = localStorage.getItem('token');
   }

   abrirDialogo(id: any, titulo: string, email: string) {
    const dialogo1 = this.dialog.open(ModalDialogComponent, {
      data: new DataModalDelete(id, titulo, email, '¿Estás seguro de eliminar este usuario?')
    });

    dialogo1.afterClosed().subscribe(datos => {
    this.eliminar(datos.id,datos.email)
    });
  }

  abrirDialogoResetearClave(id: any, titulo: string) {
    const dialogo1 = this.dialog.open(ModalDialogComponent, {
      data: new DataModal(id, titulo, '¿Estás seguro que desea resetear la clave a 0000?')
    });

    dialogo1.afterClosed().subscribe(datos => {
    this.restablecer_clave(datos.id)
    });
  }

  ngOnInit(): void {
    this.listar_usuario();

  }

  selectChange(){
    this.documentoCliente = this.opcionSeleccionada;
  }

filtrarClientes(usuarios: Array<any>){
  for(let usuario of usuarios){
    if(usuario.rol == 'cliente'){
      this.clientes.push(usuario);
    }
  }

}
  fileChangeEvent(event:any):void
{
   var files : File[];
   if(event.target.files){

    files = event.target.files;
    console.log('impresion desde componente');
    console.log(files);

   }
   }

cargarDocumento(){
this._subirArchivo.cargarArchivo(this.archivos,this.documentoCliente, this.archivosPath)
}
cargarImagenes(){
  this._subirArchivo.cargarArchivo(this.imagenes, this.documentoCliente, this.imagesPath)
  }
limpiarArchivos(){
  this.archivos = [];
}
limpiarImagenes(){
  this.imagenes = [];
}
  listar_usuario()
  {
    this._usuarioService.listar_usuario(this.token).subscribe(
      response =>{
        this.users = response.data;
        this.load_data = false;
        this.filtrarClientes(this.users);
      },
      error =>{
        console.log(error);
      }
    )
  }

  filtrar()
  {
    if(this.filtro){

      this.obtener_usuarios_filtro();

    }else {
      this.obtener_usuarios_filtro();

    }

  }

  order_by_status()
{
  console.log("prueba")
}
  
  eliminar(id:any, email: string)
  {
    console.log(email)

    this._usuarioService.eliminar_contrato_cliente(email)
    this._usuarioService.eliminar_contrato_propietario(email)
    this._usuarioService.eliminar_inventario_cliente(email)
    this._usuarioService.eliminar_imagenes_inventario_cliente(email)
    this._usuarioService.eliminar_mantenimientos_cliente(email)
    this._usuarioService.eliminar_evidencias_mantenimientos_cliente(email)
    this._usuarioService.eliminar_usuario(id,this.token).subscribe(
      response =>{
        iziToast.show({
          title: 'Success',
          titleColor: '#1DC74C',
          class: 'text-success',
          color:'#FFF',
          position: 'topRight',
          message:'Usuario eliminado correctamente'
       
    });
    this.listar_usuario();
      },
      error  =>{
        console.log(error);
      }

    )
  }

  obtener_usuarios_filtro()
  {
    this._usuarioService.listar_usuarios_filtro(this.filtro,this.token).subscribe(
      response  => {
        
        this.users = response.data;
        console.log(this.users)
        this.load_data = false;
      },
      error  => {
        console.log(error);
      }
    )

   
  }

  restablecer_clave(id:any)
  {
    this._usuarioService.resetear_clave(id,this.token).subscribe(
      response =>{
        iziToast.show({
          title: 'Success',
          titleColor: '#1DC74C',
          class: 'text-success',
          color:'#FFF',
          position: 'topRight',
          message:'La contraseña se restablecio correctamente, recuerda informarle al usuario que su contraseña es: 0000'
       
    });
      },
      error  =>{
        console.log(error);
      }

    )
  }

}
