<ng-container *ngIf="!cargando; else loadingTemplate">
    <ng-container *ngIf="mostrarInventario; else elseTemplate">
        <div *ngIf="showHeaders; else alternativeHeaders" class="card-header border-bottom  mt-5">
            <h1 class="mt-2 mt-md-4 mb-3 pt-3">Visualizar inventario</h1>
            <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                <p class="text-muted">Este módulo le permite acceder al inventario del inmueble y a las fotos de
                    evidencia.
                </p>
            </div>
        </div>
        <ng-template #alternativeHeaders>
            <div class="card-header border-bottom  mt-5">
                <a [routerLink]="['/panel/ver_requerimientos']" style="text-decoration: none;">
                    <h3 class="card-title fw-bold" style="cursor: pointer;">
                    <i class=" fa fa-solid fa-arrow-left"> </i>
                        Regresar a mis requerimientos
                    </h3>
                </a>
                <h1 class="mt-2 mt-md-4 mb-3 pt-3">Selección de inventario</h1>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">A continuación seleccione el inventario asociado a su solicitud de
                        mantenimiento.
                    </p>
                </div>
            </div>
        </ng-template>
        <section class="property-single nav-arrow-b">
            <div class="container">
                <div class="row">
                    <!--Inicio audiovisual-->
                    <div class="col-md-10 offset-md-1">
                        <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="inventario-tab" data-bs-toggle="tab"
                                    data-bs-target="#inventario" type="button" role="tab" aria-controls="home"
                                    aria-selected="true">Inventario</button>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="evidencias-tab" data-bs-toggle="pill" href="#evidencias"
                                    role="tab" aria-controls="evidencias" aria-selected="false">Evidencias
                                    inventario</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <div class="tab-content" id="pills-tabContent">
            <div class="card-header">
                <div class="container">
                    <div class="row">
                        <div *ngIf="inventarios.length > 1" class="col-6 mt-3">
                            <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                            <div class="input-group mb-3">
                                <select class="custom-select" id="cliente-select" [(ngModel)]="opcionSeleccionada"
                                    (change)="selectChange()">
                                    <!--<option selected disabled>Seleccione el contrato</option>-->
                                    <option *ngFor="let inventario of inventarios" value="{{inventario.url}}">
                                        {{inventario.nombre}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 mt-3">
                            <button class="btn btn-enviar" (click)="verSolicitudRequerimiento()">
                                <span style="font-size: 1.1 rem;">
                                    Generar requerimiento
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show active" id="inventario" role="tabpanel" aria-labelledby="home-tab">
                <!--Inicio inventario-->
                <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
                    <div class="card-body">

                        <ngx-extended-pdf-viewer [src]="pdfUrl" [handTool]="true" [showHandToolButton]="true"
                            backgroundColor="#ffffff" [height]="'100vh'" [useBrowserLocale]="true"
                            [mobileFriendlyZoom]="'150%'" [zoom]="'page-width'" [showSidebarButton]="false"
                            [showFindButton]="false" [showPagingButtons]="false" [showZoomButtons]="true"
                            [showPresentationModeButton]="true" [showOpenFileButton]="false" [showPrintButton]="false"
                            [showDownloadButton]="false" [showBookmarkButton]="false"
                            [showSecondaryToolbarButton]="false" [showRotateButton]="false" [showHandToolButton]="false"
                            [showScrollingButton]="false" [showSpreadButton]="false" [showPropertiesButton]="false"
                            [sidebarVisible]="false" [listenToURL]="false">
                        </ngx-extended-pdf-viewer>
                    </div>
                </div>
                <!--Fin inventario-->
            </div>
            <div class="tab-pane fade " id="evidencias" role="tabpanel" aria-labelledby="pills-evidencias-tab">
                <!--Inicio Galeria inventario-->
                <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
                    <div class="card-body">
                        <div class="wrapper">
                            <div class="pswp-gallery" id="gallery--getting-started">
                                <a *ngFor="let imagen of imagenes" [href]="imagen.url" data-pswp-width="1620"
                                    data-pswp-height="1080" target="_blank">
                                    <img [src]="imagen.url" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="card-header border-bottom  mt-5">
            <h1 class="mt-2 mt-md-4 mb-3 pt-3">Solicitud de mantenimiento</h1>
            <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                <p class="text-muted">
                    A continuación ingrese el asunto de la solicitud,
                    acompañado de una descripción detallada y la evidencia fotográfica.
                </p>
            </div>
        </div>
        <!--inicia tarjeta-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-body">
                <div class="container">
                    <!--Inicia Mostrar requerimientos-->
                    <div class="card box-shadow-sm">
                        <form action="">
                            <ng-container>
                                <div class="card-body ">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Asunto</label>
                                                    <input type="text" class="form-control" name="asunto"
                                                        [(ngModel)]="asunto">
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Detalle de la solicitud</label>
                                                    <textarea class="form-control mt-0"
                                                        placeholder="Dejenos sus comentarios" id="floatingTextarea"
                                                        name="comentarios" [(ngModel)]="detalle"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </form>
                        <!--inicia Subida de archivos-->
                        <div class="card">
                            <div style="max-width: 1200px; min-width: auto;">
                                <div class="card-header">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-sm">
                                                <h1>Evidencia fotográfica</h1>
                                                <h3 style="margin-bottom: 0px;">Seleccione o arrastre los archivos</h3>
                                            </div>
                                            <div class="col-sm d-flex justify-content-end align-items-end">
                                                <div class="file-select" id="src-file1">
                                                    <input appNgDropFiles [archivos]="archivos" type="file"
                                                        multiple="true" aria-label="Archivo">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div appNgDropFiles [archivos]="archivos" (mouseSobre)="estaSobreElemento = $event"
                                    [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                                    <i class="fa fa-solid fa-upload fa-8x"> </i>
                                    <span> Suelte los archivos en esta zona para subir</span>
                                </div>
                                <div class="progress mt-2" *ngFor="let archivo of archivos">
                                    <div class="progress-bar verde" role="progressbar"
                                        [ngStyle]="{ 'width': archivo.progreso + '%' }">
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer d-flex justify-content-end">
                                <button class="btn btn-cancelar" (click)="limpiarArchivos()">Remover archivos</button>
                            </div>
                        </div>
                        <!--finaliza subida de archivos-->
                        <div class="card-footer">
                            <button *ngIf="!guardandoSolicitud" class="btn btn-enviar mr-3" (click)="guardarRequerimiento()" [disabled]="!asunto || !detalle || !archivos.length">Enviar requerimiento</button>
                            <button *ngIf="guardandoSolicitud" type="button" class="btn btn-secondary" disabled>
                                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                                Creando...
                            </button>
                            <button class="btn btn-cancelar" (click)="verInventario()">Regresar</button>
                        </div>
                    </div>
                    <!--Termina mostrar requerimientos-->
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>