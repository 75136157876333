<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Actualizar integrante de equipo</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">


    </div>


</div>

<form #actualizarForm="ngForm" (ngSubmit)="actualizar(actualizarForm)">
    <div class="card box-shadow-sm">
        <div class="card-header">
            <h5 style="margin-bottom: 0px;">Información del miembro del equipo</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-12 form-group">
                                    <label for="">Foto de Perfil del miembro de equipo</label>
                                    <div class="custom-file">
                                        <input class="custom-file-input" type="file" id="file-input" (change)="onFileSelected($event)">
                                        <label class="custom-file-label" id="input-portada" for="file-input" style="white-space:nowrap;overflow:hidden">Seleccionar imagen</label>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <!-- Muestra el indicador de carga si load_data es true -->
                                    <div *ngIf="load_data" class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Cargando...</span>
                                    </div>
                                    <!-- Muestra la imagen si load_data es false -->
                                    <img *ngIf="!load_data" [src]="asesor.foto" class="img-thumbnail" alt="Rounded image">
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="col-lg-12 form-group">
                            <br>
                            <label for="">Nombres</label>
                            <input type="text" class="form-control" placeholder="Nombres" required name="nombres" [(ngModel)]="asesor.nombre">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Cargo</label>
                            <input type="text" class="form-control" placeholder="Cargo" required name="Cargo" [(ngModel)]="asesor.cargo">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Presentacion</label>
                            <textarea class="form-control" rows="5" placeholder="Presentacion" required name="presentacion" [(ngModel)]="asesor.presentacion"></textarea>
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Celular</label>
                            <input type="tel" class="form-control" placeholder="celular" required name="celular" [(ngModel)]="asesor.celular">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">correo</label>
                            <input type="tel" class="form-control" placeholder="correo" required name="correo" [(ngModel)]="asesor.correo">
                        </div>

                    
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn  mr-3 btn-enviar" type="submit">Actualizar</button>
            <button class="btn btn-cancelar" [routerLink]=" ['/panel/asesores'] ">Regresar</button>
        </div>
    </div>



</form>