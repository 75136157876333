import { Directive, EventEmitter, HostListener, ElementRef, Input,Output } from '@angular/core';
import { FileItem } from '../models/file-item';

@Directive({
  selector: '[appNgDropFiles]'
})
export class NgDropFilesDirective {

  @Input() archivos: FileItem[] = [];
@Output() mouseSobre: EventEmitter<boolean> = new EventEmitter();

public reader = new FileReader();

  constructor() { }

  @HostListener('dragover', ['$event'])
  public dragOnEnter(event: any){
this.mouseSobre.emit(true)
this._prevenirAperturaAutomatica(event)
  }
  @HostListener('dragleave', ['$event'])
  public dragOnLeave(event: any){
this.mouseSobre.emit(false)
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any){

const dataTransfer = this._getTransferData(event);

if(!dataTransfer){
  return;
}

this._extraerArchivos(dataTransfer.files)
this._prevenirAperturaAutomatica(event)

this.mouseSobre.emit(false)
  }

  @HostListener('change', ['$event.target.files'])
  public onChange(event: any){

const dataTransfer = event;


if(!dataTransfer){
  return;
}

this._extraerArchivos(dataTransfer)



  }

  private _getTransferData(event: any){

    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  private _extraerArchivos(archivosLista: FileList){
    
//Cambiar tipo Object de los archivos por Array
for(const propiedad in Object.getOwnPropertyNames( archivosLista )){
const archivoTemporal = archivosLista[propiedad];

if( this._cargarArchivo( archivoTemporal ) ){

   let nuevoArchivo = new FileItem( archivoTemporal );


  this.archivos.push( nuevoArchivo );
  this._getTempUrl(this.archivos);
}


}

console.log(this.archivos)
  }

  private _getTempUrl(archivos: FileItem[]){
for(let item of archivos){
  let reader = new FileReader();
  reader.onload = (e: any) => {
    item.tempUrl = e.target.result;
  }
  reader.readAsDataURL(item.archivo);
}
    
    
  }

  //Validaciones

  private _cargarArchivo(archivo: File): boolean{

    //if(!this._archivoExiste(archivo.name) && this._validarTipoArchivo(archivo.type)){
      if(!this._archivoExiste(archivo.name) ){
     return true; //Si cumple las condiciones, se envia al server para subirlo a la carpeta
    }
    return false;
  }

  private _prevenirAperturaAutomatica(event){
    event.preventDefault();
    event.stopPropagation();
  }

  private _archivoExiste(nombreArchivo: string):boolean{
for(let archivo of this.archivos){
  if(archivo.nombreArchivo == nombreArchivo){
    console.log('Archivo'+ nombreArchivo + 'ya se cargó');
    return true;
  }
}

    return false;
  }

  private _validarTipoArchivo(tipoArchivo: string): boolean{
    return (tipoArchivo == ''|| tipoArchivo == undefined )? false : tipoArchivo.startsWith('image' || 'doc');
  }

}
