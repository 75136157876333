import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { FileItem } from 'src/app/models/file-item';

declare var iziToast:any

@Component({
  selector: 'app-detalle-certificado',
  templateUrl: './detalle-certificado.component.html',
  styleUrls: ['./detalle-certificado.component.css']
})
export class DetalleCertificadoComponent implements OnInit {

  estaSobreElemento = false;
  public archivos: FileItem[] = [];
  public id : any;
  public requerimiento : any = {

  };
  public imagenes : any [];
  public load_btn = false;

  constructor(
    private _router: Router,
    private _ClienteService: ClienteService,
    public _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ObtenerCertificado();
  }

  ObtenerCertificado() {
    this._route.params.subscribe(params => {
      this.id = params['id'];
      this._ClienteService.obtener_Certificado_by_id(this.id).then(mantenimiento => {
        if (mantenimiento) {
          this.requerimiento = mantenimiento
          console.log(this.requerimiento)
        } else {
          console.error("No se encontró ningún mantenimiento con el ID especificado.");
        }
      }).catch(error => {
        console.error("Error al obtener el mantenimiento:", error);
      });
    });
  }

  guardarCambios(){

    this._route.params.subscribe(params => {
      this.id = params['id'];
      this._ClienteService.actualizar_certificado(this.id, this.requerimiento)
      .then(() => {
              iziToast.show({
                title: 'Success',
                titleColor: '#1DC74C',
                class: 'text-success',
                color:'#FFF',
                position: 'topRight',
                message:'Estatus actualizado'
                });
      })
      .catch(error => {
        console.error('Error al actualizar el requerimiento:', error);
      });
    });
  }


}
