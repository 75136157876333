<section class="intro-single">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <div class="title-single-box">
          <h1 class="title-single">Contáctanos</h1>
          <span class="color-text-a">Llena este formulario y nos pondremos en contacto contigo a la brevedad.</span>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
      </div>
    </div>
  </div>
</section>





<section class="contact">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-7">
            <form #contactoForm="ngForm" (ngSubmit)="enviar_mensaje(contactoForm)" class="form-a contactForm" role="form">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <input type="text" name="nombres" required [(ngModel)]="mensaje.nombres" class="form-control form-control-lg form-control-a" placeholder="Tu nombre">

                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <input name="email" type="email" required [(ngModel)]="mensaje.email" class="form-control form-control-lg form-control-a" pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$" placeholder="Tu email">

                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <input type="url" name="asunto" required [(ngModel)]="mensaje.asunto" class="form-control form-control-lg form-control-a" placeholder="Asunto">

                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <input type="url" name="telefono" required [(ngModel)]="mensaje.telefono" class="form-control form-control-lg form-control-a" placeholder="Telefono">

                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-group">
                    <textarea required class="form-control" [(ngModel)]="mensaje.mensaje" name="mensaje" cols="45" rows="8" placeholder="Mensaje"></textarea>

                  </div>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="btn btn-a">Enviar mensaje</button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-5 section-md-t1">
            <div class="icon-box section-b2">
              <div class="icon-box-icon">
                <span class="ion-ios-paper-plane"></span>
              </div>
              <div class="icon-box-content table-cell">
                <div class="icon-box-title">
                  <h4 class="icon-title">Envíanos un mensaje</h4>
                </div>
                <div class="icon-box-content">
                  <p class="mb-1">Email
                    <span class="color-a">contacto@vabait.co</span>
                  </p>
                  <p class="mb-1">Teléfono
                    <span class="color-a">+57 321 846 5585</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="icon-box section-b2">
            </div>
            <div class="icon-box">
              <div class="icon-box-icon">
                <span class="ion-ios-redo"></span>
              </div>
              <div class="icon-box-content table-cell">
                <div class="icon-box-title">
                  <h4 class="icon-title">Redes sociales</h4>
                </div>
                <div class="icon-box-content">
                  <div class="socials-footer">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a class="ms-3" href="https://wa.me/573218465585"><img src="assets/images/whatsapp.png" alt="logo whatsapp" class="logo-vabait"></a><!-- <i class="fa fa-whatsapp"></i> -->
                      </li>
                      <li class="list-inline-item">
                        <a class="ms-3" href="https://www.instagram.com/vabaitinmobiliaria/"><img src="assets/images/instagram.png" alt="logo whatsapp" class="logo-vabait"></a><!-- <i class="fa fa-whatsapp"></i> -->
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="intro-single">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <div class="title-single-box">
          <h1 class="title-single">¿Deseas arrendar con nosotros?</h1>
          <span class="color-text-a">Si deseas arrendar con nosotros debes hacer el estudio de crédito, aquí te damos las opciones para que lo realices fácil, rápido y COMPLETAMENTE GRATIS</span>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
      </div>
    </div>
  </div>
</section>

<section class="contact">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-5">
            <button type="button" class="btn btn-a" onclick="window.open('https://analisisweb.ellibertador.co/analisis-persona-natural/arrendatario', '_blank')">Persona Natural</button>
          </div>
          <div class="col-md-5">
            <button type="button" class="btn btn-a" onclick="window.open('https://analisisweb.ellibertador.co/analisis-persona-natural/arrendatario-juridico', '_blank')">Persona Jurídica</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
