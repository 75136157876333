import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PropiedadService } from '../../../../services/propiedad.service';
import { Observable } from 'rxjs';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-index-requerimientos',
  templateUrl: './index-requerimientos.component.html',
  styleUrls: ['./index-requerimientos.component.css']
})
export class IndexRequerimientosComponent implements OnInit {

  public load_data = true;
  public decodedToken;
  public token;
  public propiedades_propietario: Array<any> =[];
  public tieneRequerimiento: boolean;
  public solicitudesMantenimiento: Array<any> =[];
  public cargando = false;

  constructor(
    private _propiedad_service: PropiedadService,
    private _clienteService: ClienteService
  ) { }

  ngOnInit(): void {
  //Consultar el token el propietario
  this.token = localStorage.getItem('token');
 
  try {
    const helper = new JwtHelperService();
    this.decodedToken = helper.decodeToken(this.token);
 
  if(helper.isTokenExpired(this.token))
    {
        localStorage.clear();
    }
 
    if (!this.decodedToken) {
      localStorage.removeItem('token');
      
    }
   
 
  } catch (error) {
    localStorage.removeItem('token');
  
  }

   this.consultarMantenimiento();


  }
  consultarMantenimiento(){
    this.cargando = true;
    this._clienteService.obtener_mantenimiento_propietario(this.decodedToken.email)
      .then((showcontrato) => {
        const solicitudesMantenimiento = [];
        showcontrato.forEach( async(documento) => {
          solicitudesMantenimiento.push(documento.data());
        })

        const solicitudesMantenimientoOrdenadas = solicitudesMantenimiento.sort((a, b) => {
          // Compara los valores de los campos 'id' de cada objeto
          if (a.id < b.id) {
            return -1; // a debe venir antes que b
          }
          if (a.id > b.id) {
            return 1; // b debe venir antes que a
          }
          return 0; // Son iguales, no se cambian de posición
        });

        this.solicitudesMantenimiento = solicitudesMantenimientoOrdenadas;
      })
    .finally(() => this.cargando = false)
  }

}
