import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataModal } from '../../clases/data-modal';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.css']
})
export class ModalDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @ Inject(MAT_DIALOG_DATA) public data: DataModal
  ) { }

  ngOnInit(): void {
  }

  cancelar() {
   this.dialogRef.close();
  }

}
