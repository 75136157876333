

<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Pagos y facturas</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo muestra los pagos realizados, permite cargar la factura y consultar el histórico de facturas.</p>

    </div>
</div>
<section class="property-single nav-arrow-b">
    <div class="container">


        <div class="row">
            <!--Inicio tabs-->
            <div class="col-md-10 offset-md-1">
                <ul class="nav nav-pills-a nav-pills mb-3 section-t3 d-flex justify-content-center" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="inventario-tab" data-bs-toggle="tab" data-bs-target="#pagos" type="button" role="tab" aria-controls="home" aria-selected="true">Pagos</button>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="evidencias-tab" data-bs-toggle="pill" href="#facturas" role="tab" aria-controls="evidencias" aria-selected="false">Facturas</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="pagos-clientes" data-bs-toggle="pill" href="#pagosClientes" role="tab" aria-controls="pagos-clientes" aria-selected="false">Registrar pago</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="facturas-propietarios" data-bs-toggle="pill" href="#facturasPropietarios" role="tab" aria-controls="facturas-propietarios" aria-selected="false">Facturas Propietarios</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div class="tab-content d-flex justify-content-center" id="pills-tabContent">
    <div class="tab-pane fade show active w-75" id="pagos" role="tabpanel" aria-labelledby="home-tab">
        <!--Inicio inventario-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <!--Inicio filtro-->
                <div class="container border rounded-3">
                    <div class="row">
     
                       
            
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3 mt-3">
                            <div class="input-group">
                                <form class="example-form">
                                    <mat-form-field class="full-width" appearance="fill">
                                      <mat-label>Filtrar cliente</mat-label>
                                      <input type="text"
                                             placeholder="Seleccione un cliente"
                                             aria-label="Filtrar cliente"
                                             matInput
                                             [formControl]="myControl"
                                             [matAutocomplete]="auto">
                                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option" >
                                          {{option}}
                                        </mat-option>
                                      </mat-autocomplete>
                                    </mat-form-field>
                                  </form>
                        
                                   </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3 mt-3">
                            <div class="input-group">

                                <mat-form-field class="full-width" appearance="fill">
                                    <mat-label>Rango de fechas</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                      <input matStartDate placeholder="Start date" [(ngModel)]="filtro_fecha_i" >
                                      <input matEndDate placeholder="End date" [(ngModel)]="filtro_fecha_f">
                                    </mat-date-range-input>
                                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                  </mat-form-field>

                                   </div>
                        </div>

                    </div>
                </div>
                <!--Fin filtro-->

            </div>




            <div class="card-body">

                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead class="table-dark">
                            <tr>
                                
                                <th scope="col">Fecha</th>
                                <th scope="col">Valor</th>
                                <th scope="col">Opciones </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="load_data">
                            <tr>
                                <td class="text-center" colspan="4">
                                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody> 
                        <ng-container *ngIf="!load_data">
                            <tbody *ngFor=" let item of filtro | slice:(page-1) * pageSize: (page-1) * pageSize + pageSize   | filtro: myControl.value | filtroRangoFecha: filtro_fecha_i:filtro_fecha_f">
                                <tr>
                                    
                                    <td> {{ item.fecha }} </td>
                                    <td> {{ item.valor | currency:'COP':'symbol' }} </td>
                                    <td> <a [routerLink]="['/panel/facturacion/cargar_factura',item]"  class="descargar">Cargar Factura <i class="fa fa-solid fa-upload"></i></a></td>
                                </tr>
                            </tbody>
                        </ng-container>   
                    </table>
                 </div>
                

                <div class="container">

                </div>


            </div>

            <div class="card-footer ">


                <!--<ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="users.length">

                </ngb-pagination>-->


            </div>


        </div>
        <!--Fin inventario-->
    </div>
    <div class="tab-pane fade  w-75" id="facturas" role="tabpanel" aria-labelledby="pills-evidencias-tab">
        <!--Inicio inventario-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <!--Inicio filtro-->
                <div class="container border rounded-3">
                    <div class="row">
     
                       
            
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3 mt-3">
                            <div class="input-group">
                                <form class="example-form">
                                    <mat-form-field class="full-width" appearance="fill">
                                      <mat-label>Filtrar cliente</mat-label>
                                      <input type="text"
                                             placeholder="Seleccione un cliente"
                                             aria-label="Filtrar cliente"
                                             matInput
                                             [formControl]="controlFacturas"
                                             [matAutocomplete]="auto_f">
                                      <mat-autocomplete autoActiveFirstOption #auto_f="matAutocomplete" >
                                        <mat-option *ngFor="let option of filteredOptions_f | async" [value]="option" >
                                          {{option}}
                                        </mat-option>
                                      </mat-autocomplete>
                                    </mat-form-field>
                                  </form>
                        
                                   </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3 mt-3">
                            <div class="input-group">

                                <mat-form-field class="full-width" appearance="fill">
                                    <mat-label>Rango de fechas</mat-label>
                                    <mat-date-range-input [rangePicker]="picker_f">
                                      <input  matStartDate placeholder="Start date" [(ngModel)]="filtro_fecha_i_f" >
                                      <input  matEndDate placeholder="End date" [(ngModel)]="filtro_fecha_f_f">
                                    </mat-date-range-input>
                                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="picker_f"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker_f></mat-date-range-picker>
                                  </mat-form-field>

                                   </div>
                        </div>

                    </div>
                </div>
                <!--Fin filtro-->

            </div>




            <div class="card-body">

                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead class="table-dark">
                            <tr>
                                
                                <th scope="col">Fecha</th>
                                <th scope="col">Valor</th>
                                <th scope="col">Opciones </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="load_data_facturas">
                            <tr>
                                <td class="text-center" colspan="4">
                                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody> 
                        <ng-container *ngIf="!load_data_facturas">
                            <tbody *ngFor=" let item of facturas | slice:(page-1) * pageSize: (page-1) * pageSize + pageSize   | filtro: controlFacturas.value | filtroRangoFecha: filtro_fecha_i_f:filtro_fecha_f_f">
                                <tr>
                                    
                                    <td> {{ item.fecha | formatoFecha}} </td>
                                    <td> {{ item.valor | currency:'COP':'symbol' }} </td>
                                    <td> <a [href]="item.url" target="_blank"  class="descargar">Descargar Factura <i class="fa fa-solid fa-download"></i></a></td>
                                </tr>
                            </tbody>
                        </ng-container>   
                    </table>
                 </div>
                

                <div class="container">

                </div>


            </div>

            <div class="card-footer ">


                <!--<ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="users.length">

                </ngb-pagination>-->


            </div>


        </div>
        <!--Fin inventario-->
        
</div>

    <div class="tab-pane fade  w-75" id="pagosClientes" role="tabpanel" aria-labelledby="pills-evidencias-tab">
        <!--Inicio pagos clientes-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">

                <div class="card-header">
                    <div class="container border rounded-3">
                        <div class="row">
                            <!-- Listado de usuarios -->
                            <div class="input-group mb-3">
                                <span style="font-size: 1.2rem;" > 
                                    Seleccione el cliente y el comprobante de pago. 
                                    El pago quedará registrado con la fecha actual.
                                </span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
        
                                <div class="col-lg-12 form-group">
                                    <label for="">Cliente </label>
                                    <select class="custom-select" id="cliente-select" [(ngModel)]="pago.cliente" (ngModelChange)="selectChange()">
                                    
                                        <option selected disabled>Seleccione el cliente</option>
                                        <option *ngFor="let cliente of clientes | filtroAZClientes" value="{{cliente.email}}" > {{cliente.nombres}} {{cliente.apellidos}} </option>
                                        
                                    </select>
                                </div>
        
                                <div class="col-lg-6 form-group">
                                    <label for="">Fecha</label>
                                    <input type="text" class="form-control" disabled name="vigencia" [(ngModel)]="pago.fecha" >
                                </div>
        
                                <div class="col-lg-6 form-group">
                                    <label for="">Valor</label>
                                    <input type="text" class="form-control" name="valor" [(ngModel)]="pago.valor">
                                </div>
                                
                                <div class="form-floating">
                                    <textarea class="form-control"  id="floatingTextarea" name="comentarios" [(ngModel)]="pago.comentarios"></textarea>
                                    <label for="floatingTextarea">Comentarios</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                    <div class="card-header">
                        <div class="container">
                            <div class="row">
                            <div class="col-sm">
                                <h5 style="margin-bottom: 0px;">Seleccione o arrastre el comprobante de pago</h5>
                            </div>
                            <div class="col-sm">
                                <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                                <div class="file-select" id="src-file1">

                                    <input appNgDropFiles [archivos]="archivos" type="file" multiple="true" aria-label="Archivo">

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="card-body">
                    <div appNgDropFiles [archivos]="archivos" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                        <i class="fa fa-solid fa-upload fa-8x"> </i>
                        <span>   Suelte los archivos en esta zona para subir</span>

                    </div>
                    <div class="progress mt-2" *ngFor="let archivo of archivos">
                        <!--<div class="progress-bar" role="progressbar" style="width:25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>-->

                        <div class="progress-bar verde" role="progressbar" [ngStyle]="{ 'width': archivo.progreso + '%' }"></div>
                    </div>
                </div>

                <div class="card-footer d-flex justify-content-center align-items-center flex-wrap">
                    <button 
                        class="btn btn-enviar m-2" 
                        (click)="registrarPago()" 
                        [disabled]="archivos.length == 0 
                            || pago.cliente == 'Seleccione el cliente'">
                        Cargar comprobante
                    </button>
                    <button class="btn btn-cancelar" (click)="limpiarArchivos()">Remover archivo</button>
                </div>
        </div>
        <!-- Fin pagos clientes -->
        
    </div>
    <div class="tab-pane fade  w-75" id="facturasPropietarios" role="tabpanel" aria-labelledby="pills-evidencias-tab">
        <!--Inicio pagos clientes-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
    
                <div class="card-header">
                    <div class="container border rounded-3">
                        <div class="row">
                            <!-- Listado de usuarios -->
                            <div class="input-group mb-3">
                                <span style="font-size: 1.2rem;" > 
                                    Seleccione el propietario y la factura.
                                </span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
        
                                <div class="col-lg-12 form-group">
                                    <label for="">Propietario </label>
                                    <select class="custom-select" id="cliente-select" [(ngModel)]="detalleFactura.propietario" >
                                      
                                        <option selected disabled>Seleccione el propietario</option>
                                        <option *ngFor="let propietario of propietarios | filtroAZClientes" value="{{propietario.email}}" > {{propietario.nombres}} {{propietario.apellidos}} </option>
                                        
                                      </select>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
                     <div class="card-header">
                         <div class="container">
                            <div class="row">
                            <div class="col-sm">
                                <h5 style="margin-bottom: 0px;">Seleccione o arrastre la factura</h5>
                            </div>
                            <div class="col-sm">
                              
                                <div class="file-select" id="src-file1">
    
                                    <input appNgDropFiles [archivos]="facturaPropietario" type="file" multiple="false" aria-label="Archivo">
    
                                </div>
                            </div>
                        </div>
                    </div>
    
    
                </div>
    
                <div class="card-body">
                    <div appNgDropFiles [archivos]="facturaPropietario" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                        <i class="fa fa-solid fa-upload fa-8x"> </i>
                        <span>   Suelte los archivos en esta zona para subir</span>
    
                    </div>
                    <div class="progress mt-2" *ngFor="let archivo of facturaPropietario">
                           
                        <div class="progress-bar verde" role="progressbar" [ngStyle]="{ 'width': archivo.progreso + '%' }"></div>
                    </div>
                </div>
    
                <div class="card-footer d-flex justify-content-center align-items-center flex-wrap">
                    <button 
                        class="btn btn-enviar m-2" 
                        (click)="registrarFacturaPropietario()" 
                        [disabled]="facturaPropietario.length == 0 
                            || detalleFactura.propietario == 'Seleccione el propietario'
                            || isLoadingFactura">
                       <span> 
                            Cargar factura  
                            <i *ngIf="isLoadingFactura" 
                                class="fa fa-solid fa-spinner fa-spin">  </i>
                        </span> 
                    </button>
                    <button class="btn btn-cancelar" (click)="limpiarArchivos()">Remover archivo</button>
                </div>
        </div>
        <!-- Fin facturas propietarios -->
    </div>

</div>

