export var multi = [
    {
      "name": "Clientes",
      "series": [
        {
          "name": "2022",
          "value": 98
        },
        {
          "name": "2023",
          "value": 117
        },
        {
          "name": "2024",
          "value": 175
        }
      ]
    },
  
    {
      "name": "Propietarios",
      "series": [
        {
          "name": "2022",
          "value": 85
        },
        {
          "name": "2023",
          "value": 113
        },
        {
          "name": "2024",
          "value": 145
        }
      ]
    }
  ];
  