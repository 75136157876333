import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';
import { GLOBALSOCKET } from 'src/app/services/GLOBAL';
import Swal from 'sweetalert2';
import { io } from 'socket.io-client';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  public socket = io(GLOBALSOCKET.url);
  public mensaje :any = {

  };

  constructor(
    private _clienteService : ClienteService
  ) { }

  ngOnInit(): void {
  }



  enviar_mensaje(contactoForm)
  {
    if(contactoForm.valid)
    {

      this._clienteService.enviar_mensaje(this.mensaje).subscribe(
        response =>{
          this.socket.emit('new-message');
          Swal.fire({
            icon:'success',
            text:'Su mensaje ha sido enviado'
          })

          this.mensaje={}

        },
        error=>{
          Swal.fire({
            icon:'error',
            text:'Complete correctamente el formulario'
          })
        }
      )

    }else{
      Swal.fire({
        icon:'error',
        text:'Complete correctamente el formulario'
      })

    }
  }

}
