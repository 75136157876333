<!-- ======= Services Section ======= -->
<section class="section-property section-t8">
    <ng-container>
        <div class="container">
            <div class="row">
                <div class="col-md-12 mr-0">
                    <div class="title-wrap d-flex justify-content-between">
                        <div class="title-box">
                            <h2 class="title-a">Nuestros Servicios</h2>
                        </div>
                    </div>
                </div>
            </div>
        
        <swiper [config]="config" class="mySwiper" >
    
                <div class="swiper-wrapper">
                    <div class="swiper-slide ">
                        
                            <div class="card-box-c foo">
                                <div class="card-header-c d-flex">
                                    <div class="card-box-ico">
                                        <img src="../../../assets/icons/casa-en-venta.png">
                                    </div>
                                    <div class="card-title-c align-self-center">
                                        <h2 class="title-a">Venta</h2>
                                    </div>
                                </div>
                                <div class="card-body-a">
                                    <p class="text-left">
                                        Somos un equipo de profesionales inmobiliarios, realizamos un acompañamiento personalizado de principio a fin, nos encargamos de encontrar clientes calificados para la compra de su propiedad.
                                        Encontrarás un amplio portafolio con el cual podrás elegir el inmueble de tus sueños. </p>
                                </div>
                                <!--<div class="card-footer-c">
                                    <a [routerLink]="['/inmuebles']" class="link-c link-icon">Conocer Más
                              <span class="bi bi-chevron-right"></span>
                            </a>
                                </div>-->
                            </div>
                       
                    </div>
               
   
       
               
                    <div class="swiper-slide">
                        <div class="card-box-c foo">
                            <div class="card-header-c d-flex">
                                <div class="card-box-ico">
                                    <img src="../../../assets/icons/renta.png">
                                </div>
                                <div class="card-title-c align-self-center">
                                    <h2 class="title-a">Arriendo</h2>
                                </div>
                            </div>
                            <div class="card-body-c">
                                <p class="content-c">
                                    Contamos con todo tipo de inmueble para arriendo como casas, apartamentos, fincas, locales, entre otros.Para ti que eres propietario contamos con las mejores plataformas especializadas para promocionar tu inmueble, contamos con canon garantizado por lo que siempre recibirás el pago de manera oportuna.
                                </p>
                            </div>
                            <!--<div class="card-footer-c">
                                <a [routerLink]="['/inmuebles']" class="link-c link-icon">Conocer Más
                          <span class="bi bi-calendar4-week"></span>
                        </a>
                            </div>-->
                        </div>
                    </div>
              
           
            
              
                    <div class="swiper-slide">
                        <div class="card-box-c foo">
                            <div class="card-header-c d-flex">
                                <div class="card-box-ico">
                                    <img src="../../../assets/icons/clipboard.png">
                                </div>
                                <div class="card-title-c align-self-center">
                                    <h2 class="title-a">Avalúos</h2>
                                </div>
                            </div>
                            <div class="card-body-c">
                                <p class="content-c">
                                    Te ayudamos a estimar el valor comercial de tu inmueble mediante la caracterización física, ubicación, uso y análisis del mercado, con el propósito que el cliente tenga una base para sus negociaciones, y le sirva para los requerimientos de entidades como bancos, empresas aseguradoras, compradores y vendedores inmobiliarios.
                                </p>
                            </div>
                            <!--<div class="card-footer-c">
                                <a [routerLink]="['/contacto']" class="link-c link-icon">Conocer Más
                          <span class="bi bi-chevron-right"></span>
                        </a>
                            </div>-->
                        </div>
                    </div>
                
          
            
            
                    <div class="swiper-slide">
                        <div class="card-box-c foo">
                            <div class="card-header-c d-flex">
                                <div class="card-box-ico">
                                    <img src="../../../assets/icons/reparador-dentro-de-una-casa.png">
                                </div>
                                <div class="card-title-c align-self-center">
                                    <h2 class="title-a">Reparaciones locativas</h2>
                                </div>
                            </div>
                            <div class="card-body-c">
                                <p class="content-c">
                                    Tenemos un equipo de profesionales que nos ayudan a mantener tu inmueble en las mejores condiciones, da clic en "Conocer más" y entérate de todo lo que tenemos para ti.
                                </p>
                            </div>
                            <div class="card-footer-c">
                                <a href="http://manod.com.co/" class="link-c link-icon">Conocer Más
                          <span class="bi bi-chevron-right"></span>
                        </a>
                            </div>
                        </div>
                    </div>
                </div>
                    
          


                <div class="swiper-pagination "></div>
</swiper>
</div>
</ng-container>
</section>
<!-- End Services Section -->