import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GLOBAL, GLOBALSOCKET } from 'src/app/services/GLOBAL';
import { AsesoresComponent } from '../asesores/asesores.component';


@Component({
  selector: 'app-tarjeta-asesores',
  templateUrl: './tarjeta-asesores.component.html',
  styleUrls: ['./tarjeta-asesores.component.css']
})
export class TarjetaAsesoresComponent implements OnInit {

  public page = 1;
  public pageSize = 12;
  
  public codigo;
  public asesores : Array<any> =[];
  public propiedades_asesor : Array<any> =[];
  public asesor;
  public url;
  public load_data = true;

  public sort_by = '';

  constructor(private _asesorService : ClienteService,
    private _route : ActivatedRoute,) { 
      
    this.url = GLOBAL.url;
    this._route.params.subscribe(
      params  => {
        this.codigo = params['codigo'];
        this._asesorService.obtener_asesor(this.codigo).subscribe(
          response => {
            this.asesor=response.data;
            this.obtener_asesor_propiedad();
            console.log(this.asesor);
          }
        )
       
      }

    )
  }

  ngOnInit(): void {
    this.obtener_asesores();
  }

  obtener_asesores(){
    this._asesorService.obtener_asesores().subscribe(
      response => {
        this.asesores = response.data;
        console.log(this.asesores);
      },error =>{
        console.log(error);
      }
    )

    this.load_data = false;
  }

  obtener_asesor_propiedad(){
    this._asesorService.obtener_asesor_propiedad(this.codigo).subscribe(
      reponse => {
        this.propiedades_asesor = reponse.data;
      

      }
    )
  }

}
