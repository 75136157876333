import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexAdminsComponent } from './admins/index-admins/index-admins.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LightgalleryModule } from 'lightgallery/angular';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select'
import {  NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { style } from '@angular/animations';
import { CreateAdminComponent } from './admins/create-admin/create-admin.component';
import { FacturacionComponent } from './admins/facturacion/facturacion.component';
import { NgDropFilesDirective } from '../directives/ng-drop-files.directive';
import { NgFilesDirective } from '../directives/ng-files.directive';
import { FiltroPipe } from '../pipes/filtro.pipe';
import { FormatoFechaPipe } from '../pipes/formato-fecha.pipe';
import { FiltroRangoFechaPipe } from '../pipes/filtro-rango-fecha.pipe';
import { VigenciaPipe } from '../pipes/vigencia.pipe';
import { FiltroAZClientesPipe } from '../pipes/filtro-a-z-clientes.pipe';
import { CargarFacturaComponent } from './admins/cargar-factura/cargar-factura.component';
import { DetalleConsultaComponent } from './components/consultas/detalle-consulta/detalle-consulta.component';
import { IndexConsultasComponent } from './components/consultas/index-consultas/index-consultas.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { IndexMensajesComponent } from './components/mensajes/index-mensajes/index-mensajes.component';
import { IndexPropiedadesComponent } from './components/propiedades/index-propiedades/index-propiedades.component';
import { CreatePropiedadComponent } from './components/propiedades/create-propiedad/create-propiedad.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AgmCoreModule } from '@agm/core';
import { GaleriaPropiedadComponent } from './components/propiedades/galeria-propiedad/galeria-propiedad.component';
import { UpdatePropiedadComponent } from './components/propiedades/update-propiedad/update-propiedad.component';

import { CrearClienteComponent } from './Roles/asesor/crear-cliente/crear-cliente.component';
import { UpdateClienteComponent } from './Roles/asesor/update-cliente/update-cliente.component';
import { MostrarClientesComponent } from './Roles/asesor/mostrar-clientes/mostrar-clientes.component';
import { SubirContratoComponent } from './Roles/asesor/subir-contrato/subir-contrato.component';
import { ConsultarContratoComponent } from './Roles/cliente/consultar-contrato/consultar-contrato.component';
import { ConsultarInventarioComponent } from './Roles/cliente/consultar-inventario/consultar-inventario.component';
import { DocumentosComponent } from './Roles/cliente/documentos/documentos.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PagosComponent } from './Roles/cliente/pagos/pagos.component';
import { RealizarPagoComponent } from './Roles/cliente/realizar-pago/realizar-pago.component';
import { CrearRequerimientosComponent } from './Roles/propietario/crear-requerimientos/crear-requerimientos.component';
import { IndexRequerimientosComponent } from './Roles/propietario/index-requerimientos/index-requerimientos.component';
import { GenerarRequerimientosComponent } from './Roles/cliente/generar-requerimientos/generar-requerimientos.component';
import { CreateAsesorsComponent } from './admins/create-asesors/create-asesors.component';
import { SubirContratoPropietarioComponent } from './Roles/asesor/subir-contrato-propietario/subir-contrato-propietario.component';
import { ConsultarContratoPropietarioComponent } from './Roles/propietario/consultar-contrato-propietario/consultar-contrato-propietario.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { AgendaComponent } from './Roles/asesor/agenda/agenda.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDialogComponent } from './components/calendar-dialog/calendar-dialog.component';
import { NuevaCitaDialogComponent } from './components/nueva-cita-dialog/nueva-cita-dialog.component';
import { MostrarInventarioComponent } from './Roles/asesor/mostrar-inventario/mostrar-inventario.component';
import { MostrarPagosCanonComponent } from './Roles/asesor/mostrar-pagos-canon/mostrar-pagos-canon.component';
import { CambiarPasswordComponent } from './components/cambiar-password/cambiar-password.component';
import { registerLocaleData } from '@angular/common';
import localees from '@angular/common/locales/es-CO';
import { EditarCitaDialogComponent } from './components/editar-cita-dialog/editar-cita-dialog.component';
import { ConsultarInventarioPropietarioComponent } from './Roles/propietario/consultar-inventario-propietario/consultar-inventario-propietario.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BlankPageComponent } from './components/blank-page/blank-page.component';
import { UpdateAsesorsComponent } from './admins/update-asesors/update-asesors.component';
import { VisorInventarioComponent } from './Roles/cliente/visor-inventario/visor-inventario.component';
import { VerRequerimientosComponent } from './Roles/cliente/ver-requerimientos/ver-requerimientos.component';
import { RequerimientosComponent } from './Roles/asesor/requerimientos/requerimientos.component';
import { DetalleRequerimientoComponent } from './Roles/asesor/detalle-requerimiento/detalle-requerimiento.component';
import { SolicitarcertificadoComponent } from './Roles/propietario/solicitarcertificado/solicitarcertificado.component';
import { CrearsolicitudcertificadoComponent } from './Roles/propietario/crearsolicitudcertificado/crearsolicitudcertificado.component';
import { DetallecertificadosComponent } from './Roles/propietario/detallecertificados/detallecertificados.component';
import { GestionarCertificadosComponent } from './Roles/asesor/gestionar-certificados/gestionar-certificados.component';
import { DetalleCertificadoComponent } from './Roles/asesor/detalle-certificado/detalle-certificado.component';

registerLocaleData(localees);
@NgModule({
  declarations: [
    IndexAdminsComponent,
    SidebarComponent,
    LoginComponent,
    ModalDialogComponent,
    CreateAdminComponent,
    FacturacionComponent,
    NgDropFilesDirective,
    NgFilesDirective,
    FiltroPipe,
    FormatoFechaPipe,
    FiltroRangoFechaPipe,
    VigenciaPipe,
    FiltroAZClientesPipe,
    CargarFacturaComponent,
    DetalleConsultaComponent,
    IndexConsultasComponent,
    InicioComponent,
    IndexMensajesComponent,
    IndexPropiedadesComponent,
    CreatePropiedadComponent,
    GaleriaPropiedadComponent,
    UpdatePropiedadComponent,
    CrearClienteComponent,
    MostrarClientesComponent,
    SubirContratoComponent,
    ConsultarContratoComponent,
    ConsultarInventarioComponent,
    DocumentosComponent,
    PagosComponent,
    RealizarPagoComponent,
    CrearRequerimientosComponent,
    IndexRequerimientosComponent,
    GenerarRequerimientosComponent,
    CreateAsesorsComponent,
    SubirContratoPropietarioComponent,
    ConsultarContratoPropietarioComponent,
    NotificationsComponent,
    ReportesComponent,
    AgendaComponent,
    CalendarDialogComponent,
    NuevaCitaDialogComponent,
    MostrarInventarioComponent,
    MostrarPagosCanonComponent,
    CambiarPasswordComponent,
    EditarCitaDialogComponent,
    UpdateClienteComponent,
    ConsultarInventarioPropietarioComponent,
    LoadingComponent,
    BlankPageComponent,
    UpdateAsesorsComponent,
    VisorInventarioComponent,
    VerRequerimientosComponent,
    RequerimientosComponent,
    DetalleRequerimientoComponent,
    SolicitarcertificadoComponent,
    CrearsolicitudcertificadoComponent,
    DetallecertificadosComponent,
    GestionarCertificadosComponent,
    DetalleCertificadoComponent,
    

  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgbPaginationModule,
    NgbModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatSelectModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatInputModule,
    MatTableModule,
    NgxChartsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot(
      {apiKey:'AIzaSyCj48w__k0kEuvoBz1qYGtL5VdLL73HZQ8',
      libraries: ['places']
    }
    ),
    NgxExtendedPdfViewerModule,
    LightgalleryModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
    
   
  ],
  exports:[
    MatDatepickerModule
    
  ],
  bootstrap: [AgendaComponent],
})
export class AdminModule { }
