
<div *ngIf="!cargando; else cargandoTemplate">
    <div class="card-header border-bottom  mt-2 mb-5">
        <h1 class="mt-2 mt-md-4 mb-3 pt-3">Visualizar inventario</h1>
        <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
            <p class="text-muted">Este módulo le permite acceder al inventario del inmueble y a las fotos de evidencia.</p>

        </div>
    </div>
    <section class="property-single nav-arrow-b">
        <div class="container">


            <div class="row">
                <!--Inicio audiovisual-->
                <div class="col-md-10 offset-md-1">
                    <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="inventario-tab" data-bs-toggle="tab" data-bs-target="#inventario" type="button" role="tab" aria-controls="home" aria-selected="true">Inventario</button>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="evidencias-tab" data-bs-toggle="pill" href="#evidencias" role="tab" aria-controls="evidencias" aria-selected="false">Evidencias inventario</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="inventario" role="tabpanel" aria-labelledby="home-tab">
            <!--Inicio inventario-->
            <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
                <div *ngIf="inventarios.length > 1" class="card-header">
                    <div class="container">
                        <div class="row">                
                            <div class="col-6 mt-3">
                                <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                                <div class="input-group mb-3">
                                    <select class="custom-select" id="cliente-select" [(ngModel)]="opcionSeleccionada" (change)="selectChange()">
                                    <!--<option selected disabled>Seleccione el contrato</option>-->
                                    <option *ngFor="let inventario of inventarios" value="{{inventario.cliente}}"> {{inventario.nombre}} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="pdfUrl != ''; else blankPage"  class="card-body">

                    <ngx-extended-pdf-viewer 
                    [src]="pdfUrl" 
                    [handTool]="true" 
                    [showHandToolButton]="true" 
                    backgroundColor="#ffffff" 
                    [height]="'100vh'" 
                    [useBrowserLocale]="true" 
                    [mobileFriendlyZoom]="'150%'"
                    [zoom]="'page-width'"
                    [showSidebarButton]="false" 
                    [showFindButton]="false" 
                    [showPagingButtons]="false"
                    [showZoomButtons]="true" 
                    [showPresentationModeButton]="true" 
                    [showOpenFileButton]="false" 
                    [showPrintButton]="false" 
                    [showDownloadButton]="false" 
                    [showBookmarkButton]="false" 
                    [showSecondaryToolbarButton]="false" 
                    [showRotateButton]="false" 
                    [showHandToolButton]="false"
                    [showScrollingButton]="false" 
                    [showSpreadButton]="false" 
                    [showPropertiesButton]="false" 
                    [sidebarVisible]="false"
                    [listenToURL]="false">
                    </ngx-extended-pdf-viewer>
                </div>
                <ng-template #blankPage>
                    <app-blank-page [mensaje]="'No hemos encontrado ningún contrato'"></app-blank-page>
                </ng-template>
            </div>
            <!--Fin inventario-->
        </div>
        <div class="tab-pane fade " id="evidencias" role="tabpanel" aria-labelledby="pills-evidencias-tab">
            <!--Inicio Galeria inventario-->
            <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">

                <div class="card-body" >
            
                    <div class="wrapper">
                        <div class="pswp-gallery" id="gallery--getting-started">
                        <a *ngFor="let imagen of inventario.images"
                            [href]="imagen"
                            data-pswp-width = "1620"
                            data-pswp-height="1080"
                            target="_blank"
                        >
                            <img
                            [src]="imagen"
                            alt=""
                            />
                        </a>
                        
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
</div>
<ng-template #cargandoTemplate>
    <app-loading></app-loading>
</ng-template>