import { Component, ViewEncapsulation, AfterContentInit, NgZone } from '@angular/core';
//import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';

import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
//import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ClienteService } from 'src/app/services/cliente.service';
import Swal from 'sweetalert2';

declare var iziToast: any;

@Component({
  selector: 'app-crear-requerimientos',
  templateUrl: './crear-requerimientos.component.html',
  styleUrls: ['./crear-requerimientos.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CrearRequerimientosComponent implements AfterContentInit {

  public token;
  public decodedToken;
  public propiedad = {
    asunto: '',
    detalle: '',
    respuesta: '',
    fechaHora: ''
  }
  public codigo;
  public inmueble = {
    titulo: ''
  }
  public cliente;
  public nombreCliente;
  public imagenesMantenimiento: Array<any> = [];
  public documentId;
  public cargando = false;

  constructor(
    private _usuarioService: UsuarioService,
    private _imagenesService: CargaImagenesService,
    private ngZone: NgZone,
    private _propiedad_service: PropiedadService,
    private _route: ActivatedRoute,
    private _cliente_service: ClienteService,
    private location: Location,
    private router: Router) {

    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      var decodedToken = helper.decodeToken(this.token);

      if (helper.isTokenExpired(this.token)) {
        localStorage.clear();
      }

      if (!decodedToken) {
        localStorage.removeItem('token');
      }

    } catch (error) {
      localStorage.removeItem('token');

    }
    this._route.params.subscribe(
      params => {
        this.codigo = params['id'];
        this.obtener_requerimiento_inmueble(parseInt(this.codigo));
      }
    )
  }

  ngAfterContentInit(): void {}

  settings = {
    counter: false,
    plugins: [lgZoom]
  };

  obtener_requerimiento_inmueble(req: number) {
    this.cargando = true;
    this._propiedad_service.obtener_requerimiento_id(req)
      .then(requerimiento => {
        requerimiento.forEach(async (documento) => {
          this.propiedad = await documento.data();
          this._propiedad_service.obtener_imagenes_requerimiento(documento.id).then(imagenes => {
            imagenes.forEach(async imagen => {
              let img = await imagen.data();
              this.imagenesMantenimiento.push(img)
            });
            const lightbox = new PhotoSwipeLightbox({
              gallery: '#gallery-maintenance',
              children: 'a',
              pswpModule: PhotoSwipe,
              spacing: 0.5,
              loop: true,
            });
      
            lightbox.init();
          })
        })
      })
      .catch(error => console.log)
      .finally(() => this.cargando = false);
  }

  responder_requerimiento() {
    this._propiedad_service.actualizar_requerimiento_inmueble(this.documentId, this.propiedad.respuesta)
      .then(res => {
        Swal.fire({
          icon: 'success',
          text: 'Su respuesta ha sido enviada'
        });
      })
  }

  goBackOrToPanel(): void {
    if (this.location.getState()) {
      this.location.back();
    } else {
      this.router.navigate(['/panel']);
    }
  }

}





