import { CalendarEvent } from "angular-calendar";

export class DataModal {
    constructor(public id: any, public titulo: string, public descripcion: string) {
    }
}
export class DataModalDelete {
    constructor(public id: any, public titulo: string, public email: string, public descripcion: string) {
    }
}
export class CalendarDataModal {
    constructor(public action: string, public event: CalendarEvent) {
    }
}
