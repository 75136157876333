import { Component, OnInit,ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
//import Swiper, { SwiperOptions, Scrollbar } from 'swiper';
//import SwiperCore from 'swiper';
import SwiperCore, { Swiper, SwiperOptions,Scrollbar } from 'swiper';
/* Autocomplete angular material */

import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Router } from '@angular/router';

declare var funcion: any;


SwiperCore.use([Scrollbar]);


@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.css'],
  //encapsulation: ViewEncapsulation.None,
 
})
export class SlideshowComponent implements OnInit {
  public urlVideo ;
  public placeholderOperation = "Comprar";
  @ViewChild('video') video: ElementRef;
  config: SwiperOptions = {

    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    speed: 800,

    autoplay: {
        delay: 3000,
        disableOnInteraction: true
    },
    slidesPerView: 'auto',
   /* scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
        hide: false,
        
    },*/
     pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    },
    loop: false

  };
  public page = 1;
  public pageSize = 8;
  
  public banners : Array<any> =[];
  public staticBanner: Array<any> =[];
  public swiperIndex = this.banners.length
  public url;
  public ciudadesconsulta: Array<any> =[];
  public tipoconsulta:  Array<any> =[];
  public load_data = true;

  public params:any = {
    operacion: '',
    ciudad:'',
    tipo:'',
    precio:'',
    banos:'',
    habitaciones:''
  };

    /* Autocomplete cities and property type */

    myControl = new FormControl('');
    options: string[] = ['One', 'Two', 'Three'];
    filteredOptions: Observable<string[]>;
  
    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
  
      return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }


  constructor(private _inmuebleService : ClienteService, private router : Router) {  this.url = GLOBAL.url;

  }

  ngOnInit(): void {

    this.obtener_tipo();
    this.obtener_ciudades();

    //this.video.nativeElement.play();
    this.urlVideo = "https://firebasestorage.googleapis.com/v0/b/vabait-2b1fb.appspot.com/o/videos%2FVideoInicialLento.mp4?alt=media&token=91c86604-3022-4579-8122-bebce93d651a";
    //this.listar_banner();



    //this._bannerService.obtener_propiedad_wasi()

   /* (()=>{
      console.log('funcion autoinvocable')*/
      /*let elemento = document.getElementById('slide-intro')
      console.log(elemento)
 */
    /*})()*/

    /* Autocomplete angular material */
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

  }

  enviar(){ 
    this.router.navigate(['/app/inmuebles'], {queryParams: this.params});
  }

  obtener_tipo(){
    this._inmuebleService.obtener_tipo().subscribe(
      response =>{
        this.tipoconsulta = response.data.filter(item => item.total > 0);
      }
    )
  }

  obtener_ciudades(){
    this._inmuebleService.obtener_ciudades().subscribe(
      response =>{
        this.ciudadesconsulta = response.data.filter(item => item.total > 0) ;
        
      }
    )
  }

  changePlaceholderOperation(event){
    
    if(event.target.id == "venta")
    {
      this.placeholderOperation = "Comprar";
      this.params.operacion = "Venta";
    }
    else
    {
      this.placeholderOperation = "Rentar";
      this.params.operacion = "Renta";
    }
  }
  
  ngAfterViewInit() {
    
    //this.video.nativeElement.play();
    this.playVideo();
  }

  playVideo() {
    const media = this.video.nativeElement;
    media.muted = true; // without this line it's not working although I have "muted" in HTML
    media.play();
}
/*
  onSwiper(swiper) {
    swiper.update();
  }*/


 
  listar_banner()
  {
    this._inmuebleService.listar_banner_publico().subscribe(
      response => {
        this.banners = response.data;
        /*let i = this.banners.length - 1
        console.log(this.banners)
        this.staticBanner.push(response.data[i]);
      
        this.banners.splice(i,1)*/
       
      },error =>{
        console.log(error);
      }
    )

    this.load_data = false;
  }



}

