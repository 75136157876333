import { Component, OnInit, ViewEncapsulation,AfterContentInit,  NgZone } from '@angular/core';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-consultar-inventario-propietario',
  templateUrl: './consultar-inventario-propietario.component.html',
  styleUrls: ['./consultar-inventario-propietario.component.css']
})
export class ConsultarInventarioPropietarioComponent implements OnInit {

  
  public token;
  public usuario:any = {}
  public imagenes: Array<any> = []
  public dataSource = []
  public mobileFriendlyZoomSetting = '150%';
  public decodedToken;
  public pdfUrl;
  public cargando;
  public inventarios: Array<any> = [];
  public clientes: Array<any> = [];
  public inventario = {
    images: [], 
  }
  public opcionSeleccionada = 'Seleccione el inventario';


  constructor(
    private _usuarioService: UsuarioService,
    private _imagenesService: CargaImagenesService,
    private _ClienteService: ClienteService,
    private ngZone: NgZone
  ) { 

    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
          

      }

      if (!this.decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
        
      }

    } catch (error) {
      localStorage.removeItem('token');
     
    }

  }

  ngOnInit(): void {
    this.cargando = true;
    this.obtener_inventario();
    //this.obtener_usuario();
    //this.consultarInventario();
    this.ngZone.runOutsideAngular(() => {
      const lightbox = new PhotoSwipeLightbox({
         gallery: '#gallery--getting-started',
         children: 'a',
         pswpModule: PhotoSwipe,
         //pswModule: () => import('photoswipe/dist/photoswipe.esm.js'),
         spacing: 0.5,
         loop: true,
         
        
       });
       
       lightbox.init();
       
     });
  }

  settings = {
    counter: false,
    plugins: [lgZoom]
  };

  selectChange(){
    /*
      *Filtrar la opcion seleccionada de la lista de inventarios y asignar a la variable pdf */
    const inventarioSeleccionado = this.inventarios.find(x => x.cliente == this.opcionSeleccionada);
    if( inventarioSeleccionado){
      this.pdfUrl = inventarioSeleccionado.url;
      this.opcionSeleccionada = inventarioSeleccionado.cliente;
      this.inventario = inventarioSeleccionado;
    }
  }

   obtener_inventario(){
    this._imagenesService.obtener_inventario_propietario(this.decodedToken.email)
    .then(snapshot =>{
      let index = 0;
      snapshot.forEach(async (element) => {
        const clientImages = [];
        const images = await this._imagenesService.obtener_imagenes(element.data().cliente);
        images.forEach(image => clientImages.push(image.data().url))

        let inventario = {
          ...element.data(),
          nombre: 'Inventario ' + (index + 1),
          images: clientImages
        }

        this.inventarios.push(inventario);
        if(index == 0){
          this.pdfUrl = element.data().url;
          this.opcionSeleccionada = element.data().cliente;
          this.inventario = inventario;
        }
        index ++;

      });
      this.cargando = false;
      console.log(this.inventarios)
    })
  }

obtener_tamano_imagenes(){
  for(let imagen of this.imagenes){
     let foto = new Image(200,300);
    
    //document.images[0].src=foto.src;
    foto.src = imagen.url;
    let ancho = foto.naturalWidth;
    let alto = foto.naturalHeight;
    //let ancho = document.images[0].naturalWidth;
    //let alto =  document.images[0].naturalHeight;
    imagen.ancho = ancho;
    imagen.alto = alto;
    let image = {
      src: imagen.url,
      width: ancho,
      height: alto,
      alt: imagen.nombre
    }
    this.dataSource.push(image)
    //console.log(this.dataSource)
  }
  //Se inicializa el lightgallery una vez el arreglo de imagenes esté completo

}


  obtener_usuario()
  {
    this._usuarioService.obtener_usuario(this.token).subscribe(
      response => 
      {
        this.usuario = response.data;
        this.obtener_imagenes();
      }
    )
  }
  obtener_imagenes(){
    this._imagenesService.obtener_imagenes_propietario(this.usuario.email)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          this.imagenes.push(doc.data());
      });

  })
  .catch((error) => {
      console.log("Error getting documents: ", error);
  });
  }

}
