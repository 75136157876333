import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal } from '../../clases/data-modal';
import { FileItem } from 'src/app/models/file-item';
import { ModalDialogComponent } from '../../components/modal-dialog/modal-dialog.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-create-asesors',
  templateUrl: './create-asesors.component.html',
  styleUrls: ['./create-asesors.component.css']
})
export class CreateAsesorsComponent implements OnInit {

  public token;
  public load_data = true;
  public admins : Array<any> = [];
  public asesores : Array<any> = [];
  public page = 1;
  public url = "";
  public imagenes: Array<any> = []
  public archivos: FileItem[] = [];
  public foto : any | ArrayBuffer = '../../../../../assets/img/01.jpg';
  public pageSize = 5;
  public load_btn;
  public asesor : any = {};
  public facturaPropietario: FileItem[] = [];
  estaSobreElemento = false;

  constructor(
    private _adminService : AdminService,
    public dialog: MatDialog,
    private _router : Router,
    private _subirArchivo: CargaImagenesService,
    private cdr: ChangeDetectorRef
  ) { 
    this.token = localStorage.getItem('token');
  }

  abrirDialogo(id: any, titulo: string) {
    const dialogo1 = this.dialog.open(ModalDialogComponent, {
      data: new DataModal(id, titulo, '¿Estás seguro de eliminar este usuario?')
    });

    dialogo1.afterClosed().subscribe(datos => {
      //Swal.fire({
       // icon:'error',
       // text:'No se pudo eliminar el miembro de equipo, por favor validar con el administrador del sistema'
      //});
     this.eliminar(datos.id)
    });
  }

  ngOnInit(): void {
    //this.listar_admins();
    this.listar_asesores();
  }

  limpiarImagenes(){
    this.imagenes = [];
}

  listar_admins()
  {
    this._adminService.listar_admins(this.token).subscribe(
      response =>{
        this.admins = response.data;
        this.load_data = false;
        
      },
      error =>{
        console.log(error);
      }
    )
  }

  limpiarArchivos(){
    this.archivos = [];
    this.facturaPropietario = [];
  }

  listar_asesores()
  {
    this._adminService.obtener_asesor(this.token).subscribe(
      response =>{
        this.asesores = response.data;
        //console.log(this.asesores)
        this.load_data = false;
        
      },
      error =>{
        console.log(error);
      }
    )
  }

  eliminar(id:any)
  {
    this._adminService.eliminar_asesor(id,this.token).subscribe(
      response =>{
        Swal.fire({
          icon:'success',
          text:'Asesor eliminado correctamente'
        });
    this.listar_admins();
      },
      error  =>{
        Swal.fire({
          icon:'error',
          text:'Contacte al administrador del sistema'
        });
      }

    )
  }

  async cargarImagenes() {
    try {
        const urls = await this._subirArchivo.cargarImagenesAsesores(this.imagenes, "asesor", "asesores");
        console.log(urls);
        // Actualizar el valor del campo foto con la primera URL (puedes ajustar esto según tus necesidades)
        if (urls && urls.length > 0) {
            this.asesor.foto = urls[0];
        }
    } catch (error) {
        console.error('Error al cargar archivos', error);
    }
}




  registro(registroForm:any)
  {
    if(registroForm.valid){
      this._adminService.registro_asesor(this.asesor,this.token).subscribe(
        response => {
          console.log(response);
          Swal.fire({
            icon:'success',
            text:'Usuario registrado correctamente'
          });
          this._router.navigate(['/panel/BI'])
        },
        error => {
          Swal.fire({
            icon:'error',
            text:'No fue posible crear el usuario, debes llenar todos los campos del formulario y subir una foto'
          })
        }
      )
    }else{
      Swal.fire({
        icon:'info',
        text:'Debes llenar toda la informacion y recuerda que debes darle clic en el boton "Cargar Imagen" esperar que se suba, llenar los datos y podrás crear el asesor'
      });
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      try {
        // Creamos un FileItem con el archivo seleccionado
        const fileItem = new FileItem(file);

        this.load_data= true;
        // Llamamos al servicio para cargar la imagen
        this._subirArchivo.cargarImagenesAsesores([fileItem], "asesor", "asesores")
          .then((urls: string[]) => {
            // La URL de la imagen cargada estará en la primera posición del array
            const urlImagenCargada = urls[0];
            
            // Actualizamos el valor del campo foto con la URL de la imagen cargada
            this.asesor.foto = urlImagenCargada;

            this.cdr.detectChanges();

            this.load_data= false;
          })
          .catch(error => {
            console.error('Error al cargar la imagen', error);
          });
      } catch (error) {
        console.error('Error al cargar archivos', error);
      }
    }
  }


}
