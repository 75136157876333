
<!-- Page title-->
<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Detalles de consulta</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted"></p>

    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Detalles</h5>
    </div>

    <div class="card-body">

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <div class="card mb-3" style="width: 100%;" *ngIf="consulta.propiedad">
                    <div class="row g-0">
                        <div class="col-md-6">
                            <img [src]="consulta.propiedad.portada" class="img-fluid rounded-start" style="max-height: 300px; width: 100%;" alt="...">
                        </div>
                        <div class="col-md-6">
                            <div class="card-body">
                                <h5 class="card-title">{{consulta.propiedad.titulo}}</h5>
                                <h5 class="text-muted">{{consulta.propiedad.ciudad}} , {{consulta.propiedad.codigo}}</h5>
                                <p class="card-text text-primary fw-bold"> ${{consulta.propiedad.precio}}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12">
                <div class="card mb-3" style="width: 100%;" *ngIf="consulta.propiedad">
                    <div class="row g-0">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="card-title text-center pt-3">Consulta</h5>
                            <div class="card-body text-center">
                                <form #detallesForm="ngForm" (ngSubmit)="actualizar(detallesForm)">
                                    <div class="col-lg-12 form-group">
                                        <label for="nombres">Nombre</label>
                                        <input id="nombres" class="text-center form-control" readonly name="nombres" [(ngModel)]="consulta.nombres" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="telefono">Teléfono</label>
                                        <input id="telefono" class="text-center form-control" readonly name="telefono" [(ngModel)]="consulta.telefono" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="Email">Email</label>
                                        <input id="Email" class="text-center form-control" readonly name="Email" [(ngModel)]="consulta.email" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="telefono">Estatus</label>
                                        <select class="form-control custom-select" id="select-input" required name="tipo" [(ngModel)]="consulta.estatus">
                                                <option class="text-center" value="" selected disabled>Seleccionar</option>
                                                <option class="text-center" value="Nuevo" >Nuevo</option>
                                                <option class="text-center" value="Procesado" >Procesado</option>
                                                <option class="text-center" value="Cerrado" >Cerrado</option>
                                            </select>
                                    </div>

                                    <div class="col-lg-12 form-group">
                                        <label for="mensaje">Mensaje</label>
                                        <textarea class="form-control text-center" name="texto" id="mensaje" rows="5" readonly [(ngModel)]="consulta.texto"></textarea>
                                    </div>


                                    <div class="card-footer text-center">
                                        <button *ngIf="!load_btn" class="btn btn-enviar mr-3 mt-3" type="submit">Guardar</button>

                                        <button *ngIf="load_btn" type="button" class="btn btn-secondary">
                                                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                                                Guardando
                                              </button>
                                        <button class="btn btn-cancelar mr-3 mt-3" [routerLink]=" ['/panel/consultas'] ">Regresar</button>
                                        <!-- Button with growing spinner and text -->


                                    </div>
                                </form>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>






    </div>

</div>