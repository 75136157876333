<br>
<div class="card-header border-bottom container  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3    -3">Equipo de trabajo</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo podras administrar tu equipo de trabajo</p>
    </div>
</div>

<section class="property-single nav-arrow-b">
    <div class="container">
        <div class="row">
            <!--Inicio tabs-->
            <div class="col-md-10 offset-md-1">
                <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="asesores-tab" data-bs-toggle="tab" data-bs-target="#asesores" type="button" role="tab" aria-controls="home" aria-selected="true">Equipo De Trabajo</button>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="crear-asesor-tab" data-bs-toggle="pill" href="#crear-asesor" role="tab" aria-controls="crear-asesor" aria-selected="false">Crear Nuevo Miembro De Equipo</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="asesores" role="tabpanel" aria-labelledby="home-tab">
        <div class="card-header">
            <h5 style="margin-bottom: 0px;">Miembros Registrados</h5>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead class="table-dark">
                         <tr><th scope="col">Nombre</th>
                          <th scope="col">Cargo</th>
                          <th scope="col">Presentación</th>
                          <th scope="col">Celular</th>
                          <th scope="col">Correo</th>
                          <th scope="col">OP</th>
                         </tr>
                </thead>
                <tbody *ngIf="load_data">
                    <tr>
                        <td class="text-center" colspan="7">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ng-container *ngIf="!load_data">
                    <tbody *ngFor=" let item of asesores| slice:(page-1) * pageSize: (page-1) * pageSize + pageSize  ;">
                        <tr>

                            <td>
                                {{item.nombre}}
                            </td>
                            <td> {{item.cargo}} </td>
                            <td class="text-truncate" style="max-width: 150px;"> {{item.presentacion}}</td>
                            <td> {{item.celular}} </td>
                            <td> {{item.correo}} </td>
                            <td>
                                <div class="btn-group dropdown d-inline-block mb-3 mr-2 ">
                                    <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        Opciones <span><i class="bi bi-caret-down-fill"></i></span>
                      </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a class="dropdown-item" [routerLink]=" ['/panel/asesores',item._id] ">Editar</a>
                                        </li>
                                        <li>
                                        <a class="dropdown-item" style="cursor: pointer;" (click)="abrirDialogo(item._id, ' Eliminar usuario ' + item.correo)" >Eliminar</a>
                                    </li>
                                    </ul>
                                </div>




                            </td>
                        </tr>
                    </tbody>
                </ng-container>

            </table>
        </div>

        <div class="card-footer ">


            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="asesores.length">
    
            </ngb-pagination>
    
    
        </div>

    </div>        
</div>

<div class="tab-pane fade " id="crear-asesor" role="tabpanel" aria-labelledby="pills-evidencias-tab">

    <form #registroForm="ngForm" (ngSubmit)="registro(registroForm)">
    <!--Inicio inventario-->
    <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
        <div class="card-header">
            <h5 style="margin-bottom: 0px;">Crear un nuevo miembro de equipo</h5>
            <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                <p class="text-muted">Se deben llenar en totalidad sus datos y seleccionar una imagen para poder crear el nuevo miembro del equipo</p>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="row">
                            <div class="col-lg-12 form-group">
                                <label for="">Foto de Perfil del miembro de equipo</label>
                                <div class="custom-file">
                                    <input class="custom-file-input" type="file" id="file-input" (change)="onFileSelected($event)">
                                    <label class="custom-file-label" id="input-portada" for="file-input" style="white-space:nowrap;overflow:hidden">Seleccionar imagen</label>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <!-- Muestra el indicador de carga si load_data es true -->
                                <div *ngIf="load_data" class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Cargando...</span>
                                </div>
                                <!-- Muestra la imagen si load_data es false -->
                                <img *ngIf="!load_data" [src]="asesor.foto" class="img-thumbnail">
                            </div>
                        </div>

                        <br>

                        <div class="col-lg-12 form-group">
                            <br>
                            <label for="">Nombre</label>
                            <input type="text" class="form-control" placeholder="Nombre" required name="nombre" [(ngModel)]="asesor.nombre">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Cargo</label>
                            <input type="text" class="form-control" placeholder="Cargo" required name="cargo" [(ngModel)]="asesor.cargo">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Presentacion</label>
                            <textarea class="form-control" rows="5" placeholder="Presentacion" required name="presentacion" [(ngModel)]="asesor.presentacion"></textarea>
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Celular</label>
                            <input type="tel" class="form-control" placeholder="Celular" required name="celular" [(ngModel)]="asesor.celular">
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Correo</label>
                            <input type="text" class="form-control" placeholder="Correo" required name="correo" [(ngModel)]="asesor.correo">
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button *ngIf="!load_btn" class="btn btn-enviar mr-3" type="submit">Crear Miembre De Equipo</button>
            <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                Creando...
              </button>
        </div>
    </div>
    </form>
    <!--Fin inventario-->
</div>

</div>