

<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Documentos</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Actualmente nuestro estudio de crédito es gratis. Puedes hacerlo tanto para persona natural como para persona jurídica. Si necesitas realizar el estudio, puedes hacerlo presionando alguno de los botones que están a continuación según tu caso</p>

    </div>
</div>

<section class="contact">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-5">
              <button type="button" class="btn btn-enviar" onclick="window.open('https://analisisweb.ellibertador.co/analisis-persona-natural/arrendatario', '_blank')">Persona Natural</button>
            </div>
            <div class="col-md-5">
              <button type="button" class="btn btn-enviar" onclick="window.open('https://analisisweb.ellibertador.co/analisis-persona-natural/arrendatario-juridico', '_blank')">Persona Jurídica</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
