import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroRangoFecha'
})
export class FiltroRangoFechaPipe implements PipeTransform {

  transform(pagos: any, fechaInicial: any, fechaFinal: any): any {
    let buscarPorFecha = [];

    if(fechaInicial == null && fechaFinal == null){
      buscarPorFecha = pagos
    }
    else if(fechaInicial != null && fechaFinal != null){

    for(const pago of pagos){
      if(pago.fecha.toDate() >= fechaInicial && pago.fecha.toDate() <= fechaFinal){
        buscarPorFecha.push(pago);
      }
    }
  }
  else if(fechaInicial != null && fechaFinal == null){
    for(const pago of pagos){
      if(pago.fecha.toDate() >= fechaInicial){
        buscarPorFecha.push(pago);
      }
    }
  }

  else if(fechaInicial == null && fechaFinal != null){
    for(const pago of pagos){
      if(pago.fecha.toDate() <= fechaFinal){
        buscarPorFecha.push(pago);
      }
    }
  }
 
    return buscarPorFecha;
  }
}
