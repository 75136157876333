<app-navbar ></app-navbar>

<app-slideshow>
</app-slideshow>

<main id="main">
    <app-whatsappicon></app-whatsappicon>
    <app-install-app 
        *ngIf="deferredPrompt != null"  
        (closeEvent)="closeCard()"
        (installEvent)="installPWA()"
    >
    </app-install-app>
    <app-ultimas-propiedades></app-ultimas-propiedades>
    <app-servicios></app-servicios>
    <app-mapa></app-mapa>
    <app-asesores></app-asesores>
    <app-aliados></app-aliados>
</main>

<!--<app-footer></app-footer>-->