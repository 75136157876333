
<mat-sidenav-container (togleMenu)="togleMenu($event)" class="sidenav-container custom-scroll">
    <mat-sidenav #drawer class="sidenav " fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
      <mat-toolbar>
        <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
        class="mat-cerrar"
        >
        <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
      </button>

        <div class="logo centrar_logo">
          <a class="navbar-brand py-2 text-center" >
              <a class="navbar-brand text-brand" ><img src="../../../../assets/images/icono (1).png" alt="logo Valor Inmobiliaria" class="logo-vabait ml-3" width="180"></a>
              
          </a>
          
      </div>
      </mat-toolbar>
      <mat-nav-list class=" custom-scroll">
        <div class="cs-offcanvas-body px-0 pt-4 pb-grid-gutter mt-5" data-simplebar data-simplebar-inverse>
          <h6 class="font-size-lg text-light px-4 mt-2 mb-3 text-center">Vabait inmobiliaria</h6>
          <hr class="hr-gradient hr-light mb-2 opacity-25">
          <nav *ngIf="isAdmin" class="nav nav-light">
              <!--<a class="nav-link px-4 " (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/inicio'] " routerLinkActive="active"><i class="fa fa-thin fa-house-user mr-2"></i>Inicio</a>-->
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/BI'] " routerLinkActive="active"><i class="bi fa-business-time"></i> Business Intelligence</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/admins'] " routerLinkActive="active"><i class="bi bi-people-fill mr-2"></i>Administradores</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/asesores'] " routerLinkActive="active"><i class="bi bi-people-fill mr-2"></i>Equipo De Trabajo</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/propiedades'] " routerLinkActive="active"><i class="bi bi-house-door-fill mr-2"></i>Inmuebles</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/consultas'] " routerLinkActive="active"><i class="bi bi-megaphone-fill mr-2"></i>Consultas</a>
             <!--<a class="nav-link px-4 pt-2" [routerLink]=" ['/panel/facturacion'] " routerLinkActive="active"><i class="fa fa-light fa-business-time mr-2"></i>Facturaci&oacute;n</a>-->
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/mensajes'] " routerLinkActive="active"><i class="bi bi-chat-left-text-fill mr-2"></i>Mensajes</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/listar_requerimientos'] " routerLinkActive="active"><i class="bi bi-megaphone-fill mr-2"></i>Requerimientos</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/gestionar-certificados'] " routerLinkActive="active"><i class="bi bi-clipboard-check-fill mr-2"></i>Certificados</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/clientes'] " routerLinkActive="active"><i class="bi bi-people-fill mr-2"></i>Clientes</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/ver_Inventarios'] " routerLinkActive="active"><i class="bi bi-clipboard-check-fill mr-2"></i>Inventarios</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/subir_contrato'] " routerLinkActive="active"><i class="fa fa-thin fa-handshake mr-2"></i>Contratos arrendatarios</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/subir_contrato_propietario'] " routerLinkActive="active"><i class="fa fa-thin fa-handshake mr-2"></i>Contratos propietarios</a>
              <a class="nav-link px-4 pt-4" (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/cambiar-password'] "><i class="fa fa-edit"></i> Cambiar Contraseña </a>
              <a class="nav-link px-4 pt-5" (click)="logout()" [routerLink]=" ['/app/inicio'] "> <i class="bi bi-box-arrow-left mr-2"></i>Cerrar sesión </a>
  
          </nav>
  
          <nav *ngIf="isAsesor" class="nav nav-light">
              <!--<a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/inicio'] " routerLinkActive="active"><i class="fa fa-thin fa-house-user mr-2"></i>INICIO</a>-->
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/propiedades'] " routerLinkActive="active"><i class="bi bi-house-door-fill mr-2"></i>Inmuebles</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/consultas'] " routerLinkActive="active"><i class="bi bi-megaphone-fill mr-2"></i>Consultas</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/mensajes'] " routerLinkActive="active"><i class="bi bi-chat-left-text-fill mr-2"></i>Mensajes</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/listar_requerimientos'] " routerLinkActive="active"><i class="bi bi-megaphone-fill mr-2"></i>Requerimientos</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/clientes'] " routerLinkActive="active"><i class="bi bi-people-fill mr-2"></i>Clientes</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/ver_Inventarios'] " routerLinkActive="active"><i class="bi bi-clipboard-check-fill mr-2"></i>Inventarios</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/subir_contrato'] " routerLinkActive="active"><i class="fa fa-thin fa-handshake mr-2"></i>Contratos arrendatarios</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/subir_contrato_propietario'] " routerLinkActive="active"><i class="fa fa-thin fa-handshake mr-2"></i>Contratos propietarios</a>
              <a class="nav-link px-4 pt-4" (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/cambiar-password'] "><i class="fa fa-edit"></i> Cambiar Contraseña </a>
              <a class="nav-link px-4 pt-5" (click)="logout()" [routerLink]=" ['/app/inicio'] "><i class="bi bi-box-arrow-left mr-2"></i> Cerrar sesión </a>
  
          </nav>
  
          <nav *ngIf="isCliente" class="nav nav-light">
              <!--<a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/inicio/inicio_clientes'] " routerLinkActive="active"><i class="fa fa-thin fa-house-user mr-2"></i>INICIO</a>-->
              <a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/consultar_inventario_clientes'] " routerLinkActive="active"><i class="bi bi-clipboard-check-fill mr-2"></i>Consultar inventario</a>
              <a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/consultar_contrato'] " routerLinkActive="active"><i class="fa fa-thin fa-handshake mr-2"></i>Consultar Contrato</a>
              <a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/documentos'] " routerLinkActive="active"><i class="bi bi-archive-fill mr-2"></i>Estudio de cr&eacute;dito</a>
              <a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/ver_requerimientos'] " routerLinkActive="active"><i class="bi bi-archive-fill mr-2"></i>Requerimientos</a>
              <a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/solicitar-certificados'] " routerLinkActive="active"><i class="bi bi-clipboard-check-fill mr-2"></i>Certificados</a>
              <!-- <a class="nav-link px-4 " [routerLink]=" ['/panel/pagos'] " routerLinkActive="active"><i class="fa fa-light fa-business-time mr-2"></i>Pagos</a> -->
              <a class="nav-link px-4 pt-4" (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/cambiar-password'] "><i class="fa fa-edit"></i> Cambiar Contraseña </a>
              <a class="nav-link px-4 pt-5" (click)="logout()" [routerLink]=" ['/app/inicio'] "><i class="bi bi-box-arrow-left mr-2"></i> Cerrar sesión </a>
  
          </nav>
  
          <nav *ngIf="isPropietario" class="nav nav-light">
              <!--<a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/inicio_propietarios'] " routerLinkActive="active"><i class="fa fa-thin fa-house-user mr-2"></i>INICIO</a>-->
              <a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/consultar_inventario_propietario'] " routerLinkActive="active"><i class="bi bi-clipboard-check-fill mr-2"></i>Consultar inventario</a>
              <a class="nav-link px-4 "  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/consultar_contrato_propietario'] " routerLinkActive="active"><i class="fa fa-thin fa-handshake mr-2"></i>Consultar Contrato</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/requerimientos'] " routerLinkActive="active"><i class="bi bi-ticket-fill mr-2"></i>Mantenimiento</a>
              <a class="nav-link px-4 pt-2"  (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/solicitar-certificados'] " routerLinkActive="active"><i class="bi bi-clipboard-check-fill mr-2"></i>Certificados</a>
              <a class="nav-link px-4 pt-4" (click)="isMovil ?  drawer.toggle(): false " [routerLink]=" ['/panel/cambiar-password'] "><i class="fa fa-edit"></i> Cambiar Contraseña </a>
              <a class="nav-link px-4 pt-5" (click)="logout()" [routerLink]=" ['/app/inicio'] "><i class="bi bi-box-arrow-left mr-2"></i> Cerrar sesión </a>
  
          </nav>
  
  
      </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span>Hola! {{ user.nombres }}</span>
      </mat-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  