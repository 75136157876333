// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: 'AIzaSyDK05V1zcjUIEy-EArYjDvz0Eolua_NFac',
    authDomain: 'vabait-2b1fb.firebaseapp.com',
    databaseURL:'https://vabait-2b1fb-default-rtdb.firebaseio.com',
    projectId: 'vabait-2b1fb',
    storageBucket: 'vabait-2b1fb.appspot.com',
    messagingSenderId: '365750067677',
    appId: '1:365750067677:web:9bc51a39b27e3139071634'
}
};

export const realtimeDBEnvironment = {
  production: false,
  firebase : {
    apiKey: 'AIzaSyDK05V1zcjUIEy-EArYjDvz0Eolua_NFac',
    authDomain: 'vabait-2b1fb.firebaseapp.com',
    databaseURL:'https://vabait-2b1fb.firebaseio.com',
    projectId: 'vabait-2b1fb',
    storageBucket: 'vabait-2b1fb.appspot.com',
    messagingSenderId: '365750067677',
    appId: '1:365750067677:web:9bc51a39b27e3139071634'
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
