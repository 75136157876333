import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { GLOBAL } from 'src/app/services/GLOBAL';

@Component({
  selector: 'app-inmuebles',
  templateUrl: './inmuebles.component.html',
  styleUrls: ['./inmuebles.component.css']
})
export class InmueblesComponent implements OnInit {
  
  @HostListener('window:scroll',['$event'])
  onScroll(){
    const pos = (document.documentElement.scrollTop || document.body.scrollTop) + 1100;
    const max = (document.documentElement.scrollHeight || document.body.scrollHeight)


    /*if(pos > max){
     
      console.log('Llamar servicio')
    }*/
  }

  public page = 1;
  public pageSize = 6;
  public paginador;
  public propiedades : Array<any> =[];
  public url;
  public filter_propiedad = '';
  public load_data = true;

  public sort_by = '';
  public filter_oferta = '';
  public filter_tipo = '';
  public filter_baths = '';
  public filter_recamaras = '';
  public filter_ciudad = '';
  public filter_precio = '';
  public precio_base;
  public precio_tope;
  public ciudadesconsulta: Array<any> =[];
  public tipoconsulta:  Array<any> =[];

  
  constructor( private activatedRoute: ActivatedRoute,
                private _clienteService : ClienteService) {
                  this.url = GLOBAL.url;
                 }

  ngOnInit(): void {
   /* this.activatedRoute.params.subscribe(params=>{
      console.log('recibido en inmuebles ' + params.operacion);
      this.listar_propiedades();
    })*/
    this.activatedRoute.queryParams.subscribe(params=>{
      params.operacion === undefined ? this.filter_oferta ='' : this.filter_oferta = params.operacion
      params.tipo=== undefined ? this.filter_tipo ='' : this.filter_tipo = params.tipo
      params.banos=== undefined ? this.filter_baths ='' : this.filter_baths = params.banos
      params.habitaciones=== undefined ? this.filter_recamaras ='' : this.filter_recamaras = params.habitaciones
      params.ciudad=== undefined ? this.filter_ciudad ='' : this.filter_ciudad = params.ciudad
      params.precio=== undefined ? this.filter_precio ='' : this.filter_precio = params.precio
     
    this.buscar_propiedad();
    this.obtener_ciudades();
    this.obtener_tipo();

    
    })
  }

  listar_propiedades()
  {
    this._clienteService.listar_propiedades_publico(this.filter_propiedad,this.filter_oferta,this.filter_tipo,this.filter_baths,this.filter_recamaras, this.filter_ciudad,this.filter_precio).subscribe(
      response => {
        this.propiedades = response.data;
        
      },error =>{
        console.log(error);
      }
    )

    this.load_data = false;
  }

  buscar_propiedad()
  {
    this.load_data = true;
    this._clienteService.listar_propiedades_publico(this.filter_propiedad,this.filter_oferta,this.filter_tipo,this.filter_baths,this.filter_recamaras, this.filter_ciudad,this.filter_precio).subscribe(
      response => {
     //   let propiedades: Array<any> =[];
        this.propiedades = response.data;
     
       let paginador = this.propiedades.length / this.pageSize;
         if(!Number.isInteger(paginador)){
            this.paginador = Math.trunc(paginador) + 1
          console.log( this.paginador ) ;
         }
         else{
          this.paginador = paginador;
          console.log( this.paginador ) ;
         }
        
      },error =>{
        console.log(error);
      }
     
    )


    this.load_data = false;
  }

  reset_propiedades()
  {
    this.load_data = true;
    this._clienteService.listar_propiedades_publico('','','','','','','').subscribe(
      response => {
        this.propiedades = response.data;
        this.filter_propiedad = "";
      },error =>{
        console.log(error);
      }
      
    )
    this.filter_tipo = ''
    this.filter_oferta = ''
    this.load_data = false;
  }


  orden_por()
  {
    if(this.sort_by == 'Defecto')
    {
      
      this.propiedades.sort(function(a,b) {
        if(a.createdAt < b.createdAt)
        {
          return 1;
        }
        if(a.createdAt > b.createdAt)
        {
          return -1;
        }
        return 0;
      })
      this.load_data = false;

    }else if(this.sort_by =='+-Precio'){

      this.propiedades.sort(function(a,b){
        if(a.precio < b.precio)
        {
          return 1;

        }if(a.precio  > b.precio){
          return -1;
        }
        return 0;
      });
      this.load_data = false;
    }else if(this.sort_by =='-+Precio'){
      this.propiedades.sort(function(a,b){
        if(a.precio >  b.precio)
        {
          return 1;

        }if(a.precio  < b.precio){
          return -1;
        }
        
        return 0;
      })
      this.load_data = false;
    }

    else if(this.sort_by == 'azTitulo'){

      this.propiedades.sort(function(a,b){

        if(a.titulo > b.titulo)
        {
          return 1;
        }
        if(a.titulo < b.titulo)
        {
          return -1;
        }

        return 0;

      });
      this.load_data = false;

      

    }
    else if(this.sort_by == 'zaTitulo'){

      this.propiedades.sort(function(a,b){

        if(a.titulo < b.titulo)
        {
          return 1;
        }
        if(a.titulo > b.titulo)
        {
          return -1;
        }

        return 0;

      });

      this.load_data = false;
    }
    
  }

  obtener_ciudades(){
    this._clienteService.obtener_ciudades().subscribe(
      response =>{
        this.ciudadesconsulta = response.data;
        console.log(this.ciudadesconsulta);
      }
    )
  }

  obtener_tipo(){
    this._clienteService.obtener_tipo().subscribe(
      response =>{
        this.tipoconsulta = response.data;
        console.log(this.ciudadesconsulta);
      }
    )
  }

}
