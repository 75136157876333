import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { GLOBAL } from "./GLOBAL";
import { HttpClient,HttpHeaders, HttpParams } from "@angular/common/http";
import { FirebaseService } from './firebase.service';
import { FileItem } from '../models/file-item';
import { resolve } from 'dns';
import { rejects } from 'assert';
@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  public url;

  constructor(
    private _http : HttpClient,
    private _fb: FirebaseService
  ) { 
    this.url = GLOBAL.url;
  }

  listar_propiedades_publico(filtro:string,estatus:any,tipo:string,banos,recamaras,ciudad,precio): Observable<any>
  {

    const params = new HttpParams()
   .set('filtro', filtro)
   .set('estatus', estatus)
   .set('tipo',tipo)
   .set('banos',banos)
   .set('recamaras',recamaras)
   .set('ciudad',ciudad)
   .set('precio',precio)
   


   return this._http.get(this.url + 'listar_propiedades_publico/',{params});
  }



  obtener_propiedad_codigo_publico(codigo:any): Observable<any>
  {

   return this._http.get(this.url + 'obtener_propiedad_codigo_publico/'+codigo);
  }

  obtener_asesor_propiedad(asesor:any): Observable<any>
  {

   return this._http.get(this.url + 'obtener_asesor_propiedad/'+asesor);
  }

obtener_propiedad_wasi(){


  const xhr = new XMLHttpRequest();
  const url = 'https://api.wasi.co/v1/property/search?id_company=3715420&wasi_token=pLmH_hFDJ_Bg0V_hN6J';
  
  xhr.open('GET', url);
  xhr.onreadystatechange = function () {
    if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        console.log(xhr.responseText);
    }
};
  xhr.send();

}

  registrar_consulta_propiedad(data:any):Observable<any>
  {
    return this._http.post(this.url + 'registrar_consulta_propiedad/',data);
  }

  enviar_mensaje(data:any):Observable<any>
  {
    return this._http.post(this.url + 'enviar_mensaje/',data);
  }

  //Asesores

  obtener_asesores(): Observable<any>{
    return this._http.get(this.url + 'obtener_asesores/');
  }

  obtener_ciudades(): Observable<any>{
    return this._http.get(this.url + 'obtener_ciudades/');
  }

  obtener_tipo(): Observable<any>{
    return this._http.get(this.url + 'obtener_tipo/');
  }

  obtener_asesor(id: any): Observable<any>{
    return this._http.get(this.url + 'obtener_asesor/'+id);
  }

  actualizar_asesor(id: any, newData: any): Observable<any> {
    return this._http.put<any>(this.url + 'actualizar_asesor/' + id, newData);
  }

  //Banner inicio

  listar_banner_publico(): Observable<any>{
    return this._http.get(this.url + 'listar_banner_publico/');
  }


  //Admin services

      //facturas

    
      private registrarFacturaDB(factura: {cliente: string, fecha: any, url: string, valor:number}){
        //var CARPETA_DOCUMENTOS = 'inventario';
      this._fb.db.collection("facturas").add(factura)
       }

       cargarArchivoFactura(archivos: FileItem[], cliente: string, fecha: any, valor:number){

        
            var storage = this._fb.firebase.storage().ref();
             
            for(let item of archivos){
              item.estaSubiendo = true;
              if(item.progreso >= 100){
                continue;
              }
              const uploadTask = storage.child(`facturas/${cliente}/${item.nombreArchivo}`)
                                                                      .put(item.archivo)
       uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    
      },
      (error) => {
        console.log('error subiendo los archivos', error)
      },
       ()  => {
        console.log('imagen cargada correctamente');
    
      
       uploadTask.snapshot.ref.getDownloadURL().then( async (downloadURL)  =>  {
          
    
          item.url =  await downloadURL;
          this.registrarFacturaDB({
            cliente: cliente, 
            fecha: fecha, 
            url: item.url, 
            valor: valor
        })
        });
        item.estaSubiendo = false;
    
      });
            }
    
      }

      consultar_cliente(token:any, cliente:any): Observable<any>
      {
        let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
        //return this._http.get(this.url + 'obtener_admin/'+id);
        return this._http.get(this.url + 'consultar_cliente/'+ cliente,{headers:headers});
      }

      consultar_pagos(usuario: any):Promise<any>
      {
    
        let pagosRef = this._fb.db.collection("pagos");
        let query = pagosRef.where("cliente", "==", usuario).orderBy("fecha","desc")
        .get()
        return query
      }

      //Contratos


      obtener_contrato(cliente: string):Promise<any>
      {
        //console.log(cliente)
        let contratoRef = this._fb.db.collection("Contratos");
        let query = contratoRef.where("cliente", "==", cliente)
        .get()
        return query
      }

      obtener_contrato_propietarios(propietarios: string):Promise<any>
      {
        //console.log(cliente)
        let contratopropietariosRef = this._fb.db.collection("Contratos-propietarios");
        let query = contratopropietariosRef.where("cliente", "==", propietarios)
        .get()
        return query
      }

      eliminar_contrato(
        cliente: string, 
        nombreArchivo: string, 
        idDocumento: string):Promise<any>
      {

        const url = `Contratos/${cliente}/${nombreArchivo}`
        const desertRef = this._fb.storage.ref().child(url);
        let contratoRef = this._fb.db.collection("Contratos");

         // Eliminar archivo y eliminar registro en paralelo
        const eliminarPromesas = Promise.all([
          desertRef.delete(),
          contratoRef.doc(idDocumento).delete()
        ]);
  
        return eliminarPromesas;
      }

      eliminar_contrato_propietarios(
        cliente: string, 
        nombreArchivo: string, 
        idDocumento: string):Promise<any>
      {
        const url = `Contratos-propietarios/${cliente}/${nombreArchivo}`
        const desertRef = this._fb.storage.ref().child(url);
        let contratoPropietarioRef = this._fb.db.collection("Contratos-propietarios");

         // Eliminar archivo y eliminar registro en paralelo
        const eliminarPromesas = Promise.all([
          desertRef.delete(),
          contratoPropietarioRef.doc(idDocumento).delete()
        ]);
  
        return eliminarPromesas;
        
      }

      //Mantenimiento

      obtener_mantenimiento(cliente: string):Promise<any>
      {
        let mantenimientoRef = this._fb.db.collection("mantenimiento");
        let query = mantenimientoRef.where("cliente", "==", cliente)
        .get()
        return query
      }

      obtener_mantenimiento_propietario(propietario: string):Promise<any>
      {
        let mantenimientoRef = this._fb.db.collection("mantenimiento");
        let query = mantenimientoRef.where("propietario", "==", propietario)
        .get()
        return query
      }

      obtener_mantenimientos():Promise<any>
      {
        let mantenimientoRef = this._fb.db.collection("mantenimiento");
        let query = mantenimientoRef.get();
        return query;
      }


      obtener_mantenimiento_by_status(estado: string):Promise<any>
      {
        let mantenimientoRef = this._fb.db.collection("mantenimiento");
        let query = mantenimientoRef.where("estado", "==", estado)
        .get()
        return query
      }

      async obtener_mantenimiento_by_id(id: string): Promise<any> {
        try {
          const mantenimientoRef = this._fb.db.collection("mantenimiento").doc(id);
          const doc = await mantenimientoRef.get();
          
          if (doc.exists) {
            return doc.data()
          } else {
            throw new Error("No se encontró ningún mantenimiento con el ID especificado.");
          }
        } catch (error) {
          console.error("Error al obtener el mantenimiento por ID:", error);
          throw error; // Puedes manejar el error según tus necesidades
        }
      }

      obtener_evidencias_requerimiento(id: string):Promise<any>
      {
        let evidenciasdRef = this._fb.db.collection("evidencias-mantenimiento");
        let query = evidenciasdRef.where("idMantenimiento", "==", id)
        .get()
        return query
      }

      async actualizar_requerimiento(id: string, nuevosDatos: any): Promise<void> {
        try {
          const mantenimientoRef = this._fb.db.collection("mantenimiento").doc(id);
          await mantenimientoRef.update(nuevosDatos);
        } catch (error) {
          console.error("Error al actualizar el requerimiento:", error);
          throw error;
        }
      }


      //Certificados

      obtener_certificado_prop():Promise<any>
      {
        let mantenimientoRef = this._fb.db.collection("certificados-ingresos-prop");
        let query = mantenimientoRef.get();
        return query;
      }


      obtener_certificado_prop_by_status(estado: string):Promise<any>
      {
        let mantenimientoRef = this._fb.db.collection("certificados-ingresos-prop");
        let query = mantenimientoRef.where("estado", "==", estado)
        .get()
        return query
      }

      obtener_certificado_prop_by_email(email: string):Promise<any>
      {
        let mantenimientoRef = this._fb.db.collection("certificados-ingresos-prop");
        let query = mantenimientoRef.where("email", "==", email)
        .get()
        return query
      }

      async obtener_Certificado_by_id(id: string): Promise<any> {
        try {
          const mantenimientoRef = this._fb.db.collection("certificados-ingresos-prop").doc(id);
          const doc = await mantenimientoRef.get();
          
          if (doc.exists) {
            return doc.data()
          } else {
            throw new Error("No se encontró ningún mantenimiento con el ID especificado.");
          }
        } catch (error) {
          console.error("Error al obtener el mantenimiento por ID:", error);
          throw error; // Puedes manejar el error según tus necesidades
        }
      }

      crearsolicitud(solicitud: any): Promise<any> {
        return this._fb.db.collection('certificados-ingresos-prop').add(solicitud);
      }

      async actualizar_certificado(id: string, nuevosDatos: any): Promise<void> {
        try {
          const mantenimientoRef = this._fb.db.collection("certificados-ingresos-prop").doc(id);
          await mantenimientoRef.update(nuevosDatos);
        } catch (error) {
          console.error("Error al actualizar el requerimiento:", error);
          throw error;
        }
      }


      obtener_inventario(cliente: string):Promise<any>
      {
        console.log(cliente)
        let inventarioRef = this._fb.db.collection("inventario");
        let query = inventarioRef.where("cliente", "==", cliente)
        .get()
        return query
      }

      obtener_notificaciones(cliente: string):Promise<any>
      {
        console.log(cliente)
        let notificacionesRef = this._fb.db.collection("notificaciones");
        let query = notificacionesRef.where("cliente", "==", cliente)
        .get()
        return query
      }

      consultar_facturas(usuario: any):Promise<any>
      {
    
        let pagosRef = this._fb.db.collection("facturas");
        let query = pagosRef.where("cliente", "==", usuario).orderBy("fecha","desc")
        .get()
        return query
      }

            // Pagos y facturas

            private registrarPagoDB(
              pago: {
                cliente: string, 
                comentarios: string, 
                fecha: string, 
                urlSoporte: string, 
                valor:number, 
                nombre?: string}){
              //var CARPETA_DOCUMENTOS = 'inventario';
            this._fb.db.collection("pagos").add(pago)
             }
      
             cargarArchivo(archivos: FileItem[], pago: any){
      
              
                  var storage = this._fb.firebase.storage().ref();
                   
                  for(let item of archivos){
                    item.estaSubiendo = true;
                    if(item.progreso >= 100){
                      continue;
                    }
                    const uploadTask = storage.child(`pagos/${pago.cliente}/${item.nombreArchivo}`)
                                                                            .put(item.archivo)
             uploadTask.on('state_changed',
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          
            },
            (error) => {
              console.log('error subiendo los archivos', error)
            },
             ()  => {
              console.log('imagen cargada correctamente');
          
            
             uploadTask.snapshot.ref.getDownloadURL().then( async (downloadURL)  =>  {
                
          
                item.url =  await downloadURL;
                this.registrarPagoDB({
                  cliente: pago.cliente, 
                  comentarios: pago.comentarios, 
                  fecha: pago.fecha, 
                  urlSoporte: item.url, 
                  valor: pago.valor,
                  nombre: pago.nombre
              })
              });
              item.estaSubiendo = false;
          
            });
                  }
          
            }

             // Facturas Propietarios

             private registrarFacturaPropietarioDB(
              facturaPropietario: {
                propietario: string, 
                fecha: string, 
                urlSoporte: string}):Promise<any>{

                return new Promise<any>((resolve, reject) => {
                  console.log('promise')
                  this._fb.db.collection("facturasPropietarios").add(facturaPropietario)
                    .then((data) => {
                      console.log('finally: ' + data)
                      resolve(data); // Resuelve la promesa con el resultado de la operación
                    })
                    .catch((error) => {
                      reject(error); // Rechaza la promesa con el error ocurrido
                    });
                })
             }
      
             cargarFacturaPropietario(archivos: FileItem[], detalleFactura: any): Promise<any> {
              var storage = this._fb.firebase.storage().ref();
              let archivoPromesas: Promise<any>[] = [];
            
              for (let item of archivos) {
                item.estaSubiendo = true;
                if (item.progreso >= 100) {
                  continue;
                }
                const uploadTask = storage.child(`facturasPropietarios/${detalleFactura.email}/${item.nombreArchivo}`)
                  .put(item.archivo);
            
                const promesaArchivo = new Promise<any>((resolve, reject) => {
                  uploadTask.on('state_changed',
                    (snapshot) => {
                      item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    },
                    (error) => {
                      console.log('error subiendo los archivos', error);
                      reject(error);
                    },
                    () => {
                      console.log('imagen cargada correctamente');
                      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        item.url = downloadURL;
                        const archivoFactura = {
                          propietario: detalleFactura.propietario,
                          fecha: detalleFactura.fecha,
                          urlSoporte: item.url
                        };
                        resolve(archivoFactura);
                      });
                      item.estaSubiendo = false;
                    });
                });
            
                archivoPromesas.push(promesaArchivo);
              }
            
              return new Promise<any>((resolve, reject) => {
                Promise.all(archivoPromesas)
                  .then((archivos) => {
                    const promesasDB: Promise<any>[] = [];
                    for (let archivo of archivos) {
                      promesasDB.push(this._fb.db.collection("facturasPropietarios").add(archivo));
                    }
                    return Promise.all(promesasDB);
                  })
                  .then((data) => {
                    console.log('finalizado', data);
                    resolve(data);
                  })
                  .catch((error) => {
                    console.error('Error al cargar las facturas', error);
                    reject(error);
                  });
              });
            }

      obtener_inventario_cliente_propietario(cliente: string, propietario: string):Promise<any>
      {
        console.log(cliente)
        let inventarioRef = this._fb.db.collection("inventario");
        let query = inventarioRef.where("cliente", "==", cliente).where("propietario", "==", propietario)
        .get()
        return query
      }
            
}
