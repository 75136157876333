<div>
    <button class="btn-close" type="button" (click)="cancelar()" aria-label="Close"></button>
    <div class="modal-header">
        
        <h4 class="modal-title"> {{data.action}} </h4>
        
    </div>
    <div mat-dialog-content>

        <div class="modal-body">
            <p class="fs-sm"> Título: {{data.event.title}} </p>
            <p class="fs-sm"> Inicio: {{data.event.start}} </p>
            <p class="fs-sm"> Fin: {{data.event.end}} </p>
        </div>


    </div>
    <div mat-dialog-actions>
        <button mat-button class="btn-cancelar" (click)="cancelar()">Cancelar</button>
        <button mat-button [mat-dialog-close]="data" class="btn-enviar" cdkFocusInitial>Confirmar</button>
    </div>
</div>