import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { GLOBAL, GLOBALSOCKET } from 'src/app/services/GLOBAL';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { io } from "socket.io-client";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal } from '../../../clases/data-modal';
import { ModalDialogComponent } from '../../modal-dialog/modal-dialog.component';

declare var iziToast: any;
declare var $: any;
@Component({
  selector: 'app-index-consultas',
  templateUrl: './index-consultas.component.html',
  styleUrls: ['./index-consultas.component.css']
})
export class IndexConsultasComponent implements OnInit {

  
  public consultas  : Array<any> = [];
  public arr_consultas :  Array<any> = [];
  public token;
  public url;
  public load_data;
  public filtro;
  public filter_status = "Todos";
  public socket = io(GLOBALSOCKET.url,{transports: ['websocket'], upgrade: false});
  public page = 1;
  public pageSize = 10;

  constructor(
    private _adminService: AdminService,
    private _propiedadService : PropiedadService,
    public dialog: MatDialog
  ) {
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;
    this.socket.on('get-messages',(data)=>{
      this.obtener_consultas();
    })
   }

   abrirDialogo(id: any, titulo: string) {
    const dialogo1 = this.dialog.open(ModalDialogComponent, {
      data: new DataModal(id, titulo, '¿Estás seguro de eliminar esta consulta?')
    });

    dialogo1.afterClosed().subscribe(datos => {
    this.eliminar_consulta(datos.id)
    });
  }
  ngOnInit(): void {
    this.obtener_consultas();
    
  }


  obtener_consultas()
  {
  
    this._propiedadService.obtener_consultas(this.token).subscribe(
      response => {
        response.data.forEach(consulta => {
          console.log(consulta._id)
          
          if(consulta.propiedad){

            this.consultas.push(consulta)
          }
          else{
            this._propiedadService.eliminar_consulta_admin(consulta._id,this.token).subscribe(response=>{
              console.log(response)
            })
          }
        });
        
        

      }
    )
  }


  order_by_status()

  {
    if(this.filter_status == 'Todos')
    {
      this.obtener_consultas();
    }else
    {
        this._propiedadService.obtener_consultas(this.token).subscribe(
          response => {
            this.consultas = response.data;
            this.consultas = this.consultas.filter(item => item.estatus == this.filter_status)
          })
        

    }
  }

  eliminar_consulta(id:any)
  {
      
        this._propiedadService.eliminar_consulta_admin(id,this.token).subscribe(
          response =>{
            iziToast.show({
              title: 'Success',
              titleColor: '#1DC74C',
              class: 'text-success',
              color:'#FFF',
              position: 'topRight',
              message:'Consulta eliminada correctamente'})
           // this.obtener_consultas();
           this.consultas = this.consultas.filter(item => item._id == id)
          },
          error =>{
            console.log(error);
          }
        )
        
      

  }

}
