import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ClienteService } from './services/cliente.service';
import { GLOBAL } from './services/GLOBAL';

declare var funcion: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'VabaitApp';

  constructor(private metaTagService: Meta){}


  ngOnInit(): void {
    this.metaTagService.addTags([
      {property:'og:description',content:'Fincas, casas, lotes, apartamentos, locales comerciales para venta y arriendo en oriente antioqueño - Rionegro, La Ceja, El Carmén de Viboral, El retiro, ...'}
    ])
  }
 
}
