import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-ver-requerimientos',
  templateUrl: './ver-requerimientos.component.html',
  styleUrls: ['./ver-requerimientos.component.css']
})
export class VerRequerimientosComponent implements OnInit {

  public decodedToken;
  public token;
  public solicitudesMantenimiento = [];
  public cargando;

  constructor(private _clienteService : ClienteService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
      }

      if (!this.decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
      }

    } catch (error) {
      localStorage.removeItem('token');
     
    }
    this.consultarMantenimiento();
  }

  consultarMantenimiento(){
    this.cargando = true;
    this._clienteService.obtener_mantenimiento(this.decodedToken.email)
      .then((showcontrato) => {
        const solicitudesMantenimiento = [];
        showcontrato.forEach( async(documento) => {
          solicitudesMantenimiento.push(documento.data());
        })

        const solicitudesMantenimientoOrdenadas = solicitudesMantenimiento.sort((a, b) => {
          // Compara los valores de los campos 'id' de cada objeto
          if (a.id < b.id) {
            return -1; // a debe venir antes que b
          }
          if (a.id > b.id) {
            return 1; // b debe venir antes que a
          }
          return 0; // Son iguales, no se cambian de posición
        });

        this.solicitudesMantenimiento = solicitudesMantenimientoOrdenadas;
      })
    .finally(() => this.cargando = false)
  }
}
