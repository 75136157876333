import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { GLOBAL } from "./GLOBAL";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { FirebaseService } from './firebase.service';
@Injectable({
  providedIn: 'root'
})
export class PropiedadService {

  public url;

  constructor(

    private _http : HttpClient,
    private _fb: FirebaseService
  ) {

    this.url = GLOBAL.url;
   }

   registro_propiedad_admin(data:any,file:any,token:any):Observable<any>

   {
    let headers = new HttpHeaders({'Authorization':token});
    const fd = new FormData();

    fd.append('titulo',data.titulo);
    fd.append('codigo',data.codigo);
    fd.append('wasi',data.wasi);
    fd.append('ciudad',data.ciudad);
    fd.append('precio',data.precio);
    fd.append('descripcion',data.descripcion);
    fd.append('tipo',data.tipo);
    fd.append('oferta',data.oferta);
    fd.append('habitaciones',data.habitaciones);
    fd.append('banos',data.banos);
    fd.append('parqueaderos',data.parqueaderos);
    fd.append('area',data.area);
    fd.append('area_construida',data.area_construida);
    fd.append('yearbuilt',data.yearbuilt);
    fd.append('portada',file);
    fd.append('lat',data.lat);
    fd.append('long',data.long);
    fd.append('asesor',data.asesor);
    fd.append('propietario',data.propietario[0].uid);
    fd.append('video', data.video);
    fd.append('url_360', data.url_360);


    return this._http.post(this.url + 'registro_propiedad_admin',fd,{headers:headers});
   }

   listar_propiedades_admin(filtro:any,token:any): Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.get(this.url + 'listar_propiedades_admin/'+filtro,{ headers: headers });
   }

   obtener_propiedad_admin(id:any,token:any): Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.get(this.url + 'obtener_propiedad_admin/'+id, { headers: headers });
   }

 

   actualizar_propiedad_admin(data:any,id:any,token:any): Observable<any>{

    if(data.portada){
      let headers = new HttpHeaders({'Authorization':token});
      const fd = new FormData();

      fd.append('titulo',data.titulo);
      fd.append('codigo',data.codigo);
      fd.append('wasi',data.wasi);
      fd.append('ciudad',data.ciudad);
      fd.append('precio',data.precio);
      fd.append('descripcion',data.descripcion);
      fd.append('tipo',data.tipo);
      fd.append('oferta',data.oferta);
      fd.append('habitaciones',data.habitaciones);
      fd.append('banos',data.banos);
      fd.append('parqueaderos',data.parqueaderos);
      fd.append('area',data.area);
      fd.append('area_construida',data.area_construida);
      fd.append('yearbuilt',data.yearbuilt);
      fd.append('portada',data.portada);
      fd.append('lat',data.lat);
      fd.append('long',data.long);
      fd.append('disponible',data.disponible);
      fd.append('asesor',data.asesor);
      fd.append('propietario',data.propietario);
      fd.append('video', data.video);
      fd.append('url_360', data.url_360);
   
       return this._http.put(this.url + 'actualizar_propiedad_admin/'+id,fd,{ headers: headers });
    }else{
      let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
      return this._http.put(this.url + 'actualizar_propiedad_admin/'+ id,data, { headers: headers });
      

    }

   }

   eliminar_propiedad_admin(id,token):Observable<any>
   {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.delete(this.url + 'eliminar_propiedad_admin/' + id,{ headers: headers });
   }


   
   agregar_imagen_galeria_admin(id:any,data:any,token:any): Observable<any>{

    let headers = new HttpHeaders({'Authorization':token});
    
    const fd = new FormData();
  
    fd.append('_id',data._id);
  
    fd.append('imagen',data.imagen);
  
  
     return this._http.put(this.url + 'agregar_imagen_galeria_admin/'+id,fd,{ headers: headers });
   }

 eliminar_imagen_galeria_admin(id:any,data:any,token:any): Observable<any>{


      
  let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});

  return this._http.put(this.url + 'eliminar_imagen_galeria_admin/'+ id,data, { headers: headers });
  
}

listar_propiedades_publico(filtro:any): Observable<any>
  {
    let headers = new HttpHeaders().set('Content-Type','application/json');



   return this._http.get(this.url + 'listar_propiedades_publico/'+filtro,{headers:headers});
  }

  listar_propiedades_venta(filtro:any,token:any): Observable<any>
  {
   

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});

   return this._http.get(this.url + 'listar_propiedades_admin_venta/'+filtro,{ headers: headers });
  }

  listar_propiedades_renta(filtro:any,token:any): Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});



   return this._http.get(this.url + 'listar_propiedades_admin_renta/'+filtro,{ headers: headers });
  }



  obtener_consultas(token:any): Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.get(this.url + 'obtener_consultas/',{ headers: headers });
   }

   obtener_consulta_admin(id:any,token:any):Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.get(this.url + 'obtener_consulta_admin/'+id, { headers: headers });
   }

   actualizar_estatus_consulta(id:any,data:any,token:any):Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.put(this.url + 'actualizar_estatus_consulta/'+id,data, { headers: headers });
   }

   eliminar_consulta_admin(id:any,token:any):Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.delete(this.url + 'eliminar_consulta_admin/'+id, { headers: headers });
   }

   consultar_asesores(): Observable<any>{
    return this._http.get(this.url + 'obtener_asesores/');
  }

  obtener_propiedades_propietario(propietario:any): Observable<any>
  {

   return this._http.get(this.url + 'obtener_propiedades_propietario/'+propietario);
  }
  
  obtener_propiedades_cliente(cliente:any): Observable<any>
  {
   console.log(cliente)
   return this._http.get(this.url + 'obtener_propiedades_cliente/'+cliente);
   
  }

  obtener_requerimiento_inmueble(propiedad: string):Promise<any>
  {

    let propiedadRef = this._fb.db.collection("mantenimiento");
    let query = propiedadRef.where("inmueble", "==", propiedad)
    .get()
    return query
  }

  obtener_requerimiento_id(req: number):Promise<any>
  {
    let propiedadRef = this._fb.db.collection("mantenimiento");
    let query = propiedadRef.where("id", "==", req)
    .get()
    return query
  }
  obtener_imagenes_requerimiento(req: string):Promise<any>
  {
    let evidenciasdRef = this._fb.db.collection("evidencias-mantenimiento");
    let query = evidenciasdRef.where("idMantenimiento", "==", req)
    .get()
    return query
  }

  actualizar_requerimiento_inmueble(documento: string, respuesta: string):Promise<any>
  {
    let propiedadRef = this._fb.db.collection("mantenimiento").doc(documento);
    let res = propiedadRef.update({respuesta: respuesta})
    return res
        
  }

  crear_requerimiento_inmueble(documento: string, respuesta: string):Promise<any>
  {
    let propiedadRef = this._fb.db.collection("mantenimiento").doc(documento);
    let res = propiedadRef.update({respuesta: respuesta})
    return res
        
  }
}
