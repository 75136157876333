export class Propietario {
/*public nombreCompleto: string;
public uid: any
public nombres: string
public  apellidos: string
public  rol: string
public  email: string
public  telefono: string*/
public propietarios : Array<any> = [];

/*constructor(
{uid,
 nombres,
 apellidos,
 rol,
 email,
 telefono}
){
    this.uid = uid;
    this.nombres = nombres;
    this.apellidos = apellidos;
    this.rol = rol;
    this.email = email;
    this.telefono = telefono;
    this.nombreCompleto = nombres + ' ' + apellidos
}*/
constructor(private usuarios:any[]){

/*let uid: any
let nombres: string
let apellidos: string
let nombreCompleto: string;
let  rol: string
let  email: string
let  telefono: string*/

    usuarios.forEach(propietario => {
        
        let uid = propietario.uid;
        let nombres = propietario.nombres;
        let apellidos = propietario.apellidos;
        let nombreCompleto = propietario.nombres + ' ' + propietario.apellidos;
        let rol = propietario.rol;
        let email = propietario.email;
        let telefono = propietario.telefono;

        this.propietarios.push({uid,nombres,apellidos,nombreCompleto,rol,email,telefono})
    });
}
}
