import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import {CommonModule} from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { FileItem } from 'src/app/models/file-item';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';


declare var iziToast:any
@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {

  estaSobreElemento = false;
  public archivos: FileItem[] = [];
  public imagenes: FileItem[] = [];
  public contratos: Array<any> = []
  //public files: File[] = [];
  public token;
  public contrato;
  public usuario:any = {}
  public load_data = true;
  public users : Array<any> = [];
  public clientes : Array<any> = [];
  public page = 1;
  public pageSize = 5;
  public imagesPath = 'imagenes';
  public archivosPath = 'documentos-estudio';
  public archivosPath2 = 'pago-estudio';
  public documentoCliente = '';
  public opcionSeleccionada = 'Seleccione el cliente';
  public esactivo = false;
  public pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/vabait-2b1fb.appspot.com/o/facturas%2Fjuanfe1694%40misena.com%2FDepartamento%20de%20Antioquia%20-%20Impuestos%20de%20veh%C3%ADculos%20_%20Webcheckout%20PlacetoPay.pdf?alt=media&token=7d1569dd-4a4d-414e-b3bb-9bd93d20b638';
  pdfExample ="../../../../assets/pdfs/DOCUMENTACION PARA CLIENTES ARRIENDO VABAIT.pdf"
  

  constructor(private _usuarioService : UsuarioService,
    private _imagenesService: CargaImagenesService,
    private _ClienteService: ClienteService,
    private _subirArchivo: CargaImagenesService) {
      this.token = localStorage.getItem('token');
     }

  ngOnInit(): void {
    this.listar_usuario();
      //console.log('obtener_contrato()');
  }

 

  selectChange(){
    this.documentoCliente = this.opcionSeleccionada;
    document.getElementById('pdf').setAttribute('src',this.pdfUrl)
  }

  filtrarClientes(usuarios: Array<any>){
    for(let usuario of usuarios){
      console.log(usuario)
      if(usuario.rol == 'cliente'){
        this.clientes.push(usuario);
      }
    }
  
  }
    fileChangeEvent(event:any):void
  {
     var files : File[];
     if(event.target.files){
  
      files = event.target.files;
      console.log('impresion desde componente');
      console.log(files);
  
     }
     }
  
  cargarDocumento()
  {
    this._subirArchivo.cargarArchivo(this.archivos,this.documentoCliente, this.archivosPath)
  }

  cargarDocumento2()
  {
    this._subirArchivo.cargarArchivo(this.archivos,this.documentoCliente, this.archivosPath2)
  }
 
  limpiarArchivos(){
    this.archivos = [];
  }

    listar_usuario()
    {
      this._usuarioService.listar_usuario(this.token).subscribe(
        response =>{
          this.users = response.data;
          this.load_data = false;
          this.filtrarClientes(this.users);
        },
        error =>{
          console.log(error);
        }
      )
    }
    
    eliminar(id:any)
    {
      this._usuarioService.eliminar_usuario(id,this.token).subscribe(
        response =>{
          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color:'#FFF',
            position: 'topRight',
            message:'Usuario eliminado correctamente'
         
      });
      this.listar_usuario();
        },
        error  =>{
          console.log(error);
        }
  
      )
    }

}
