<!--<app-sidebar (setUsuario) = "getUsuario($event)"></app-sidebar>-->

<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5 text-center">Realizar pago</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">


    </div>


</div>

   
    <div class="card box-shadow-sm">
        <div class="card-header">
            <h5 style="margin-bottom: 0px;">Información del pago</h5>
        </div>
        <div class="card-body text-center" *ngIf="load_data" >
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <form action="">
        <ng-container *ngIf="!load_data">
        <div class="card-body "  >
            <div class="row">
                <div class="col-12">
                    <div class="row">

                        <div class="col-lg-12 form-group">
                            <label for="">Cliente </label>
                            <input type="text" class="form-control" disabled  name="cliente" [(ngModel)]="contrato.nombres">
                        </div>

                        <div class="col-lg-6 form-group">
                            <label for="">Vigencia</label>
                            <input type="text" class="form-control" disabled name="vigencia" [(ngModel)]="contrato.vigencia" >
                        </div>

                        <div class="col-lg-6 form-group">
                            <label for="">Valor</label>
                            <input type="text" class="form-control" disabled name="valor" [(ngModel)]="contrato.valor">
                        </div>
                        
                        <div class="form-floating">
                            <textarea class="form-control" placeholder="Dejenos sus comentarios" id="floatingTextarea" name="comentarios" [(ngModel)]="pago.comentarios"></textarea>
                            <label for="floatingTextarea">Comentarios</label>
                          </div>
                        



                    </div>
                </div>
            </div>
        </div>

                <!--inicia tarjeta-->
                <div  class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
                    <div *ngIf="imagenes.length == 0 " class="card-header">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm">
                                    <h5 style="margin-bottom: 0px;">Seleccione/capture o arrastre el soporte de pago.</h5>
                                </div>
                                <div class="col-sm">
                                    <!--<p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap" [routerLink]=" ['/panel/clientes/registro'] ">Buscar archivo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>-->
                                    <div class="file-select" id="src-file1">
        
                                        <input appNgFiles  [imagenes]="imagenes"  type="file" multiple="true" aria-label="Archivo" >
        
                                    </div>
                                </div>
        
                            </div>
                        </div>
        
        
                    </div>
        
                    <div class="card-body" >
                        <div appNgFiles *ngIf="imagenes.length == 0 " [imagenes]="imagenes" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento}" class="well drop-zone">
                            <i class="fa fa-solid fa-upload fa-8x"> </i>
                            <span>   Suelte los archivos en esta zona para subir</span>
        
                        </div>
        
                        <!--Inicio tarjetas vista previa-->
                        <div class="container mt-5">
                            <div class="row">
        
                                <div class="card-group">
                                    <div class="col " *ngFor="let imagen of imagenes">
                                        <div class="card">
                                            <img class="card-img-top" [src]="imagen.tempUrl" alt="imagen.nombreArchivo">
                                            <div class=" card-body ">
                                                <!--<h5 class="card-title "></h5>-->
                                                <p class="card-text "> {{ imagen.nombreArchivo }} </p>
                                                <div class="progress mt-2 ">
                                                    <!--<div class="progress-bar " role="progressbar " style="width:25%; " aria-valuenow="25 " aria-valuemin="0 " aria-valuemax="100 "></div>-->
        
                                                    <div class="progress-bar verde " role="progressbar " [ngStyle]="{ 'width': imagen.progreso + '%' } "></div>
                                                </div>
                                            </div>
                                            <div class="card-footer ">


                                                
                                                <button class="btn btn-cancelar " style="width: 100%;" (click)="limpiarImagenes() ">Eliminar comprobante</button>
                                
                                
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <!--fin tarjetas vista previa-->
        
        
                    </div>
        

        
        
                </div>
                <!--fin tarjeta-->
       </ng-container>
    </form>
        <div class="card-footer">
            
            <button *ngIf="!load_btn" class="btn btn-enviar mr-3" (click)="registrarPago()">Registrar pago</button>
            <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true" ></span>
                Creando...
              </button>
            <button class="btn btn-cancelar" [routerLink]=" ['/panel/pagos'] ">Regresar</button>
    
        </div>
    </div>




