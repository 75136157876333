import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AdminService } from '../../../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GLOBAL } from '../../../services/GLOBAL';
import { GetUsuarioService } from '../../../services/get-usuario.service';



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public url;
  public user : any = {};
  //@Output() setUsuario = new EventEmitter<any>();
  //public id;
  public usuario: any = {};
  public token;
  public isAdmin=false ;
  public isAsesor=false ;
  public isCliente =false;
  public isPropietario =false;
  public isMovil = false;
  public cssUrl: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
 
  );


  constructor(
    private breakpointObserver: BreakpointObserver,
    private _adminService: AdminService,
    private _route : ActivatedRoute,
    private _router : Router,
    
    //private _getUsuario: GetUsuarioService

  ) {
    this.url = GLOBAL.url

    
    //this.id = localStorage.getItem('_id');
    this.token = localStorage.getItem('token');
 
    try {
      const helper = new JwtHelperService();
      var decodedToken = helper.decodeToken(this.token);

    if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
          

      }

      if (!decodedToken) {
        console.log('no acceso')
        _router.navigate(['/login'])
        localStorage.removeItem('token');
        
      }
     

    } catch (error) {
      localStorage.removeItem('token');
    
    }
     
 
    this.validar_rol(decodedToken['rol'])
    this.user.nombres = decodedToken['nombres']
    this.user.apellidos = decodedToken['apellidos']
  
   }

  ngOnInit(): void {
   this.obtener_admin();
  this.isHandset$.subscribe(data =>{
    this.isMovil = data;
  }
   
  )
  }

  togleMenu(event: any){
    console.log(event)

  }
  obtener_admin()
  {
    this._adminService.obtener_admin(this.token).subscribe(
       response => 
      {
         this.usuario =   response.data;
        // this._getUsuario.emitirUsuario(this.usuario)
         //this.setUsuario.emit(this.usuario);
       // this.validar_rol(this.user.rol)
      }
    )
  }

  validar_rol(rol: any){
    
    switch(rol){
      case "admin":
        this.isAdmin = true;
        break;
        case "cliente":
        this.isCliente = true;
        //this._router.navigate(['/inicio/inicio_clientes'])
        //this._router.navigate(['/inicio/documentos'])
        break;
        case "asesor":
        this.isAsesor = true;
        break;
        case "propietario":
        this.isPropietario = true;
        break;
    }
  
 
  }

  logout()
  {
    localStorage.clear()
  }

}
