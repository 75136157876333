import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CalendarDataModal, DataModal } from '../../../clases/data-modal';
//import { ModalDialogComponent } from '../../../components/modal-dialog/modal-dialog.component';
import {
  ChangeDetectionStrategy,
  ViewChild,
  Component,
  OnInit,
  ViewEncapsulation,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  LOCALE_ID,
  Inject,
  TemplateRef
} from '@angular/core';

import { CalendarEventTitleFormatter,
        CalendarEventTimesChangedEvent,
        CalendarEventTimesChangedEventType,
        CalendarEvent,
        CalendarEventAction,
        CalendarView,
        CalendarUtils,
        CalendarDateFormatter

 } from 'angular-calendar';
import { WeekViewHourSegment,
        WeekDay,
        MonthView,
        MonthViewDay,
        ViewPeriod,
        EventColor,
        validateEvents 

} from 'calendar-utils';
import { MonthViewModel } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { 
  addDays, 
  addMinutes, 
  endOfWeek,
  startOfDay,
  endOfDay,
  subDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours, 

} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDialogComponent } from 'src/app/panel/components/calendar-dialog/calendar-dialog.component';
import { NuevaCitaDialogComponent } from 'src/app/panel/components/nueva-cita-dialog/nueva-cita-dialog.component';
import { CustomDateFormatter } from './agenda.provider';
import { AgendaService } from 'src/app/services/agenda.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ModalDialogComponent } from 'src/app/panel/components/modal-dialog/modal-dialog.component';
import { EditarCitaDialogComponent } from 'src/app/panel/components/editar-cita-dialog/editar-cita-dialog.component';

registerLocaleData(localeEs);
declare var iziToast:any;

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

export interface CalendarMonthViewBeforeRenderEvent {
  header: WeekDay[];
  body: MonthViewDay[];
  period: ViewPeriod;
}

export interface CalendarMonthViewEventTimesChangedEvent<
  EventMetaType = any,
  DayMetaType = any
> extends CalendarEventTimesChangedEvent<EventMetaType> {
  day: MonthViewDay<DayMetaType>;
}

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}


function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarEventTitleFormatter
    },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
    {
      provide: LOCALE_ID,
      useValue: 'es'
    }
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AgendaComponent  {

  /*Código funcional
  viewDate = new Date();
  weekStartsOn: 0 = 0;
  dragToCreateActive = false;
  events: CalendarEvent[] = [];
  days: any[] = [];
  slots: any[] = [];
  
  */
  
  /*Inicio data 1015 */
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  locale: string = 'es';

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  eventos: CalendarEvent[] = [];

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.abrirDialogoReprogramar(event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.abrirDialogoEliminar(event.id, event.title);
      },
    },
  ];

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];/*[
    
    {
      start: subDays(startOfDay(new Date()), 1),
      end: addDays(new Date(), 1),
      title: 'A 3 day event',
      color: { ...colors.red },
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
    {
      start: startOfDay(new Date()),
      title: 'An event with no end date',
      color: { ...colors.yellow },
      actions: this.actions,
    },
    {
      start: subDays(endOfMonth(new Date()), 3),
      end: addDays(endOfMonth(new Date()), 3),
      title: 'A long event that spans 2 months',
      color: { ...colors.blue },
      allDay: true,
    },
    {
      start: addHours(startOfDay(new Date()), 2),
      end: addHours(new Date(), 2),
      title: 'A draggable and resizable event',
      color: { ...colors.yellow },
      actions: this.actions,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
  ];*/

  activeDayIsOpen: boolean = true;
  

  constructor(private modal: NgbModal, public dialog: MatDialog, private _fb: FirebaseService) {}

  ngOnInit(): void {
    this.consultarCitas()
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  /* Cita movida con drag and drop */
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });

    //hago el update en firebase
    let starCountRef =  this._fb.RTDB.ref(`agenda/${event.id}`);

    const {title, color} = event;

    starCountRef.update({ start: newStart,  end: newEnd })
      .then(()=>{
        //muestro el modal en caso exitoso
        this.abrirDialogo('Evento modificado correctamente', event);
      })
      //muestro toast en caso de error
      .catch(error =>{
        iziToast.show({
          title: 'Error',
          titleColor: 'FF0000',
          class: 'text-danger',
          color:'red',
          position: 'topRight',
          message: error
      });
      })    
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  abrirDialogo(action: string, event: CalendarEvent) {
    this.modalData = { event, action };
    const dialogo1 = this.dialog.open(CalendarDialogComponent, {
      data: new CalendarDataModal(action , event)
    });
  }

  agregarCitaDialogo(action: string, event: any) {

    this.modalData = { event, action };
    const dialogoCita = this.dialog.open(NuevaCitaDialogComponent, {
      data: new CalendarDataModal(action , event)
    });

    dialogoCita.afterClosed().subscribe(datos => {
    //this.eliminar(datos.id)
    });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        id:'123',
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  consultarCitas(){
    let starCountRef =  this._fb.RTDB.ref('agenda');
    starCountRef.on('value', (snapshot) => {
      snapshot.forEach(data =>{
        this.events.push({
          ...data.val(),
          start: new Date(data.val().start),
          end: new Date(data.val().end),
          id: data.key,
          //color: { ...colors.red },
          actions: this.actions,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          draggable: true
        })

      })   
    });

  }

  abrirDialogoEliminar(id: any, titulo: string) {
    const dialogo1 = this.dialog.open(ModalDialogComponent, {
      data: new DataModal(id, titulo, '¿Estás seguro de eliminar este evento?')
    });

    dialogo1.afterClosed().subscribe(datos => {
      
      if(datos) {
          
          let starCountRef =  this._fb.RTDB.ref(`agenda/${datos.id}`);
          starCountRef.remove()
            .then(() =>{
              this.events = this.events.filter((iEvent) => iEvent.id !== datos.id);
              iziToast.show({
                title: 'Exito',
                titleColor: '#1DC74C',
                class: 'text-success',
                color:'#FFF',
                position: 'topRight',
                message:'Evento eliminado'
                });
            })
            .catch((error) => {
              iziToast.show({
                title: 'Error',
                titleColor: 'FF0000',
                class: 'text-danger',
                color:'red',
                position: 'topRight',
                message: error
            });
    
            })
        }
    });
  }

  abrirDialogoReprogramar( event: CalendarEvent ) {
    const dialogo = this.dialog.open(EditarCitaDialogComponent, {
      data: new CalendarDataModal( '', event )
    });

    dialogo.afterClosed().subscribe(datos => {
      
      if(datos) {

        let starCountRef =  this._fb.RTDB.ref(`agenda/${datos.id}`);
          starCountRef.update({
            title: datos.titulo.value,
            start: datos.inicio.value,
            end: datos.fin.value,
            color: datos.prioridad.value == 'alta' 
            ? {...colors.red }
            : {...colors.blue}
          })
            .then(() =>{
              this.events = this.events.filter((iEvent) => iEvent.id !== datos.id);
              iziToast.show({
                title: 'Exito',
                titleColor: '#1DC74C',
                class: 'text-success',
                color:'#FFF',
                position: 'topRight',
                message:'Evento reprogramado'
                });
            })
            .catch((error) => {
              iziToast.show({
                title: 'Error',
                titleColor: 'FF0000',
                class: 'text-danger',
                color:'red',
                position: 'topRight',
                message: error
            });
    
            })
        }
    });
  }

  
}
  /* Fin data 1015 */


    

