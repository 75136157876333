
<div class="card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Gestión de clientes</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este módulo lista todos los clientes y prospectos registrados.</p>

    </div>
</div>
<section class="property-single nav-arrow-b">
    <div class="container">


        <div class="row">
            <!--Inicio audiovisual-->
            <div class="col-md-10 offset-md-1">
                <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="users-tab" data-bs-toggle="tab" data-bs-target="#users" type="button" role="tab" aria-controls="home" aria-selected="true">Usuarios sistema</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="users" role="tabpanel" aria-labelledby="home-tab">
        <!--aqui empieza-->
        <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
            <div class="card-header">
                <div class="container">
                    <div class="row">
                        <div class="col-sm">
                            <h5 style="margin-bottom: 0px;">Usuarios registrados</h5>
                        </div>
                        <div class="col-sm">
                            <p class="font-size-sm font-weight-medium pl-md-4"><a class="text-nowrap btn-modulo" [routerLink]=" ['/panel/clientes/registro'] ">Registrar nuevo<i class="bi bi-caret-right-square font-size-base align-middle ml-1"></i></a></p>
                        </div>

                    </div>
                </div>


            </div>

            <div class="card-header">
                <div class="row">
                    <div class="col-md-8">
                        <div class="input-group mb-3">
                            <input class="form-control" type="text" name="filtro" [(ngModel)]="filtro"  placeholder="Nombre">
                            <button class="btn btn-primary btn-cancelar" type="button" (click)="filtrar()">Buscar</button>
                        </div>
                    </div>
                </div>
        
            </div>

            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Apellidos</th>
                                <th scope="col">Dirección</th>
                                <th scope="col">Rol</th>
                                <th scope="col">Teléfono</th>
                                <th scope="col">OP</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="load_data">
                            <tr>
                                <td class="text-center" colspan="7">
                                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <ng-container *ngIf="!load_data">
                            <tbody *ngFor=" let item of users| slice:(page-1) * pageSize: (page-1) * pageSize + pageSize  ;">
                                <tr>

                                    <td>
                                        {{item.nombres}}
                                    </td>
                                    <td> {{item.apellidos}} </td>
                                    <td> {{item.email}} </td>


                                    <td> {{item.rol}} </td>
                                    <td> {{item.telefono}} </td>
                                    <td>
                                        <div class="btn-group dropdown d-inline-block mb-3 mr-2 ">
                                            <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Opciones <span><i class="bi bi-caret-down-fill"></i></span>
                      </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" [routerLink]=" ['/panel/clientes',item.uid] ">Editar</a>
                                                <a class="dropdown-item" style="cursor: pointer;" (click)="abrirDialogo(item.uid,'Se eliminaran todos los documentos de: ' + item.email, item.email)">Eliminar</a>
                                                <a class="dropdown-item" style="cursor: pointer;" (click)="abrirDialogoResetearClave(item.uid,'Restablecer Contraseña ' + item.email)">Restablecer Contraseña</a>
                                            </div>
                                        </div>



                                       <!-- <div class="modal fade" id="delete-{{item.uid}}" tabindex="-1" style="display: none;" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">Eliminar, {{item.nombres}} {{item.apellidos}} </h4>
                                                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p class="fs-sm">¿Deseas eliminar este usuario?</p>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button class="btn btn-secondary btn-sm" type="button" data-bs-dismiss="modal">Cancelar</button>
                                                        <button class="btn btn-primary btn-sm" data-bs-dismiss="modal" (click)="eliminar(item.uid)" type="button">Si, eliminar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>-->
                                    </td>
                                </tr>
                            </tbody>
                        </ng-container>

                    </table>
                </div>



            </div>

            <div class="card-footer ">


                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="users.length" [maxSize]="4">

                </ngb-pagination>


            </div>


        </div>
        <!--aqui termina-->
    </div>
</div>