import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { PropiedadService } from 'src/app/services/propiedad.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal } from '../../../clases/data-modal';
import { ModalDialogComponent } from '../../modal-dialog/modal-dialog.component';


declare var iziToast: any;
declare var $: any;
@Component({
  selector: 'app-index-propiedades',
  templateUrl: './index-propiedades.component.html',
  styleUrls: ['./index-propiedades.component.css']
})
export class IndexPropiedadesComponent implements OnInit {

  @ViewChild(MatTable) tabla1!: MatTable<DataModal>;
  //@Output() togleMenu = new EventEmitter<boolean>();
  
  public showModal = false;
  public filtro = "";
  public load_data;
  public page1 = 1;
  public pageSize = 10;
  public token;
  public propiedades : Array<any> = [];
  public url;
  public sort_status = 'Todos';


  constructor(
    private _propiedadService : PropiedadService,
    private _adminService: AdminService,
    public dialog: MatDialog
  ) { 
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;
    
  }

  abrirDialogo(id: any, titulo: string) {
    const dialogo1 = this.dialog.open(ModalDialogComponent, {
      data: new DataModal(id, titulo, '¿Estás seguro de eliminar este inmueble?')
    });

    dialogo1.afterClosed().subscribe(datos => {
    this.eliminar(datos.id)
    });
  }
/*
  borrarFila(cod: number) {
    if (confirm("Realmente quiere borrarlo?")) {
      this.datos.splice(cod, 1);
      this.tabla1.renderRows();
    }
  }

  agregar(art: DataModal) {
    this.datos.push(new DataModal(art.id, art.descripcion, art.titulo));
    this.tabla1.renderRows();
  }
*/

  ngOnInit(): void {
    this.obtener_propiedades_admin();
  }

  modal(){
    this.showModal = !this.showModal;
    //this.togleMenu.emit(false);

  }
  hideModal(){
    this.showModal = false;
    //this.togleMenu.emit(true);
  }

  filtrar()
  {
    if(this.filtro){

      this.obtener_propiedades_admin();

    }else {
      this.obtener_propiedades_admin();

    }

  }

  obtener_propiedades_admin()
  {
    this._propiedadService.listar_propiedades_admin(this.filtro,this.token).subscribe(
      response  => {
        
        this.propiedades = response.data;
        this.load_data = false;
      },
      error  => {
        console.log(error);
      }
    )

   
  }






eliminar(id:any)
{
  this._propiedadService.eliminar_propiedad_admin(id,this.token).subscribe(
    response  =>
    {
      iziToast.show({
        title: 'Success',
        titleColor: '#1DC74C',
        class: 'text-success',
        color:'#FFF',
        position: 'topRight',
        message:'Propiedad eliminada correctamente'
  });

  this.obtener_propiedades_admin();


  
    },
    error  => {
      console.log(error);
    }
  )
}

reset()
{
  this.obtener_propiedades_admin();
}

order_by_status()
{
  if(this.sort_status == 'Todos')
  {
    this.obtener_propiedades_admin();
  }else{
    this._propiedadService.listar_propiedades_admin(this.filtro,this.token).subscribe(
      response  => {

        this.propiedades = response.data;

        this.propiedades = this.propiedades.filter(item => item.oferta == this.sort_status);

        this.load_data = false;
      },
      error  => {
        console.log(error);
      }
    )
  }
}

}
