<div>
   <!-- <div class="modal-header">
        
        <h4 class="modal-title"> Nueva cita </h4>
        
    </div>-->
    <div mat-dialog-content>

        <div class="modal-body">
            <div class="example-container">
                <div>
                    <mat-form-field appearance="legacy">
                        <mat-label>Título</mat-label>
                        <input matInput
                            [formControl]="titulo" 
                            required
                            id="titulo">
                            <mat-error *ngIf="titulo.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="legacy">
                    <mat-label>Inicio</mat-label>
                    <input matInput type="datetime-local" 
                            placeholder="Fecha / hora de inicio" 
                            [formControl]="inicio" 
                            required
                            id="inicio">
                    <mat-error *ngIf="inicio.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="legacy">
                    <mat-label>Fin</mat-label>
                    <input matInput  type="datetime-local" 
                            placeholder="Fecha / hora de fin" 
                            [formControl]="fin" 
                            required
                            id="fin">
                    <mat-error *ngIf="fin.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="legacy">
                        <mat-label>Prioridad</mat-label>
                        <mat-select 
                            [formControl]="prioridad" 
                            required
                            id="prioridad">
                          <mat-option default value="normal">Normal</mat-option>
                          <mat-option value="alta">Alta</mat-option>
                        </mat-select>
                        <mat-error *ngIf="prioridad.invalid">{{getErrorMessage()}}</mat-error>
                      </mat-form-field>
                </div>
                
              </div>
        </div>


    </div>
    <div mat-dialog-actions class="d-flex justify-content-center">
        <button mat-button class="btn-cancelar" (click)="cancelar()">Cancelar</button>
        <button mat-button [mat-dialog-close]="data" class="btn-enviar" cdkFocusInitial  (click)="agendar()">Agendar</button>
    </div>
</div>