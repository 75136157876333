import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blank-page',
  templateUrl: './blank-page.component.html',
  styleUrls: ['./blank-page.component.css']
})
export class BlankPageComponent implements OnInit {
  
  @Input() mensaje: string;
  constructor() { }

  ngOnInit(): void {
  }

}
