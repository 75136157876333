import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalServicesService {
  public displayInstallCard = null;

  public installPWA() {
    if (this.displayInstallCard) {
      this.displayInstallCard.prompt();
      this.displayInstallCard.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('El usuario ha aceptado la instalación');
        }
        this.displayInstallCard = null;
      });
    }
  }

  constructor() { }
}
