import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal } from '../../clases/data-modal';
import { FileItem } from 'src/app/models/file-item';
import { ModalDialogComponent } from '../../components/modal-dialog/modal-dialog.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-update-asesors',
  templateUrl: './update-asesors.component.html',
  styleUrls: ['./update-asesors.component.css']
})
export class UpdateAsesorsComponent implements OnInit {
  public token;
  public load_data = true;
  public asesor : any = {};
  public asesores : any = {};
  public id : any;
  public imagenes: Array<any> = []

  constructor(
    private _adminService : AdminService,
    private _clienteservice : ClienteService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _router : Router,
    private _subirArchivo: CargaImagenesService,
    private cdr: ChangeDetectorRef
  ) { 
    this.token = localStorage.getItem('token');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const idasesor = params.get('id');
      this.id = idasesor;
      this.obtenerasesor();
    });
    
  }


  obtenerasesor(){
    this._clienteservice.obtener_asesor(this.id).subscribe(
      response => {
        this.asesor = response.data;
        console.log(this.asesor)
        this.load_data = false;
      } 
    )
  }

  actualizar(actualizarForm){

    const asesorid = this.asesor._id

    this._clienteservice.actualizar_asesor(asesorid, this.asesor).subscribe(
      response => {
        Swal.fire({
          icon:'success',
          text:'Asesor actualizado correctamente'
        });
        this._router.navigate(['/panel/asesores'])
      },
      error => {
        Swal.fire({
          icon:'error',
          text:'No se pudo actualizar el asesor'
        });
      }
    );
  }

  actualizarasesor(){
    this._clienteservice.actualizar_asesor
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      try {
        // Creamos un FileItem con el archivo seleccionado
        const fileItem = new FileItem(file);

        this.load_data= true;
        // Llamamos al servicio para cargar la imagen
        this._subirArchivo.cargarImagenesAsesores([fileItem], "asesor", "asesores")
          .then((urls: string[]) => {
            // La URL de la imagen cargada estará en la primera posición del array
            const urlImagenCargada = urls[0];
            
            // Actualizamos el valor del campo foto con la URL de la imagen cargada
            this.asesor.foto = urlImagenCargada;

            this.cdr.detectChanges();

            this.load_data= false;
          })
          .catch(error => {
            console.error('Error al cargar la imagen', error);
          });
      } catch (error) {
        console.error('Error al cargar archivos', error);
      }
    }
  }

}
