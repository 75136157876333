import { Component, OnInit,ContentChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from "../../../services/admin.service";
import Swal from 'sweetalert2';

declare var iziToast: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    

'./login.component.css'
]
})


export class LoginComponent implements OnInit {

  @Output() cerrarModal = new EventEmitter<any>();
  public user: any={};
  public usuario :any={};
  public token :any = {};
  fieldTextType: boolean = false;

  constructor(
    private _adminService : AdminService,
    private _router : Router

  ) {

    this.token = this._adminService.getToken();
    
    
    
  }

  ngOnInit(): void {
    ;
    if(this.token)
    {
      this._router.navigate(['/panel']);
    }else{
      // Mantener componente
    }
  }

  login(loginForm: any) {
    if (loginForm.valid) {
      

      let data = {
        email: this.user.email,
        password: this.user.password
      }

      this._adminService.login_admin(data).subscribe(
        response => {
          if (response.data == undefined) {
            iziToast.show({
              title: 'Success',
              titleColor: 'FF0000',
              class: 'text-danger',
              color:'green',
              position: 'topRight',
              message:response.message
        });
          } else {
            this.usuario = response.data;

            localStorage.setItem('token', response.token);
            //localStorage.setItem('_id',response.data._id);
            
            //this._router.navigate(['/']);
            this.cerrarModal.emit(false);
           // location.href ="https://admin.vabait.co/#/inicio";

           if(this.usuario.rol == 'admin'){
            console.log(this.usuario.rol)
            this._router.navigate(['/panel/BI']);
           }else if(this.usuario.rol == 'cliente') {
            this._router.navigate(['/panel/pagos']);
           }else if(this.usuario.rol == 'propietario'){
            this._router.navigate(['/panel/requerimientos']);
           }else if(this.usuario.rol == 'asesor'){
            this._router.navigate(['/panel/propiedades']);
           }
           

        } },
        error => { Swal.fire({
          icon:'error',
          text:'El correo o la contraseña con la que estas intentando ingresar son incorrectos'
        })}
      );
    } else {
      Swal.fire({
        icon:'error',
        text:'Debe escribir el correo y la contraseña que te asigno tu asesor para ingresar al sistema'
      })
    }


  }


  showPassword()
  {
    this.fieldTextType = !this.fieldTextType
  }
 


}
