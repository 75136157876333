<ng-container *ngIf="cargando; else elseTemplate">
    <app-loading></app-loading>
</ng-container>
<ng-template #elseTemplate>
    <div class="card-header border-bottom  mt-5">
        <h1 class="mt-2 mt-md-4 mb-3 pt-3">Consultar Requerimientos</h1>
        <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
            <p class="text-muted">
                En este módulo podras visualizar los requerimientos que los arrendatarios han generado y la gestión realizada sobre los mismos.
            </p>
        </div>
    </div>
    <div class="card box-shadow-sm" style="max-width: 1200px; min-width: auto;">
        <div *ngIf="solicitudesMantenimiento.length > 0" class="card-body pt-0">
            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead class="table-dark">
                        <tr>
                            <th scope="col"># Solicitud</th>
                            <th scope="col">Asunto</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let solicitud of solicitudesMantenimiento">
                            <td> {{solicitud.id}}</td>
                            <td> {{solicitud.asunto}}</td>
                            <td> {{solicitud.estado}}</td>
                            <td>
                                <a [routerLink]="['/panel/detalle_requerimiento', solicitud.id]" class="descargar">
                                    Detalles
                                    <i class="fa fa-solid fa-eye"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>