import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { GLOBAL } from './GLOBAL';
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { FirebaseService } from './firebase.service';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public url;
  public rol;

  constructor(
    private _http : HttpClient,
    private _fb: FirebaseService
 
  ) 
  { 
    this.url = GLOBAL.url;
  }

  login_admin(data:any): Observable<any>
  {
    let headers = new HttpHeaders().set('Content-Type','application/json');

   return this._http.post(this.url + 'login_admin', data,{headers:headers});
  }

  getToken()
  {
    return localStorage.getItem('token');
  }

  //Servicios Admin panel

  public isAuthenticated(allowRoles: string[]): boolean {
    
    const token = localStorage.getItem('token');
    
   //this.obtener_admin(token).subscribe(response =>{ this. rol = response.data.rol})
  
    if (!token) {
      return false;
    }
    try {
      const helper = new JwtHelperService();
      var decodedToken = helper.decodeToken(token);

      if(helper.isTokenExpired(token))
      {
          localStorage.clear();
          return false;

      }

      if (!decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
        return false;
      }

    } catch (error) {
      localStorage.removeItem('token');
      return false;
    }
    
    //console.log(this.rol)
    return allowRoles.includes(decodedToken['rol']);
    
  }
  
  listar_admins(token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this._http.get(this.url + 'listar_admin/',{headers: headers});
  }


  eliminar_admin(id:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    


    return this._http.delete(this.url + 'eliminar_admin/' +id,{headers:headers});
  }

  eliminar_asesor(id:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    


    return this._http.delete(this.url + 'eliminar_asesor/' +id,{headers:headers});
  }

  obtener_admin(token:any): Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    //return this._http.get(this.url + 'obtener_admin/'+id);
    return this._http.get(this.url + 'obtener_admin',{headers:headers});
  }

  obtener_asesor(token:any): Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    //return this._http.get(this.url + 'obtener_admin/'+id);
    return this._http.get(this.url + 'obtener_asesores',{headers:headers});
  }

  registro_admin(data:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    


    return this._http.post(this.url + 'registro_admin/',data,{headers:headers});
  }

  actualizar_pass_user(data:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    


    return this._http.post(this.url + 'actualizar_pass_user/',data,{headers:headers});
  }

  actualizar_pass_admin(data:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    


    return this._http.post(this.url + 'actualizar_pass_admin/',data,{headers:headers});
  }

  registro_asesor(data:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    


    return this._http.post(this.url + 'crear_asesor/',data,{headers:headers});
  }




  consultar_facturas():Promise<any>
  {

    let pagosRef = this._fb.db.collection("facturas");
    let query = pagosRef.orderBy("fecha","desc")
    .get()
    return query
  }

  consultar_pagos(usuario: any, fecha:any):Promise<any>
  {
    let pagosRef = this._fb.db.collection("pagos");
    let query = pagosRef.orderBy("fecha","desc")
    .get()
    return query
  }

  listar_mensajes(token:any): Observable<any>
  {
   

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});

    return this._http.get(this.url + 'listar_mensajes/',{headers: headers});
  }

  cerrar_mensaje_admin(id:any,data:any,token:any): Observable<any>
  {

   let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});

    return this._http.put(this.url + 'cerrar_mensaje_admin/'+id,data,{headers: headers });
  }
  eliminar_mensaje_admin(id:any,token:any): Observable<any>
  {

   let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});

    return this._http.delete(this.url + 'eliminar_mensaje_admin/'+id,{headers: headers });
  }

  registrarVisita(postData){
    let visitasRef = this._fb.RTDB.ref('visitas/-NQ8LUvLMHQO43rylqxz');
    var nuevasVisitas = {};
    nuevasVisitas['visitas/-NQ8LUvLMHQO43rylqxz'] = postData;
    visitasRef.update(postData)

 }

  consultarVisitas() {

 let starCountRef =  this._fb.RTDB.ref('visitas/-NQ8LUvLMHQO43rylqxz');
 starCountRef.get().then((snapshot) => {
   const data = snapshot.val();
   const newData = [
    {
      name: "Visitas Mes Anterior",
      value: data[0].value
    },
    {
      name: "Vistas Mes Actual",
      value: data[1].value + 1
    }]
    this.registrarVisita(newData)

 });
}



}