<div class="card dialog" >
    <div class="card-header">
        <h3>Llévanos siempre contigo</h3>
    </div>
    <div class="card-body">
        <button 
            type="button" 
            class="btn azul" 
            style="color: #0e3144;"
            (click)="closeChild()"
        >
            Cerrar
        </button>
        <button 
            type="button" 
            class="btn float-end" 
            (click)="installApp()"
            style="background-color: #D2691E; color: white;"
            
        >
            Instala nuestra APP
        </button>
    </div>
  </div>