import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarDataModal } from '../../clases/data-modal';
import {FormControl, Validators} from '@angular/forms';
import { EventColor } from 'calendar-utils';
import { format } from 'date-fns'

@Component({
  selector: 'app-editar-cita-dialog',
  templateUrl: './editar-cita-dialog.component.html',
  styleUrls: ['./editar-cita-dialog.component.css']
})
export class EditarCitaDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditarCitaDialogComponent>,
    @ Inject(MAT_DIALOG_DATA) public data: CalendarDataModal
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    console.log(JSON.stringify(this.data))
  }

  colors: Record<string, EventColor> = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    }
  };
  

  event = {
    titulo : new FormControl(this.data.event.title, [Validators.required]),
    inicio : new FormControl(format(this.data.event.start, "yyyy-MM-dd'T'HH:mm"), [Validators.required]),
    fin : new FormControl(format(this.data.event.end, "yyyy-MM-dd'T'HH:mm") , [Validators.required]),
    prioridad : new FormControl(this.data.event.color.primary == "#ad2121" ? "alta" : "normal", [Validators.required]),
    id: this.data.event.id
  };

  getErrorMessage() {
    
    if (this.event.inicio.hasError('required')) {
      return 'Este campo es requerido';
    }

    return this.event.inicio.hasError('inicio') ? 'Ingresa una fecha de inicio correcta' : '';
  }

  cancelar() {
    this.dialogRef.close();
   }

}
