import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal360',
  templateUrl: './modal360.component.html',
  styleUrls: ['./modal360.component.css']
})
export class Modal360Component implements OnInit {
  public vista360;
  public isUndefined;
  constructor() { }

  ngOnInit(): void {
  }

}
