
<!-- Page title-->
<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Detalles de la solicitud de certificado</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted"></p>

    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <div class="card mb-3" style="width: 800px;" *ngIf="certificado">
                    <div class="row g-0">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="card-title text-center pt-3">Solicitud</h5>
                            <div class="card-body text-center">
                                <form #detallesForm="ngForm" >
                                    <div class="col-lg-12 form-group">
                                        <label for="Asunto">Asunto</label>
                                        <input id="Asunto" class="text-center form-control" readonly name="asunto" [(ngModel)]="certificado.asunto" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="estado">Estado</label>
                                        <input id="estado" class="text-center form-control" readonly name="estado" [(ngModel)]="certificado.estado" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="comentario">Comentario del asesor</label>
                                        <textarea class="form-control text-center" name="comentario" readonly name="comentario" rows="5" [(ngModel)]="certificado.comentario"></textarea>
                                    </div>
                                    <div class="col-lg-12 form-group">
                                        <label for="comentario">Certificado</label>

                                        <ngx-extended-pdf-viewer 
                                        [src]="certificado.url" 
                                        [handTool]="true" 
                                        [showHandToolButton]="true" 
                                        backgroundColor="#ffffff" 
                                        [height]="'100vh'" 
                                        [useBrowserLocale]="true" 
                                        [mobileFriendlyZoom]="'150%'"
                                        [zoom]="'page-width'"
                                        [showSidebarButton]="false" 
                                        [showFindButton]="false" 
                                        [showPagingButtons]="false"
                                        [showZoomButtons]="true" 
                                        [showPresentationModeButton]="true" 
                                        [showOpenFileButton]="false" 
                                        [showPrintButton]="false" 
                                        [showDownloadButton]="false" 
                                        [showBookmarkButton]="false" 
                                        [showSecondaryToolbarButton]="false" 
                                        [showRotateButton]="false" 
                                        [showHandToolButton]="false"
                                        [showScrollingButton]="false" 
                                        [showSpreadButton]="false" 
                                        [showPropertiesButton]="false" 
                                        [sidebarVisible]="false"
                                        [listenToURL]="false">
                                        </ngx-extended-pdf-viewer>
                                    </div>
                                </form>
                                <div class="card-footer">
                                    <button class="btn btn-cancelar" (click)="goBackOrToPanel()" >Regresar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>