<p>create-propiedad works!</p>

<!-- Page title-->
<div class="card-header border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Propiedades</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">


    </div>
</div>

<form #registroForm="ngForm" (ngSubmit)="registro(registroForm)">


    <div class="card box-shadow-sm">
        <div class="card-header">
            <h5 style="margin-bottom: 0px;">Registro de inmuebles</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <label for="">Titulo</label>
                            <input type="text" class="form-control" placeholder="Titulo" required name="titulo" [(ngModel)]="propiedad.titulo">
                        </div>
                        <h4>Seleccione ubicación del inmueble:</h4>
                        <label for="">Buscar por dirección</label>
                        <div class="col-lg-12 form-group">
                            <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Busca una locación" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                        </div>

                        <div class="col-lg-12 form-group">
                            <!--<agm-map [latitude]="latAca" [longitude]="lngAca" [zoom]="zoom" (mapClick)="addMarker($event)" [scrollwheel]="null" [mapTypeControl]='true'>
                            -->
                            <agm-map [latitude]="latAca" [longitude]="lngAca" [zoom]="zoom" (mapClick)="addMarker($event)" [scrollwheel]="null" [gestureHandling]="'cooperative'">  
                                <agm-map-type-control></agm-map-type-control>
                                <agm-marker [markerDraggable]="true" (dragEnd)="markerDragEnd($event)" [latitude]="latAca" [longitude]="lngAca"></agm-marker>

                            </agm-map>
                        </div>

                        <div class="col-lg-12 form-group">
                            <label for="">Ciudad</label>
                            <select class="form-control custom-select " id="select-input " required name="ciudad " [(ngModel)]="propiedad.ciudad ">
                                <option value="" selected disabled>Seleccionar</option>
                                        <option value="El Retiro" >El Retiro</option>
                                        <option value="El Santuario" >El Santuario</option>
                                        <option value="Guarne" >Guarne</option>
                                        <option value="La Ceja" >La Ceja</option>
                                        <option value="La Union" >La Unión</option>
                                        <option value="Rionegro" >Rionegro</option>
                                        <option value="Llanogrande" >Llanogrande</option>
                                        <option value="Marinilla" >Marinilla</option>
                                        <option value="Medellin" >Medellín</option>
                                        <option value="Itagui" >Itagüi</option>
                                        <option value="La Estrella" >La Estrella</option>
                                        <option value="Sopetran" >Sopetrán</option>
                                        <option value="San Vicente" >San Vicente</option>
                                        <option value="Sonson" >Sonsón</option>
                                        <option value="Carmen de Viboral" >El Carmen de V.</option>
                                        <option value="Barbosa" >Barbosa</option>
                                        <option value="Envigado" >Envigado</option>
                                        <option value="El Peñol" >El Peñol</option>
                                        <option value="Alejandria" >Alejandría</option>
                                        <option value="Sabaneta" >Sabaneta</option>
                                        <option value="Cartagena" >Cartagena</option>

                           </select>

                        </div>
                        <div class=" col-lg-4 form-group ">
                            <label for=" ">Código</label>
                            <input type="text " class="form-control " placeholder="Codigo " required name="codigo " [(ngModel)]="propiedad.codigo ">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Wasi</label>
                            <input type="text " class="form-control " placeholder="Codigo Wasi " required name="wasi " [(ngModel)]="propiedad.wasi ">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Area total</label>
                            <input type="number " class="form-control " placeholder="Area en m2 " required name="area " [(ngModel)]="propiedad.area ">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Area construida</label>
                            <input type="number " class="form-control " placeholder="Area en m2 " required name="area_construida " [(ngModel)]="propiedad.area_construida">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Año de construcción</label>
                            <input type="number " class="form-control " placeholder="Año " name="yearbuilt " [(ngModel)]="propiedad.yearbuilt ">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Precio</label>
                            <input type="number " class="form-control " placeholder="Precio " required name="precio " [(ngModel)]="propiedad.precio ">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Tipo de inmueble</label>
                            <select class="form-control custom-select " id="select-input " required name="tipo " [(ngModel)]="propiedad.tipo ">
                                        <option value="" selected disabled>Seleccionar</option>
                                        <option value="Apartamento " >Apartamento</option>
                                        <option value="Apartaestudio " >Apartaestudio</option>
                                        <option value="Bodega " >Bodega</option>
                                        <option value="Casa " >Casa</option>
                                        <option value="Casa campestre " >Casa campestre</option>
                                        <option value="Cabanas " >Cabañas</option>
                                        <option value="Casa-lote " >Casa-lote</option>
                                        <option value="Finca " >Finca</option>
                                        <option value="Local " >Local</option>
                                        <option value="Lote " >Lote</option>
                                        <option value="Oficina " >Oficina</option>
                                    </select>
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Oferta</label>
                            <select class="form-control custom-select " id="select-input " required name="oferta " [(ngModel)]="propiedad.oferta ">
                                        <option value="" selected disabled>Seleccionar</option>
                                        <option value="Venta" >Venta</option>
                                        <option value="Renta" >Arriendo</option>
                                        <option value="Proyecto" >Proyecto</option>
                                    </select>
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Habitaciones</label>
                            <input type="number " class="form-control " placeholder="Habitaciones " name="habitaciones " [(ngModel)]="propiedad.habitaciones ">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Baños</label>
                            <input type="number " class="form-control " placeholder="Baños " name="banos " [(ngModel)]="propiedad.banos ">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Parqueaderos</label>
                            <input type="number " class="form-control " placeholder="parqueaderos " name="parqueaderos " [(ngModel)]="propiedad.parqueaderos ">
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Asesor</label>
                            <select class="form-control custom-select " id="select-input " required name="asesor " [(ngModel)]="propiedad.asesor ">
                                        <option value="" disabled>Seleccionar</option>
                                        <option *ngFor="let asesor of asesores" [value]="asesor._id " > {{ asesor.nombre }} </option>
                                    </select>
                        </div>
                        <div class="col-lg-4 form-group ">
                            <label for=" ">Propietario</label>
                            <!--<select class="form-control custom-select " id="select-input " required name="propietario " [(ngModel)]="propiedad.propietario ">
                                        <option value="">Seleccionar</option>
                                        <option value="Venta " >Venta</option>
                                        <option value="Renta " >Renta</option>
                                        <option value="Proyecto " >Proyecto</option>
                                    </select>-->
                                    <ng-multiselect-dropdown
                                        name="propietario " 
                                        [placeholder]="'Seleccionar'"
                                        [settings]="dropdownSettings"
                                        [data]="propietarios"
                                        [(ngModel)]="propiedad.propietario"
                                        (onSelect)="onItemSelect($event)"

                                    >
                                    </ng-multiselect-dropdown>
                        </div>

                        <div class="col-lg-4 form-group ">
                            <label for=" ">Video</label>
                            <input type="text " class="form-control " placeholder="URL video " name="Recorrido360" [(ngModel)]="propiedad.video ">
                        </div>

                        <div class="col-lg-4 form-group ">
                            <label for=" ">Recorrido 360</label>
                            <input type="text " class="form-control " placeholder="URL Del Recorrido360" name="Recorrido360" [(ngModel)]="propiedad.url_360">
                        </div>



                        <div class="col-lg-12 form-group ">
                            <label for=" ">Descripción</label>
                            <textarea class="form-control " placeholder="Descripcion del inmueble " required rows="5 " name="descripcion " [(ngModel)]="propiedad.descripcion "></textarea>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4">

                    <h3>Seleccione archivos</h3>


                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <label for="">Portada</label>
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" id="file-input" (change)="fileChangeEvent($event)">
                                <label class="custom-file-label" id="input-portada" for="file-input" style="white-space:nowrap;overflow:hidden">Seleccionar imagen</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <img [src]="imgSelect" class="img-thumbnail" alt="Rounded image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer ">
            <button *ngIf="!load_btn " class="btn btn-enviar mr-3 " type="submit ">Crear</button>
            <button *ngIf="load_btn " type="button " class="btn btn-secondary " disabled>
                            <span class="spinner-grow spinner-grow-sm me-2 " role="status " aria-hidden="true "></span>
                            Creando...
                          </button>
            <button class="btn btn-cancelar " [routerLink]=" [ '/panel/propiedades'] ">Regresar</button>
        </div>
    </div>



</form>