import { Component, OnInit, ChangeDetectorRef, NgZone, ViewChild, ElementRef } from '@angular/core';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MapsAPILoader} from '@agm/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UsuarioService } from '../../../../services/usuario.service';
import { Propietario } from '../../../clases/propietario';

declare var $: any;
declare var iziToast: any;

@Component({
  selector: 'app-update-propiedad',
  templateUrl: './update-propiedad.component.html',
  styleUrls: ['./update-propiedad.component.css']
})
export class UpdatePropiedadComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings = {};
  public propiedad : any = {};
  public load_btn = false;
  public imgSelect: any | ArrayBuffer = '../../../../../assets/img/01.jpg';
  public token;
  public id : any;
  public url;
  public file: any
  public asesores : Array<any> = [];
  public propietarios : Array<any> = [];
  public propietarioSeleccionado : Array<any> = [];
  public desestPropietario;
  public zoom =11;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    private _propiedadService : PropiedadService,
    private _router: Router,
    public _route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _usuarioService: UsuarioService

  ) {
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;
   }

  ngOnInit(): void {


    this._route.params.subscribe(

      params  => {
        this.id = params['id'];
        this._propiedadService.obtener_propiedad_admin(this.id,this.token).subscribe(
          response  => {
            if(response.data == undefined)
            {
              this.propiedad = undefined;
            }else{
              this.propiedad = response.data;
              this.imgSelect = this.propiedad.portada;
              console.log(this.propiedad)
            }
          },
          error  => {
            console.log(error)
          }
        )
      }
    )

    this._propiedadService.consultar_asesores().subscribe(asesores =>{
      this.asesores = asesores.data;
      })
  
      this._usuarioService.listar_usuario(this.token).subscribe(propietarios =>{
        propietarios.data = propietarios.data.filter(propietario => propietario.rol == 'propietario')
        let usuario = new Propietario(propietarios.data)
        
        this.propietarios = usuario.propietarios;
        let temp = this.propietarios.filter(item => item.uid == this.propiedad.propietario)
        this.desestPropietario = {
          uid: temp[0].uid,
          nombreCompleto: temp[0].nombreCompleto
        }
        this.propietarioSeleccionado.push(this.desestPropietario)
        this.propiedad.propietario = this.propietarioSeleccionado
        console.log(this.propiedad.propietario)
      
        

        
      })
      

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.propiedad.lat = place.geometry.location.lat();
          this.propiedad.long= place.geometry.location.lng();
          this.zoom = 12;

        });
      });
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'uid',
      textField: 'nombreCompleto',
      //selectAllText: 'Select All',
     // unSelectAllText: 'UnSelect All',
     enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  recuperar_propietario(propietario: Propietario[]){

        this.propietarioSeleccionado.push(propietario)
        console.log(this.propietarioSeleccionado)
  }

  addMarker($event){

    this.propiedad.lat = $event.coords.lat;
    this.propiedad.long = $event.coords.lng;


    
  }

  markerDragEnd($event) {

    this.propiedad.lat = $event.coords.lat;
    this.propiedad.long = $event.coords.lng;
    
  }

  actualizar(actualizarForm)
  {
    if(actualizarForm.valid){
      let prop;
      if(this.propiedad.propietario != undefined){
        prop = this.propiedad.propietario[0].uid;
      }

      var data : any = {};
      if(this.file != undefined){
        data.portada = this.file;
      }

      data.titulo = this.propiedad.titulo;
      data.ciudad = this.propiedad.ciudad;
      data.codigo = this.propiedad.codigo;
      data.wasi = this.propiedad.wasi;
      data.area = this.propiedad.area;
      data.area_construida = this.propiedad.area_construida;
      data.yearbuilt = this.propiedad.yearbuilt;
      data.precio = this.propiedad.precio;
      data.tipo = this.propiedad.tipo;
      data.oferta = this.propiedad.oferta;
      data.habitaciones = this.propiedad.habitaciones;
      data.banos = this.propiedad.banos;
      data.parqueaderos = this.propiedad.parqueaderos;
      data.descripcion = this.propiedad.descripcion;
      data.lat = this.propiedad.lat;
      data.long = this.propiedad.long;
      data.asesor = this.propiedad.asesor;
      data.propietario = prop;
      data.video = this.propiedad.video;
      this.load_btn = true;

      this._propiedadService.actualizar_propiedad_admin(data,this.id,this.token).subscribe(
        response => {

          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color:'#FFF',
            position: 'topRight',
            message:'El inmueble se ha actualizado correctamente'
      });
            this.load_btn = false;

            this._router.navigate(['/panel/propiedades'])
        },
        error =>
        {
          console.log(error);
          this.load_btn = false;
        }
      )

    }else{
      iziToast.show({
        title: 'Error',
        titleColor: 'FF0000',
        class: 'text-danger',
        color:'red',
        position: 'topRight',
        message:'Los datos de formulario no son validos'
  });
  this.load_btn = false;
    }
  }

  fileChangeEvent(event:any):void
{
   var file : any;
   if(event.target.files  && event.target.files[0]){

    file = <File>event.target.files[0];


   } else{
    iziToast.show({
      title: 'Error',
      titleColor: 'FF0000',
      class: 'text-danger',
      color:'red',
      position: 'topRight',
      message:'No hay una imagen.'
  });

   }

   if(file.size  <= 8000000){

    if(file?.type== 'image/png' || file?.type == 'image/web' || file?.type == 'image/jpg' || file?.type == 'image/gif' || file?.type == 'image/jpeg')
    {
      const reader = new FileReader();
      reader.onload = e  => this.imgSelect = reader.result;

      reader.readAsDataURL(file); 
      this.file = file; 

    }else{
      iziToast.show({
        title: 'Error',
        titleColor: 'FF0000',
        class: 'text-danger',
        color:'red',
        position: 'topRight',
        message:'El archivo debe ser una imagen'
    });
    $('#input-portada').text('Seleccionar imagen');
    this.imgSelect = '../../../../../assets/img/01.jpg';
    this.file = undefined;

    }

   }else{
    iziToast.show({
      title: 'Error',
      titleColor: 'FF0000',
      class: 'text-danger',
      color:'red',
      position: 'topRight',
      message:'La imagen no puede superar los 8MB'
  });
  $('#input-portada').text('Seleccionar imagen');
  this.imgSelect = '../../../../../assets/img/01.jpg';
  this.file = undefined;

   }


}

}
