<!-- ======= Property Search Section ======= -->
<div class="click-closed"></div>
<!--/ Form Search Star /-->
<div class="box-collapse">
    <div class="title-box-d">
        <h3 class="title-d">Encontrar propiedad</h3>
    </div>
    <span class="close-box-collapse right-boxed bi bi-x close_search" (click)="cerrar()"></span>
    <div class="box-collapse-wrap form">
        <form class="form-a">
            <div class="row">
                <div class="col-md-12 mb-2">
                    <div class="form-group">
                        <!--<input type="text" class="form-control form-control-lg form-control-a" placeholder="C&oacute;digo" [(ngModel)]="params.codigo">-->
                        <br>
                        <input type="text" class="form-control form-control-lg form-control-a" placeholder="C&oacute;digo" (input)="getCodigo($event)">
                    </div>
                </div>


                <div class="col-md-6 mb-2">
                    <div class="form-group mt-3">
                        <select [disabled]="disabled" class=" form-control form-select form-control-a " [(ngModel)]="params.operacion" [ngModelOptions]="{standalone: true}">
                            <option value=""  [selected]="true" >Operación</option>
                            <option value="Venta" >Venta</option>
                            <option value="Renta" >Arriendo</option>
                            <option value="Proyecto" >Proyecto</option>
              </select>

                    </div>
                </div>
                <div class="col-md-6 mb-2 ">
                    <div class="form-group mt-3 ">
                        <select [disabled]="disabled" class="form-control form-select form-control-a " [(ngModel)]="params.ciudad" [ngModelOptions]="{standalone: true}">
                            <option value="" [selected]="true" >Ciudad</option>
                            <option *ngFor="let item of ciudadesconsulta" value="{{item.name}}">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6 mb-2 ">
                    <div class="form-group mt-3 ">
                        <select [disabled]="disabled" class="form-control form-select form-control-a " [(ngModel)]="params.tipo" [ngModelOptions]="{standalone: true}">
                           <option value="" [selected]="true " >Tipo</option>
                           <option *ngFor="let item of tipoconsulta" value="{{item.nombre}}" >{{item.nombre}}</option>
              </select>
                    </div>
                </div>



                <div class="col-md-6 mb-2 ">
                <div class="form-group mt-3 ">
                <select [disabled]="disabled" class="form-control form-select form-control-a " [(ngModel)]="params.precio" [ngModelOptions]="{standalone: true}">
                <option value="" [selected]="true " >Precio</option>
                <option value=500000>$500,000 - $1,000,000</option>
                <option value=1000000>$1,000,001 - $2,000,000</option>
                <option value=2000000>$2,000,001 - $10,000,000</option>
                <option value=10000000>$10,000,001 - $100,000,000</option>
                <option value=100000000>$100,000,001 - $1,000,000,000</option>
                <option value=1000000000>$1,000,000,001 - $10,000,000,000</option>
              </select>
                    </div>
                </div>
                <!--<div class="col-md-6 mb-2 ">
                    <div class="form-group mt-3 ">
                        <label class="pb-2 ">Baños</label>
                        <select [disabled]="disabled" class="form-control form-select form-control-a" [(ngModel)]="params.banos" [ngModelOptions]="{standalone: true}">
                            <option value="" [selected]="true " >Baños</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                    </div>
                </div>-->


                <div class="col-md-6 mb-2 ">
                    <div class="form-group mt-3 ">
                        <select [disabled]="disabled" class="form-control form-select form-control-a" [(ngModel)]="params.habitaciones" [ngModelOptions]="{standalone: true}">
                            <option value="" [selected]="true " >Habitaciones</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                    </div>
                </div>

                <div class="col-md-6 mb-2 ">
                    <div class="form-group mt-3 ">
                    </div>
                </div>

                <div class="col-md-12 mb-2 ">
                    <div class="form-group mt-3 btn_filter">
                    <button type="submit " class="btn btn-b " (click)="enviar() ">Buscar</button>
               </div>

               <div class="form-group mt-3 btn_filter">
                <button type="Reset" class="btn btn-b btn-limpiar" value="Borrar filtros">Limpiar</button>
                 </div>
               </div>

                <!--<div class="col-md-6 mb-2 ">
                    <div class="form-group mt-3 ">
                        <button type="Reset" class="btn btn-b" value="Borrar filtros">Limpiar</button>
                    </div>
                </div>-->
            </div>
            
        </form>
    </div>
</div>
<!-- End Property Search Section -->

<!-- ======= Header/Navbar ======= -->
<nav class="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
    <div class="container">
        <button id="navButton" class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span></span>
        <span></span>
        <span></span>
      </button>
        <a class="navbar-brand text-brand" href="index.html"><img src="assets/images/logo-valorinmobiliaria.png" alt="logo Valor Inmobiliaria" class="logo-vabait"></a>

        <div class="navbar-collapse collapse justify-content-center" id="navbarDefault">
            <ul class="navbar-nav">

                <li class="nav-item">
                    <a class="nav-link " [routerLink]="['/app/inicio']" routerLinkActive="active" (click)="hiddeNav()">INICIO</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link " [routerLink]="['/app/inmuebles']" routerLinkActive="active" (click)="hiddeNav()">INMUEBLES</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link " [routerLink]="['/app/colaboradores']" (click)="hiddeNav()">COLABORADORES</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link " [routerLink]="['/app/contacto']" (click)="hiddeNav()">CONTACTO</a>
                </li>
                <li class="nav-item" >
                    <!--<a class="nav-link"  (click)="modal()">INGRESAR</a>-->
                    <a class="nav-link"  [routerLink]="['/login']" (click)="hiddeNav()">INGRESAR</a>
                </li>                
            </ul>
        </div>

        <button type="button" (click)="abrir()" class="btn btn-b-n navbar-toggle-box navbar-toggle-box-collapse" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01">
            <i class="bi bi-search"></i>
        </button>

    </div>
    <!--<ng-container ></ng-container><section [ngClass]="{'modal': !showModal, 'modal-show': showModal}" id="target">
        <div class="modal_container">
            <a class="modal_close close_login" (click)="hideModal()">X</a>

        -->
            <!--<app-login  (cerrarModal) = "cerrarModal($event)"></app-login>-->


           
       <!--</div>

    </section>--> 
</nav>
<!-- End Header/Navbar -->