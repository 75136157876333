
<div class="card-header card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Notificaciones</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este modulo solo estara habilitado durante el tiempo de desarrollo para notificar las nuevas funcionalidades que se vayan publicando</p>

    </div>
</div>

<div class="card box-shadow-lg" style="width:auto;">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;"></h5>
    </div>


    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                    <tr>
                        <th>Fecha</th>
                        <th>Actualizacion Realizada</th>
                    </tr>
                </thead>

                <tbody *ngIf="load_data">
                    <tr>
                        <td class="text-center" colspan="7">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <ng-container *ngIf="!load_data">
                    <tbody *ngFor=" let item of notificacion">
                        <tr >

                            <td class="text-center"> {{item.fecha}} </td>
                            <td> {{item.actualizacion}} </td>
                        </tr>
                    </tbody>
                </ng-container>


            </table>
        </div>
    </div>
</div>