import { Component, OnInit } from '@angular/core';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { ActivatedRoute, Router } from '@angular/router';
import { PropiedadService } from 'src/app/services/propiedad.service';

declare var iziToast:any
@Component({
  selector: 'app-detalle-consulta',
  templateUrl: './detalle-consulta.component.html',
  styleUrls: ['./detalle-consulta.component.css']
})
export class DetalleConsultaComponent implements OnInit {

  public consulta : any = {
  
  };
  public token;
  public id : any;
  public file : any;
  public imgSelect: any | ArrayBuffer = 'assets/img/01.jpg';
  public load_btn = false;
  public url;


  constructor(
    private _propiedadService : PropiedadService,
    private _router: Router,
    public _route: ActivatedRoute
  ) {
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;
   }

  ngOnInit(): void {
    this._route.params.subscribe(
      params => {
        this.id = params['id'];
        this._propiedadService.obtener_consulta_admin(this.id,this.token).subscribe(
          response =>{
            if(response.data == undefined)
            {
              this.consulta = undefined;
            }else{
              this.consulta = response.data;
            }
          },
          error => {
            console.log(error);
          }
        )
      }
    )
  }


  actualizar(detallesForm:any)
  {
    this.load_btn = true;
    this._propiedadService.actualizar_estatus_consulta(this.id,this.consulta,this.token).subscribe(
      response  =>
      {
        iziToast.show({
          title: 'Success',
          titleColor: '#1DC74C',
          class: 'text-success',
          color:'#FFF',
          position: 'topRight',
          message:'Estatus actualizado'
    });
        this.load_btn = false;
        this._router.navigate(['/panel/consultas'])
      },
      error  =>{
        console.log(error);
      }
    )
  }

}
