import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { GLOBAL } from './GLOBAL';
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { FirebaseService } from './firebase.service';
import { FileItem } from '../models/file-item';
import { resolve } from 'dns';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public url;
  public rol;

  constructor(
    private _http : HttpClient,
    private _fb: FirebaseService
  ) 
  { 
    this.url = GLOBAL.url;
  }


  listar_usuario(token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    return this._http.get(this.url + 'listar_usuario/',{headers: headers});
  }

  registro_usuario(data:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    


    return this._http.post(this.url + 'registro_usuario/',data,{headers:headers});
  }

  actualizar_usuario(data:any,id:any,token:any): Observable<any>{

      let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
      return this._http.post(this.url + 'actualizar_usuario/'+ id,data, { headers: headers });

   }

   listar_usuarios_filtro(filtro:any,token:any): Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.get(this.url + 'listar_usuario_filtro/'+filtro,{ headers: headers });
   }

  eliminar_usuario(id:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    


    return this._http.delete(this.url + 'eliminar_usuario/' +id,{headers:headers});
  }

  eliminar_contrato_cliente(email: string):Promise<any>
  {
    let pagosRef = this._fb.db.collection("Contratos");
    let query = pagosRef.where("cliente", "==", email);

    return query.get().then(snapshot => {
      if (!snapshot.empty) {
        const batch = this._fb.db.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
        return batch.commit().then(() => {
          console.log('Documentos eliminados exitosamente');
          return this.eliminarCarpetaStorage(email);
        });
      } else {
        console.log('No se encontró ningún documento con el email proporcionado');
        return this.eliminarCarpetaStorage(email); // Intentar eliminar la carpeta aunque no haya documentos
        //return Promise.resolve(); // Resuelve la promesa aunque no se encuentren documentos
      }
    }).catch(error => {
      console.error("Error al eliminar documentos: ", error);
      return Promise.reject(error);
    });
  }

  private eliminarCarpetaStorage(email: string): Promise<void> {
    const storageRef = this._fb.storage.ref(`Contratos/${email}`);

    return storageRef.listAll().then(result => {
      const promises = result.items.map(itemRef => itemRef.delete());

      return Promise.all(promises).then(() => {
        console.log(`Todos los archivos han sido eliminados`);
      });
    }).catch(error => {
      console.error("Error al eliminar la carpeta en Storage: ", error);
    });
  }

  eliminar_contrato_propietario(email: string):Promise<any>
  {
    let pagosRef = this._fb.db.collection("Contratos-propietarios");
    let query = pagosRef.where("cliente", "==", email);

    return query.get().then(snapshot => {
      if (!snapshot.empty) {
        const batch = this._fb.db.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
        return batch.commit().then(() => {
          console.log('Documentos eliminados exitosamente');
          return this.eliminarCarpetaStoragePropietario(email);
        });
      } else {
        console.log('No se encontró ningún documento con el email proporcionado');
        return this.eliminarCarpetaStoragePropietario(email); // Intentar eliminar la carpeta aunque no haya documentos
        //return Promise.resolve(); // Resuelve la promesa aunque no se encuentren documentos
      }
    }).catch(error => {
      console.error("Error al eliminar documentos: ", error);
      return Promise.reject(error);
    });
  }

  private eliminarCarpetaStoragePropietario(email: string): Promise<void> {
    const storageRef = this._fb.storage.ref(`Contratos-propietarios/${email}`);

    return storageRef.listAll().then(result => {
      const promises = result.items.map(itemRef => itemRef.delete());

      return Promise.all(promises).then(() => {
        console.log(`Todos los archivos han sido eliminados`);
      });
    }).catch(error => {
      console.error("Error al eliminar la carpeta en Storage: ", error);
    });
  }

  eliminar_inventario_cliente(email: string):Promise<any>
  {
    let pagosRef = this._fb.db.collection("inventario");
    let query = pagosRef.where("cliente", "==", email);

    return query.get().then(snapshot => {
      if (!snapshot.empty) {
        const batch = this._fb.db.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
        return batch.commit().then(() => {
          console.log('Documentos eliminados exitosamente');
          return this.eliminarCarpetaStorageinventariocliente(email);
        });
      } else {
        console.log('No se encontró ningún documento con el email proporcionado');
        return this.eliminarCarpetaStorageinventariocliente(email); // Intentar eliminar la carpeta aunque no haya documentos
        //return Promise.resolve(); // Resuelve la promesa aunque no se encuentren documentos
      }
    }).catch(error => {
      console.error("Error al eliminar documentos: ", error);
      return Promise.reject(error);
    });
  }

  private eliminarCarpetaStorageinventariocliente(email: string): Promise<void> {
    const storageRef = this._fb.storage.ref(`inventario/${email}`);

    return storageRef.listAll().then(result => {
      const promises = result.items.map(itemRef => itemRef.delete());

      return Promise.all(promises).then(() => {
        console.log(`Todos los archivos han sido eliminados`);
      });
    }).catch(error => {
      console.error("Error al eliminar la carpeta en Storage: ", error);
    });
  }

  eliminar_imagenes_inventario_cliente(email: string):Promise<any>
  {
    let pagosRef = this._fb.db.collection("imagenes");
    let query = pagosRef.where("cliente", "==", email);

    return query.get().then(snapshot => {
      if (!snapshot.empty) {
        const batch = this._fb.db.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
        return batch.commit().then(() => {
          console.log('Documentos eliminados exitosamente');
          return this.eliminarCarpetaStorageimagenesinventariocliente(email);
        });
      } else {
        console.log('No se encontró ningún documento con el email proporcionado');
        return this.eliminarCarpetaStorageimagenesinventariocliente(email); // Intentar eliminar la carpeta aunque no haya documentos
        //return Promise.resolve(); // Resuelve la promesa aunque no se encuentren documentos
      }
    }).catch(error => {
      console.error("Error al eliminar documentos: ", error);
      return Promise.reject(error);
    });
  }

  private eliminarCarpetaStorageimagenesinventariocliente(email: string): Promise<void> {
    const storageRef = this._fb.storage.ref(`imagenes/${email}`);

    return storageRef.listAll().then(result => {
      const promises = result.items.map(itemRef => itemRef.delete());

      return Promise.all(promises).then(() => {
        console.log(`Todos los archivos han sido eliminados`);
      });
    }).catch(error => {
      console.error("Error al eliminar la carpeta en Storage: ", error);
    });
  }

  eliminar_mantenimientos_cliente(email: string):Promise<any>
  {
    let pagosRef = this._fb.db.collection("mantenimiento");
    let query = pagosRef.where("cliente", "==", email);

    return query.get().then(snapshot => {
      if (!snapshot.empty) {
        const batch = this._fb.db.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
        return batch.commit().then(() => {
          console.log('Documentos eliminados exitosamente');
          return this.eliminarCarpetaStoragemantenimientocliente(email);
        });
      } else {
        console.log('No se encontró ningún documento con el email proporcionado');
        return this.eliminarCarpetaStoragemantenimientocliente(email); // Intentar eliminar la carpeta aunque no haya documentos
        //return Promise.resolve(); // Resuelve la promesa aunque no se encuentren documentos
      }
    }).catch(error => {
      console.error("Error al eliminar documentos: ", error);
      return Promise.reject(error);
    });
  }

  private eliminarCarpetaStoragemantenimientocliente(email: string): Promise<void> {
    const storageRef = this._fb.storage.ref(`mantenimiento/${email}`);

    return storageRef.listAll().then(result => {
      const promises = result.items.map(itemRef => itemRef.delete());

      return Promise.all(promises).then(() => {
        console.log(`Todos los archivos han sido eliminados`);
      });
    }).catch(error => {
      console.error("Error al eliminar la carpeta en Storage: ", error);
    });
  }

  eliminar_evidencias_mantenimientos_cliente(email: string):Promise<any>
  {
    let pagosRef = this._fb.db.collection("evidencias-mantenimiento");
    let query = pagosRef.where("cliente", "==", email);

    return query.get().then(snapshot => {
      if (!snapshot.empty) {
        const batch = this._fb.db.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.ref);
        });
        return batch.commit().then(() => {
          console.log('Documentos eliminados exitosamente');
          return this.eliminarCarpetaStorageevidencias_mantenimientocliente(email);
        });
      } else {
        console.log('No se encontró ningún documento con el email proporcionado');
        return this.eliminarCarpetaStorageevidencias_mantenimientocliente(email); // Intentar eliminar la carpeta aunque no haya documentos
        //return Promise.resolve(); // Resuelve la promesa aunque no se encuentren documentos
      }
    }).catch(error => {
      console.error("Error al eliminar documentos: ", error);
      return Promise.reject(error);
    });
  }

  private eliminarCarpetaStorageevidencias_mantenimientocliente(email: string): Promise<void> {
    const storageRef = this._fb.storage.ref(`evidencias-mantenimiento/${email}`);

    return storageRef.listAll().then(result => {
      const promises = result.items.map(itemRef => itemRef.delete());

      return Promise.all(promises).then(() => {
        console.log(`Todos los archivos han sido eliminados`);
      });
    }).catch(error => {
      console.error("Error al eliminar la carpeta en Storage: ", error);
    });
  }

  

  resetear_clave(id:any,token:any):Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
    let options = { headers: headers };
    return this._http.post(this.url + 'resetear_clave_usuario/'+id,{},options);
  }

  obtener_usuario(token:any): Observable<any>
  {
    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});

    return this._http.get(this.url + 'obtener_usuario',{headers:headers});
  }

  obtener_usuario_id(id:any,token:any): Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization':token});
 
     return this._http.get(this.url + 'obtener_usuario_id/'+id, { headers: headers });
   }

}