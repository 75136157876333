<div *ngIf="isLoading"  id="preloader"></div>
<!-- ======= Latest Properties Section ======= -->
<section class="section-property section-t8">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-wrap  d-flex  justify-content-between flex-wrap">
                    <div class="title-box">
                        <h2 class="title-a">Propiedades recientes</h2>
                    </div>
                    <div class="title-link ">
                        <a [routerLink]="['/app/inmuebles']">Ver todas
                <span class="bi bi-chevron-right"></span>
              </a>
                    </div>
                </div>
            </div>
        </div>

        <swiper [config]="config" id="property-carousel" >
            <div class="swiper-wrapper">
                <!--Listener-->
               <!-- <div class="carousel-item-b swiper-slide ">
                    <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img [src]="staticPropery[0].portada" width="100%" height="300" alt="" class="bd-placeholder-img card-img-top" style="object-fit: cover;">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h4 class="card-title-a">
                                        <a [routerLink]="['/detalle/',staticPropery[0].codigo]">{{staticPropery[0].titulo}}</a>
                                    </h4>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">{{staticPropery[0].precio|currency}}</span>
                                    </div>
                                    <a [routerLink]="['/detalle/',staticPropery[0].codigo]" class="link-a">Ver propiedad
                      <span class="bi bi-chevron-right"></span>
                        </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li class="text-center">
                                            <h4 class="card-info-title">Area</h4>
                                            <span class="justify-content-center">{{staticPropery[0].area}}m
                          <sup>2</sup>
                        </span>
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title ">Habitaciones</h4>
                                            <span class="text-center">{{staticPropery[0].habitaciones}}</span>
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title">Baños</h4>
                                            <span>{{staticPropery[0].banos}}</span>
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title">Parqueaderos</h4>
                                            <span class="text-center">{{staticPropery[0].parqueaderos}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>--><!--swiper slide-->
                <!-- End carousel item -->
                <div class="carousel-item-b swiper-slide" *ngFor="let item of propiedades | slice:(page-1) * pageSize: (page-1) * pageSize + pageSize; ">
                    <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img [src]="item.portada" width="100%" height="300" alt="" class="bd-placeholder-img card-img-top" style="object-fit: cover;">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h4 class="card-title-a">
                                        <a [routerLink]="['/app/detalle/',item.codigo]">{{item.titulo}}</a>
                                    </h4>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">{{item.precio|currency}}</span>
                                    </div>
                                    <a [routerLink]="['/app/detalle/',item.codigo]" class="link-a">Ver propiedad
                      <span class="bi bi-chevron-right"></span>
                        </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li class="text-center">
                                            <h4 class="card-info-title"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-aspect-ratio-fill" viewBox="0 0 16 16">
                                                <path d="M0 12.5v-9A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5zM2.5 4a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 1 0V5h2.5a.5.5 0 0 0 0-1h-3zm11 8a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-1 0V11h-2.5a.5.5 0 0 0 0 1h3z"/>
                                              </svg>

                                              <span *ngIf="item.area > 0; then area   else areaConstruida" class="justify-content-center"></span>
                                                <ng-template #area > {{ item.area}}m <sup>2</sup> </ng-template>
                                                <ng-template #areaConstruida> {{ item.area_construida}}m <sup>2</sup> </ng-template>
                                                
                                            </h4>
                                            
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title "><i class="fa fa-bed"> </i>
                                                <span class="text-center"> {{ item.habitaciones }} </span>
                                            </h4>
                                            
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title"><i class="fa fa-bath"> </i>
                                                <span> {{ item.banos }}</span>
                                            </h4>
                                            
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title"> <i class="fa fa-car"></i>
                                              <span class="text-center"> {{ item.parqueaderos }} </span>
                                            </h4>
                                            
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--swiper slide-->
                <!-- End carousel item -->


            </div> <!--swiper-wrapper-->

        </swiper><!--Swiper main container-->
        <!--<div class="swiper-scrollbar"></div>-->
        <div class="propery-carousel-pagination carousel-pagination"></div>


    </div>
</section>
<!-- End Latest Properties Section -->