<section class="section-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <div class="widget-a">
                    <div class="text-center">
                        <h3 class="w-title-a text-brand">Llámanos</h3>

                    </div>

                    <div class="text-center">
                        <a href="tel:3218465585"><h1 class=""><i class="fa fa-phone fa-2x" aria-hidden="true"></i></h1></a>
                    </div>
                    <div class="text-center">
                        <ul class="list-unstyled">
                            <li class="color-a">
                                321 846 5585</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-3">
                <div class="widget-a">
                    <div class="text-center">
                        <h3 class="w-title-a text-brand">Escríbenos</h3>

                    </div>

                    <a href="https://wa.me/573218465585">
                        <div class="text-center">
                        <h1 class=""><i class="fa fa-whatsapp fa-2x" aria-hidden="true"></i></h1>
                        </div>
                    </a>
                    <div class="text-center">
                        <ul class="list-unstyled">
                            <li class="color-a">
                                321 846 5585</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-3 section-md-t3">
                <div class="widget-a">
                    <div class="text-center">
                        <h3 class="w-title-a text-brand">Ubicación</h3>
                        <h1><i class="fa fa-map-marker fa-2x" aria-hidden="true"></i></h1>
                    </div>
                    <ul class="list-unstyled">
                        <li class="color-a text-center">
                            La Ceja</li>
                        <li class="color-a text-center">
                            Cll. 12 # 17A - 34 </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 section-md-t3">
                <div class="widget-a">
                    <div class="text-center">
                        <h3 class="w-title-a text-brand">Correo</h3>
                        <a href="mailto:contacto@vabait.co"><i class="color-a fa fa-envelope fa-5x" aria-hidden="true"></i></a>
                    </div>
                    <div class="text-center">


                        <ul class="list-unstyled">
                            <li class="color-a">
                                contacto@vabait.co</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="copyright-footer">
                    <p class="copyright color-text-a">
                        &copy; Copyright
                        <span class="color-a">Grupo Vabait</span> Todos los derechos reservados.
                    </p>
                </div>
                <div class="credits">

                    Desarrollado por: <a href="http://tecnocompany.co/">Tecno Company</a>
                </div>
            </div>
        </div>
    </div>
</footer>