import { Component, OnInit, ViewEncapsulation,AfterContentInit,  NgZone } from '@angular/core';
//import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';

import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { FileItem } from 'src/app/models/file-item';



@Component({
  selector: 'app-consultar-inventario',
  templateUrl: './consultar-inventario.component.html',
  styleUrls: ['./consultar-inventario.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConsultarInventarioComponent implements OnInit {

  public token;
  public usuario:any = {}
  public imagenes: Array<any> = []
  public dataSource = []
  public mobileFriendlyZoomSetting = '150%';
  public decodedToken;
  public pdfUrl;
  public inventarios = [];
  public inventarioActivo;
  public opcionSeleccionada;
  public esactivo;
  public cargando;
  public mostrarInventario = true;
  public asunto;
  public detalle;
  public archivos: FileItem[] = [];
  estaSobreElemento = false;

  constructor(
    private _usuarioService: UsuarioService,
    private _imagenesService: CargaImagenesService,
    private _ClienteService: ClienteService,
    private ngZone: NgZone) {
      
    this.token = localStorage.getItem('token');
    try {
      const helper = new JwtHelperService();
      this.decodedToken = helper.decodeToken(this.token);

      if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
      }
      if (!this.decodedToken) {
        console.log('no acceso')
        localStorage.removeItem('token');
        
      }
    } catch (error) {
      localStorage.removeItem('token');
    }
   }

  ngOnInit(): void {
    this.obtener_inventario();
    this.obtener_usuario();
    this.ngZone.runOutsideAngular(() => {
      const lightbox = new PhotoSwipeLightbox({
         gallery: '#gallery--getting-started',
         children: 'a',
         pswpModule: PhotoSwipe,
         //pswModule: () => import('photoswipe/dist/photoswipe.esm.js'),
         spacing: 0.5,
         loop: true,
       });
       
       lightbox.init();
       
     });
  }

  settings = {
    counter: false,
    plugins: [lgZoom]
  };

  limpiarArchivos(){
    this.archivos = [];
  }

  verInventario(){
    this.mostrarInventario = true;
  }

  verSolicitudRequerimiento(){
    this.mostrarInventario = false;
  }

  selectChange(){
    this.pdfUrl = this.opcionSeleccionada;  
    const inventario = this.inventarios.find(x => x.url == this.opcionSeleccionada);
    this.inventarioActivo = inventario;
  }
  
  obtener_inventario(){
    this._imagenesService.obtener_inventario(this.decodedToken.email)
    .then((inventarios) => {
      let index = 0;
      inventarios.forEach((x) => {
        let inventario = {
          ...x.data(),
          nombre: 'Inventario ' + (index + 1)
        }
        this.inventarios.push(inventario)
        if(index == 0){
          this.pdfUrl = x.data().url;
          this.opcionSeleccionada = x.data().url;
          this.inventarioActivo = inventario;
        }
        this.esactivo = true;
        index ++;
      })
      this.cargando = false;
    })
  }

obtener_tamano_imagenes(){
  for(let imagen of this.imagenes){
     let foto = new Image(200,300);
    
    //document.images[0].src=foto.src;
    foto.src = imagen.url;
    let ancho = foto.naturalWidth;
    let alto = foto.naturalHeight;
    //let ancho = document.images[0].naturalWidth;
    //let alto =  document.images[0].naturalHeight;
    imagen.ancho = ancho;
    imagen.alto = alto;
    let image = {
      src: imagen.url,
      width: ancho,
      height: alto,
      alt: imagen.nombre
    }
    this.dataSource.push(image)
    //console.log(this.dataSource)
  }
  //Se inicializa el lightgallery una vez el arreglo de imagenes esté completo

}

 /* onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };*/

  obtener_usuario()
  {
    this._usuarioService.obtener_usuario(this.token).subscribe(
      response => 
      {
        this.usuario = response.data;
        //console.log(this.usuario)
        this.obtener_imagenes();

      }
    )
  }
  obtener_imagenes(){
    this._imagenesService.obtener_imagenes(this.usuario.email)
    .then((querySnapshot) => {
      
      querySnapshot.forEach((doc) => {
          
          
          this.imagenes.push(doc.data());
          //console.log("imagenes");
          //console.log(this.imagenes);
          
          
      });
      //this.obtener_tamano_imagenes();
      //this.cargar_inventario = false;
  })
  .catch((error) => {
      console.log("Error getting documents: ", error);
  });
  }
/*
  consultarInventario(){
    this._ClienteService.obtener_inventario(this.decodedToken.email).then((showinventario) => {
      showinventario.forEach( async(documento) => {
        this.inventario = await documento.data()
        this.pdfUrl = this.inventario.url;
        this.esactivo= true;
        //console.log(documento.data)
        console.log(this.pdfUrl)
        document.getElementById("pdf").setAttribute("src",this.pdfUrl)
      })
    })
  }*/
}
