import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { ActivatedRoute } from '@angular/router';
import { trigger, style, state, transition, animate, keyframes } from '@angular/animations';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {

  public showModal = false;
  public codigo = '';
  public ciudadesconsulta: Array<any> =[];
  public tipoconsulta:  Array<any> =[];
 
  disabled = false;
  public params:any = {
  operacion: '',
  ciudad:'',
  tipo:'',
  precio:'',
  banos:'',
  habitaciones:''
};

  public id = 814;
  public deferredPrompt;
  public showDownLoadButton = false;

  constructor(private _navbarService : ClienteService,
              private router: Router,         
    ) {}


    enviar(){
      if(this.codigo == ''){
      this.router.navigate(['/app/inmuebles'], {queryParams: this.params});
     
    }
    else{
      this.router.navigate(['/app/detalle', this.codigo]);
    }
      this.cerrar();

 
    }
    getCodigo(event: Event){
      this.codigo = (<HTMLInputElement>event.target).value;
      console.log(this.codigo);
      if(this.codigo != ''){
        this.disabled = true;
        console.log('Validando');
      }
      else{
        this.disabled = false;
      }
    }

  abrir() {
    console.log("abriendo");
    document.body.classList.add('box-collapse-open');
    document.body.classList.remove('box-collapse-closed');
  }

  cerrar(): void {
    console.log("cerrando");
    document.body.classList.add('box-collapse-closed');
    document.body.classList.remove('box-collapse-open');
  }
  
  ngOnInit(): void {
    this.obtener_ciudades();
    this.obtener_tipo();
  }

  navegarAdmin(){
    this.router.navigateByUrl('http://admin.tecnocompany.co/#/inicio/')
  }

  modal(){
    this.showModal = !this.showModal;
  

  }
  hideModal(){
    this.showModal = false;
  }
  
  cerrarModal(event){
    this.showModal = event;
  }

  hiddeNav(){

    document.getElementById("navbarDefault").setAttribute("class","navbar-collapse justify-content-center hidde collapsing");
    
    document.getElementById("navButton").setAttribute("class","navbar-toggler collapsed");
    document.getElementById("navButton").setAttribute("aria-expanded","false");
    document.getElementById("navbarDefault").setAttribute("class","navbar-collapse justify-content-center hidde collapse");

  }

  obtener_ciudades(){
    this._navbarService.obtener_ciudades().subscribe(
      response =>{
        this.ciudadesconsulta = response.data;
        console.log(this.ciudadesconsulta);
      }
    )
  }

  obtener_tipo(){
    this._navbarService.obtener_tipo().subscribe(
      response =>{
        this.tipoconsulta = response.data;
        console.log(this.ciudadesconsulta);
      }
    )
  }
}