import { Component, Inject, OnInit,PLATFORM_ID,NgModule, HostListener, NgZone, ViewEncapsulation } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { GLOBAL, GLOBALSOCKET } from 'src/app/services/GLOBAL';
import Swal from 'sweetalert2';
import { io } from 'socket.io-client';
import { Meta } from '@angular/platform-browser';
import { SwiperOptions } from 'swiper';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Console } from 'console';

/* Light gallery */

import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe';

declare var getExists: any;

declare var $ :any;
@Component({
  selector: 'app-detalle-inmueble',
  templateUrl: './detalle-inmueble.component.html',
  styleUrls: ['./detalle-inmueble.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DetalleInmuebleComponent implements OnInit {


  config: SwiperOptions = {
    pagination: { 
      el: '.swiper-pagination', 
      clickable: true
    },
    scrollbar: { draggable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 1,
    loop:true
  
  };
  
  public slug;
  public codigo;
  public propiedad : any = {}
  public asesor : any = {}
  public url;
  public lat;
  public lng;
  public currentPage;
  public whatsappurl;
  public load_data = true;
  public mostrarGaleria = true;
  public mostrarMapa = true;
  public mostrarVideo = false;
  public controlGaleria = true;
  public controlMapa = false;
  public controlVideo = false;
  public vista360 ;
  public video;
  public isUndefined = false;
  public url360;
  public showModal = false;
  
  


  public consulta :any = {
    texto: 'Me interesa esta propiedad y quiero recibir información.'
  };

  public socket = io(GLOBALSOCKET.url);

  constructor(
    private _clienteService : ClienteService,
    private _route : ActivatedRoute,
    private _router: Router,
    private metaTagService: Meta,
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone

  ) {
    
    this.url = GLOBAL.url;




   }
   getFolder(subFolder: string): Observable<any> {
    const folderPath = `./assets/Propiedades360/${this.codigo}/app-files`;
    return this.httpClient
      .get(`${folderPath}/index.html`, { observe: 'response', responseType: 'blob' })
      .pipe(
        map(response => {
          return folderPath;
         
        }),
        catchError(error => {
          //return of('assets/folder/default');
          return of(false)
        })
      );
  }
  /*fileExists(url: string): Observable<boolean> {
    return this.httpClient.get(url)
        .pipe(
            map(response => {
                return true;
            }),
            catchError(error => {
                return of(false);
            })
        );}*/
        
  ngOnInit(): void {



    this._route.params.subscribe(
      params  => {
        this.codigo = params['codigo'];
       this.buscar_Inmueble();
       this.metaTagService.updateTag({name:'og:image',content:this.propiedad.portada})
        this.metaTagService.updateTag({property:'og:url',content:this.currentPage})
        this.metaTagService.updateTag({property:'og:image:secure_url',content:this.propiedad.portada})
        this.metaTagService.updateTag({property:'og:title',content:this.propiedad.tipo + ' ' + this.propiedad.direccion})
        this.metaTagService.updateTag({property:'og:description',content:this.propiedad.descripcion})
      }

    )

      this.currentPage = window.location.href
      this.whatsappurl = encodeURI('Me interesa esta propiedad y quiero recibir información' +' ' + this.currentPage)      
      
      this.ngZone.runOutsideAngular(() => {
        const lightbox = new PhotoSwipeLightbox({
           gallery: '#gallery--getting-started',
           children: 'a',
           pswpModule: PhotoSwipe,
           spacing: 0.5,
           loop: true,
           
          
         });
         
         lightbox.init();
         
       });

  }

  settings = {
    counter: false,
    plugins: [lgZoom]
  };

  share() {
    navigator.share({
      url: 'https://vabait.co/#/app/detalle/' + this.codigo,
    })
      .then(() => {
        alert('Compartido Correctamente');
      })
      .catch(() => {
        alert('no se pudo compartir, intenta nuevamente');
      });
  }

  buscar_Inmueble()
  {
 
   this._clienteService.obtener_propiedad_codigo_publico(this.codigo).subscribe(
   response  => {
     
     this.propiedad = response.data;
     this.lat = response.data.lat;
     this.lng = response.data.long;
     this.load_data = false
     this.url360 = response.data.url_360;
     
     if(this.propiedad.video != null && this.propiedad.video != "undefined" && this.propiedad.video !=""){
       this.mostrarVideo = true;
   
       var iframe = document.getElementById("frameVideo");

       iframe.setAttribute("src", `https://www.youtube.com/embed/${this.propiedad.video}?autoplay=1`);
     }

     if(this.propiedad.url_360 != null && this.propiedad.url_360 != "undefined" && this.propiedad.url_360 !=""){
      this.isUndefined = true;
  
      var iframe = document.getElementById("vista");

      iframe.setAttribute("src", this.url360);
    }

    this._clienteService.obtener_asesor(response.data.asesor).subscribe(asesor =>{
       this.asesor = asesor.data;

     })

     if(this.propiedad.galeria.length < 1){

      this.mostrarGaleria = false;
      this.controlGaleria = false;
      this.controlMapa = true;
       //let mapa = document.getElementById("pills-map-tab");
       //mapa.setAttribute("ariaSelected", "true")

      if(this.propiedad.lat == ""){
       this.controlMapa = false;
       this.mostrarMapa = false;

       if(!this.propiedad.video){
         this.controlVideo = false;
         this.mostrarVideo = false;
       }
       else {
         this.controlVideo = true;
       }

      }
      else{
       this.controlMapa = true;
       //let mapa = document.getElementById("pills-map-tab");
       //mapa.setAttribute("ariaSelected", "true")

       console.log(this.controlMapa)
      }
      console.log('no hay imagenes en galeria')
     }

     else {
       this.controlGaleria = true;
     }

     this.metaTagService.updateTag({name:'og:image',content:this.propiedad.portada})
     this.metaTagService.updateTag({property:'og:url',content:this.currentPage})
     this.metaTagService.updateTag({property:'og:image:secure_url',content:this.propiedad.portada})
     this.metaTagService.updateTag({property:'og:title',content:this.propiedad.tipo + ' ' + this.propiedad.direccion})
     this.metaTagService.updateTag({property:'og:description',content:this.propiedad.descripcion})


   },error =>{
     console.log(error);
   }

   
 )
}

  registro_consulta(consultaForm:any)
  {
    this.consulta.propiedad = this.propiedad._id
    if(consultaForm.valid)
    {
      this._clienteService.registrar_consulta_propiedad(this.consulta).subscribe(
        response  =>

        {
          this.socket.emit('new-message');
          Swal.fire({
            icon:'success',
            text:'Su mensaje ha sido enviado'
          })
        this.consulta = {
          texto: 'Me interesa esta propiedad y quiero recibir información.'
        };
        },
        error  =>
        {
          Swal.fire({
            icon:'error',
            text:'Complete correctamente el formulario'
          })
        }
      )
    }else{
      Swal.fire({
        icon:'error',
        text:'Complete correctamente el formulario'
      })
    }


  }

  modal(){
    this.showModal = !this.showModal;
  

  }
  hideModal(){
    this.showModal = false;
  }

}
