import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClienteService } from 'src/app/services/cliente.service';
import { FileItem } from 'src/app/models/file-item';
import { GetUsuarioService } from 'src/app/services/get-usuario.service';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';


@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {
  estaSobreElemento = false;
  public archivos: FileItem[] = [];
  public imagenes: FileItem[] = [];
  public contratos: Array<any> = []

  public token;
  public load_data = true;
  public usuario:any = {}
  public page = 1;
  public pageSize = 5;
  public pagos = [];
  public contrato;
  public disabled = true;
  public archivosPath = 'pago-canon';
  public mensaje ='Estás al día. El botón de pagos se habilitará nuevamente el siguiente mes';
  public documentoCliente = '';
  public fechapago = '';
  public opcionSeleccionada = 'Seleccione el cliente';
  public fechaseleccionada = 'Seleccione la fecha';
  public decodedToken;
  public pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/vabait-2b1fb.appspot.com/o/facturas%2Fjuanfe1694%40misena.com%2FDepartamento%20de%20Antioquia%20-%20Impuestos%20de%20veh%C3%ADculos%20_%20Webcheckout%20PlacetoPay.pdf?alt=media&token=7d1569dd-4a4d-414e-b3bb-9bd93d20b638';
  constructor(private _cliente: ClienteService,
    private _subirArchivo: CargaImagenesService,
    //private _getUsuario: GetUsuarioService
    ) { 


    }

  ngOnInit(): void {
 //this.obtener_usuario()
 this.token = localStorage.getItem('token');
 
 try {
   const helper = new JwtHelperService();
   this.decodedToken = helper.decodeToken(this.token);

 if(helper.isTokenExpired(this.token))
   {
       localStorage.clear();
       

   }

   if (!this.decodedToken) {
     console.log('no acceso')
     localStorage.removeItem('token');
     
   }
  

 } catch (error) {
   localStorage.removeItem('token');
 
 }
  


 this.obtener_contrato(this.decodedToken['email']);
    
  }

  obtener_usuario(){
    //this._getUsuario.usuario$.subscribe(usuario => {
      //this.usuario = usuario;
      console.log(this.usuario.email)
      
      
    }

    selectChange(){
      this.fechapago = this.fechaseleccionada;
      document.getElementById('pdf').setAttribute('src',this.pdfUrl)
    }

  

  obtener_contrato(usuario: any){
    this._cliente.obtener_contrato(usuario).then((querySnapshot) => {
      
      querySnapshot.forEach((doc) => {
           this.contrato = doc.data();
           console.log(this.contrato)
           
              
      });
      //this.obtener_tamano_imagenes();
      this.consultar_facturas(this.decodedToken['email']);  
  })
  .catch((error) => {
      console.log("Error obteniendo contrato: ", error);
  });
  }

  cargarDocumento()
  {
    this._subirArchivo.cargarArchivoPorFecha(this.archivos,this.decodedToken.email, this.archivosPath, this.fechapago)
  }

  limpiarArchivos(){
    this.archivos = [];
  }

  consultar_facturas(usuario: any){
    this._cliente.consultar_facturas(usuario).then((querySnapshot) => {
      
      querySnapshot.forEach((doc) => {
         this.pagos.push(doc.data());
       //console.log(this.pagos[0].fecha.toDate().toDateString());
       let mes = new Date(this.pagos[0].fecha.toDate())
       
      });
      let mesUltimoPago = new Date(this.pagos[0].fecha.toDate())
      let diaPago = new Date(this.contrato.fecha.toDate())
      let fechaHoy = new Date(Date.now())
      
      if(fechaHoy.getDate() >= diaPago.getDate() && mesUltimoPago.getMonth() != fechaHoy.getMonth()){
       
      this.disabled = false;
        this.mensaje = 'Puede realizar su pago dando clic en el botón Realizar pago'
    }
    for(let element of this.pagos){
        let fecha = new Date(element.fecha.toDate());
        let dia = fecha.getDate();
        let mes = fecha.toLocaleString('default', {month: 'long', });
        let ano = fecha.getFullYear();
        element.fecha =  dia + '/' + mes + '/' + ano;
      
    }

    this.load_data = false;
  })
  .catch((error) => {
      console.log("Error consultando facturas: ", error);
  });
  

  }
}
