<main id="main">
    <app-whatsappicon></app-whatsappicon>
    <!-- ======= Intro Single ======= -->
    <section class="intro-single">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-8">
                    <div class="title-single-box">
                        <h1 class="title-single">Nuestras propiedades</h1>
                        <!--<span class="color-text-a">Grid Properties</span>-->
                    </div>
                </div>
                <div class="col-md-12 col-lg-4">
                    <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/app/inicio']">Inicio</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                Todas las propiedades
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    <!-- End Intro Single-->

    <div class="container border rounded-3">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
            <select id="sort_by" class="form-select" [(ngModel)]="filter_tipo" style="max-width: 100%;" (change)="buscar_propiedad()">
              <option value="" selected>Todos los tipos de propiedades</option>
              <option *ngFor="let item of tipoconsulta" value="{{item.nombre}}" >{{item.nombre}}</option>
              
            </select>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3 mt-3">
                <div class="input-group">
                    <!--<input type="text" class="form-control" (keyup)="buscar_propiedad()" placeholder="Dirección" name="filter_propiedad" [(ngModel)]="filter_propiedad" aria-label="Recipient's username" aria-describedby="button-addon2">-->
                    <select id="ciudad" class="form-select" [(ngModel)]="filter_ciudad" (change)="buscar_propiedad()">
                        
                        <option value="" selected>Todas las ciudades</option>
                        <option *ngFor="let item of ciudadesconsulta" value="{{item.name}}">{{item.name}}</option>
                        
                      </select>
                    <!--<button class="btn " style="background-color: #91C151; color: white;" type="submit" (click)="buscar_propiedad()">Filtrar</button>-->
                </div>
            </div>




            <div class="d-flex justify-content-between">
                <div data-bs-toggle="collapse" data-bs-target="#advancedSearch" role="button" aria-expanded="false" aria-controls="advancedSearch" class="ms-1 advanced col-md-auto"> Busqueda avanzada <i class="fa fa-angle-down"></i> </div>
                <p><span> {{propiedades.length}}</span> Resultados</p>
            </div>
            <div class="collapse" id="advancedSearch">
                <div class="rounded-3 mb-3 row">
                    <div class="col-sm-6 col-md-3 mb-2 mt-2">
                        <select id="sort_by" class="form-select" [(ngModel)]="filter_oferta" (change)="buscar_propiedad()">
                <option value="">Venta/Arriendo</option>
                <option value="Venta">Venta</option>
                <option value="Renta">Arriendo</option>
                <option value="Proyecto">Proyecto</option>
              </select>
                    </div>

                    <div class="col-sm-6 col-md-3 mb-2 mt-2">
                        <select class="form-select" [(ngModel)]="filter_baths" (change)="buscar_propiedad()">
                <option value="">Baños</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
                    </div>
                    <div class="col-sm-6 col-md-3 mb-2 mt-2">
                        <select class="form-select" [(ngModel)]="filter_recamaras" (change)="buscar_propiedad()">
                <option value="">Habitaciones</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
                    </div>
                    <div class="col-sm-6 col-md-3 mb-2 mt-2">
                        <select class="form-select" id="btn-filtro" name="sort_by" [(ngModel)]="sort_by" (change)="orden_por()">
                <option value="" selected disabled>Ordenar por:</option>
                
                <option value="Defecto">Defecto</option>
                <option value="+-Precio" >Mayor - Menor precio</option>
                <option value="-+Precio" >Menor - Mayor precio</option>
                <option value="azTitulo">A - Z </option>
                <option value="zaTitulo">Z - A </option>
              </select>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <!-- ======= Property Grid ======= -->
    <section class="property-grid grid">
        <div class="container">
            <div class="row">
                <!--<div class="col-sm-12">
                    <div class="grid-option">
                        <form>
                            <select class="custom-select">
                  <option selected>All</option>
                  <option value="1">New to Old</option>
                  <option value="2">For Rent</option>
                  <option value="3">For Sale</option>
                </select>
                        </form>
                    </div>
                </div>-->
                <!--<div class="col-md-4" *ngFor="let item of propiedades | slice:(page-1) * pageSize: (page-1) * pageSize + pageSize; ">-->
                    <div class="col-md-4" *ngFor="let item of propiedades ">
                   <!-- <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img [src]="item.portada" width="100%" height="300" alt="" class="bd-placeholder-img card-img-top" style="object-fit: cover;">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h4 class="card-title-a">
                                        <a [routerLink]="['/app/detalle/',item.codigo]">{{item.titulo}}</a>
                                    </h4>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">{{item.precio|currency}}</span>
                                    </div>
                                    <a [routerLink]="['/app/detalle/',item.codigo]" class="link-a">Ver propiedad
                      <span class="bi bi-chevron-right"></span>
                    </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li class="text-center">
                                            <h4 class="card-info-title">Area</h4>
                                            <span class="justify-content-center">{{item.area}}m
                          <sup>2</sup>
                        </span>
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title ">Habitaciones</h4>
                                            <span class="text-center">{{item.habitaciones}}</span>
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title">Baños</h4>
                                            <span>{{item.banos}}</span>
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title">Parqueaderos</h4>
                                            <span class="text-center">{{item.parqueaderos}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img [src]="item.portada" width="100%" height="300" alt="" class="bd-placeholder-img card-img-top" style="object-fit: cover;">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h4 class="card-title-a">
                                        <a [routerLink]="['/app/detalle/',item.codigo]">{{item.titulo}}</a>
                                    </h4>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">{{item.precio|currency}}</span>
                                    </div>
                                    <a [routerLink]="['/app/detalle/',item.codigo]" class="link-a">Ver propiedad
                      <span class="bi bi-chevron-right"></span>
                        </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li class="text-center">
                                            <h4 class="card-info-title"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-aspect-ratio-fill" viewBox="0 0 16 16">
                                                <path d="M0 12.5v-9A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5zM2.5 4a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 1 0V5h2.5a.5.5 0 0 0 0-1h-3zm11 8a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-1 0V11h-2.5a.5.5 0 0 0 0 1h3z"/>
                                              </svg>

                                              <span *ngIf="item.area > 0; then area   else areaConstruida" class="justify-content-center"></span>
                                                <ng-template #area > {{ item.area}}m <sup>2</sup> </ng-template>
                                                <ng-template #areaConstruida> {{ item.area_construida}}m <sup>2</sup> </ng-template>
                                            </h4>
                                            
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title "><i class="fa fa-bed"> </i>
                                                <span class="text-center"> {{ item.habitaciones }} </span>
                                            </h4>
                                            
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title"><i class="fa fa-bath"> </i>
                                                <span> {{ item.banos }}</span>
                                            </h4>
                                            
                                        </li>
                                        <li class="text-center">
                                            <h4 class="card-info-title"> <i class="fa fa-car"></i>
                                              <span class="text-center"> {{ item.parqueaderos }} </span>
                                            </h4>
                                            
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="col-md-4">
                    <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img src="assets/img/property-3.jpg" alt="" class="img-a img-fluid">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h2 class="card-title-a">
                                        <a href="#">204 Mount
                        <br /> Olive Road Two</a>
                                    </h2>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">rent | $ 12.000</span>
                                    </div>
                                    <a href="property-single.html" class="link-a">Click here to view
                      <span class="bi bi-chevron-right"></span>
                    </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li>
                                            <h4 class="card-info-title">Area</h4>
                                            <span>340m
                          <sup>2</sup>
                        </span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Beds</h4>
                                            <span>2</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Baths</h4>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Garages</h4>
                                            <span>1</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img src="assets/img/property-6.jpg" alt="" class="img-a img-fluid">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h2 class="card-title-a">
                                        <a href="#">204 Mount
                        <br /> Olive Road Two</a>
                                    </h2>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">rent | $ 12.000</span>
                                    </div>
                                    <a href="property-single.html" class="link-a">Click here to view
                      <span class="bi bi-chevron-right"></span>
                    </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li>
                                            <h4 class="card-info-title">Area</h4>
                                            <span>340m
                          <sup>2</sup>
                        </span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Beds</h4>
                                            <span>2</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Baths</h4>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Garages</h4>
                                            <span>1</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img src="assets/img/property-7.jpg" alt="" class="img-a img-fluid">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h2 class="card-title-a">
                                        <a href="#">204 Mount
                        <br /> Olive Road Two</a>
                                    </h2>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">rent | $ 12.000</span>
                                    </div>
                                    <a href="property-single.html" class="link-a">Click here to view
                      <span class="bi bi-chevron-right"></span>
                    </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li>
                                            <h4 class="card-info-title">Area</h4>
                                            <span>340m
                          <sup>2</sup>
                        </span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Beds</h4>
                                            <span>2</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Baths</h4>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Garages</h4>
                                            <span>1</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img src="assets/img/property-8.jpg" alt="" class="img-a img-fluid">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h2 class="card-title-a">
                                        <a href="#">204 Mount
                        <br /> Olive Road Two</a>
                                    </h2>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">rent | $ 12.000</span>
                                    </div>
                                    <a href="property-single.html" class="link-a">Click here to view
                      <span class="bi bi-chevron-right"></span>
                    </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li>
                                            <h4 class="card-info-title">Area</h4>
                                            <span>340m
                          <sup>2</sup>
                        </span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Beds</h4>
                                            <span>2</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Baths</h4>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Garages</h4>
                                            <span>1</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card-box-a card-shadow">
                        <div class="img-box-a">
                            <img src="assets/img/property-10.jpg" alt="" class="img-a img-fluid">
                        </div>
                        <div class="card-overlay">
                            <div class="card-overlay-a-content">
                                <div class="card-header-a">
                                    <h2 class="card-title-a">
                                        <a href="#">204 Mount
                        <br /> Olive Road Two</a>
                                    </h2>
                                </div>
                                <div class="card-body-a">
                                    <div class="price-box d-flex">
                                        <span class="price-a">rent | $ 12.000</span>
                                    </div>
                                    <a href="property-single.html" class="link-a">Click here to view
                      <span class="bi bi-chevron-right"></span>
                    </a>
                                </div>
                                <div class="card-footer-a">
                                    <ul class="card-info d-flex justify-content-around">
                                        <li>
                                            <h4 class="card-info-title">Area</h4>
                                            <span>340m
                          <sup>2</sup>
                        </span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Beds</h4>
                                            <span>2</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Baths</h4>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <h4 class="card-info-title">Garages</h4>
                                            <span>1</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
            
            <!--<div class="row">
                <div class="col-sm-12">
                    <nav class="pagination-a">
                        <ul class="pagination justify-content-end">
                            <li class="page-item disabled">
                                <a class="page-link"  tabindex="-1">
                                    <span class="bi bi-chevron-left"></span>
                                </a>
                            </li>
                            <li  class="page-item ">
                                <a class="page-link " > 1 </a>
                            </li>

                            <li class="page-item next">
                                <a class="page-link" >
                                    <span class="bi bi-chevron-right"></span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>-->
        </div>
    </section>
    <!-- End Property Grid Single-->

</main>
<!-- End #main -->