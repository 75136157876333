import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  config: SwiperOptions = {
    pagination: { 
      el: '.swiper-pagination', 
      clickable: true
    },
    slidesPerView: "auto",
    spaceBetween: 10,
    loop:false,
    breakpoints: {
      320: {
          slidesPerView: 1,
          spaceBetween: 20
      },

      768: {
          slidesPerView: 2,
          spaceBetween: 10

      },
      1200:{
        slidesPerView: 3,
          spaceBetween: 10
      }
    }
  
  };

  constructor() { }

  ngOnInit(): void {
  }

}
