<div class="container">
    <div class="row">
        <div class="text-center col-12 pt-3">
            <h1>Bienvenido {{admin.nombres}} {{admin.apellidos}} </h1>
        </div>

    </div>

    <div class="row">
        <div class="col-12">



        </div>
    </div>
    <router-outlet></router-outlet>
</div>