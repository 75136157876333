<main id="main">


    <app-whatsappicon></app-whatsappicon>
    <!-- ======= Intro Single ======= -->
    <section class="intro-single pb-0">
        <div class="container">
            <div class="row flex-wrap-reverse">
                <div class="col-md-12 col-lg-8">
                    <div class="title-single-box">
                        <h1 class="title-single">{{propiedad.titulo}}</h1>
                        <span class="color-text-a h-425">{{propiedad.ciudad}}, Codigo:  {{propiedad.codigo}}</span>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4">
                    <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/app/inicio']">Inicio</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/app/inmuebles/']">Propiedades</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                {{propiedad.codigo}}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    <!-- End Intro Single-->

    <!-- ======= Property Single ======= -->
    <section class="property-single nav-arrow-b">
        <div class="container">


            <div class="row">
                <!--Inicio audiovisual-->
                <div class="col-md-10 offset-md-1">
                    <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button *ngIf="mostrarGaleria" class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="false">Galeria</button>
                        </li>
                        <li class="nav-item" *ngIf="isUndefined">
                            <!--<a class="nav-link" id="pills-plans-tab" data-bs-toggle="pill" href="#pills-plans" role="tab" aria-controls="pills-plans" aria-selected="false" (click)="modal()">Recorrido virtual</a>-->
                            <a class="nav-link" id="pills-plans-tab" data-bs-toggle="pill" role="tab" aria-controls="pills-plans" aria-selected="false" (click)="modal()" style="cursor: pointer;">Recorrido virtual</a>
                        </li>
                        <li class="nav-item" *ngIf="mostrarMapa">
                            <a class="nav-link" [ngClass]="{'active': controlMapa}" id="pills-map-tab" data-bs-toggle="pill" href="#pills-map" role="tab" aria-controls="pills-map" [attr.aria-selected]="controlMapa" >Ubicacion</a>
                        </li>
                        <li class="nav-item" *ngIf="mostrarVideo">
                            <a  class="nav-link "  id="pills-video-tab" data-bs-toggle="pill" href="#pills-video" role="tab" aria-controls="pills-video" [attr.aria-selected]="controlVideo">Video</a>
                          </li>

                    </ul>
                    <div  class="tab-content" id="pills-tabContent" >
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" >
                            <!--<div id="property-single-carousel" class="owl-carousel owl-arrow gallery-property">-->
                            <ng-container >
                                <swiper [config]="config ">

                                    <div class="swiper-wrapper" id="gallery--getting-started">
                                        
                                        <a *ngFor="let item of propiedad.galeria; let indice=index " 
                                                class="swiper-slide "
                                                [href]="item.imagen"
                                                data-pswp-width = "1620"
                                                data-pswp-height="1080"
                                                target="_blank"
                                                
                                            >
                                            <img 

                                            [src]="item.imagen " 
                                            alt=" img 1 " 
                                            width="100% " 
                                            height="500 " 
                                            alt=" " 
                                            class="bd-placeholder-img card-img-top img-swiper-gallery" 
                                            >
                                            </a>
                                            </div>
                                    

                                    
                                    <div class="swiper-pagination "></div>

                                  
                                    <div class="swiper-button-next "></div>
                                    <div class="swiper-button-prev "></div>

                                </swiper>
                                                          
                            </ng-container>

                            <!--</div>-->
                        </div>
                        <div class="tab-pane fade" id="pills-plans" role="tabpanel" aria-labelledby="pills-plans-tab">
                            <!--<iframe id="vista" style="border: 0; width: 100%; height: 720px;" [src]='vista360' width="300" height="150"></iframe>-->
                        </div>
                        <div class="tab-pane fade" [ngClass]="{'active': controlMapa, 'show':controlMapa}" id="pills-map" role="tabpanel" aria-labelledby="pills-map-tab">
                            <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.1422937950147!2d-73.98731968482413!3d40.75889497932681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25855c6480299%3A0x55194ec5a1ae072e!2sTimes+Square!5e0!3m2!1ses-419!2sve!4v1510329142834"
                                width="100%" height="460" frameborder="0" style="border:0" allowfullscreen>
                                </iframe>-->

                            <div class="map-responsive pb-5">
                                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="18" [scrollwheel]="null">



                                    <agm-map-type-control></agm-map-type-control>

                                    <agm-marker [latitude]="propiedad.lat" [longitude]="propiedad.long">
                                        <agm-info-window>
                                            <div class="text-center">
                                                <p class="text-center fw-bold text-dark text-truncate" style="color:#010158;"> {{propiedad.tipo}} en {{propiedad.direccion}}</p>
                                                <p class="text-center fw-bold" style="color:#010158;"> {{propiedad.precio |currency:"MXN":"symbol"}} </p>
                                                <img class="img-fluid2 text-center" [src]="propiedad.portada" alt="">

                                            </div>
                                        </agm-info-window>
                                    </agm-marker>
                                </agm-map>
                            </div>
                        </div>
                        <div  class="tab-pane fade " id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab">
                            <iframe id="frameVideo" [src]="video" width="100%" height="460" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            
                        <!--<video [src]="propiedad.video" controls autoplay></video> -->
                    </div>
                    </div>
                </div>

                <!--Final audiovisual-->

                <div class="col-sm-12 h-mt-5-425">
                    <div class="title-box-d section-t4 pt-0">
                        <h3 class="title-d">Descripción De La Propiedad</h3>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-md-5 col-lg-4">
                            <div class="property-summary">
                                    
                                <div class="summary-list">
                                    <ul class="list">
                                        <li class="d-flex justify-content-between h-fs-05">
                                            <strong>Codigo:</strong>
                                            <span>{{propiedad.codigo}}</span>
                                        </li>
                                        <li class="d-flex justify-content-between h-fs-05">
                                            <strong>Ubicacion:</strong>
                                            <span>{{propiedad.ciudad}}</span>
                                        </li>
                                        <li class="d-flex justify-content-between h-fs-05">
                                            <strong>Tipo De Propiedad:</strong>
                                            <span>{{propiedad.tipo}}</span>
                                        </li>
                                        <li class="d-flex justify-content-between h-fs-05">
                                            <strong>Oferta:</strong>
                                            <span>{{propiedad.oferta}}</span>
                                        </li>
                                        <li *ngIf="propiedad.area > 0" class="d-flex justify-content-between h-fs-05">
                                            <strong>Area total:</strong>
                                            <span>{{propiedad.area}}m
                                                <sup>2</sup>
                                            </span>
                                        </li>
                                        <li *ngIf="propiedad.area_construida > 0" class="d-flex justify-content-between h-fs-05">
                                            <strong>Area construida:</strong>
                                            <span>{{propiedad.area_construida}}m
                                                <sup>2</sup>
                                            </span>
                                        </li>
                                        <li class="d-flex justify-content-between h-fs-05">
                                            <strong>Habitaciones:</strong>
                                            <span>{{propiedad.habitaciones}}</span>
                                        </li>
                                        <li class="d-flex justify-content-between h-fs-05">
                                            <strong>Baños:</strong>
                                            <span>{{propiedad.banos}}</span>
                                        </li>
                                        <li class="d-flex justify-content-between h-fs-05">
                                            <strong>Parqueaderos:</strong>
                                            <span>{{propiedad.parqueaderos}}</span>
                                        </li>
                                        <li class="d-flex justify-content-between h-fs-05">
                                            <strong>Precio</strong>
                                            <span>{{propiedad.precio|currency}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-7 col-lg-7 section-md-t3">
                            <div class="row">
                                <!--<div class="col-sm-12">
                                    <div class="title-box-d">
                                        <h3 class="title-d">Descripción De La Propiedad</h3>
                                    </div>
                                </div>-->
                            </div>
                            <div class="property-description">
                                <p class="description color-text-a" [innerHTML]="propiedad.descripcion">
                                    <!--{{propiedad.descripcion}}-->
                                </p>
                            </div>
                            <!--
                            <div class="row section-t3">
                                <div class="col-sm-12">
                                    <div class="title-box-d">
                                        <h3 class="title-d">Comodidades</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="amenities-list color-text-a">
                                <ul class="list-a no-margin">
                                    <li>Balcon</li>
                                    <li>Caseta </li>
                                    <li>Tv Por Cable</li>
                                    <li>Internet</li>
                                    <li>Parqueadero</li>
                                    <li>Espacio Libre</li>
                                    <li>Vigilancia las 24 horas</li>
                                </ul>
                            </div>-->
                        </div>
                    </div>
                </div>

                <button class="btn btn-enviar mr-3 " id="button" (click)="share()">Compartir</button>
                <br/>
                
                <div class="col-md-12">
                    <div class="row section-t3">
                        <div class="col-sm-12">
                            <div class="title-box-d">
                                <h3 class="title-d">Contactar Asesor</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-4">
                            <img [src]="asesor.foto" alt="" class="img-fluid">
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="property-agent">
                                <h4 class="title-agent"> {{ asesor.nombre }} </h4>
                                <p class="color-text-a">
                                {{ asesor.presentacion }}
                                </p>
                                <ul class="list-unstyled">
                                    <li class="d-flex justify-content-between">
                                        <strong>Celular:</strong>
                                        <span class="color-text-a"> {{ asesor.celular }} </span>
                                    </li>
                                    <li class="d-flex justify-content-between">
                                        <strong>Email:</strong>
                                        <span class="color-text-a"> {{ asesor.correo }} </span>
                                    </li>
                                </ul>
                                <div class="socials-a">
                                    <ul class="list-inline">
                                        <li class="list-inline-item">
                                            <a href="https://www.facebook.com/vabaitinmobiliaria">
                                                <i class="bi bi-facebook" aria-hidden="true" style="font-size: 2rem;"></i>
                                            </a>
                                        </li>
                                        <!--<li class="list-inline-item">
                                            <a href="#">
                                                <i class="bi bi-twitter" aria-hidden="true"></i>
                                            </a>
                                        </li>-->
                                        <li class="list-inline-item">
                                            <a href="https://www.instagram.com/vabaitinmobiliaria/?igshid=YmMyMTA2M2Y=">
                                                <i class="bi bi-instagram" aria-hidden="true" style="font-size: 2rem;"></i>
                                            </a>
                                        </li>
                                        <!--<li class="list-inline-item">
                                            <a href="#">
                                                <i class="bi bi-linkedin" aria-hidden="true"></i>
                                            </a>
                                        </li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="property-contact">
                                <form #consultaForm="ngForm" (ngSubmit)="registro_consulta(consultaForm)">
                                    <div class="row">

                                        <div class="col-md-12 mb-3">
                                            <div class="form-group">
                                                <input type="text" name="nombre" required [(ngModel)]="consulta.nombres" class="form-control form-control-lg form-control-a" placeholder="Nombre" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                                                <div class="validation"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <div class="form-group">
                                                <input name="email" type="email" required [(ngModel)]="consulta.email" class="form-control form-control-lg form-control-a" pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                    placeholder="Correo" data-rule="email" data-msg="Please enter a valid email">
                                                <div class="validation"></div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 mb-3">
                                            <div class="form-group">
                                                <input type="url" name="subject" required [(ngModel)]="consulta.telefono" class="form-control form-control-lg form-control-a" placeholder="Teléfono" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject">
                                                <div class="validation"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <div class="form-group">
                                                <textarea name="message" required class="form-control" [(ngModel)]="consulta.texto" name="message" cols="45" rows="6" data-rule="required" data-msg="Please write something for us" placeholder="Mensaje"></textarea>
                                                <div class="validation"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <button type="submit" class="btn btn-a">Enviar mensaje</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section [ngClass]="{'modal': !showModal, 'modal-show': showModal}" id="target">
        <div class="modal_container">
            <a class="modal_close" (click)="hideModal()">X</a>

            <iframe id="vista" style="border: 0; width: 100%; height: 100%; position: absolute;" [src]='url360'></iframe>



            <!--<img [src]="propiedad.galeria[0].imagen" width="50%" height="50%">-->
            <!--<iframe src="http://localhost.com:4200" width="100%" height="100%" frameborder="0"></iframe>-->
        </div>

    </section>
    <!-- End Property Single-->

</main>
<!-- End #main -->