import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { GLOBAL, GLOBALSOCKET } from 'src/app/services/GLOBAL';
import { PropiedadService } from 'src/app/services/propiedad.service';
import { io } from "socket.io-client";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataModal } from '../../clases/data-modal';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import Swal from 'sweetalert2';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cambiar-password',
  templateUrl: './cambiar-password.component.html',
  styleUrls: ['./cambiar-password.component.css']
})
export class CambiarPasswordComponent implements OnInit {

  public load_btn;
  public token;
  public url;
  public load_data;
  public user: any={};
  public usuario: any = {};
  public isAdmin=false ;
  public isAsesor=false ;
  public isCliente =false;
  public isPropietario =false;
  public isMovil = false;
  public emailuser = "";
  public socket = io(GLOBALSOCKET.url,{transports: ['websocket'], upgrade: false});

  constructor(
    private _adminService: AdminService,
    public dialog: MatDialog,
    private _router : Router,
  ) {

    this.token = localStorage.getItem('token');

    try {
      const helper = new JwtHelperService();
      var decodedToken = helper.decodeToken(this.token);

    if(helper.isTokenExpired(this.token))
      {
          localStorage.clear();
          

      }

      if (!decodedToken) {
        console.log('no acceso')
        _router.navigate(['/login'])
        localStorage.removeItem('token');
        
      }
     

    } catch (error) {
      localStorage.removeItem('token');
    
    }
     
 
    this.validar_rol(decodedToken['rol'])
    this.user.nombres = decodedToken['nombres']
    this.user.apellidos = decodedToken['apellidos']
    this.emailuser = decodedToken['email']

    console.log(this.emailuser)
  
   }

  ngOnInit(): void {
    this.obtener_admin();
  }

  obtener_admin()
  {
    this._adminService.obtener_admin(this.token).subscribe(
       response => 
      {
         this.usuario =   response.data;
        // this._getUsuario.emitirUsuario(this.usuario)
         //this.setUsuario.emit(this.usuario);
       // this.validar_rol(this.user.rol)
      }
    )
  }


  validar_rol(rol: any){
    
    switch(rol){
      case "admin":
        this.isAdmin = true;
        break;
        case "cliente":
        this.isCliente = true;
        //this._router.navigate(['/inicio/inicio_clientes'])
        //this._router.navigate(['/inicio/documentos'])
        break;
        case "asesor":
        this.isAsesor = true;
        break;
        case "propietario":
        this.isPropietario = true;
        break;
    }
  
 
  }

  changepsw(cambiarpasswordform: any) {
    this.load_btn = true;

    if (cambiarpasswordform.valid) {

      let validatepassword = {
        oldpassword: this.user.oldpassword,
        password: this.user.password,
        confirmpassword: this.user.confirmpassword
      }

      console.log(validatepassword)
      
      if(validatepassword.oldpassword == validatepassword.password){
        Swal.fire({
          icon:'error',
          text:'La nueva contraseña no puede ser igual a la contraseña actual'
        })
      } else {

        if(validatepassword.password == validatepassword.confirmpassword){

          let data = {
            email: this.emailuser,
            password: this.user.password
          }

          console.log(data)

          if(this.isAdmin == true || this.isAsesor){

            this._adminService.actualizar_pass_admin(data,this.token).subscribe(
              response => {
                Swal.fire({
                  icon:'success',
                  text:'Contraseña actualizada correctamente'
                });
                this.load_btn = false;

                if(this.isAdmin== true){
                  this._router.navigate(['/panel/BI']);
                }else {
                  this._router.navigate(['/panel/propiedades']);
                }

                
              },
              error => {
                Swal.fire({
                  icon:'error',
                  text:'No fue posible actualizar, por favor contactar al equipo de soporte'
                })
              }
            )

          }else{
            console.log(data)
            this._adminService.actualizar_pass_user(data,this.token).subscribe(
              response => {
                Swal.fire({
                  icon:'success',
                  text:'Contraseña actualizada correctamente'
                });
                this.load_btn = false;
                
                if(this.isPropietario== true){
                  this._router.navigate(['/panel/requerimientos']);
                }else {
                  this._router.navigate(['/panel/pagos']);
                }
              },
              error => {
                Swal.fire({
                  icon:'error',
                  text:'No fue posible actualizar, por favor contactar al equipo de soporte'
                })
              }
            )

          }
  
  
  
        } else {
          Swal.fire({
          icon:'error',
          text:'Las contraseñas no coinciden'
        })
        }
      }

      this.load_btn = false;

    } else {
      Swal.fire({
        icon:'error',
        text:'Debe escribir el correo, la contraseña anterior, contraseña nueva y confirmar la contraseña'
      })
      this.load_btn = false;
    }
  }

}
