import { Component, OnInit,ChangeDetectorRef,NgZone,ViewChild, ElementRef, Input, Output } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';


declare var funcion: any;


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  public loading = true;
  public deferredPrompt: any;
  public isCardVisible = true;


  constructor(private clienteService: ClienteService,
              private adminService: AdminService,
              private globalService: GlobalServicesService) {
                this.deferredPrompt = this.globalService.displayInstallCard;
               }

  ngOnInit(): void {
    console.log(this.isCardVisible);
    
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('listener')
      e.preventDefault();
      this.deferredPrompt = e;
      console.log(this.deferredPrompt);
      // Muestra el botón de instalación o realiza alguna otra acción para notificar al usuario.
    });
       this.adminService.consultarVisitas(); 
        
       let isLoaded = document.querySelector('app-mapa');
       /*if(isLoaded){
        this.loading = false;
       }*/
       console.log(isLoaded.innerHTML)
  }

  public installPWA() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('El usuario ha aceptado la instalación');
        }
        this.deferredPrompt = null;
      });
    }
  }

  public closeCard(){
    console.log('close')
    this.deferredPrompt = null;
    console.log(this.deferredPrompt);
  }

  /* Cuando se carga el documento llama la funcion que inicializa swiper slider */

  ngAfterViewInit() {
    funcion();

  }
}
