import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import {CommonModule} from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { FileItem } from 'src/app/models/file-item';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
import Swal from 'sweetalert2';

declare var iziToast:any

@Component({
  selector: 'app-mostrar-pagos-canon',
  templateUrl: './mostrar-pagos-canon.component.html',
  styleUrls: ['./mostrar-pagos-canon.component.css']
})
export class MostrarPagosCanonComponent implements OnInit {

  estaSobreElemento = false;
  public archivos: FileItem[] = [];
  public imagenes: Array<any> = []
  public contratos: Array<any> = []
  //public files: File[] = [];
  public token;
  public contrato;
  public usuario:any = {}
  public dataSource = []
  public load_data = true;
  public users : Array<any> = [];
  public clientes : Array<any> = [];
  public page = 1;
  public pageSize = 5;
  public imagesPath = 'imagenes';
  public archivosPath = 'Contratos';
  public documentoCliente = '';
  public fecha = '';
  public opcionSeleccionada = 'Seleccione el cliente';
  public fechaseleccionada = 'Fecha Seleccionada';
  public esactivo = false;
  //public pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/vabait-2b1fb.appspot.com/o/facturas%2Fjuanfe1694%40misena.com%2FDepartamento%20de%20Antioquia%20-%20Impuestos%20de%20veh%C3%ADculos%20_%20Webcheckout%20PlacetoPay.pdf?alt=media&token=7d1569dd-4a4d-414e-b3bb-9bd93d20b638';
  public pdfUrl;
 // pdfExample ="../../../../assets/pdfs/CONTRATO 100V-28 APTO 101 TORRE 8 JUAN PABLO II-1-9.pdf"

  constructor(private _usuarioService : UsuarioService,
    private _imagenesService: CargaImagenesService,
    private _ClienteService: ClienteService,
    private _subirArchivo: CargaImagenesService) {
      this.token = localStorage.getItem('token')
     }

  ngOnInit(): void {
    this.listar_usuario();
  }

  selectChange(){
    this.documentoCliente = this.opcionSeleccionada;

  }

  selectChangeData(){
    this.fecha = this.fechaseleccionada;
    //console.log(this.documentoCliente);
    //console.log(this.fecha);
    this.obtener_imagenes_canon();
    this.validar_imagenes();
  }


  filtrarClientes(usuarios: Array<any>){
    for(let usuario of usuarios){

      if(usuario.rol == 'cliente'){
        this.clientes.push(usuario);
      }
    }
  
  }
  
    listar_usuario()
    {
      this._usuarioService.listar_usuario(this.token).subscribe(
        response =>{
          this.users = response.data;
          this.load_data = false;
          this.filtrarClientes(this.users);
        },
        error =>{
          console.log(error);
        }
      )
    }

  obtener_inventario(){
    this._imagenesService.obtener_inventario(this.opcionSeleccionada).then(snapshot =>{
    snapshot.forEach(element => {
      this.pdfUrl = element.data().url;
      console.log(this.opcionSeleccionada)
    });
    })
  }

  obtener_pagocanon(){
    this._imagenesService.obtener_pago_canon(this.opcionSeleccionada,this.fecha).then(snapshot =>{
    snapshot.forEach(element => {
      this.pdfUrl = element.data().url;
    });
    if (this.pdfUrl==null){
      iziToast.show({
        title: '¡Alerta!',
        titleColor: '#1DC74C',
        class: 'text-alert',
        color:'#FFF',
        position: 'topRight',
        message:'No hay informacion de pago para este cliente con esta fecha'
      })
    }
    })
  }

  obtener_imagenes(){
    this._imagenesService.obtener_imagenes(this.opcionSeleccionada)
    .then((querySnapshot) => {
        
      querySnapshot.forEach((doc) => {
            
            
          this.imagenes.push(doc.data());
            //console.log("imagenes");
            //console.log(this.imagenes);
            
            
      });
        this.obtener_tamano_imagenes();
        //this.cargar_inventario = false;
  })
  .catch((error) => {
      console.log("Error getting documents: ", error);
  });
  }

  obtener_imagenes_canon(){
    this._imagenesService.obtener_pago_canon(this.opcionSeleccionada, this.fecha)
    .then((querySnapshot) => {
        
      querySnapshot.forEach((doc) => {
            
            
          this.imagenes.push(doc.data());
            //console.log("imagenes");
            //console.log(this.imagenes); 
      });
        //this.obtener_tamano_imagenes();
        //this.cargar_inventario = false;
        //this.validar_imagenes();
  })
  .catch((error) => {
      console.log("Error getting documents: ", error);
  });
  }

  validar_imagenes(){
    if (this.imagenes = []){
      iziToast.show({
        title: '¡Alerta!',
        titleColor: '#1DC74C',
        class: 'text-alert',
        color:'#FFF',
        position: 'topRight',
        message:'Si no carga la imagen automaticamente, es porque no hay informacion de pago para este cliente con esta fecha'
      })
    }
  }

  obtener_tamano_imagenes(){
    for(let imagen of this.imagenes){
       let foto = new Image(200,300);
      
      //document.images[0].src=foto.src;
      foto.src = imagen.url;
      let ancho = foto.naturalWidth;
      let alto = foto.naturalHeight;
      //let ancho = document.images[0].naturalWidth;
      //let alto =  document.images[0].naturalHeight;
      imagen.ancho = ancho;
      imagen.alto = alto;
      let image = {
        src: imagen.url,
        width: ancho,
        height: alto,
        alt: imagen.nombre
      }
      this.dataSource.push(image)
      //console.log(this.dataSource)
    }
    //Se inicializa el lightgallery una vez el arreglo de imagenes esté completo
  
  }


  
    
    eliminar(id:any)
    {
      this._usuarioService.eliminar_usuario(id,this.token).subscribe(
        response =>{
          iziToast.show({
            title: 'Success',
            titleColor: '#1DC74C',
            class: 'text-success',
            color:'#FFF',
            position: 'topRight',
            message:'Usuario eliminado correctamente'
         
      });
      this.listar_usuario();
        },
        error  =>{
          console.log(error);
        }
  
      )
    }
    

    obtener_contrato(){
      this._imagenesService.obtener_contrato(this.usuario.email)
      .then((querySnapshot) => {
        
        querySnapshot.forEach((doc) => {
            
            
            this.contratos.push(doc.data());

            
        });

    })
    .catch((error) => {
      Swal.fire({
        icon:'error',
        text:'Error obteniendo el contrato del cliente, este error puede generarse porque actualmente el cliente no tiene ningun contrato asignado'
      });
    });
    }

}
