import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-install-app',
  templateUrl: './install-app.component.html',
  styleUrls: ['./install-app.component.css']
})

export class InstallAppComponent implements OnInit {
 
  public displayCard: any;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() installEvent: EventEmitter<void> = new EventEmitter<void>();

  closeChild() {
    this.closeEvent.emit();
  }

  installApp() {
    this.installEvent.emit();
  }

  constructor() {}

  ngOnInit(): void {

  }

}
