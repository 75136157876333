import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CalendarDataModal } from '../../clases/data-modal';

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.css']
})
export class CalendarDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CalendarDialogComponent>,
    @ Inject(MAT_DIALOG_DATA) public data: CalendarDataModal
  ) { }

  ngOnInit(): void {
    console.log("Action" + this.data.action);
    console.log("Event" + this.data.event);
  }

  cancelar() {
   this.dialogRef.close();
  }

}