<div class="card-header card-header border-bottom  mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-3">Business Intelligence</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <p class="text-muted">Este modulo podras ver los reportes y realizar Business Intelligence, lo que en español llamamos como inteligencia de negocio y poder tomar las mejores decisiones en tu día a día</p>

    </div>
</div>

<div class="card box-shadow-lg" style="width:auto;">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;"></h5>
        <a>Total De visitas a la página</a>
    </div>

    <div class="card-body">

        <ngx-charts-advanced-pie-chart
            [view]="[800, 400]"
            [scheme]="colorScheme"
            [results]="data"
            [gradient]="gradient"
            (select)="onSelect2($event)"
            (activate)="onActivate2($event)"
            (deactivate)="onDeactivate2($event)"
            >
            </ngx-charts-advanced-pie-chart>
    </div>

    <div class="card-header">
        <h5 style="margin-bottom: 0px;"></h5>
        <a>Total De Clientes Y Propietarios</a>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <div class="table-responsive">
                <ngx-charts-line-chart
                [view]="view"
                [scheme]="colorScheme"
                [legend]="legend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [timeline]="timeline"
                [results]="multi"
                (select)="onSelect($event)"
                (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)"
                >
                </ngx-charts-line-chart>
            </div>
        </div>
    </div>



</div>